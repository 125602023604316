import { BASE_URL } from "./Authservice";

export const SERVICE = {
  ACCESIBLEBRANCHALL_DOCUMENTPREPARATION: `${BASE_URL}/api/accessiblebranchdocumentpreparations`,
  ACCESSIBLEBRANCHALL_COMPANY_DOCUMENTPREPARATION: `${BASE_URL}/api/accessiblebranchcompanydocumentpreparations`,
  ACCESSIBLEBRANCHALL_TEMPLATECREATION: `${BASE_URL}/api/accessibletemplatecreations`,
  ACCESSIBLETEMPLATECONTROLPANEL: `${BASE_URL}/api/accessibletemplatecontrolpanel`,
  ACCOUNTGROUP: `${BASE_URL}/api/accountgroups`,
  ACCOUNTGROUP_CREATE: `${BASE_URL}/api/accountgroup/new`,
  ACCOUNTGROUP_SINGLE: `${BASE_URL}/api/accountgroup`,
  ACCOUNTHEAD: `${BASE_URL}/api/accounts`,
  ACCOUNTHEAD_CREATE: `${BASE_URL}/api/account/new`,
  ACCOUNTHEAD_SINGLE: `${BASE_URL}/api/account`,
  ACCURACYMASTERGETALL: `${BASE_URL}/api/accuracymaster`,
  ACCURACYMASTER_CREATE: `${BASE_URL}/api/accuracymaster/new`,
  ACCURACYQUEUEGROUPING: `${BASE_URL}/api/accuracyqueuegroupings`,
  ACCURACYQUEUEGROUPING_CREATE: `${BASE_URL}/api/accuracyqueuegrouping/new`,
  ACCURACYQUEUEGROUPING_SINGLE: `${BASE_URL}/api/accuracyqueuegrouping`,
  ACCURACY_HOME: `${BASE_URL}/api/acheivedaccuracyindividualhome`,
  ACHEIVEDACCURACYCLIENTGETALL: `${BASE_URL}/api/acheivedaccuracyclient`,
  ACHEIVEDACCURACYCLIENT_CREATE: `${BASE_URL}/api/acheivedaccuracyclient/new`,
  ACHEIVEDACCURACYCLIENT_SINGLEBYDETAILS: `${BASE_URL}/api/acheivedaccuracyclient/single`,
  ACHEIVEDACCURACYGETALL: `${BASE_URL}/api/acheivedaccuracy`,
  ACHEIVEDACCURACYGETALL_SORT: `${BASE_URL}/api/acheivedaccuracysort`,
  ACHEIVEDACCURACYINDIVIDUAL_SORT: `${BASE_URL}/api/achievedaccuracyindividualsort`,
  ACHEIVEDACCURACYINTERNALGETALL: `${BASE_URL}/api/acheivedaccuracyinternal`,
  ACHEIVEDACCURACYINTERNAL_CREATE: `${BASE_URL}/api/acheivedaccuracyinternal/new`,
  ACHEIVEDACCURACYINTERNAL_SINGLEBYDETAILS: `${BASE_URL}/api/acheivedaccuracyinternal/single`,
  ACHEIVEDACCURACY_CREATE: `${BASE_URL}/api/acheivedaccuracy/new`,
  ACHEIVEDACCURACY_SINGLEBYDETAILS: `${BASE_URL}/api/acheivedaccuracy/single`,
  ACHIEVEDACCURACYINDIVIDUALFILTER: `${BASE_URL}/api/acheivedaccuracyindividual`,
  ACPOINTCALCULATION: `${BASE_URL}/api/acpointcalculation`,
  ACPOINTCALCULATIONASSIGNBRANCH: `${BASE_URL}/api/acpointcalculationassignbranch`,
  ACPOINTCALCULATION_CREATE: `${BASE_URL}/api/acpointcalculation/new`,
  ACPOINTCALCULATION_SORT: `${BASE_URL}/api/acpointcalculationsort`,
  ACTIVEALL_PASSWORD: `${BASE_URL}/api/activeallpasswords`,
  ACTIVEALL_PASSWORD_ACCESS: `${BASE_URL}/api/activeallpasswordsaccess`,
  ACTIVEAPPLYLEAVE: `${BASE_URL}/api/activeuserapplyleaves`,
  ACTIVECANDIDATES: `${BASE_URL}/api/activecandidates`,
  ACTIVEPERMISSIONS: `${BASE_URL}/api/activeuserpersmissions`,
  APPLYPERMISSIONS_EMPLOYEEID_FILTER: `${BASE_URL}/api/applypermissionemployeeidfilter`,
  ACTIVESTATUS_ROCKETCHAT_USER: `${BASE_URL}/api/activestatusrocketchatuser`,
  ADDACHEIVEDACCURACYINDIVIDUAL: `${BASE_URL}/api/achievedaccuracyindividual/new`,
  ADDEXISTSALL: `${BASE_URL}/api/addexistalls`,
  ADDEXISTSALL_CREATE: `${BASE_URL}/api/addexistall/new`,
  ADDEXISTSALL_SINGLE: `${BASE_URL}/api/addexitsall`,
  ADDTOPRINTQUEUE: `${BASE_URL}/api/addtoprintqueues`,
  ADDTOPRINTQUEUEFILTER: `${BASE_URL}/api/addtoprintqueuefilter`,
  ADDTOPRINTQUEUE_CREATE: `${BASE_URL}/api/addtoprintqueue/new`,
  ADDTOPRINTQUEUE_LIMIT: `${BASE_URL}/api/addtoprintqueueslimit`,
  ADDTOPRINTQUEUE_LIMIT_PRINT: `${BASE_URL}/api/addtoprintqueueslimitprint`,
  ADDTOPRINTQUEUE_SINGLE: `${BASE_URL}/api/addtoprintqueue`,
  ADDTOPRINT_PRINT_LIMITED_ACCESS: `${BASE_URL}/api/addtoprintqueueslimitprintaccess`,
  ADDTOPRINT_QUEUE_LIMITED_ACCESS: `${BASE_URL}/api/addtoprintqueueslimitaccess`,
  ADD_DAY_POINTS: `${BASE_URL}/api/daypoint/new`,
  ADD_DAY_POINTS_TEMP: `${BASE_URL}/api/daypointtemp/new`,
  ADD_MANAGEPENALTYMONTH: `${BASE_URL}/api/managepenaltymonth/new`,
  ADD_PENALTYDAYUPLOAD: `${BASE_URL}/api/penaltydayupload/new`,
  ADD_PENALTY_CLIENT: `${BASE_URL}/api/penaltyclientamount/new`,
  ADD_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/productionconsolidated/new`,
  ADD_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/productiontempconsolidated/new`,
  ADD_ROCKETCHAT_GROUPING_INDIVIDUAL: `${BASE_URL}/api/addnewuserinindividual`,
  ADD_TEMP_POINTS: `${BASE_URL}/api/temppoint/new`,
  ADD_VENDORDETAILS: `${BASE_URL}/api/vendordetails/new`,
  ADD_VENDORGROUPING: `${BASE_URL}/api/vendorgrouping/new`,
  ADVANCE: `${BASE_URL}/api/advance`,
  ADVANCEBYASSIGNBRANCH: `${BASE_URL}/api/advancebyassignbranch`,
  ADVANCE_CREATE: `${BASE_URL}/api/advance/new`,
  ADVANCE_HOME: `${BASE_URL}/api/advancebyassignbranchhome`,
  ADVANCE_HOME_LIST: `${BASE_URL}/api/advancebyassignbranchhomelist`,
  ALLASSIGNDOCUMENT: `${BASE_URL}/api/alldocumentassigned`,
  ALLCOMPANYDOMAIN: `${BASE_URL}/api/allcompanydomain`,
  ALLDATATO_ADDTOPRINTQUEUE: `${BASE_URL}/api/alldatatoaddtoprintqueue`,
  ALLEXITINTERVIEWQUESTIONS: `${BASE_URL}/api/exitinterviewquestions`,
  ALLINCOMEANDEXPENSE: `${BASE_URL}/api/allincomeandexpenses`,
  ALLINTERVIEWQUESTION: `${BASE_URL}/api/allinterviewquestions`,
  ALLJOBOPENINGS: `${BASE_URL}/api/alljobopenings`,
  ALLMANUALALL_TASKFORUSER: `${BASE_URL}/api/allmanualtaskforusers`,
  ALLMANUALALL_TASKFORUSER_ID: `${BASE_URL}/api/allmanualtaskforusersids`,
  ALLNOTINEMPLOYEES: `${BASE_URL}/api/allnotinemployees`,
  ALLOTTED_QUEUE_LIST_FILTER: `${BASE_URL}/api/allottedqueuelistfilter`,
  ALLOTTED_RESPONSIBLE_QUEUE_LIST_FILTER: `${BASE_URL}/api/allottedresponsiblequeuelist`,
  ALLREMINDER: `${BASE_URL}/api/allreminder`,
  ALLTASKCOMPLETED: `${BASE_URL}/api/getallcompletedtask`,
  ALLTASKSADMIN: `${BASE_URL}/api/getalltasksadminview`,
  ALLUSER: `${BASE_URL}/api/allusers`,
  ALLUSEREMPLOYEE: `${BASE_URL}/api/alluseremployee`,
  ALLUSERENQLIVE: `${BASE_URL}/api/alluserenquierylive`,
  ALLUSERSWITHOUTSTATUS: `${BASE_URL}/api/userswithoutstatus`,
  ALL_ACCURACYMASTER: `${BASE_URL}/api/accuracymastersort`,
  ALL_ACCURACYQUEUEGROUPING_SORT: `${BASE_URL}/api/accuracyqueuegroupingsort`,
  ALL_ANNOUNCEMENT: `${BASE_URL}/api/announcements`,
  ALL_ANNOUNCEMENTCATEGORY: `${BASE_URL}/api/announcementcategorys`,
  ALL_ASSETCAPACITY: `${BASE_URL}/api/assetcapacitys`,
  ALL_ASSETMODEL: `${BASE_URL}/api/assetmodels`,
  ALL_ASSETSIZE: `${BASE_URL}/api/assetsizes`,
  ALL_ASSETSPECIFICATIONGROUPING: `${BASE_URL}/api/assetspecificationgroupings`,
  ALL_ASSETSPECIFICATIONTYPE: `${BASE_URL}/api/assetspecificationtypes`,
  ALL_ASSETTYPEGROUPING: `${BASE_URL}/api/assettypegroupings`,
  ALL_ASSETTYPEMASTER: `${BASE_URL}/api/assettypemasters`,
  ALL_ASSETVARIANT: `${BASE_URL}/api/assetvariants`,
  ALL_ASSIGNDOCUMENT: `${BASE_URL}/api/allassigndocument`,
  ALL_ASSIGNDOCUMENT_ACCESSS_BRANCH: `${BASE_URL}/api/allassigndocumentaccessbranch`,
  ALL_ASSIGNEDBY_SORT: `${BASE_URL}/api/assignedbysort`,
  ALL_BRANDMASTER: `${BASE_URL}/api/brandmasters`,
  ALL_CARDPREPARATION: `${BASE_URL}/api/cardpreparations`,
  ALL_CARDPREPARATION_ASSIGNBRANCH: `${BASE_URL}/api/assignbranchidcardpreparation`,
  ALL_CHECKLISTINTERVIEW: `${BASE_URL}/api/checklistinterviews`,
  ALL_CLIENTUSERID: `${BASE_URL}/api/clientuserids`,
  ALL_CLIENTUSERIDDATA: `${BASE_URL}/api/clientuseridsdata`,
  ALL_CLIENTUSERIDDATA_LIMITED_TIMESTUDY: `${BASE_URL}/api/clientuseridlimitedtimestudy`,
  ALL_CLIENTUSERID_REPORT_LIMITED_IDSONLY: `${BASE_URL}/api/clientuseridsreportidsonly`,
  ALL_CLOCKSPEED: `${BASE_URL}/api/clockspeeds`,
  ALL_COLOURS: `${BASE_URL}/api/colourss`,
  ALL_COMPANY_DOCUMENTPREPARATION: `${BASE_URL}/api/companydocumentpreparations`,
  ALL_COMPATIBLEDEVICES: `${BASE_URL}/api/compatibledevicess`,
  ALL_CONNECTIVITY: `${BASE_URL}/api/connectivitys`,
  ALL_CONTROLNAME: `${BASE_URL}/api/controlnames`,
  ALL_COOLINGFANCOUNT: `${BASE_URL}/api/coolingfancounts`,
  ALL_CORE: `${BASE_URL}/api/cores`,
  ALL_DATARANGE: `${BASE_URL}/api/dataranges`,
  ALL_DISTANCE: `${BASE_URL}/api/distances`,
  ALL_DOCUMENT: `${BASE_URL}/api/documents`,
  ALL_DOCUMENTPREPARATION: `${BASE_URL}/api/documentpreparations`,
  ALL_DOCUMENT_TRAINING: `${BASE_URL}/api/documentstraining`,
  ALL_EBUSEINSTRUMENTS: `${BASE_URL}/api/ebuseinstruments`,
  ALL_EMPLOYEESTATUS: `${BASE_URL}/api/employeestatuss`,
  ALL_ETHERNETPORTS: `${BASE_URL}/api/ethernetportss`,
  ALL_EVENT: `${BASE_URL}/api/allscheduleevents`,
  ALL_EVENTFILTER: `${BASE_URL}/api/eventallfilter`,
  ALL_EXPECTEDACCURACYMASTER: `${BASE_URL}/api/expectedaccuracysort`,
  ALL_FILEACCESS: `${BASE_URL}/api/fileaccesss`,
  ALL_FILESHARE: `${BASE_URL}/api/fileshares`,
  ALL_FILESHARE_ACCESSBRANCH: `${BASE_URL}/api/filesharesaccessbranch`,
  ALL_FREQUENCY: `${BASE_URL}/api/frequencys`,
  ALL_FREQUENCYMASTER: `${BASE_URL}/api/frequencymasters`,
  ALL_HOLIDAY: `${BASE_URL}/api/holidays`,
  ALL_INDIVIDUAL_SETTING: `${BASE_URL}/api/allindividualsettings`,
  ALL_INTERACTORMODE: `${BASE_URL}/api/interactormode`,
  ALL_INTERACTORPURPOSE: `${BASE_URL}/api/interactorpurpose`,
  ALL_INTERACTORTYPE: `${BASE_URL}/api/interactortype`,
  ALL_INTERNS: `${BASE_URL}/api/allinterns`,
  ALL_LENGTH: `${BASE_URL}/api/lengths`,
  ALL_MAINTENANCE_HIERARCHY_REPORTS: `${BASE_URL}/api/maintenancehierarchyreports`,
  ALL_MANAGEASSIGNED_SORT: `${BASE_URL}/api/manageassignedsort`,
  ALL_MANAGETYPEPG: `${BASE_URL}/api/managetypepg`,
  ALL_MEETING: `${BASE_URL}/api/allschedulemeetings`,
  ALL_MIKROTIKMASTER: `${BASE_URL}/api/getallmikrotikmaster`,
  ALL_NONSCHEDULE_TRAININGDETAILS: `${BASE_URL}/api/nonscheduletrainingdetailss`,
  ALL_NOOFCHANNELS: `${BASE_URL}/api/noofchannelss`,
  ALL_NOTIFICATION_SOUNDS: `${BASE_URL}/api/notificationsounds`,
  ALL_ONLINE_TEST_MASTER: `${BASE_URL}/api/onlinetestmasters`,
  ALL_ONLINE_TEST_QUESTION: `${BASE_URL}/api/onlinetestquestions`,
  ALL_ORGDOCUMENT: `${BASE_URL}/api/allorgdocuments`,
  ALL_OTHERPAYMENTS: `${BASE_URL}/api/allotherpayments`,
  ALL_OTHERTASKCOMPANY_SORT: `${BASE_URL}/api/othertasksortcompany`,
  ALL_OTHERTASKEMPLOYEE_SORT: `${BASE_URL}/api/othertasksortemployee`,
  ALL_OTHERTASK_SORT: `${BASE_URL}/api/othertasksort`,
  ALL_OUTPUT: `${BASE_URL}/api/outputs`,
  ALL_OUTPUTPOWER: `${BASE_URL}/api/outputpowers`,
  ALL_PANELTYPE: `${BASE_URL}/api/paneltypes`,
  ALL_PASSWORD: `${BASE_URL}/api/allpasswords`,
  ALL_POWERSTATION: `${BASE_URL}/api/powerstations`,
  ALL_PROCESSQUEUENAME: `${BASE_URL}/api/processqueuenames`,
  ALL_PROCESS_AND_TEAM: `${BASE_URL}/api/processteams`,
  ALL_PROCESS_AND_TEAM_ASSIGNBRANCH: `${BASE_URL}/api/processteamsassignbranch`,
  ALL_PROCESS_AND_TEAM_FILTER: `${BASE_URL}/api/processteam_filter`,
  ALL_PROCESS_AND_TEAM_FILTER_LIMITED: `${BASE_URL}/api/processteamfilterlimited`,
  ALL_PROFFESIONALTAXMASTER: `${BASE_URL}/api/professionaltaxmasters`,
  ALL_PROFFESIONALTAXMASTERBYASSIGNBRANCH: `${BASE_URL}/api/professionaltaxmastersbyassignbranch`,
  ALL_PURPOSE: `${BASE_URL}/api/purpose`,
  ALL_REFDOCUMENT: `${BASE_URL}/api/allrefdocuments`,
  ALL_SALARYSLAB: `${BASE_URL}/api/salaryslabs`,
  ALL_SALARYSLAB_LIST_FILTER: `${BASE_URL}/api/salaryslablistfilter`,
  ALL_SALARYSLAB_SORT: `${BASE_URL}/api/salaryslabprocessfiltersort`,
  ALL_SALARYSLAB_SORTASSIGNBRANCH: `${BASE_URL}/api/salaryslabprocessfiltersortbyassignbranch`,
  ALL_SCHEDULEPAYMENTMASTER: `${BASE_URL}/api/allschedulepaymentmasters`,
  ALL_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/allschedulepaymentnotaddedbills`,
  ALL_SCREENRESOLUTION: `${BASE_URL}/api/screenresolutions`,
  ALL_SLOT: `${BASE_URL}/api/slots`,
  ALL_SORTED_TASKFORUSER: `${BASE_URL}/api/sortedtasksforusers`,
  ALL_SORTED_TASKMAINTENACEFORUSER: `${BASE_URL}/api/sortedtaskmaintenanceforusers`,
  ALL_SORTED_TRAININGFORUSER: `${BASE_URL}/api/sortedtrainingforusers`,
  ALL_SPEED: `${BASE_URL}/api/speeds`,
  ALL_STATUSMASTER: `${BASE_URL}/api/statusmasters`,
  ALL_TARGETPOINTS_SORT: `${BASE_URL}/api/targetpointsort`,
  ALL_TASKDESIGNATIONGROUPING: `${BASE_URL}/api/taskdesignationgroupings`,
  ALL_TASKDESIGNATIONGROUPING_ACTIVE: `${BASE_URL}/api/taskdesignationgroupingsactive`,
  ALL_TASKDESIGNATIONGROUPING_ASSIGNBRANCH: `${BASE_URL}/api/taskdesignationgroupingsassignbranch`,
  ALL_TASKFORUSER: `${BASE_URL}/api/taskforusers`,
  ALL_TASKFORUSER_COMPLETED: `${BASE_URL}/api/taskforuserscompleted`,
  ALL_TASKFORUSER_MANUAL: `${BASE_URL}/api/taskforusersmanuual`,
  ALL_TASKFORUSER_ONPROGRESS: `${BASE_URL}/api/taskforusersonprogress`,
  ALL_TASKFORUSER_REPORTS: `${BASE_URL}/api/taskforuserreports`,
  ALL_TASKFORUSER_REPORTS_OVERALL: `${BASE_URL}/api/taskforuserreportsoverall`,
  ALL_TASKFORUSER_USERNAME: `${BASE_URL}/api/taskforusersusername`,
  ALL_TASKMAINTENACEFORUSER: `${BASE_URL}/api/taskmaintenanceforusers`,
  ALL_TASKMAINTENACEFORUSER_AUTOGENERATE: `${BASE_URL}/api/taskmaintenanceautogenerate`,
  ALL_TASKMAINTENACEFORUSER_COMPLETED: `${BASE_URL}/api/taskmaintenancecompleted`,
  ALL_TASKMAINTENACEFORUSER_ONPROGRESS: `${BASE_URL}/api/taskmaintenanceonprogress`,
  ALL_TASKMAINTENACEFORUSER_REPORTS: `${BASE_URL}/api/taskmaintenanceforusersreports`,
  ALL_TASKSCHEDULEGROUPING: `${BASE_URL}/api/taskschedulegroupings`,
  ALL_TASK_HIERARCHY_REPORTS: `${BASE_URL}/api/taskhierarchyreports`,
  ALL_TASK_MAINTENANCE_NONSCHEDULEGROUPING: `${BASE_URL}/api/taskmaintenancenonschedulegroupings`,
  ALL_TASK_NONSCHEDULEGROUPING: `${BASE_URL}/api/tasknonschedulegroupings`,
  ALL_TASK_NONSCHEDULEGROUPING_ACCESSBRANCH: `${BASE_URL}/api/tasknonschedulegroupingsaccessbranch`,
  ALL_TEMPLATECREATION: `${BASE_URL}/api/templatecreations`,
  ALL_TRAINIGFORUSER_REPORTS: `${BASE_URL}/api/trainingforuserreports`,
  ALL_TRAINIGFORUSER_REPORTS_OVERALL: `${BASE_URL}/api/trainingforuserreportsoverall`,
  ALL_TRAININGDETAILS: `${BASE_URL}/api/trainingdetailss`,
  ALL_TRAININGDETAILS_DOCUMENT: `${BASE_URL}/api/trainingdetailsdocument`,
  ALL_TRAININGDETAILS_DOCUMENT_ACTIVE: `${BASE_URL}/api/trainingdetailsdocumentactive`,
  ALL_TRAINING_FOR_USER: `${BASE_URL}/api/trainingforusers`,
  ALL_TRAINING_FOR_USER_COMPLETED: `${BASE_URL}/api/trainingforuserscompleted`,
  ALL_TRAINING_FOR_USER_ONPROGRESS: `${BASE_URL}/api/trainingforusersonprogress`,
  ALL_TRAINING_FOR_USER_POSTPONED: `${BASE_URL}/api/trainingforuserspostponed`,
  ALL_TRAINING_HIERARCHY_REPORTS: `${BASE_URL}/api/traininghierarchyreports`,
  ALL_TRAINING_USER_RESPONSE: `${BASE_URL}/api/usertrainingresponses`,
  ALL_TRAINING_USER_RESPONSE_COMPLETED: `${BASE_URL}/api/usertrainingresponsescompleted`,
  ALL_USER_PASS: `${BASE_URL}/api/alluserspasswordchange`,
  ALL_USER_PASSASSIGNBRANCH: `${BASE_URL}/api/alluserspasswordchangeassignbranch`,
  ALL_VENDORDETAILS: `${BASE_URL}/api/allvendordetails`,
  ALL_VENDOREB: `${BASE_URL}/api/allvendormasterforeb`,
  ALL_VENDORGROUPING: `${BASE_URL}/api/vendorgrouping`,
  ALL_VISITORS: `${BASE_URL}/api/allvisitors`,
  ALL_VISITORSFORCANDIDATE: `${BASE_URL}/api/allvisitorsforcandidate`,
  ALL_VISITORS_CHECKOUT: `${BASE_URL}/api/allvisitorscheckout`,
  ALL_VISITORS_FILTEREDID: `${BASE_URL}/api/visitorsfilteredid`,
  ALL_VISITORS_REGISTER: `${BASE_URL}/api/allvisitorsregister`,
  ALL_VOMMASTERNAME: `${BASE_URL}/api/vommasternames`,
  ANNOUNCEMENTCATEGORY_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/announcementcategory`,
  ANNOUNCEMENTCATEGORY_OVERALLDELETE: `${BASE_URL}/api/overalldelete/announcementcategory`,
  ANNOUNCEMENTCATEGORY_OVERALLEDIT: `${BASE_URL}/api/overalledit/announcementcategory`,
  APPLYLEAVE: `${BASE_URL}/api/applyleaves`,
  APPLYLEAVE_APPROVED: `${BASE_URL}/api/applyleavesapproved`,
  APPLYLEAVE_APPROVEDASSIGNBRANCH: `${BASE_URL}/api/applyleavesapprovedassignbranch`,
  APPLYLEAVE_CREATE: `${BASE_URL}/api/applyleave/new`,
  APPLYLEAVE_FILTERED: `${BASE_URL}/api/applyleavesfilter`,
  APPLYLEAVE_FILTERED_HOME_COUNT: `${BASE_URL}/api/applyleavesfilterhomecount`,
  APPLYLEAVE_SINGLE: `${BASE_URL}/api/applyleave`,
  APPLY_LEAVE_HOME: `${BASE_URL}/api/applyleavesapprovedassignbranchhome`,
  APPROVEDLEAVE: `${BASE_URL}/api/approvedleaves`,
  APPROVEDPERMISSIONS: `${BASE_URL}/api/approvedpersmissions`,
  APPROVEDS: `${BASE_URL}/api/approveds`,
  APPROVEDS_CREATE: `${BASE_URL}/api/approved/new`,
  APPROVEDS_SINGLE: `${BASE_URL}/api/approved`,
  AREAGROUPING: `${BASE_URL}/api/areagroupings`,
  AREAGROUPING_CREATE: `${BASE_URL}/api/areagrouping/new`,
  AREAGROUPING_SINGLE: `${BASE_URL}/api/areagrouping`,
  AREAOVERALLUPDATE: `${BASE_URL}/api/overallareasupdate`,
  AREAS: `${BASE_URL}/api/areas`,
  AREA_CREATE: `${BASE_URL}/api/area/new`,
  AREA_SINGLE: `${BASE_URL}/api/area`,
  ASSETCATEGORYGROUPING: `${BASE_URL}/api/assetcategorygroupings`,
  ASSETCATEGORYGROUPING_CREATE: `${BASE_URL}/api/assetcategorygrouping/new`,
  ASSETCATEGORYGROUPING_SINGLE: `${BASE_URL}/api/assetcategorygrouping`,
  ASSETDETAIL: `${BASE_URL}/api/assetdetails`,
  ASSETDETAILCOUNTFILTER: `${BASE_URL}/api/assetdetailscountfilter`,
  ASSETDETAILFILTER: `${BASE_URL}/api/assetdetailsfilter`,
  ASSETDETAIL_CREATE: `${BASE_URL}/api/assetdetail/new`,
  ASSETDETAIL_DAMAGED: `${BASE_URL}/api/damagedasset`,
  ASSETDETAIL_LIMITED: `${BASE_URL}/api/assetdetailslimited`,
  ASSETDETAIL_REPAIRED: `${BASE_URL}/api/repairedasset`,
  ASSETDETAIL_REPAIR_LIMITED: `${BASE_URL}/api/assetdetailsrepairfilter`,
  ASSETDETAIL_SINGLE: `${BASE_URL}/api/assetdetail`,
  ASSETDETAIL_SINGLE_REPAIR: `${BASE_URL}/api/assetdetailsinglerepair`,
  ASSETDETAIL_STOCK_LIMITED: `${BASE_URL}/api/assetdetailsstocklimited`,
  ASSETMATERIALIP: `${BASE_URL}/api/assetmaterialips`,
  ASSETMATERIALIP_CREATE: `${BASE_URL}/api/assetmaterialip/new`,
  ASSETMATERIALIP_LIMITED: `${BASE_URL}/api/assetmaterialipslimited`,
  ASSETMATERIALIP_SINGLE: `${BASE_URL}/api/assetmaterialip`,
  ASSETPROBLEMMASTER: `${BASE_URL}/api/assetproblemmaster`,
  ASSETPROBLEMMASTER_CREATE: `${BASE_URL}/api/assetproblemmaster/new`,
  ASSETS: `${BASE_URL}/api/assets`,
  ASSETWORKSTAION: `${BASE_URL}/api/assetworkstations`,
  ASSETWORKSTAION_CREATE: `${BASE_URL}/api/assetworkstation/new`,
  ASSETWORKSTAION_SINGLE: `${BASE_URL}/api/assetworkstation`,
  ASSETWORKSTATIONGROUP: `${BASE_URL}/api/assetworkgrps`,
  ASSETWORKSTATIONGROUP_CREATE: `${BASE_URL}/api/assetworkgrp/new`,
  ASSETWORKSTATIONGROUP_SINGLE: `${BASE_URL}/api/assetworkgrp`,
  ASSET_CREATE: `${BASE_URL}/api/asset/new`,
  ASSET_DAMAGED_ACCESS: `${BASE_URL}/api/damagedassetaccess`,
  ASSET_DAMAGE_HOME: `${BASE_URL}/api/assetdetailsdamagehome`,
  ASSET_DATA_FILTER_ACCESS: `${BASE_URL}/api/assetdetailsfilteraccess`,
  ASSET_HOME: `${BASE_URL}/api/assetdetailsfilteraccesshome`,
  ASSET_LOG_LIMITED: `${BASE_URL}/api/assetdetaillog`,
  ASSET_MATERIALIP_LIMITED_ACCESS: `${BASE_URL}/api/assetmaterialipslimitedaccess`,
  ASSET_REPAIR_ACCESS: `${BASE_URL}/api/repairedassetaccess`,
  UNASSIGNEDIPS: `${BASE_URL}/api/getallipconfigunassigned`,
  ASSET_REPAIR_HOME: `${BASE_URL}/api/assetdetailsrepairhome`,
  ASSET_SINGLE: `${BASE_URL}/api/asset`,
  ASSET_WORKSTATION_GRP_ACCESS: `${BASE_URL}/api/assetworkgrpsaccess`,
  ASSIGNBRANCH: `${BASE_URL}/api/assignbranches`,
  ASSIGNBRANCH_ACCESSIBLE: `${BASE_URL}/api/assignbranchesaccessible`,
  ASSIGNBRANCH_CREATE: `${BASE_URL}/api/assignbranch/new`,
  ASSIGNBRANCH_SINGLE: `${BASE_URL}/api/assignbranch`,
  ASSIGNCHECKLISTALLJOBOPENINGS: `${BASE_URL}/api/assignchecklistalljobopenings`,
  ASSIGNCLOCKINIP: `${BASE_URL}/api/assignallclockinip`,
  ASSIGNDOCUMENT_CREATE: `${BASE_URL}/api/assigndocument/new`,
  ASSIGNDOCUMENT_SINGLE: `${BASE_URL}/api/assigndocument`,
  ASSIGNEDBY: `${BASE_URL}/api/assignedby`,
  ASSIGNEDBY_CREATE: `${BASE_URL}/api/assignedby/new`,
  ASSIGNED_HOME: `${BASE_URL}/api/taskforassignedhome`,
  ASSIGNINTERVIEWERS: `${BASE_URL}/api/assigninterviewers`,
  ASSIGNINTERVIEWER_CREATE: `${BASE_URL}/api/assigninterviewer/new`,
  ASSIGNINTERVIEWER_SINGLE: `${BASE_URL}/api/assigninterviewer`,
  ASSIGNINTERVIEW_FILTER: `${BASE_URL}/api/assigninterviewersfilter`,
  ASSIGNINTERVIEW_FILTER_ISSUING_AUTHORITY: `${BASE_URL}/api/assigninterviewersfilterissuingauthority`,
  ASSIGNINTERVIEW_FILTER_MANUAL: `${BASE_URL}/api/assigninterviewersfiltermanual`,
  ASSIGN_INTERVIEWER_VISITOR: `${BASE_URL}/api/assigninterviewervisitor`,
  ATTANDANCE_STATUS: `${BASE_URL}/api/attandancesstatus`,
  ATTANDANCE_STATUS_DATE: `${BASE_URL}/api/attandancesstatusdates`,
  ATTANDANCE_STATUS_USERDATE: `${BASE_URL}/api/attandancesstatususerdates`,
  ATTENDANCE_CLOCKIN_CREATE: `${BASE_URL}/api/attandanceclockintimecreate`,
  ATTENDANCE_CLOCKIN_SINGLE: `${BASE_URL}/api/attandanceclockinouttimeedit`,
  ATTENDANCE_COLUMN_FILTER: `${BASE_URL}/api/attendancecolumnfilter`,
  ATTENDANCE_FILTER: `${BASE_URL}/api/attendancefilter`,
  ATTENDANCE_HIERARCHYFILTER: `${BASE_URL}/api/attendancehierarchyfilter`,
  ATTENDANCE_HIERARCHYFILTERANOTHER: `${BASE_URL}/api/attendancehierarchyfilteranother`,
  ATTENDANCE_ID_FILTER: `${BASE_URL}/api/attandanceidfilter`,
  ATTENDANCE_MODE_STATUS: `${BASE_URL}/api/allattendancemodestatus`,
  ATTENDANCE_MODE_STATUS_CREATE: `${BASE_URL}/api/attendancemodestatus/new`,
  ATTENDANCE_MODE_STATUS_SINGLE: `${BASE_URL}/api/attendancemodestatus`,
  ATTENDANCE_STATUS: `${BASE_URL}/api/attendancestatus`,
  ATTENDANCE_STATUS_CREATE: `${BASE_URL}/api/attendancestatus/new`,
  ATTENDANCE_STATUS_OVERALL_DELETE: `${BASE_URL}/api/attendancemodestatusoveralldelete`,
  ATTENDANCE_STATUS_OVERALL_EDIT: `${BASE_URL}/api/attendancemodestatusoveralledit`,
  BANKDETAILS_SINGLE: `${BASE_URL}/api/bankdetailsverfication/single`,
  BANK_RELEASE: `${BASE_URL}/api/bankreleases`,
  BANK_RELEASE_CLOSED: `${BASE_URL}/api/bankreleaseclosed`,
  BANK_RELEASE_CREATE: `${BASE_URL}/api/bankrelease/new`,
  BANK_RELEASE_LIMITED: `${BASE_URL}/api/bankreleaselimited`,
  BANK_RELEASE_SINGLE: `${BASE_URL}/api/bankrelease`,
  BIRTHDAYEMAIL_SENT: `${BASE_URL}/api/schedule-birthdayemail`,
  BRANCH: `${BASE_URL}/api/branches`,
  BRANCHADDRESS: `${BASE_URL}/api/branchaddress`,
  BRANCHALLCHECK: `${BASE_URL}/api/branchAllCheck`,
  BRANCHDESIGCHECK: `${BASE_URL}/api/designation/branchcheck`,
  BRANCHFLOORASSETDETAIL: `${BASE_URL}/api/branchfloorassetdetails`,
  BRANCHLIMIT: `${BASE_URL}/api/brancheslimit`,
  BRANCHNAMECHECK: `${BASE_URL}/api/checkbranch`,
  BRANCHOVERALLUPDATE: `${BASE_URL}/api/branchoverallupdate`,
  BRANCHQRCODE: `${BASE_URL}/api/branchesqrcode`,
  BRANCHUNIT: `${BASE_URL}/api/branchunits`,
  BRANCHWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelbranchreportocunt`,
  BRANCHWISE_UNIT: `${BASE_URL}/api/branchwiseunit`,
  BRANCH_ACCESSBRANCH_SORT: `${BASE_URL}/api/branchesaccessbranch`,
  BRANCH_BY_COMPANY_ACCESS: `${BASE_URL}/api/branchlimitedbycompanyaccess`,
  BRANCH_CREATE: `${BASE_URL}/api/branch/new`,
  BRANCH_DEPT: `${BASE_URL}/api/getdeptbybranch`,
  BRANCH_DEPT_TEAM: `${BASE_URL}/api/getteambybranchanddept`,
  BRANCH_IP: `${BASE_URL}/api/getipbybranch`,
  BRANCH_LIMITED: `${BASE_URL}/api/branchlimited`,
  BRANCH_SINGLE: `${BASE_URL}/api/branch`,
  BRANCH_TEAM: `${BASE_URL}/api/getteambybranchandunit`,
  BRANCH_UNIT: `${BASE_URL}/api/getunitbybranch`,
  BULKOVERALLDELETE_ALOTTEDQUEUE_LIST: `${BASE_URL}/api/bulkoveralldelete`,
  BULKOVERALL_ALOTTEDQUEUE_LIST: `${BASE_URL}/api/bulkoveralleditallottedqueuelist`,
  BULK_DELETE_UNITRATE_UNALLOT: `${BASE_URL}/api/bulkdeleteunitrateunallot`,
  BULK_DELETE_UNITRATE_UNALLOT_TEMP: `${BASE_URL}/api/bulkdeleteunitrateunallottemp`,
  BULK_ERROR_UPLOADS: `${BASE_URL}/api/bulkerroruploads`,
  BULK_ERROR_UPLOADS_CREATE: `${BASE_URL}/api/bulkerroruploads/new`,
  BULK_ERROR_UPLOADS_FILENAME: `${BASE_URL}/api/bulkerroruploadsfilename`,
  BULK_ERROR_UPLOADS_FILENAME_UNIQUE: `${BASE_URL}/api/bulkerroruploadsunique`,
  BULK_ERROR_UPLOADS_FILTER: `${BASE_URL}/api/multiplebulkerroruploadsfilter`,
  BULK_ERROR_UPLOADS_FILTER_LIST: `${BASE_URL}/api/bulkerroruploadsfilterlist`,
  BULK_ERROR_UPLOADS_SINGLE: `${BASE_URL}/api/bulkerroruploadssingle`,
  BULK_UPDATE_UNDO_FIELDNAME: `${BASE_URL}/api/updatefieldundonamebulk`,
  BULK_UPDATE_UNDO_FIELDNAME_TEMP: `${BASE_URL}/api/updatefieldundonamebulktemp`,
  CANDIDATEBYIDFORDOCUMENT: `${BASE_URL}/api/candidatefordocument`,
  CANDIDATEDOCUMENT: `${BASE_URL}/api/candidatedocuments`,
  CANDIDATEDOCUMENT_CREATE: `${BASE_URL}/api/candidatedocument/new`,
  CANDIDATEDOCUMENT_SINGLE: `${BASE_URL}/api/candidatedocument`,
  CANDIDATEFILEUPLOAD_LINK: `${BASE_URL}/api/candidatefileuploadusinglink`,
  CANDIDATES: `${BASE_URL}/api/candidates`,
  CANDIDATESALL: `${BASE_URL}/api/allcandidates`,
  CANDIDATESALLBYRESTRICTION: `${BASE_URL}/api/candidatesbyrestricted`,
  CANDIDATESALLCOUNT: `${BASE_URL}/api/allcandidatescount`,
  CANDIDATESALLFIELDS: `${BASE_URL}/api/candidatesallfields`,
  CANDIDATESALLFIELDS_FILTER: `${BASE_URL}/api/candidatesafieldsfilter`,
  CANDIDATESTATUS_FILTER: `${BASE_URL}/api/candidatestatusfilter`,
  CANDIDATES_ALL_COUNT_HOME: `${BASE_URL}/api/allcandidatescounthome`,
  CANDIDATES_ALL_UPCOMING: `${BASE_URL}/api/allcandidatesupcominginterview`,
  CANDIDATES_CREATE: `${BASE_URL}/api/candidate/new`,
  CANDIDATES_ROLEUPDATE: `${BASE_URL}/api/updatecandidaterole`,
  CANDIDATES_SINGLE: `${BASE_URL}/api/candidate`,
  CANDIDATE_MISSINGFIELDS: `${BASE_URL}/api/candidatesmissingfields`,
  CANDIDATE_SCREENING: `${BASE_URL}/api/canidatescreening`,
  CATEGORYANDSUBCATEGORYGETALL: `${BASE_URL}/api/categoryandsubcategory`,
  CATEGORYANDSUBCATEGORY_CREATE: `${BASE_URL}/api/categoryandsubcategory/new`,
  CATEGORYDATECHANGE: `${BASE_URL}/api/categorydatechange`,
  CATEGORYDATECHANGE_CREATE: `${BASE_URL}/api/categorydatechange/new`,
  CATEGORYDATECHANGE_SORT: `${BASE_URL}/api/categorydatechangesort`,
  CATEGORYDOCUMENT: `${BASE_URL}/api/documentcategories`,
  CATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/documentcategory/new`,
  CATEGORYDOCUMENT_OVERALLDELETE: `${BASE_URL}/api/documentscategorydelete`,
  CATEGORYDOCUMENT_OVERALLEDIT: `${BASE_URL}/api/overalldocumentedit`,
  CATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/documentcategory`,
  CATEGORYEDUCATION: `${BASE_URL}/api/educationcategories`,
  CATEGORYEDUCATION_CREATE: `${BASE_URL}/api/educationcategory/new`,
  CATEGORYEDUCATION_SINGLE: `${BASE_URL}/api/educationcategory`,
  CATEGORYEXCEL: `${BASE_URL}/api/categoriesexcel`,
  CATEGORYEXCEL_BULK_DELETE: `${BASE_URL}/api/categoryexcelbulkdelete`,
  CATEGORYEXCEL_CHECK: `${BASE_URL}/api/vendor/categorycheck`,
  CATEGORYEXCEL_CREATE: `${BASE_URL}/api/categoryexcel/new`,
  CATEGORYINTERVIEW: `${BASE_URL}/api/interviewcategories`,
  CATEGORYINTERVIEW_CREATE: `${BASE_URL}/api/interviewcategory/new`,
  CATEGORYINTERVIEW_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewcategory`,
  CATEGORYINTERVIEW_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewcategory`,
  CATEGORYINTERVIEW_OVERALLEDIT: `${BASE_URL}/api/overalledit/interviewcategory`,
  CATEGORYINTERVIEW_SINGLE: `${BASE_URL}/api/interviewcategory`,
  CATEGORYMASTER: `${BASE_URL}/api/themecategorymasters`,
  CATEGORYMASTERGETALL: `${BASE_URL}/api/categorymaster`,
  CATEGORYMASTERS_CREATE: `${BASE_URL}/api/themecategorymaster/new`,
  CATEGORYMASTERS_SINGLE: `${BASE_URL}/api/themecategorymaster`,
  CATEGORYMASTER_CREATE: `${BASE_URL}/api/categorymaster/new`,
  CATEGORYPROCESSMAP: `${BASE_URL}/api/categoryprocessmaps`,
  CATEGORYPROCESSMAPASSIGNBRANCH: `${BASE_URL}/api/categoryprocessmapsassignbranch`,
  CATEGORYPROCESSMAP_BULKDELETE: `${BASE_URL}/api/categoryprocessmapmutidelete`,
  CATEGORYPROCESSMAP_CREATE: `${BASE_URL}/api/categoryprocessmap/new`,
  CATEGORYPROCESSMAP_LIMITED: `${BASE_URL}/api/categoryprocessmapslimited`,
  CATEGORYPROCESSMAP_SINGLE: `${BASE_URL}/api/categoryprocessmap`,
  CATEGORYPROCESSMAP_SORT: `${BASE_URL}/api/categoryprocessmapssort`,
  CATEGORYPROD: `${BASE_URL}/api/categoriesprod`,
  CATEGORYPROD_CREATE: `${BASE_URL}/api/categoryprod/new`,
  CATEGORYPROD_LIMITED: `${BASE_URL}/api/categoryprodlimited`,
  CATEGORYPROD_LIMITED_ORIGINAL: `${BASE_URL}/api/categoryprodlimitedoriginal`,
  CATEGORYPROD_LIMITED_REPORT_MULTI: `${BASE_URL}/api/categoryprodlimitedreportsmultiselect`,
  CATEGORYPROD_LIMITED_TEMP_FLAGCALC: `${BASE_URL}/api/categoryprodlimitedtempflagcalc`,
  CATEGORYPROD_LIMITED_UNALLOT: `${BASE_URL}/api/categoryprodlimitedunallot`,
  CATEGORYPROD_LIMITED_UPLOAD: `${BASE_URL}/api/categorylimitedkeyword`,
  CATEGORYPROD_OVERALL_EDIT: `${BASE_URL}/api/categoryprodoveralledit`,
  CATEGORYPROD_OVERALL_EDIT_BULKUPDATE: `${BASE_URL}/api/categoryprodoveralleditbulkupdate`,
  CATEGORYPROD_SINGLE: `${BASE_URL}/api/categoryprod`,
  CATEGORYPROD_SORT: `${BASE_URL}/api/categoriesprodsort`,
  CATEGORYPROJECTCHECK: `${BASE_URL}/api/category/projectcheck`,
  CATEGORYQUEUE: `${BASE_URL}/api/categoryqueuegrouping`,
  CATEGORYSUBCATEGORY: `${BASE_URL}/api/categorysubcategorytime`,
  CATEGORYTICKET: `${BASE_URL}/api/ticketcategories`,
  CATEGORYTICKET_CREATE: `${BASE_URL}/api/ticketcategory/new`,
  CATEGORYTICKET_SINGLE: `${BASE_URL}/api/ticketcategory`,
  CATEGORYWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelcategoryreportocunt`,
  CATEGORY_DROPDOWNS: `${BASE_URL}/api/categorydowns`,
  CATEGORY_DROPDOWNS_EDIT: `${BASE_URL}/api/categorydownsedit`,
  CATEGORY_OVERALL_CHECK_BULKDELETE: `${BASE_URL}/api/categoryoverallcheckdeletebulk`,
  CATEGORY_OVERALL_CHECK_DELETE: `${BASE_URL}/api/categoryoverallcheckdelete`,
  CATEGORY_OVERALL_NONLINK_BULKDELETE: `${BASE_URL}/api/categoryoverallnonlinkbulkdelete`,
  CATEGORY_PROD_LIMITED_NAMEONLY: `${BASE_URL}/api/categorylimitednameonly`,
  CATEGROYTHEMEGROUPING: `${BASE_URL}/api/categorythemegroupings`,
  CATEGROYTHEMEGROUPING_CREATE: `${BASE_URL}/api/categorythemegrouping/new`,
  CATEGROYTHEMEGROUPING_SINGLE: `${BASE_URL}/api/categorythemegrouping`,
  CERTIFICATION: `${BASE_URL}/api/certifications`,
  CERTIFICATION_CREATE: `${BASE_URL}/api/certification/new`,
  CERTIFICATION_SINGLE: `${BASE_URL}/api/certification`,
  CHATCONFIGURATIONALL: `${BASE_URL}/api/chatconfigurationall`,
  CHATCONFIGURATION_CREATE: `${BASE_URL}/api/chatconfiguration/new`,
  CHATCONFIGURATION_SINGLE: `${BASE_URL}/api/chatconfiguration`,
  CHECKCOMPANYDOMAINUSER: `${BASE_URL}/api/checkcompanydomainuser`,
  CHECKHIERARCHYADDNEWEMP: `${BASE_URL}/api/checkhierarchyaddnewemp`,
  CHECKHIERARCHYEDITEMPDETAILS: `${BASE_URL}/api/checkhierarchyeditempdetails`,
  CHECKHIERARCHYEDITEMPDETAILSDESIGN: `${BASE_URL}/api/checkhierarchyeditempdetailsdesig`,
  CHECKLISTCATEGORY: `${BASE_URL}/api/checklistcategories`,
  CHECKLISTCATEGORY_CREATE: `${BASE_URL}/api/checklistcategory/new`,
  CHECKLISTCATEGORY_SINGLE: `${BASE_URL}/api/checklistcategory`,
  CHECKLISTMODULE: `${BASE_URL}/api/checklistmodule`,
  CHECKLISTMODULE_CREATE: `${BASE_URL}/api/checklistmodule/new`,
  CHECKLISTMODULE_SINGLE: `${BASE_URL}/api/checklistmodule`,
  CHECKLISTNOTICEPERIODAPPLY: `${BASE_URL}/api/checklistnoticeperiodapplies`,
  CHECKLISTTYPE: `${BASE_URL}/api/checklisttypes`,
  CHECKLISTTYPE_CREATE: `${BASE_URL}/api/checklisttype/new`,
  CHECKLISTTYPE_SINGLE: `${BASE_URL}/api/checklisttype`,
  CHECKLISTUSERDATAS: `${BASE_URL}/api/fetchuserdatas`,
  CHECKLISTVERIFICATIONMASTER: `${BASE_URL}/api/checklistverificationmasters`,
  CHECKLISTVERIFICATIONMASTER_CREATE: `${BASE_URL}/api/checklistverificationmaster/new`,
  CHECKLISTVERIFICATIONMASTER_SINGLE: `${BASE_URL}/api/checklistverificationmaster`,
  CHECKPAIDDATEFIX: `${BASE_URL}/api/checkpaiddatefix`,
  CHECKPAIDSTATUS_PAYRUN: `${BASE_URL}/api/checkpaidstatuspayrun`,
  CHECKPOINTGROUP: `${BASE_URL}/api/checkptgroups`,
  CHECKPOINTGROUPTOTASK: `${BASE_URL}/api/task/checkgroup`,
  CHECKPOINTGROUP_CHECK: `${BASE_URL}/api/checkptgroup/category`,
  CHECKPOINTGROUP_CREATE: `${BASE_URL}/api/checkptgroup/new`,
  CHECKPOINTGROUP_SINGLE: `${BASE_URL}/api/checkptgroup`,
  CHECKPOINTTICKET: `${BASE_URL}/api/checkpointtickets`,
  CHECKPOINTTICKET_CREATE: `${BASE_URL}/api/checkpointticket/new`,
  CHECKPOINTTICKET_SINGLE: `${BASE_URL}/api/checkpointticket`,
  CHECKREPORTINGHEADER: `${BASE_URL}/api/checkreportingheader`,
  CHECKSUBCATEGORY_MANUAL_CREATION: `${BASE_URL}/api/checksubcategoryformanualcreation`,
  CHECKUNITRATE_MANUAL_CREATION: `${BASE_URL}/api/checkunitrateformanualcreation`,
  CHECKWITH_BANK_RELEASE: `${BASE_URL}/api/checkwithbankrelease`,
  CHECK_DAYPOINT_ORG_BYDATE: `${BASE_URL}/api/checklastdaypointorg`,
  CHECK_DUPE_BILL_BEFORE_EB: `${BASE_URL}/api/checkdupebillbefore`,
  CHECK_DUPE_BILL_BEFORE_EB_EDIT: `${BASE_URL}/api/checkdupebillbeforeedit`,
  CHECK_DUPE_BILL_EB: `${BASE_URL}/api/checkdupebill`,
  CHECK_DUPE_BILL_EB_EDIT: `${BASE_URL}/api/checkdupebilledit`,
  CHECK_DUPE_DAILY_EB: `${BASE_URL}/api/checkdupedaily`,
  CHECK_DUPE_DAILY_EB_EDIT: `${BASE_URL}/api/checkdupedailyedit`,
  CHECK_DUPE_MONTH_EB: `${BASE_URL}/api/checkdupemonth`,
  CHECK_DUPE_MONTH_EB_EDIT: `${BASE_URL}/api/checkdupemonthedit`,
  CHECK_EBREADINGDETAIL: `${BASE_URL}/api/ebreadingdetailsFilter`,
  CHECK_EBREADINGDETAILLIST: `${BASE_URL}/api/ebreadingdetailslistFilter`,
  CHECK_EBUSE_INSTRUMENTS: `${BASE_URL}/api/ebuseinstrumentsFilter`,
  CHECK_ISDAYPOINT_CREATED: `${BASE_URL}/api/checkdaypointiscreated`,
  CHECK_ISDAYPOINT_CREATED_TEMP: `${BASE_URL}/api/checkdaypointiscreatedtemp`,
  CHECK_ISPRODDAY_CREATED: `${BASE_URL}/api/checkisproddaycreated`,
  CHECK_ISPRODDAY_CREATED_TEMP: `${BASE_URL}/api/checkisproddaycreatedtemp`,
  CHECK_ISPRODDAY_CREATED_TEMP_DAYCREATE: `${BASE_URL}/api/checkisproddaycreatedtempdaycreate`,
  CHECK_PAYRUN_ISCREATED: `${BASE_URL}/api/checkpayruniscreated`,
  CHECK_VPN_DETAILS: `${BASE_URL}/api/checkuservpndetails`,
  CHECK_ZERO_MISMATCH_PRESENT: `${BASE_URL}/api/checkzeromismatchpresent`,
  CHECK_ZERO_MISMATCH_PRESENT_TEMP: `${BASE_URL}/api/checkzeromismatchpresenttemp`,
  CLIENTSUPPORT: `${BASE_URL}/api/clientsupport`,
  CLIENTSUPPORT_OVERALLEXPORT: `${BASE_URL}/api/clientsupportoverallexport`,
  CLIENTUSERID_CHECK_USER: `${BASE_URL}/api/clientuseridusercheck`,
  CLIENTUSERID_LIMITED: `${BASE_URL}/api/clientuseridslimited`,
  CLIENTUSERID_LIMITED_BYCOMPNYNAME: `${BASE_URL}/api/clientuseridlimitedtimestudybycompanyname`,
  CLIENTUSERID_LIMITED_USER: `${BASE_URL}/api/clientuseridlimiteduser`,
  CLIENTUSERID_SORT: `${BASE_URL}/api/clientuseridsort`,
  CLOCKINIP: `${BASE_URL}/api/allclockinip`,
  CLOCKINIP_CREATE: `${BASE_URL}/api/clockinip/new`,
  CLOCKINIP_SINGLE: `${BASE_URL}/api/clockinip`,
  CLOSEDALLJOBOPENINGS: `${BASE_URL}/api/withoutclosedjobopenings`,
  COMPANY: `${BASE_URL}/api/companies`,
  COMPANYACCESS: `${BASE_URL}/api/companyaccess`,
  COMPANYDOMAIN: `${BASE_URL}/api/companydomain`,
  COMPANYDOMAIN_CREATE: `${BASE_URL}/api/companydomain/new`,
  COMPANYLIMIT: `${BASE_URL}/api/companieslimit`,
  COMPANYNAME_DUPLICATECHECK_CREATE: `${BASE_URL}/api/checkcompanynamecreate`,
  COMPANYNAME_DUPLICATECHECK_EDIT: `${BASE_URL}/api/checkcompanynameedit`,
  COMPANY_CREATE: `${BASE_URL}/api/company/new`,
  COMPANY_DOCUMENT_PREPARATION_AUTOID: `${BASE_URL}/api/companydocumentpreparationautoid`,
  COMPANY_DOCUMENT_PREPARATION_CODES: `${BASE_URL}/api/companydocumentpreparationcodes`,
  COMPANY_LIMITED_BY_ACCESS: `${BASE_URL}/api/companynamelimitedbyaccess`,
  COMPANY_OVERALLDELETE: `${BASE_URL}/api/companydelete`,
  COMPANY_SINGLE: `${BASE_URL}/api/company`,
  COMPANY_TEMPLATECREATION: `${BASE_URL}/api/companytemplatecreations`,
  COMPLETEDALL_TASKFORUSER: `${BASE_URL}/api/completedtaskforusers`,
  COMPONENTMASTER: `${BASE_URL}/api/components`,
  COMPONENTSGROUPING: `${BASE_URL}/api/componentsgroupings`,
  COMPONENTSGROUPINGLIMIT: `${BASE_URL}/api/componentsgrouplimit`,
  COMPONENTSGROUPING_CREATE: `${BASE_URL}/api/componentsgroup/new`,
  COMPONENTSGROUPING_SINGLE: `${BASE_URL}/api/componentsgroup`,
  COMPONENT_CREATE: `${BASE_URL}/api/component/new`,
  COMPONENT_EDITOVERALL: `${BASE_URL}/api/componentoverall`,
  COMPONENT_SINGLE: `${BASE_URL}/api/component`,
  COMPSUBCOMPONENT: `${BASE_URL}/api/compsubcomponents`,
  COMPSUBCOMPONENT_CREATE: `${BASE_URL}/api/compsubcomponent/new`,
  COMPSUBCOMPONENT_SINGLE: `${BASE_URL}/api/compsubcomponent`,
  CONFIRM_CONSOLIDATED_RELEASE_SAVE: `${BASE_URL}/api/confirmconsolidatedreleasesave`,
  CONFIRM_FIXHOLDSALARYDATE: `${BASE_URL}/api/confirmfixholdsalarydate`,
  CONFIRM_FIXHOLDSALARY_LOGUPDATE: `${BASE_URL}/api/confirmfixholdsalarylogupdate`,
  CONFIRM_FIXHOLDSALARY_REJECT: `${BASE_URL}/api/fixholdsalaryreject`,
  CONFIRM_FIXSALARYDATE: `${BASE_URL}/api/confirmfixsalarydate`,
  CONFIRM_FIX_HOLDRELEASE_SAVE: `${BASE_URL}/api/confirmholdreleasesave`,
  CONSOLIDATED_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/consolidatedheirarchyalldefault`,
  CONSOLIDATED_HIERARCHY_DEFAULT_PRISECTER: `${BASE_URL}/api/consolidatedheirarchyprimsectertdefault`,
  CONSOLIDATED_HIERARCHY_FILTER_ALL: `${BASE_URL}/api/consolidatedheirarchyall`,
  CONSOLIDATED_HIERARCHY_FILTER_PRISECTER: `${BASE_URL}/api/consolidatedheirarchyprimsectert`,
  CONSOLIDATED_INDIVIDUAL_LIST: `${BASE_URL}/api/consolidatedindividual`,
  CONSOLIDATED_INDIVIDUAL_LISTFILTER: `${BASE_URL}/api/consolidatedindividualfilter`,
  CONSOLIDATED_INDIVIDUAL_LISTFILTER_ALL: `${BASE_URL}/api/consolidatedindividualfilterall`,
  CONSOLIDATED_INDIVIDUAL_LIST_ALL: `${BASE_URL}/api/consolidatedindividualall`,
  CONSOLIDATED_SALARY_RELEASE: `${BASE_URL}/api/consolidatedsalaryrelease`,
  CONSOLIDATED_SALARY_RELEASE_CREATE: `${BASE_URL}/api/consolidatedsalaryrelease/new`,
  CONSOLIDATED_SALARY_RELEASE_MONTHWISE: `${BASE_URL}/api/consolidatedsalaryreleasemonthwise`,
  CONSWORKORDER_LIST: `${BASE_URL}/api/consworkorderlist`,
  CONSWORKORDER_LIST_ALL: `${BASE_URL}/api/consworkorderlistAll`,
  CONSWORKORDER_LIST_FILTER: `${BASE_URL}/api/consworkorderlistFilter`,
  CONSWORKORDER_LIST_FILTER_ALL: `${BASE_URL}/api/consworkorderlistAllFilter`,
  CONTROLCRITERIA: `${BASE_URL}/api/controlcriterias`,
  CONTROLCRITERIA_CREATE: `${BASE_URL}/api/controlcriteria/new`,
  CONTROLCRITERIA_SINGLE: `${BASE_URL}/api/controlcriteria`,
  CONTROLNAME: `${BASE_URL}/api/designationcontname`,
  CONTROLNAME_SINGLE: `${BASE_URL}/api/controlname`,
  CONTROLSGROUPING: `${BASE_URL}/api/controlsgroupings`,
  CONTROLSGROUPING_CREATE: `${BASE_URL}/api/controlsgrouping/new`,
  CONTROLSGROUPING_SINGLE: `${BASE_URL}/api/controlsgrouping`,
  CONTROL_NAMES_BASED_ON_DESIG: `${BASE_URL}/api/controlnamesbasedondesignation`,
  CONTROL_SETTINGS_LAST_INDEX: `${BASE_URL}/api/getoverallsettingslastindex`,
  CREATEEXITINTERVIEWQUESTION: `${BASE_URL}/api/exitinterviewquestion/new`,
  CREATEPOSTFIXMAILUSER: `${BASE_URL}/api/postfixmailusercreate`,
  CREATEPOSTFIXMAILUSERBYEMPLOYEE: `${BASE_URL}/api/postfixmailusercreatebyemployee`,
  CREATESINGLESHIFTGROUP: `${BASE_URL}/api/shiftgrouping/new`,
  CREATE_ANNOUNCEMENT: `${BASE_URL}/api/announcement/new`,
  CREATE_ANNOUNCEMENTCATEGORY: `${BASE_URL}/api/announcementcategory/new`,
  CREATE_ASSETCAPACITY: `${BASE_URL}/api/assetcapacity/new`,
  CREATE_ASSETMODEL: `${BASE_URL}/api/assetmodel/new`,
  CREATE_ASSETSIZE: `${BASE_URL}/api/assetsize/new`,
  CREATE_ASSETSPECIFICATIONGROUPING: `${BASE_URL}/api/assetspecificationgrouping/new`,
  CREATE_ASSETSPECIFICATIONTYPE: `${BASE_URL}/api/assetspecificationtype/new`,
  CREATE_ASSETTYPEGROUPING: `${BASE_URL}/api/assettypegrouping/new`,
  CREATE_ASSETTYPEMASTER: `${BASE_URL}/api/assettypemaster/new`,
  CREATE_ASSETVARIANT: `${BASE_URL}/api/assetvariant/new`,
  CREATE_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/createattendancecontrolcriteria`,
  CREATE_AUTOLOGOUT: `${BASE_URL}/api/createautologout`,
  CREATE_BRANDMASTER: `${BASE_URL}/api/brandmaster/new`,
  CREATE_CARD_PREPARATION: `${BASE_URL}/api/cardpreparation/new`,
  CREATE_CHECKLISTINTERVIEW: `${BASE_URL}/api/checklistinterview/new`,
  CREATE_CLIENTUSERID: `${BASE_URL}/api/clientuserid/new`,
  CREATE_CLOCKSPEED: `${BASE_URL}/api/clockspeed/new`,
  CREATE_COLOURS: `${BASE_URL}/api/colours/new`,
  CREATE_COMPANY_DOCUMENT_PREPARATION: `${BASE_URL}/api/companydocumentpreparation/new`,
  CREATE_COMPATIBLEDEVICES: `${BASE_URL}/api/compatibledevices/new`,
  CREATE_CONNECTIVITY: `${BASE_URL}/api/connectivity/new`,
  CREATE_CONTROLNAME: `${BASE_URL}/api/controlname/new`,
  CREATE_COOLINGFANCOUNT: `${BASE_URL}/api/coolingfancount/new`,
  CREATE_CORE: `${BASE_URL}/api/core/new`,
  CREATE_DATARANGE: `${BASE_URL}/api/datarange/new`,
  CREATE_DISTANCE: `${BASE_URL}/api/distance/new`,
  CREATE_DOCUMENT_PREPARATION: `${BASE_URL}/api/documentpreparation/new`,
  CREATE_EBUSEINSTRUMENTS: `${BASE_URL}/api/ebuseinstrument/new`,
  CREATE_EMPLOYEESTATUS: `${BASE_URL}/api/employeestatus/new`,
  CREATE_ETHERNETPORTS: `${BASE_URL}/api/ethernetports/new`,
  CREATE_EVENT: `${BASE_URL}/api/scheduleevent/new`,
  CREATE_FILEACCESS: `${BASE_URL}/api/fileaccess/new`,
  CREATE_FILESHARE: `${BASE_URL}/api/fileshare/new`,
  CREATE_FREQUENCY: `${BASE_URL}/api/frequency/new`,
  CREATE_FREQUENCYMASTER: `${BASE_URL}/api/frequencymaster/new`,
  CREATE_HOLIDAY: `${BASE_URL}/api/holiday/new`,
  CREATE_INDIVIDUAL_SETTING: `${BASE_URL}/api/individualsettings/new`,
  CREATE_INTERACTORMODE: `${BASE_URL}/api/interactormode/new`,
  CREATE_INTERACTORPURPOSE: `${BASE_URL}/api/interactorpurpose/new`,
  CREATE_INTERACTORTYPE: `${BASE_URL}/api/interactortype/new`,
  CREATE_INT_FORM_ALLOT: `${BASE_URL}/api/interviewanswerallot/new`,
  CREATE_INT_FORM_DESIGN: `${BASE_URL}/api/interviewformdesign/new`,
  CREATE_LENGTH: `${BASE_URL}/api/length/new`,
  CREATE_MANAGETYPEPG: `${BASE_URL}/api/managetypepg/new`,
  CREATE_MEETING: `${BASE_URL}/api/schedulemeeting/new`,
  CREATE_MIKROTIKMASTER: `${BASE_URL}/api/createmikrotikmaster`,
  CREATE_MIKROTIK_PROFILE: `${BASE_URL}/api/createmikrotikprofile`,
  CREATE_MIKROTIK_SECRET: `${BASE_URL}/api/createmikrotiksecret`,
  CREATE_MYCREATION: `${BASE_URL}/api/createmycreation`,
  CREATE_NONSCHEDULE_TRAININGDETAILS: `${BASE_URL}/api/nonscheduletrainingdetails/new`,
  CREATE_NOOFCHANNELS: `${BASE_URL}/api/noofchannels/new`,
  CREATE_NOTIFICATION_SOUND: `${BASE_URL}/api/notificationsound/new`,
  CREATE_ONLINE_TEST_MASTER: `${BASE_URL}/api/onlinetestmaster/new`,
  CREATE_ONLINE_TEST_QUESTION: `${BASE_URL}/api/onlinetestquestion/new`,
  CREATE_OUTPUT: `${BASE_URL}/api/output/new`,
  CREATE_OUTPUTPOWER: `${BASE_URL}/api/outputpower/new`,
  CREATE_OVERALL_SETTINGS: `${BASE_URL}/api/createoverallsettings`,
  CREATE_PANELTYPE: `${BASE_URL}/api/paneltype/new`,
  CREATE_PASSWORD: `${BASE_URL}/api/password/new`,
  CREATE_POWERSTATION: `${BASE_URL}/api/powerstation/new`,
  CREATE_PROCESSQUEUENAME: `${BASE_URL}/api/processqueuename/new`,
  CREATE_PROCESS_AND_TEAM: `${BASE_URL}/api/processteam/new`,
  CREATE_PROFFESIONALTAXMASTER: `${BASE_URL}/api/professionaltaxmaster/new`,
  CREATE_PURPOSE: `${BASE_URL}/api/purpose/new`,
  CREATE_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/createrocketchatchannel`,
  CREATE_ROCKETCHAT_TEAM: `${BASE_URL}/api/createrocketchatteam`,
  CREATE_ROCKETCHAT_TEAMCHANNELGROUPING: `${BASE_URL}/api/createrocketchatteamchannelgrouping`,
  CREATE_ROCKETCHAT_USER: `${BASE_URL}/api/createrocketchatuser`,
  CREATE_ROCKETCHAT_USER_INEDIT: `${BASE_URL}/api/createrocketchataccountinedit`,
  CREATE_SALARYSLAB: `${BASE_URL}/api/salaryslab/new`,
  CREATE_SCREENRESOLUTION: `${BASE_URL}/api/screenresolution/new`,
  CREATE_SLOT: `${BASE_URL}/api/slot/new`,
  CREATE_SPEED: `${BASE_URL}/api/speed/new`,
  CREATE_STATUSMASTER: `${BASE_URL}/api/statusmaster/new`,
  CREATE_TASKDESIGNATIONGROUPING: `${BASE_URL}/api/taskdesignationgrouping/new`,
  CREATE_TASKFORUSER: `${BASE_URL}/api/taskforuser/new`,
  CREATE_TASKMAINTENACEFORUSER: `${BASE_URL}/api/taskmaintenanceforusers/new`,
  CREATE_TASKSCHEDULEGROUPING: `${BASE_URL}/api/taskschedulegrouping/new`,
  CREATE_TASK_MAINTENANCE_NONSCHEDULEGROUPING: `${BASE_URL}/api/taskmaintenancenonschedulegrouping/new`,
  CREATE_TASK_NONSCHEDULEGROUPING: `${BASE_URL}/api/tasknonschedulegrouping/new`,
  CREATE_TEMPLATECREATION: `${BASE_URL}/api/templatecreation/new`,
  CREATE_TRAININGDETAILS: `${BASE_URL}/api/trainingdetails/new`,
  CREATE_TRAINING_FOR_USER: `${BASE_URL}/api/trainingforuser/new`,
  CREATE_TRAINING_USER_RESPONSE: `${BASE_URL}/api/createusertrainingresponse/new`,
  CREATE_USERCHECKS: `${BASE_URL}/api/maintenancelog/new`,
  CREATE_USER_RESPONSE: `${BASE_URL}/api/interviewuserresponse/new`,
  CREATE_VISITORS: `${BASE_URL}/api/visitors/new`,
  CREATE_VOMMASTERNAME: `${BASE_URL}/api/vommastername/new`,
  CUSTOMERDROP: `${BASE_URL}/api/custdropdowns`,
  CUSTOMEREXCEL: `${BASE_URL}/api/customerexcel`,
  CUSTOMERWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelcustomerreportocunt`,
  CUSTOMFORMS: `${BASE_URL}/api/customforms`,
  CUSTOMFORMS_LIST: `${BASE_URL}/api/customformslist`,
  CUSTOMFORM_CREATE: `${BASE_URL}/api/customform/new`,
  CUSTOMFORM_LIST_CREATE: `${BASE_URL}/api/customformlist/new`,
  CUSTOMFORM_LIST_SINGLE: `${BASE_URL}/api/customformlist`,
  CUSTOMFORM_SINGLE: `${BASE_URL}/api/customform`,
  DAYPOINT_DELETE_BYDATE: `${BASE_URL}/api/daypointtempdeletebydate`,
  DAY_POINTS_DATAS: `${BASE_URL}/api/daypointsdatasfetch`,
  DAY_POINTS_DATAS_TEMP: `${BASE_URL}/api/daypointsdatasfetchtemp`,
  DAY_POINTS_FILTER: `${BASE_URL}/api/daypointsfilter`,
  DAY_POINTS_FILTER_HOME: `${BASE_URL}/api/daypointsfilterhome`,
  DAY_POINTS_FILTER_TEMP: `${BASE_URL}/api/daypointsfiltertemp`,
  DAY_POINTS_MONTH_YEAR_FILTER: `${BASE_URL}/api/daypointsmonthwisefilter`,
  DAY_POINTS_MONTH_YEAR_FILTER_NXTMONTH: `${BASE_URL}/api/daypointsmonthwisefilternxtmonth`,
  DAY_POINTS_MONTH_YEAR_FILTER_NXTMONTH_TEMP: `${BASE_URL}/api/daypointsmonthwisefilternxtmonthtemp`,
  DAY_POINTS_MONTH_YEAR_FILTER_TEMP: `${BASE_URL}/api/daypointsmonthwisefiltertemp`,
  DELETEANYLOG: `${BASE_URL}/api/deleteanylog`,
  DELETESHIFTANYLOG: `${BASE_URL}/api/deleteshiftanylog`,
  DELETE_DAYPOINT_ORG_BYDATE: `${BASE_URL}/api/daypointdeletebydate`,
  DELETE_INTERVIEWROUND: `${BASE_URL}/api/deleteinterviewround`,
  DELETE_MIKROTIK_PROFILE: `${BASE_URL}/api/deletemikrotikprofile`,
  DELETE_MIKROTIK_SECRET: `${BASE_URL}/api/deletemikrotiksecret`,
  DELETE_PAYRUN_BULKDATA: `${BASE_URL}/api/deletepayrunbulkdata`,
  DELETE_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/deleterocketchatchannel`,
  DELETE_ROCKETCHAT_TEAM: `${BASE_URL}/api/deleterocketchatteam`,
  DELETE_ROCKETCHAT_USER: `${BASE_URL}/api/deleterocketchatuser`,
  DEPARTMENT: `${BASE_URL}/api/departments`,
  DEPARTMENTANDDESIGNATIONGROUPING: `${BASE_URL}/api/departmentanddesignationgroupings`,
  DEPARTMENTANDDESIGNATIONGROUPING_CREATE: `${BASE_URL}/api/departmentanddesignationgrouping/new`,
  DEPARTMENTANDDESIGNATIONGROUPING_SINGLE: `${BASE_URL}/api/departmentanddesignationgrouping`,
  DEPARTMENTGROUPINGS: `${BASE_URL}/api/departmentgroupings`,
  DEPARTMENTGROUPING_CREATE: `${BASE_URL}/api/departmentgrouping/new`,
  DEPARTMENTGROUPING_SINGLE: `${BASE_URL}/api/departmentgrouping`,
  DEPARTMENTMONTHSETYEARMONTH: `${BASE_URL}/api/yearmonthdepartmentmonthset`,
  DEPARTMENTOVERALLBULKCHECK: `${BASE_URL}/api/departoverallbulkcheck`,
  DEPARTMENTOVERALLCHECK: `${BASE_URL}/api/departoverallcheck`,
  DEPARTMENTOVERALLUPDATE: `${BASE_URL}/api/departoverallupdate`,
  DEPARTMENT_CREATE: `${BASE_URL}/api/department/new`,
  DEPARTMENT_SINGLE: `${BASE_URL}/api/department`,
  DEPMONTHSETAUTO_ALL: `${BASE_URL}/api/deptmonthsetautos`,
  DEPMONTHSETAUTO_CREATE: `${BASE_URL}/api/deptmonthsetauto/new`,
  DEPMONTHSETAUTO_SINGLE: `${BASE_URL}/api/deptmonthsetauto`,
  DEPMONTHSET_ALL: `${BASE_URL}/api/departmentmonthsets`,
  DEPMONTHSET_CREATE: `${BASE_URL}/api/departmentmonthset/new`,
  DEPMONTHSET_SINGLE: `${BASE_URL}/api/departmentmonthset`,
  DEPTMONTHSET_LIMITED: `${BASE_URL}/api/departmentmonthsetslimited`,
  DEPTMONTHSET_LIMITED_WITH_DEPT: `${BASE_URL}/api/departmentmonthsetslimitedforleave`,
  DEPTMONTHSET_PROD_LIMITED: `${BASE_URL}/api/departmentmonthsetsprodlimited`,
  DEPT_TEAM: `${BASE_URL}/api/getdeptandteam`,
  DESIGNATION: `${BASE_URL}/api/designation`,
  DESIGNATIONBULKCHECK: `${BASE_URL}/api/designationbulkcheck`,
  DESIGNATIONCONTROLGROUPINGGROUPING: `${BASE_URL}/api/designationandcontrolgroupings`,
  DESIGNATIONCONTROLGROUPINGGROUPING_CREATE: `${BASE_URL}/api/designationandcontrolgrouping/new`,
  DESIGNATIONCONTROLGROUPINGGROUPING_SINGLE: `${BASE_URL}/api/designationandcontrolgrouping`,
  DESIGNATIONGROUPBULKCHECK: `${BASE_URL}/api/getoveralldesignationgroupBulkcheck`,
  DESIGNATIONGRP: `${BASE_URL}/api/designationgroup`,
  DESIGNATIONGRP_CREATE: `${BASE_URL}/api/designationgroup/new`,
  DESIGNATIONMONTHSET_ALL: `${BASE_URL}/api/designationmonthsets`,
  DESIGNATIONMONTHSET_CREATE: `${BASE_URL}/api/designationmonthset/new`,
  DESIGNATIONMONTHSET_SINGLE: `${BASE_URL}/api/designationmonthset`,
  DESIGNATIONOVERALLCHECK: `${BASE_URL}/api/desigoverallcheck`,
  DESIGNATIONOVERALLUPDATE: `${BASE_URL}/api/desigoverallupdate`,
  DESIGNATIONREQUIREMENTS: `${BASE_URL}/api/designationrequirements`,
  DESIGNATIONREQUIREMENTSFILTER: `${BASE_URL}/api/designationmanpowerfilter`,
  DESIGNATIONREQUUIREMENTS_SINGLE: `${BASE_URL}/api/designationrequirement`,
  DESIGNATIONREQ_CREATE: `${BASE_URL}/api/designationrequirement/new`,
  DESIGNATION_CREATE: `${BASE_URL}/api/designation/new`,
  DOCUMENTGROUPING: `${BASE_URL}/api/documentgroupings`,
  DOCUMENTGROUPING_CREATE: `${BASE_URL}/api/documentgrouping/new`,
  DOCUMENTGROUPING_SINGLE: `${BASE_URL}/api/documentgrouping`,
  DOCUMENT_CREATE: `${BASE_URL}/api/documents/new`,
  DOCUMENT_PREPARATION_AUTOID: `${BASE_URL}/api/documentpreparationautoid`,
  DOCUMENT_PREPARATION_CODES: `${BASE_URL}/api/documentpreparationcodes`,
  DOCUMENT_PREPARATION_MAIL: `${BASE_URL}/api/documentpreparationmail`,
  DOCUMENT_SINGLE: `${BASE_URL}/api/document`,
  DRAFT: `${BASE_URL}/api/drafts`,
  DRAFTDUPLICATE: `${BASE_URL}/api/draftduplicatecheck`,
  DRAFT_CREATE: `${BASE_URL}/api/draft/new`,
  DRAFT_SINGLE: `${BASE_URL}/api/draft`,
  DUEDATE: `${BASE_URL}/api/duedatemasters`,
  DUEDATE_CREATE: `${BASE_URL}/api/duedatemaster/new`,
  DUEDATE_SINGLE: `${BASE_URL}/api/duedatemaster`,
  DUPLICATECANDIDATEFACEDETECT: `${BASE_URL}/api/duplicatecandidatefacecheck`,
  DUPLICATECANDIDATEFACEDETECTVISITOR: `${BASE_URL}/api/duplicatecandidatefacecheckvisitor`,
  DUPLICATEFACEDETECT: `${BASE_URL}/api/duplicatefacecheck`,
  DUPLICATIONTEMPLATECONTROLPANEL: `${BASE_URL}/api/duplicatetemplatecontrolpanel`,
  DYNAMICEVENT_CONTROLLER: `${BASE_URL}/api/queryeventsonpc`,
  DYNAMICUSER_CONTROLLER: `${BASE_URL}/api/dynamicqueryuserapi`,
  DYNAMIC_USERACTIVITY_CONTROLLER: `${BASE_URL}/api/dynamicqueryuseractivitycontroller`,
  DYNAMIC_USERACTIVITY_LIVESCREEN_CONTROLLER: `${BASE_URL}/api/dynamicqueryuseractivitylivescreencontroller`,
  DYNAMIC_USERACTIVITY_SCREENSHOT_CONTROLLER: `${BASE_URL}/api/dynamicqueryuseractivityscreeenshotcontroller`,
  EBMATERIALDETAILS: `${BASE_URL}/api/ebmaterialdetails`,
  EBMATERIALDETAILS_CREATE: `${BASE_URL}/api/ebmaterialdetail/new`,
  EBMATERIALDETAILS_SINGLE: `${BASE_URL}/api/ebmaterialdetail`,
  EBRATES: `${BASE_URL}/api/ebrates`,
  EBRATES_CREATE: `${BASE_URL}/api/ebrate/new`,
  EBRATES_SINGLE: `${BASE_URL}/api/ebrate`,
  EBREADINGDETAIL: `${BASE_URL}/api/ebreadingdetails`,
  EBREADINGDETAIL_CREATE: `${BASE_URL}/api/ebreadingdetail/new`,
  EBREADINGDETAIL_LIST: `${BASE_URL}/api/ebreadingdetailslist`,
  EBREADINGDETAIL_SINGLE: `${BASE_URL}/api/ebreadingdetail`,
  EBSERVICEMASTER: `${BASE_URL}/api/ebservicemasters`,
  EBSERVICEMASTER_CREATE: `${BASE_URL}/api/ebservicemaster/new`,
  EBSERVICEMASTER_SINGLE: `${BASE_URL}/api/ebservicemaster`,
  EB_READING_DATACOUNT: `${BASE_URL}/api/ebreadingdatacount`,
  EB_SERVICEFILTER: `${BASE_URL}/api/ebservicefilter`,
  EDUCATION: `${BASE_URL}/api/educations`,
  EDUCATIONSPECILIZATION: `${BASE_URL}/api/educationspecilizations`,
  EDUCATIONSPECILIZATION_CREATE: `${BASE_URL}/api/educationspecilization/new`,
  EDUCATIONSPECILIZATION_SINGLE: `${BASE_URL}/api/educationspecilization`,
  EDUCATION_CREATE: `${BASE_URL}/api/education/new`,
  EDUCATION_SINGLE: `${BASE_URL}/api/education`,
  EMPLOYEEASSET: `${BASE_URL}/api/employeeassets`,
  EMPLOYEEASSET_CREATE: `${BASE_URL}/api/employeeasset/new`,
  EMPLOYEEASSET_SINGLE: `${BASE_URL}/api/employeeasset`,
  EMPLOYEECODEOVERALLUPDATE: `${BASE_URL}/api/employeecodeoverallupdate`,
  EMPLOYEECODE_AUTOGENERATE: `${BASE_URL}/api/employeecodeautogenerate`,
  EMPLOYEEDOCUMENT: `${BASE_URL}/api/employeedocuments`,
  EMPLOYEEDOCUMENTIDCARD: `${BASE_URL}/api/employeedocumentsidcard`,
  EMPLOYEEDOCUMENTPRE: `${BASE_URL}/api/preemployeedocuments`,
  EMPLOYEEDOCUMENT_CREATE: `${BASE_URL}/api/employeedocuments/new`,
  EMPLOYEEDOCUMENT_SINGLE: `${BASE_URL}/api/employeedocument`,
  EMPLOYEEDOCUMENT_SINGLEWITHALLBYCOMMONID: `${BASE_URL}/api/employeedocumentcommonidwithall`,
  EMPLOYEEDOCUMENT_SINGLEWITHALLBYCOMMONIDONE: `${BASE_URL}/api/employeedocumentcommonidwithallnew`,
  EMPLOYEENAMEOVERALLUPDATE: `${BASE_URL}/api/employeenameoverallupdate`,
  EMPLOYEE_ASSET_ACCESS: `${BASE_URL}/api/employeeassetsaccess`,
  EMPLOYEE_HOME_COUNT: `${BASE_URL}/api/userwithstatushomecount`,
  EMPLOYEE_MISSINGFIELDS: `${BASE_URL}/api/getemployeemissingfields`,
  EMPLOYEE_NAMES_BASES_ON_STATUS_PAYRUN: `${BASE_URL}/api/employeenamesstatuswisepayrun`,
  EMPLOYEE_TEMPLATECREATION: `${BASE_URL}/api/employeetemplatecreations`,
  EMP_DETAILS: `${BASE_URL}/api/getemployeedetails`,
  EMP_DISTRIBUTION_HOME: `${BASE_URL}/api/employeeassetsaccesshome`,
  EMP_NAME: `${BASE_URL}/api/getemployeename`,
  ERAAMOUNTS: `${BASE_URL}/api/eraamounts`,
  ERAAMOUNTSASSIGNBRANCH: `${BASE_URL}/api/eraamountsassignbranch`,
  ERAAMOUNTSLIMITED: `${BASE_URL}/api/eraamountslimited`,
  ERAAMOUNTS_FILTER: `${BASE_URL}/api/eraamountsfilters`,
  ERAAMOUNTS_PAGINATED_TABLE_COLUMN_FILTER: `${BASE_URL}/api/eraamountspaginatedtablecolumnfilter`,
  ERAAMOUNT_CREATE: `${BASE_URL}/api/eraamount/new`,
  ERAAMOUNT_FILEDEL: `${BASE_URL}/api/eraamountbulkdel`,
  ERAAMOUNT_SINGLE: `${BASE_URL}/api/eraamount`,
  ERAAMOUNT_SORT: `${BASE_URL}/api/eraamountsort`,
  ERRORMODES: `${BASE_URL}/api/errormodes`,
  ERRORMODE_ALLOTED_LIST: `${BASE_URL}/api/errormodeallotedlist`,
  ERRORMODE_CREATE: `${BASE_URL}/api/errormode/new`,
  ERRORMODE_SINGLE: `${BASE_URL}/api/errormode`,
  ERRORMODE_UNALLOT_LIST: `${BASE_URL}/api/errormodeunallotlist`,
  ERROR_MODE_FILTER: `${BASE_URL}/api/errormodefilter`,
  ERROR_REASON_FILTER: `${BASE_URL}/api/penaltyerrorreasonfilter`,
  ERROR_REASON_FILTER_STATUS: `${BASE_URL}/api/errortypefilterstatus`,
  ERROR_TYPE_FILTER: `${BASE_URL}/api/errortypefilter`,
  ERROR_UPLOAD_CONFIRM_HIERARCHY: `${BASE_URL}/api/erroruploadconfirmhierarchylist`,
  EVENTDROPDOWN_CONTROLLER: `${BASE_URL}/api/alleventstorage`,
  EVENTSCREDENTIALSUPDATE: `${BASE_URL}/api/updatemultipleusers`,
  EXCEL: `${BASE_URL}/api/excels`,
  EXCELFILEUPLOADSTORE: `${BASE_URL}/api/upload`,
  EXCELFILTERED: `${BASE_URL}/api/excelsfiltered`,
  EXCELINDIVIDUALPRIMARYWORKORDER: `${BASE_URL}/api/excelindividualprimaryworkorder`,
  EXCELINDIVIDUALPRIMARYWORKORDER_ALLLIST: `${BASE_URL}/api/excelindividualprimaryalllist`,
  EXCELINDIVIDUALPRIMARYWORKORDER_NEARTAT: `${BASE_URL}/api/excelindividualprimaryneartat`,
  EXCELMAPCATEGORY: `${BASE_URL}/api/categoryexcel`,
  EXCELMAPDATA: `${BASE_URL}/api/excelmapdatas`,
  EXCELMAPDATAFILTERED: `${BASE_URL}/api/excelmapfiltered`,
  EXCELMAPDATAQUEUECHECK: `${BASE_URL}/api/timpoints/queue`,
  EXCELMAPDATARESPERSON: `${BASE_URL}/api/excelmaprespersondatas`,
  EXCELMAPDATARESPERSON_BRANCHTOTAL: `${BASE_URL}/api/excelbranchtotal`,
  EXCELMAPDATARESPERSON_CREATE: `${BASE_URL}/api/excelmaprespersondata/new`,
  EXCELMAPDATARESPERSON_INDIVIDUALWORKORDER: `${BASE_URL}/api/individualworkorderlist`,
  EXCELMAPDATARESPERSON_RESPERSONTOTAL: `${BASE_URL}/api/excelrespersontotal`,
  EXCELMAPDATARESPERSON_SINGLE: `${BASE_URL}/api/excelmaprespersondata`,
  EXCELMAPDATARESPERSON_TEAMTOTAL: `${BASE_URL}/api/excelteamtotal`,
  EXCELMAPDATA_CREATE: `${BASE_URL}/api/excelmapdata/new`,
  EXCELMAPDATA_SINGLE: `${BASE_URL}/api/excelmapdata`,
  EXCELMAP_MAPPERSON_DELETE: `${BASE_URL}/api/excelmapandpersondelete`,
  EXCELOLDPRIMARYWORKORDER: `${BASE_URL}/api/liveprimaryall`,
  EXCELOLDPRIMARYWORKORDER_ALLLIST: `${BASE_URL}/api/liveworkorderprimaryall`,
  EXCELOLDPRIMARYWORKORDER_NEARTAT: `${BASE_URL}/api/liveworkordernearprimaryall`,
  EXCELUNALLOTEDFILTERED: `${BASE_URL}/api/unallotedexcelqueuelist`,
  EXCELUNALLOTEDFILTERED_OVERALL: `${BASE_URL}/api/unallotedexcelqueuelistOverall`,
  EXCELUNALLOTEDRESPERSONFILTERED: `${BASE_URL}/api/unallotedexcelrespersonlist`,
  EXCEL_CREATE: `${BASE_URL}/api/excel/new`,
  EXCEL_SINGLE: `${BASE_URL}/api/excel`,
  EXITINTERVIEWTESTMASTER: `${BASE_URL}/api/exitinterviewtestmaster`,
  EXITINTERVIEWTESTMASTER_CREATE: `${BASE_URL}/api/exitinterviewtestmaster/new`,
  EXITINTERVIEW_LOGIN: `${BASE_URL}/api/exitinterviewlogin`,
  EXPECTEDACCURACYGETALL: `${BASE_URL}/api/expectedaccuracy`,
  EXPECTEDACCURACY_CREATE: `${BASE_URL}/api/expectedaccuracy/new`,
  EXPECTEDACCURACY_SINGLEBYDETAILS: `${BASE_URL}/api/expectedaccuracy/single`,
  EXPENSECATEGORY: `${BASE_URL}/api/expensecategories`,
  EXPENSECATEGORY_AUTOID: `${BASE_URL}/api/expensecategoriesautoid`,
  EXPENSECATEGORY_CREATE: `${BASE_URL}/api/expensecategory/new`,
  EXPENSECATEGORY_SINGLE: `${BASE_URL}/api/expensecategory`,
  EXPENSEREMINDER: `${BASE_URL}/api/expensereminders`,
  EXPENSEREMINDER_CREATE: `${BASE_URL}/api/expensereminder/new`,
  EXPENSEREMINDER_SINGLE: `${BASE_URL}/api/expensereminder`,
  EXPENSESALL: `${BASE_URL}/api/allexpenses`,
  EXPENSESUBCAT: `${BASE_URL}/api/expensesubcat`,
  EXPENSES_CREATE: `${BASE_URL}/api/expenses/new`,
  EXPENSES_HOME: `${BASE_URL}/api/allexpenseshome`,
  EXPENSES_SINGLE: `${BASE_URL}/api/expenses`,
  EXPENSE_AUTOID: `${BASE_URL}/api/expensesautoid`,
  EXPERIENCEBASE: `${BASE_URL}/api/expericencebases`,
  EXPERIENCEBASE_CREATE: `${BASE_URL}/api/expericencebase/new`,
  EXPERIENCEBASE_SINGLE: `${BASE_URL}/api/expericencebase`,
  FACEDETECTLOGINMODEL: `${BASE_URL}/api/weights`,
  FETCH_BANKRELEASE_PAYRUNLIST_MONTHWISE: `${BASE_URL}/api/fetchbankreleasepayrunlistmonthwise`,
  FETCH_ENABLEPAGES_BASED_PROJ_CATE_SUB: `${BASE_URL}/api/fetchenbalepagesbasedprojcatesub`,
  FETCH_FIELDNAME_BYPROCESS: `${BASE_URL}/api/fetchfieldnamebyprocess`,
  FETCH_HOLD_SALARY_CONFIRMLIST: `${BASE_URL}/api/holdsalaryyettoconfirmlist`,
  FETCH_PAYRUNLIST_MONTHWISE: `${BASE_URL}/api/fetchpayrunlistdatamonthwise`,
  FILTERALLUSER_RESUME: `${BASE_URL}/api/ageandlocationfilter`,
  FILTERASSETDETAIL: `${BASE_URL}/api/boardingassetdetails`,
  FILTEREBSERVICEMASTER: `${BASE_URL}/api/boardingebservicemasters`,
  FILTEREDVENDOREXCELUPLOAD: `${BASE_URL}/api/projectvendorsfilteredexcelupload`,
  FILTERED_EMPLOYEE_NAMES_PAYSLIP: `${BASE_URL}/api/filteredempnamespayslip`,
  FILTERED_PENALTYAMOUNTCONSOLIDATED: `${BASE_URL}/api/filterpenaltyamountconsolidated`,
  FILTERTEMPLATECONTROLPANEL: `${BASE_URL}/api/filtertemplatecontrolpanel`,
  FILTERTRAININGSUBCATEGORY: `${BASE_URL}/api/filtertrainingsubcategories`,
  FILTER_DOCUMENT_TRAINING: `${BASE_URL}/api/filterdocumentstraining`,
  FILTER_MANAGEPENALTYMONTH: `${BASE_URL}/api/filtermanagepenaltymonth`,
  FILTER_PAY_RUN_REPORT_DATA: `${BASE_URL}/api/filterpayrunreportdata`,
  FILTER_PAY_RUN_REPORT_EMPLOYEE_NAMES: `${BASE_URL}/api/filterpayrunemployeenames`,
  FILTER_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/filterproductionconsolidated`,
  FILTER_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/filterproductiontempconsolidated`,
  FIND_ATTANDANCESTATUS: `${BASE_URL}/api/findattendance`,
  FLAG_COUNT_SORT: `${BASE_URL}/api/othertasksortflag`,
  FLAG_COUNT_SORT_VIEW: `${BASE_URL}/api/othertasksortview`,
  FLOOR: `${BASE_URL}/api/floors`,
  FLOORCHECK: `${BASE_URL}/api/floorcheck`,
  FLOOROVERALLCHECK: `${BASE_URL}/api/flooroverallcheck`,
  FLOOROVERALLUPDATE: `${BASE_URL}/api/flooroverallupdate`,
  FLOOR_CREATE: `${BASE_URL}/api/floor/new`,
  FLOOR_SINGLE: `${BASE_URL}/api/floor`,
  FOOTERMESSAGESETTINGALL: `${BASE_URL}/api/footermessage`,
  FOOTERMESSAGESETTING_CREATE: `${BASE_URL}/api/footermessage/new`,
  FORMERUSERS: `${BASE_URL}/api/formerusernames`,
  GETACHEIVEDACCURACYINDIVIDUAL: `${BASE_URL}/api/achievedaccuracyindividual`,
  GETACHEIVEDACCURACYINDIVIDUALINTERNALSTATUSLIST: `${BASE_URL}/api/achievedaccuracyindividualclientstatus`,
  GETALLBANKVERIFICATIONUSERS: `${BASE_URL}/api/bankdetailsverfication/all`,
  GETALLCHECKLISTBYPAGINATION: `${BASE_URL}/api/mychecklistbypagination`,
  GETALLCHECKLISTBYPAGINATIONNOTASSIGNED: `${BASE_URL}/api/mychecklistbypaginationnotassigned`,
  GETALLFILTEREDUSERS: `${BASE_URL}/api/filteredusers`,
  GETALLSHIFTGROUPS: `${BASE_URL}/api/shiftgroupings`,
  GETALLSHIFTGROUPSBULK: `${BASE_URL}/api/shiftgroupingsbulk`,
  GETALLSHIFTGROUPSBYCONDITION: `${BASE_URL}/api/shiftgroupingsbycondition`,
  GETALL_MIKROTIK_INTERFACES: `${BASE_URL}/api/getallmikrotikinterfaces`,
  GETCHECKPOINTANDTIME: `${BASE_URL}/api/checkpointgettime`,
  GETCHECKPOINTANDTIMEUSECASES: `${BASE_URL}/api/checkpointgettimeusecases`,
  GETDEPARTMENTMONTHSETAUTOBYPAGINATION: `${BASE_URL}/api/deptmonthsetautobypagination`,
  GETDEPARTMENTMONTHSETBYPAGINATION: `${BASE_URL}/api/departmentmonthsetspaginationlimited`,
  GETDESIGNATIONMONTHSETBYPAGINATION: `${BASE_URL}/api/designationmonthsetspaginationlimited`,
  GETDOCUMENTS: `${BASE_URL}/api/employeedocumentcommonid`,
  GETFILTERDATA: `${BASE_URL}/api/nonproductionfilter`,
  GETFILTEREMOTEUSER: `${BASE_URL}/api/getfilterremoteuser`,
  GETFILTEREUSERDATA: `${BASE_URL}/api/getfilteralluserdata`,
  GETFILTEREUSERDATALONGABSEND: `${BASE_URL}/api/getfilteralluserdatalongabsend`,
  GETFILTEREUSERDATALONGABSEND_COMPLETED: `${BASE_URL}/api/getfilteralluserdatalongabsendcompleted`,
  GETFILTEREUSERDATALONGABSEND_HIRARCHY_COMPLETED: `${BASE_URL}/api/longabsentrestrictionhierarchylistcompleted`,
  GETHRMANAGERS: `${BASE_URL}/api/gethrmanagers`,
  GETOVERALL_EXCELMAPDATA: `${BASE_URL}/api/getoverallallottedqueue`,
  GETPOSTFIXMAILUSERS: `${BASE_URL}/api/postfixmailusers`,
  GETPROCESSMONTHSETBYPAGINATION: `${BASE_URL}/api/processmonthsetspaginationlimited`,
  GETPROFILES: `${BASE_URL}/api/employeeprofile`,
  GETSINGLESHIFTGROUP: `${BASE_URL}/api/shiftgrouping`,
  GETSINGLEUSERBRANCH: `${BASE_URL}/api/singleassignbranch`,
  GETSINGLEUSERDETAILSUSINGEMPID: `${BASE_URL}/api/bankdetailsverfication/single/empidbased`,
  GETSINGLEUSERDETAILSUSINGEMPIDARR: `${BASE_URL}/api/bankdetailsverfication/single/empidbasedarr`,
  GETTASKBOARDIDSTOUPDATE: `${BASE_URL}/api/gettaskidstoupdaterequirements`,
  GETTODAYSHIFT: `${BASE_URL}/api/todayshifts`,
  GETUSERASSIGNBRANCH: `${BASE_URL}/api/usersassignbranch`,
  GETUSERDATES: `${BASE_URL}/api/getallusersdates`,
  GETUSE_TOTALSHIFT_DAYS: `${BASE_URL}/api/getalluserstotalshiftdays`,
  GET_ALL_DOB: `${BASE_URL}/api/getallusersdob`,
  GET_ALL_DOJ: `${BASE_URL}/api/getallusersdoj`,
  GET_ALL_DOM: `${BASE_URL}/api/getallusersdom`,
  GET_ALL_ROCKETCHAT_CHANNELS: `${BASE_URL}/api/getrocketchatchannels`,
  GET_ALL_ROCKETCHAT_TEAMCHANNELGROUPING: `${BASE_URL}/api/getallrocketchatteamchannelgrouping`,
  GET_ALL_ROCKETCHAT_TEAMS: `${BASE_URL}/api/getrocketchatteams`,
  GET_ALL_USER_EMPLOYEE: `${BASE_URL}/api/getalluseremployee`,
  GET_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/allattendancecontrolcriteria`,
  GET_ATTENDANCE_CONTROL_CRITERIAASSIGNBRANCH: `${BASE_URL}/api/allattendancecontrolcriteriaassignbranch`,
  GET_ATTENDANCE_CONTROL_CRITERIA_LAST_INDEX: `${BASE_URL}/api/allattendancecontrolcriterialastindex`,
  GET_AUTOLOGOUT: `${BASE_URL}/api/allautologout`,
  GET_AUTOLOGOUTASSIGNBRANCH: `${BASE_URL}/api/allautologoutassignbranch`,
  GET_DAYPOINT_ID_BYDATE: `${BASE_URL}/api/getdaypointidbydate`,
  GET_DAYPOINT_ID_BYDATE_TEMP: `${BASE_URL}/api/getdaypointidbydatetemp`,
  GET_DAY_POINTS: `${BASE_URL}/api/daypoints`,
  GET_DAY_POINTS_LIMITED: `${BASE_URL}/api/daypointslimited`,
  GET_DAY_POINTS_LIMITED_DATE: `${BASE_URL}/api/checkdaypointdate`,
  GET_DAY_POINTS_LIMITED_DATE_ONLY: `${BASE_URL}/api/daypointslimiteddateonly`,
  GET_DAY_POINTS_LIMITED_DATE_TEMP: `${BASE_URL}/api/checkdaypointdatetemp`,
  GET_DAY_POINTS_LIMITED_TEMP: `${BASE_URL}/api/daypointslimitedtemp`,
  GET_DAY_POINTS_TEMP: `${BASE_URL}/api/daypointstemp`,
  GET_DAY_POINTS_TEMP_DATE_ONLY: `${BASE_URL}/api/daypointstempdateonly`,
  GET_EMPLOYEE_PRODUCTION_LAST_THREEMONHTS: `${BASE_URL}/api/getemployeeproductionlastthreemonths`,
  GET_EMPLOYEE_PRODUCTION_LAST_THREEMONHTS_TEMP: `${BASE_URL}/api/getemployeeproductionlastthreemonthstemp`,
  GET_INT_FORM_ALLOT: `${BASE_URL}/api/interviewanswerallots`,
  GET_INT_FORM_DESIGN: `${BASE_URL}/api/interviewformdesigns`,
  GET_LASTVALUEOVERALL_SETTINGS: `${BASE_URL}/api/lastgetoverallsettings`,
  GET_LOGINALLOT_ID_DETAILS: `${BASE_URL}/api/getloginallotiddetails`,
  GET_MANAGEPENALTYMONTH: `${BASE_URL}/api/managepenaltymonths`,
  GET_MIKROTIK_ACTIVE_CONNECTIONS: `${BASE_URL}/api/getmikrotikactiveconnections`,
  GET_MIKROTIK_IPPOOL: `${BASE_URL}/api/getmikrotikippool`,
  GET_MIKROTIK_IPPOOL_USEDADDRESSES: `${BASE_URL}/api/getmikrotikippoolusedaddresses`,
  GET_MIKROTIK_L2TPSERVER: `${BASE_URL}/api/getmikrotikl2tpserver`,
  GET_MIKROTIK_LOGS: `${BASE_URL}/api/getallmikrotiklogs`,
  GET_MIKROTIK_PPTPSERVER: `${BASE_URL}/api/getmikrotikpptpserver`,
  GET_MIKROTIK_PROFILES: `${BASE_URL}/api/getmikrotikprofiles`,
  GET_MIKROTIK_SECRETS: `${BASE_URL}/api/getmikrotiksecrets`,
  GET_MIKROTIK_SECRETS_LOCAL: `${BASE_URL}/api/getmikrotiksecretslocal`,
  GET_MIKROTIK_SECRETS_LOCAL_FILTER: `${BASE_URL}/api/getmikrotiksecretslocalfilter`,
  GET_MISMATCH_DATAS_ID: `${BASE_URL}/api/getmismatchdatasid`,
  GET_MISMATCH_DATAS_ID_TEMP: `${BASE_URL}/api/getmismatchdatasidtemp`,
  GET_MISMATCH_UPDATEDLIST: `${BASE_URL}/api/getmismatchupdatedlist`,
  GET_MISMATCH_UPDATEDLIST_TEMP: `${BASE_URL}/api/getmismatchupdatedlisttemp`,
  GET_MYCREATION: `${BASE_URL}/api/allmycreation`,
  GET_ORGIN_DATA: `${BASE_URL}/api/getorgindata`,
  GET_ORGSUBCAT: `${BASE_URL}/api/getorgsubcategoryref`,
  GET_OVERALL_SETTINGS: `${BASE_URL}/api/getoverallsettings`,
  GET_OVERALL_SETTINGSASSIGNBRANCH: `${BASE_URL}/api/getoverallsettingsassignbranch`,
  GET_OVERALL_USERSWITCH_INFO: `${BASE_URL}/api/getoverallusersswitch`,
  GET_PAYRUN_BULKDATA_EXCEL: `${BASE_URL}/api/getpayrunbulkdataexcel`,
  GET_PENALTYDAYUPLOAD: `${BASE_URL}/api/penaltydayuploads`,
  GET_PENALTY_CLIENT: `${BASE_URL}/api/penaltyclientamounts`,
  GET_PRODUCTIONDAY_LAST_THREEMONTHS: `${BASE_URL}/api/getempproductiondaylastthreemonths`,
  GET_PRODUCTIONDAY_LAST_THREEMONTHS_TEMP: `${BASE_URL}/api/getempproductiondaylastthreemonthstemp`,
  GET_PRODUCTIONUPDATE_CURRMONTH: `${BASE_URL}/api/getallproductionsbyuserforcurrmonth`,
  GET_PRODUCTIONUPDATE_CURRMONTH_TEMP: `${BASE_URL}/api/getallproductionsbyuserforcurrmonthtemp`,
  GET_PRODUCTIONUPDATE_CURRMONTH_VIEW: `${BASE_URL}/api/getallproductionsbyuserforcurrmonthview`,
  GET_PRODUCTIONUPDATE_CURRMONTH_VIEW_TEMP: `${BASE_URL}/api/getallproductionsbyuserforcurrmonthviewtemp`,
  GET_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/productionconsolidateds`,
  GET_PRODUCTION_SINGLE_DAYUSER: `${BASE_URL}/api/getproductionsignledayuser`,
  GET_PRODUCTION_SINGLE_DAYUSER_TEMP: `${BASE_URL}/api/getproductionsignledayusertemp`,
  GET_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/productiontempconsolidateds`,
  GET_ROCKETCHAT_ROLES: `${BASE_URL}/api/getrocketchatroles`,
  GET_SUBCAT: `${BASE_URL}/api/getsubcategoryref`,
  GET_TEMP_POINTS: `${BASE_URL}/api/temppoints`,
  GET_UNIQID_FROM_DATE_PRODUPLOAD: `${BASE_URL}/api/getuniqidfromdateprodupload`,
  GET_UNIQID_FROM_DATE_PRODUPLOAD_TEMP: `${BASE_URL}/api/getuniqidfromdateproduploadtemp`,
  GET_USER_RESPONSES: `${BASE_URL}/api/interviewuserresponses`,
  GET_WEEOFF_DAYS_FORUSER: `${BASE_URL}/api/getuserweekoffdays`,
  GET_WEEOFF_DAYS_FORUSER_ATTENDANCE: `${BASE_URL}/api/getuserweekoffdaysattendance`,
  GROUP: `${BASE_URL}/api/groups`,
  GROUPTODESIGNATIONCHECK: `${BASE_URL}/api/designation/groupcheck`,
  GROUP_CREATE: `${BASE_URL}/api/group/new`,
  GROUP_SINGLE: `${BASE_URL}/api/group`,
  HEADER_CONTROLS_FILTER: `${BASE_URL}/api/headercontrolsfilter`,
  HIERARCHI_SALARY_FILTER: `${BASE_URL}/api/getallhierarchylistsalary`,
  HIERARCHI_TEAM_DESIGNATION_CHECK: `${BASE_URL}/api/hierarchyteamdesignationcheck`,
  HIERARCHY_BASED_USER_LOGIN_STATUS: `${BASE_URL}/api/hierarchybasedemployeeloginstatus`,
  HIERARCHY_BASED_USER_LOGIN_STATUS_DEFAULT: `${BASE_URL}/api/hierarchybasedemployeeloginstatusdefault`,
  HIERARCHY_BASED_USER_RESTRICTION: `${BASE_URL}/api/hierarchybaseduserrestriction`,
  HIERARCHY_BASED_USER_WORKSTATION: `${BASE_URL}/api/hierarchybasedemployeeworkstationhira`,
  HIERARCHY_BASED_USER_WORKSTATION_DEFAULT: `${BASE_URL}/api/hierarchybasedemployeeworkstation`,
  HIERARCHY_DEISGNATIONLOG_RELATION: `${BASE_URL}/api/hierarchydesignationlogrelation`,
  HIERARCHY_PROCESSALOOT_TEAM_RELATION: `${BASE_URL}/api/hierarchyprocessteamrelation`,
  HIERARCHY_REPORTING_TO: `${BASE_URL}/api/hierarchyreportingtousers`,
  HIRERARCHI: `${BASE_URL}/api/hirerarchies`,
  HIRERARCHI_CREATE: `${BASE_URL}/api/hirerarchi/new`,
  HIRERARCHI_EDIT_MATCHCHECK: `${BASE_URL}/api/hierarchyeditmatchcheck`,
  HIRERARCHI_GROU_LIST_INDIVIDUAL: `${BASE_URL}/api/hirerarchigroupindividual`,
  HIRERARCHI_SINGLE: `${BASE_URL}/api/hirerarchi`,
  HOLD_SALARY_RELEASE: `${BASE_URL}/api/holdsalaryrelease`,
  HOLD_SALARY_RELEASE_CREATE: `${BASE_URL}/api/holdsalaryrelease/new`,
  HOLIDAYFILTER: `${BASE_URL}/api/holidayfilter`,
  IGNORED_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/ignorednotaddedbills`,
  IMAGEALL_ORGDOCUMENT: `${BASE_URL}/api/imageorgdocuments`,
  INCOME: `${BASE_URL}/api/incomes`,
  INCOME_CREATE: `${BASE_URL}/api/income/new`,
  INCOME_HOME: `${BASE_URL}/api/incomeshome`,
  INCOME_SINGLE: `${BASE_URL}/api/income`,
  INDIVIDUALALL_TASKFORUSER: `${BASE_URL}/api/individualtaskforusers`,
  INDIVIDUAL_SETTINGS_COMPANY: `${BASE_URL}/api/userindividual`,
  INDIVIDUAL_SETTINGS_LAST_INDEX: `${BASE_URL}/api/userindividuallastindex`,
  INDIVIDUAL_USER_LOGIN_STATUS: `${BASE_URL}/api/individualusersloginstatus`,
  INDIVIDUAL_WORKORDER_OTHERSFILTER: `${BASE_URL}/api/workorderindividualfilter`,
  INDIVIDUAL_WORKORDER_OTHERSLIST: `${BASE_URL}/api/workorderindividual`,
  INDUSERSLOGINOUT: `${BASE_URL}/api/userindividualattandances`,
  INTERNCOURSE: `${BASE_URL}/api/internCourses`,
  INTERNCOURSE_CREATE: `${BASE_URL}/api/internCourse/new`,
  INTERNCOURSE_SINGLE: `${BASE_URL}/api/internCourse`,
  INTERNUPDATE_STATUS: `${BASE_URL}/api/updateinternstatus`,
  INTERN_STATUS: `${BASE_URL}/api/deactiveallinterns`,
  INTERVIEWANSWERALLOT_OVERALL: `${BASE_URL}/api/overalledit/interviewanswerallot`,
  INTERVIEWANSWERALLOT_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewanswerallot`,
  INTERVIEWANSWERALLOT_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewanswerallot`,
  INTERVIEWCANDIDATES: `${BASE_URL}/api/allinterviewcandidates`,
  INTERVIEWMAIL_SENT: `${BASE_URL}/api/interviewmail`,
  INTERVIEWQUESTION: `${BASE_URL}/api/interviewquestions`,
  INTERVIEWQUESTIONGROUPING: `${BASE_URL}/api/interviewquestiongroupings`,
  INTERVIEWQUESTIONGROUPING_CREATE: `${BASE_URL}/api/interviewquestiongrouping/new`,
  INTERVIEWQUESTIONGROUPING_FILTER: `${BASE_URL}/api/interviewquestiongroupingsfilter`,
  INTERVIEWQUESTIONGROUPING_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewquestiongrouping`,
  INTERVIEWQUESTIONGROUPING_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewquestiongrouping`,
  INTERVIEWQUESTIONGROUPING_OVERALLEDIT: `${BASE_URL}/api/overalledit/interviewquestiongrouping`,
  INTERVIEWQUESTIONGROUPING_SINGLE: `${BASE_URL}/api/interviewquestiongrouping`,
  INTERVIEWQUESTIONSORDER: `${BASE_URL}/api/interviewquestionsorders`,
  INTERVIEWQUESTIONSORDER_CREATE: `${BASE_URL}/api/interviewquestionsorder/new`,
  INTERVIEWQUESTIONSORDER_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewquestionorder`,
  INTERVIEWQUESTIONSORDER_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewquestionorder`,
  INTERVIEWQUESTIONSORDER_SINGLE: `${BASE_URL}/api/interviewquestionsorder`,
  INTERVIEWQUESTION_CREATE: `${BASE_URL}/api/interviewquestion/new`,
  INTERVIEWQUESTION_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewquestions`,
  INTERVIEWQUESTION_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewquestions`,
  INTERVIEWQUESTION_OVERALLEDIT: `${BASE_URL}/api/overalledit/interviewquestions`,
  INTERVIEWQUESTION_SINGLE: `${BASE_URL}/api/interviewquestion`,
  INTERVIEWROUNDORDER: `${BASE_URL}/api/interviewroundorders`,
  INTERVIEWROUNDORDER_CREATE: `${BASE_URL}/api/interviewroundorder/new`,
  INTERVIEWROUNDORDER_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewroundorder`,
  INTERVIEWROUNDORDER_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewroundorder`,
  INTERVIEWROUNDORDER_SINGLE: `${BASE_URL}/api/interviewroundorder`,
  INTERVIEWSTATUSALLOT_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewstatusallot`,
  INTERVIEWSTATUSALLOT_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewstatusallot`,
  INTERVIEWSTATUSALLOT_OVERALLEDIT: `${BASE_URL}/api/overalledit/interviewstatusallot`,
  INTERVIEWTESTMASTER: `${BASE_URL}/api/interviewtestmaster`,
  INTERVIEWTESTMASTER_CREATE: `${BASE_URL}/api/interviewtestmaster/new`,
  INTERVIEWTESTMASTER_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewtestmaster`,
  INTERVIEWTESTMASTER_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewtestmaster`,
  INTERVIEWTESTMASTER_OVERALLEDIT: `${BASE_URL}/api/overalledit/interviewtestmaster`,
  INTERVIEWTYPEMASTER: `${BASE_URL}/api/interviewtypemasters`,
  INTERVIEWTYPEMASTER_CREATE: `${BASE_URL}/api/interviewtypemaster/new`,
  INTERVIEWTYPEMASTER_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewtypemaster`,
  INTERVIEWTYPEMASTER_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewtypemaster`,
  INTERVIEWTYPEMASTER_OVERALLEDIT: `${BASE_URL}/api/overalledit/interviewtypemaster`,
  INTERVIEWTYPEMASTER_SINGLE: `${BASE_URL}/api/interviewtypemaster`,
  INTERVIEWTYPINGQUESTION: `${BASE_URL}/api/allinterviewtypingquestions`,
  INTERVIEW_LOGIN: `${BASE_URL}/api/interviewlogin`,
  INTERVIEW_ROUND: `${BASE_URL}/api/fetchinterviewround`,
  INVALID_ERROR_ENTRY_HIERARCHY: `${BASE_URL}/api/invaliderrorentryhierarchy`,
  IPCATEGORY: `${BASE_URL}/api/ipcategories`,
  IPCATEGORY_CREATE: `${BASE_URL}/api/ipcategorie/new`,
  IPCATEGORY_SINGLE: `${BASE_URL}/api/ipcategorie`,
  IPMASTER: `${BASE_URL}/api/ipmasters`,
  IPMASTER_CREATE: `${BASE_URL}/api/ipmaster/new`,
  IPMASTER_DELETE: `${BASE_URL}/api/ipmasterdelete`,
  IPMASTER_SINGLE: `${BASE_URL}/api/ipmaster`,
  IPMASTER_UPDATE: `${BASE_URL}/api/ipmasterupdate`,
  IPMASTER_UPDATE_UPDATEBY: `${BASE_URL}/api/ipmasterupdateedby`,
  IP_MASTER_ACCESS: `${BASE_URL}/api/ipmastersaccess`,
  IP_SUBCAT: `${BASE_URL}/api/ipsubcategory`,
  JOBOPENINGSDESIGNATION: `${BASE_URL}/api/jobopeningdesignation`,
  JOBOPENING_CREATE: `${BASE_URL}/api/jobopening/new`,
  JOBOPENING_SINGLE: `${BASE_URL}/api/jobopening`,
  JOBOPNEING_FILTER: `${BASE_URL}/api/jobfilters`,
  JOB_OPEN_CANDIDATE_REGISTER: `${BASE_URL}/api/jobopenregister`,
  LABELNAME: `${BASE_URL}/api/labelname`,
  LABELNAME_CREATE: `${BASE_URL}/api/labelname/new`,
  LASTINDEX_VISITORS: `${BASE_URL}/api/lastindexvisitors`,
  LEADS: `${BASE_URL}/api/leads`,
  LEAD_CREATE: `${BASE_URL}/api/lead/new`,
  LEAD_SINGLE: `${BASE_URL}/api/lead`,
  LEAVECRITERIA: `${BASE_URL}/api/leavecriterias`,
  LEAVECRITERIA_CREATE: `${BASE_URL}/api/leavecriteria/new`,
  APPLYLEAVE_EMPLOYEEID_FILTER: `${BASE_URL}/api/applyleaveemployeeidfilter`,
  LEAVECRITERIA_FOR_APPLY_LEAVE: `${BASE_URL}/api/leavecriteriaforapplyleave`,
  LEAVECRITERIA_SINGLE: `${BASE_URL}/api/leavecriteria`,
  LEAVETYPE: `${BASE_URL}/api/leavetype`,
  LEAVETYPE_CREATE: `${BASE_URL}/api/leavetype/new`,
  LEAVEVERIFICATION: `${BASE_URL}/api/leaveverifications`,
  LEAVEVERIFICATION_ASSIGNBRANCH: `${BASE_URL}/api/leaveverificationsassignbranch`,
  LEAVEVERIFICATION_CREATE: `${BASE_URL}/api/leaveverification/new`,
  LEAVEVERIFICATION_SINGLE: `${BASE_URL}/api/leaveverification`,
  LEAVE_HOME: `${BASE_URL}/api/applyleaveshome`,
  LEAVE_HOME_LIST: `${BASE_URL}/api/applyleaveshomelist`,
  LISTOFDOCUMENT: `${BASE_URL}/api/listofdocuments`,
  LISTOFDOCUMENT_CREATE: `${BASE_URL}/api/listofdocument/new`,
  LISTOFDOCUMENT_SINGLE: `${BASE_URL}/api/listofdocument`,
  LISTPAGEACCESSMODES: `${BASE_URL}/api/listpageaccessmode`,
  LISTPAGEACCESSMODESAGGREGATION: `${BASE_URL}/api/listpageaccessmodeaggregation`,
  LISTPAGEACCESSMODE_CREATE: `${BASE_URL}/api/listpageaccessmode/new`,
  LIVE_SCREEN_UPDATE: `${BASE_URL}/api/update-livescreen-status`,
  LIVE_SCREEN_VIEW: `${BASE_URL}/api/livescreen`,
  LOAN: `${BASE_URL}/api/loan`,
  LOANBYASSIGNBRANCH: `${BASE_URL}/api/loanbyassignbrach`,
  LOAN_CREATE: `${BASE_URL}/api/loan/new`,
  LOAN_HOME: `${BASE_URL}/api/loanbyassignbrachhome`,
  LOCATION: `${BASE_URL}/api/locations`,
  LOCATIONGROUPING: `${BASE_URL}/api/locationgroupings`,
  LOCATIONGROUPING_CREATE: `${BASE_URL}/api/locationgrouping/new`,
  LOCATIONGROUPING_SINGLE: `${BASE_URL}/api/locationgrouping`,
  LOCATIONOVERALLUPDATE: `${BASE_URL}/api/locationoverallupdate`,
  LOCATIONWISEBRANCH: `${BASE_URL}/api/locationwisebranch`,
  LOCATIONWISE_ALL: `${BASE_URL}/api/locationwiseall`,
  LOCATION_CREATE: `${BASE_URL}/api/location/new`,
  LOCATION_SINGLE: `${BASE_URL}/api/location`,
  LOGALLUSER: `${BASE_URL}/api/alluserslog`,
  LOGINALLOTLOG: `${BASE_URL}/api/loginallotlog`,
  LOGINOUT: `${BASE_URL}/api/attandances`,
  LOGINOUT_CREATE: `${BASE_URL}/api/attandance/new`,
  LOGINOUT_SINGLE: `${BASE_URL}/api/attandance`,
  LOGINOUT_STATUSFALSE: `${BASE_URL}/api/attandancefalse`,
  LOGINOUT_STATUSTRUE: `${BASE_URL}/api/attandancetrue`,
  LOGINOUT_USERID: `${BASE_URL}/api/attandanceid`,
  LOGIN_ALLOT_HIERARCHY_LIST: `${BASE_URL}/api/productionloginallothierarchyfilter`,
  LONGABSENTRESTRICTION_HIERARCHYLIST: `${BASE_URL}/api/longabsentrestrictionhierarchylist`,
  LONG_ABSENT_HOME: `${BASE_URL}/api/longabsentrestrictionhierarchylistHome`,
  MAILCONFIGURATIONALL: `${BASE_URL}/api/mailconfigurationall`,
  MAILCONFIGURATION_CREATE: `${BASE_URL}/api/mailconfiguration/new`,
  MAILCONFIGURATION_SINGLE: `${BASE_URL}/api/mailconfiguration`,
  MAINPAGE: `${BASE_URL}/api/mainpages`,
  MAINPAGE_CREATE: `${BASE_URL}/api/mainpage/new`,
  MAINPAGE_SINGLE: `${BASE_URL}/api/mainpage`,
  MAINTENANCE_ACCESS: `${BASE_URL}/api/maintentancesaccess`,
  MAINTENANCE_FOR_USER_ASSIGNID: `${BASE_URL}/api/maintenanceforuserassignuser`,
  MAINTENANCE_HOME: `${BASE_URL}/api/sortedtaskmaintenanceforusershome`,
  MAINTENANCE_HOME_LIST: `${BASE_URL}/api/sortedtaskmaintenanceforusershomelist`,
  MAINTENTANCE: `${BASE_URL}/api/maintentances`,
  MAINTENTANCE_ACTIVE: `${BASE_URL}/api/maintentancesactive`,
  MAINTENTANCE_CREATE: `${BASE_URL}/api/maintentance/new`,
  MAINTENTANCE_SINGLE: `${BASE_URL}/api/maintentance`,
  MANAGE: `${BASE_URL}/api/manages`,
  MANAGEASSIGNEDMODE: `${BASE_URL}/api/manageassignedmode`,
  MANAGEASSIGNEDMODE_OVERALL: `${BASE_URL}/api/manageassignedmodeoverall`,
  MANAGEASSIGNEDMODE_OVERALL_BULKDELETE: `${BASE_URL}/api/manageassignedmodeoverallbulkdelete`,
  MANAGEASSIGNED_CREATE: `${BASE_URL}/api/manageassignedmode/new`,
  MANAGECATEGORY: `${BASE_URL}/api/managecategorys`,
  MANAGECATEGORYGETALL: `${BASE_URL}/api/managecategorypercentage`,
  MANAGECATEGORYPERCENTAGE_CREATE: `${BASE_URL}/api/managecategorypercentage/new`,
  MANAGECATEGORY_CREATE: `${BASE_URL}/api/managecategory/new`,
  MANAGECATEGORY_SINGLE: `${BASE_URL}/api/managecategory`,
  MANAGECATEGORY_SORT: `${BASE_URL}/api/managecategoryssort`,
  MANAGECLIENTDETAILS: `${BASE_URL}/api/manageclientdetailss`,
  MANAGECLIENTDETAILS_CREATE: `${BASE_URL}/api/manageclientdetails/new`,
  MANAGECLIENTDETAILS_SINGLE: `${BASE_URL}/api/manageclientdetails`,
  MANAGECOMPANY: `${BASE_URL}/api/managecompany`,
  MANAGECOMPANY_CREATE: `${BASE_URL}/api/managecompany/new`,
  MANAGEIDLEWORK: `${BASE_URL}/api/manageidleworks`,
  MANAGEIDLEWORK_CREATE: `${BASE_URL}/api/manageidlework/new`,
  MANAGEIDLEWORK_SINGLE: `${BASE_URL}/api/manageidlework`,
  MANAGEMATERIAL: `${BASE_URL}/api/managematerials`,
  MANAGEMATERIAL_CREATE: `${BASE_URL}/api/managematerial/new`,
  MANAGEMATERIAL_SINGLE: `${BASE_URL}/api/managematerial`,
  MANAGEOTHERTASK: `${BASE_URL}/api/manageothertasks`,
  MANAGEOTHERTASK_CREATE: `${BASE_URL}/api/manageothertask/new`,
  MANAGEOTHERTASK_SINGLE: `${BASE_URL}/api/manageothertask`,
  MANAGEPOWERSHUTDOWNTYPE: `${BASE_URL}/api/managepowershutdowntype`,
  MANAGEPOWERSHUTDOWNTYPE_CREATE: `${BASE_URL}/api/managepowershutdowntype/new`,
  MANAGESHORTAGEMASTER: `${BASE_URL}/api/manageshortagemasters`,
  MANAGESHORTAGEMASTER_CREATE: `${BASE_URL}/api/manageshortagemaster/new`,
  MANAGESHORTAGEMASTER_SINGLE: `${BASE_URL}/api/manageshortagemaster`,
  MANAGESHORTAGEMASTER_SORT: `${BASE_URL}/api/manageshortagemasterssort`,
  MANAGESTOCKITEMS: `${BASE_URL}/api/managestockitems`,
  MANAGESTOCKITEMS_CREATE: `${BASE_URL}/api/managestockitems/new`,
  MANAGETICKETGROUPING: `${BASE_URL}/api/allticketgrouping`,
  MANAGETICKETGROUPING_CREATE: `${BASE_URL}/api/createticketgrouping`,
  MANAGETICKETGROUPING_SINGLE: `${BASE_URL}/api/singleticketgrouping`,
  MANAGE_CREATE: `${BASE_URL}/api/manage/new`,
  MANAGE_SINGLE: `${BASE_URL}/api/manage`,
  MANPOWER: `${BASE_URL}/api/allmanpowers`,
  MANPOWERAREAFILTER: `${BASE_URL}/api/manpowerareas`,
  MANPOWER_CREATE: `${BASE_URL}/api/manpower/new`,
  MANPOWER_FLOOR_FILTER: `${BASE_URL}/api/floormanpowers`,
  MANPOWER_SINGLE: `${BASE_URL}/api/manpower`,
  MANUALALL_TASKFORUSER: `${BASE_URL}/api/manualtaskforusers`,
  MANUAL_CLIENT_INFO: `${BASE_URL}/api/manualclientinfos`,
  MANUAL_CLIENT_INFO_CREATE: `${BASE_URL}/api/manualclientinfo/new`,
  MANUAL_CLIENT_INFO_FILTER: `${BASE_URL}/api/manualclientinfosfilter`,
  MANUAL_CLIENT_INFO_FILTER_SELFREPORT: `${BASE_URL}/api/manualclientinfosfilterselfreport`,
  MANUAL_CLIENT_INFO_LIMITED: `${BASE_URL}/api/manualclientinfoslimited`,
  MANUAL_CLIENT_INFO_SINGLE: `${BASE_URL}/api/manualclientinfo`,
  MANUAL_STOCKPURCHASE: `${BASE_URL}/api/manualstocks`,
  MANUAL_STOCKPURCHASE_CREATE: `${BASE_URL}/api/manualstock/new`,
  MANUAL_STOCKPURCHASE_SINGLE: `${BASE_URL}/api/manualstock`,
  MANUAL_STOCK_ACCESS: `${BASE_URL}/api/manualstocksaccess`,
  MANUAL_STOCK_ACCESS_PAGINATION: `${BASE_URL}/api/manualstocksaccessstock`,
  MASTERFIELDNAME: `${BASE_URL}/api/masterfieldnames`,
  MASTERFIELDNAME_CREATE: `${BASE_URL}/api/masterfieldname/new`,
  MASTERFIELDNAME_SINGLE: `${BASE_URL}/api/masterfieldname`,
  MATTERMOST_ALL_TEAM_CHANNELS: `${BASE_URL}/api/getmattermostallteamchannels`,
  MATTERMOST_CHANNEL_NAMES: `${BASE_URL}/api/getmattermostchannelnames`,
  MATTERMOST_CREATE_CHANNEL: `${BASE_URL}/api/createmattermostchannel`,
  MATTERMOST_CREATE_TEAM: `${BASE_URL}/api/createmattermostteam`,
  MATTERMOST_DEACTIVATE_USER: `${BASE_URL}/api/deactivatemattermostuser`,
  MATTERMOST_DELETE_CHANNEL: `${BASE_URL}/api/deletemattermostchannel`,
  MATTERMOST_DELETE_TEAM: `${BASE_URL}/api/deletemattermostteam`,
  MATTERMOST_RESTORE_CHANNEL: `${BASE_URL}/api/restoremattermostchannel`,
  MATTERMOST_RESTORE_TEAM: `${BASE_URL}/api/restoremattermostteam`,
  MATTERMOST_TEAM_NAMES: `${BASE_URL}/api/getmattermostteamnames`,
  MATTERMOST_UPDATE_CHANNEL: `${BASE_URL}/api/updatemattermostchannel`,
  MATTERMOST_UPDATE_TEAM: `${BASE_URL}/api/updatemattermostteam`,
  MEETINGCONFIGURATIONALL: `${BASE_URL}/api/meetingconfigurationall`,
  MEETINGCONFIGURATION_CREATE: `${BASE_URL}/api/meetingconfiguration/new`,
  MEETINGCONFIGURATION_SINGLE: `${BASE_URL}/api/meetingconfiguration`,
  MEETINGMASTER: `${BASE_URL}/api/meetingmasters`,
  MEETINGMASTER_CREATE: `${BASE_URL}/api/meetingmaster/new`,
  MEETINGMASTER_SINGLE: `${BASE_URL}/api/meetingmaster`,
  MEETING_DELETE: `${BASE_URL}/api/deletemultipleschedulemeeting`,
  MEETING_PARTICIPANTS: `${BASE_URL}/api/getparticipantsformeeting`,
  MINIMUMPOINTS: `${BASE_URL}/api/minimumpointss`,
  MINIMUMPOINTSACCESSBRANCH: `${BASE_URL}/api/minimumpointssaccessbranch`,
  MINIMUMPOINTS_BULKDELETE: `${BASE_URL}/api/minimumpointsbulkdelete`,
  MINIMUMPOINTS_SORT: `${BASE_URL}/api/minimumpointssort`,
  MINIMUMPOINT_CREATE: `${BASE_URL}/api/minimumpoints/new`,
  MINIMUMPOINT_SINGLE: `${BASE_URL}/api/minimumpoints`,
  MINIMUM_HOME: `${BASE_URL}/api/getalluserstotalshiftdayshome`,
  MODULE: `${BASE_URL}/api/modules`,
  MODULEFILTERREPORTINGTOHEADER: `${BASE_URL}/api/modulereportingtoheaerfilter`,
  MODULELIMIT: `${BASE_URL}/api/moduleslimit`,
  MODULES_DROP: `${BASE_URL}/api/moduleDrop`,
  MODULETASKCHECK: `${BASE_URL}/api/moduletaskcheck`,
  MODULETASKCHECKEDIT: `${BASE_URL}/api/moduletaskcheckedit`,
  MODULETOSUBMODULEMODULECHECK: `${BASE_URL}/api/submodule/checkmodule`,
  MODULETOTASKMODULECHECK: `${BASE_URL}/api/task/checkmodule`,
  MODULE_CREATE: `${BASE_URL}/api/module/new`,
  MODULE_SINGLE: `${BASE_URL}/api/module`,
  MULTIPLEPENALTYERRORUPLOAD_SINGLE: `${BASE_URL}/api/multiplepenaltyerroruploads`,
  MULTIPLEPENALTYTOTALFIELDUPLOAD_SINGLE: `${BASE_URL}/api/multiplepenaltytotalfieldupload`,
  MULTIPLE_BULK_ERROR_UPLOAD_SINGLE: `${BASE_URL}/api/multiplebulkerroruploads`,
  MYCHECKLIST: `${BASE_URL}/api/mychecklist`,
  MYCHECKLISTVIEW: `${BASE_URL}/api/checklistview`,
  MYCHECKLIST_CREATE: `${BASE_URL}/api/mychecklist/new`,
  MYCHECKLIST_SINGLE: `${BASE_URL}/api/mychecklist`,
  MYCHECKLIST_SINGLEBYOBJECTID: `${BASE_URL}/api/mychecklist/usingobjectid`,
  MYFIELDVERIFICATION: `${BASE_URL}/api/myfieldverifications`,
  MYFIELDVERIFICATIONASSIGNBRANCH: `${BASE_URL}/api/myfieldverificationsassignbranch`,
  MYFIELDVERIFICATION_CREATE: `${BASE_URL}/api/myfieldverification/new`,
  MYFIELDVERIFICATION_SINGLE: `${BASE_URL}/api/myfieldverification`,
  MYVERIFICATION: `${BASE_URL}/api/myverifications`,
  MYVERIFICATIONASSIGNEDBRANCH: `${BASE_URL}/api/myverificationsassignbranchuser`,
  MYVERIFICATION_CREATE: `${BASE_URL}/api/myverification/new`,
  MYVERIFICATION_SINGLE: `${BASE_URL}/api/myverification`,
  MY_PRODUCTION_INDIVIDUAL_FILTERED: `${BASE_URL}/api/myproductionindividual`,
  NEW_OTHERPAYMENTS: `${BASE_URL}/api/otherpayment/new`,
  NEW_SCHEDULEPAYMENTMASTER: `${BASE_URL}/api/schedulepaymentmaster/new`,
  NEW_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/schedulepaymentnotaddedbills/new`,
  NONPRODUCTION: `${BASE_URL}/api/nonproduction`,
  NONPRODUCTIONUNITALLOT: `${BASE_URL}/api/nonproductionunitallot`,
  NONPRODUCTIONUNITALLOT_CREATE: `${BASE_URL}/api/nonproductionunitallot/new`,
  NONPRODUCTIONUNITRATEGETALL: `${BASE_URL}/api/nonproductionunitrate`,
  NONPRODUCTIONUNITRATE_CREATE: `${BASE_URL}/api/nonproductionunitrate/new`,
  NONPRODUCTION_CREATE: `${BASE_URL}/api/nonproduction/new`,
  NONSCHEDULETASKFORUSER: `${BASE_URL}/api/nonscheduletaskforuserlog`,
  NONSCHEDULLOGREASSIGNTASKFORUSER: `${BASE_URL}/api/nonschedulelogreassignforuser`,
  NOTADDED_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/notaddednotaddedbills`,
  NOTASSIGNHIERARCHYLISTDATA: `${BASE_URL}/api/notassignhierarchylistdata`,
  NOTASSIGN_HIERARCHY_DEISGNATIONLOG: `${BASE_URL}/api/notassignhierarchydata`,
  NOTASSIGN_HIERARCHY_DEISGNATIONLOG_BACKEND: `${BASE_URL}/api/notassignhierarchylistdatabackend`,
  NOTASSIGN_HIERARCHY_DEISGNATIONLOG_FILTERED: `${BASE_URL}/api/notassignhierarchydatafiltered`,
  NOTCLOCKIN_HOME_COUNT: `${BASE_URL}/api/userhomecountnotclockin`,
  NOTCLOCKIN_HOME_COUNT_LIST: `${BASE_URL}/api/userhomecountnotclockinlist`,
  NOTICEPERIODAPPLY: `${BASE_URL}/api/noticeperiodapplies`,
  NOTICEPERIODAPPLYBYASSIGNBRANCH: `${BASE_URL}/api/noticeperiodappliesbyassignbranch`,
  NOTICEPERIODAPPLY_CREATE: `${BASE_URL}/api/noticeperiodapply/new`,
  NOTICEPERIODAPPLY_FOR_LEAVE: `${BASE_URL}/api/noticeperiodappliesforleave`,
  NOTICEPERIODAPPLY_SINGLE: `${BASE_URL}/api/noticeperiodapply`,
  NOTICEREASON: `${BASE_URL}/api/noticereasons`,
  NOTICEREASON_CREATE: `${BASE_URL}/api/noticereason/new`,
  NOTICEREASON_SINGLE: `${BASE_URL}/api/noticereason`,
  NOTICE_HIERARCHY_LIST: `${BASE_URL}/api/noticehierarchylist`,
  NOTIFICATION: `${BASE_URL}/api/notifications`,
  NOTIFICATION_CREATE: `${BASE_URL}/api/notification/new`,
  NOTIFICATION_SINGLE: `${BASE_URL}/api/notification`,
  NOTIFICATION_SOUND_SINGLE: `${BASE_URL}/api/notificationsound`,
  NOTTASKASSIGN_BOARD_LIST_TABLEDATA: `${BASE_URL}/api/nottaskassignboardliststabledata`,
  ONBOARDING_SALARY_FIX_FILTERREPORT: `${BASE_URL}/api/onboardingsalaryfixfilter`,
  ONPROGRESSALLJOBOPENINGS: `${BASE_URL}/api/onprogressjobopenings`,
  ONPROGRESSALL_TASKFORUSER: `${BASE_URL}/api/onprogresstaskforusers`,
  ORGANISATION: `${BASE_URL}/api/addexistworks`,
  ORGANISATION_CREATE: `${BASE_URL}/api/addexistwork/new`,
  ORGANISATION_SINGLE: `${BASE_URL}/api/addexistwork`,
  ORGCATEGORYDOCUMENT: `${BASE_URL}/api/organizationdocumentcategorys`,
  ORGCATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/organizationdocumentcategory/new`,
  ORGCATEGORYDOCUMENT_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/organizationcategory`,
  ORGCATEGORYDOCUMENT_OVERALLEDIRT: `${BASE_URL}/api/organizationcategorydocumentedit`,
  ORGCATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/organizationdocumentcategory`,
  ORGDOCUMENT_CREATE: `${BASE_URL}/api/orgdocuments/new`,
  ORGDOCUMENT_OVERALLDELETE: `${BASE_URL}/api/orgdocumentdelete`,
  ORGDOCUMENT_SINGLE: `${BASE_URL}/api/orgdocument`,
  ORIGINALMISMATCHFILTER: `${BASE_URL}/api/originalmismatchfilter`,
  ORIGINALMISMATCHUPDATEFLAGCOUNT: `${BASE_URL}/api/originalmismatchfilter/updateflagcount`,
  ORIGINALUNMATCHFILTER: `${BASE_URL}/api/originalunmatchfilter`,
  ORIGINALUNMATCHFILTERCOUNTCHECK: `${BASE_URL}/api/originalunmatchfiltercountcheck`,
  OTHERPENALTYCONTROL: `${BASE_URL}/api/otherpenaltycontrols`,
  OTHERPENALTYCONTROL_CREATE: `${BASE_URL}/api/otherpenaltycontrol/new`,
  OTHERPENALTYCONTROL_SINGLE: `${BASE_URL}/api/otherpenaltycontrol`,
  OTHERWORKORDERALL: `${BASE_URL}/api/otherworkorderall`,
  OTHERWORKORDERALL_LIST: `${BASE_URL}/api/excelWorkOrderOtherList`,
  OTHER_WORKORDER_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/orderhierarchyworkorderdefault`,
  OTHER_WORKORDER_HIERARCHY_FILTER: `${BASE_URL}/api/orderhierarchyworkorder`,
  OVERALLACHEIVEDACCURACYINDIVIDUAL_SORT: `${BASE_URL}/api/overallachievedaccuracyindividualsort`,
  OVERALLAREACHECK: `${BASE_URL}/api/overallareascheck`,
  OVERALLEDIT_VENDOR: `${BASE_URL}/api/getoverallvendormasteredit`,
  OVERALLEDIT_VENDOR_BULKDELETE: `${BASE_URL}/api/getoverallbulkdeletevendormasteredit`,
  OVERALLEDIT_VENDOR_DELETE: `${BASE_URL}/api/getoverallDeletevendormasteredit`,
  OVERALLLOCATIONCHECK: `${BASE_URL}/api/locationoverallcheck`,
  OVERALLQUEUE: `${BASE_URL}/api/getoverallqueuemasteredit`,
  OVERALLQUEUE_BULK_DELETE: `${BASE_URL}/api/getoverallqueuemasterbulkdelete`,
  OVERALLQUEUE_DELETE: `${BASE_URL}/api/getoverallqueuemasterdelete`,
  OVERALLQUEUE_GROUP_BULK_DELETE: `${BASE_URL}/api/getoverallqueuegroupbulkdelete`,
  OVERALLQUEUE_GROUP_DELETE: `${BASE_URL}/api/getoverallqueuegroupdelete`,
  OVERALLQUEUE_GROUP_EDIT: `${BASE_URL}/api/getoverallqueuegroupdit`,
  OVERALLSORT_ASSET: `${BASE_URL}/api/overallassettablesort`,
  OVERALLSORT_EBREADING: `${BASE_URL}/api/ebreadingdetailpagenationsort`,
  OVERALLSORT_STOCK: `${BASE_URL}/api/stockpurchasesort`,
  OVERALLUNITCHECK: `${BASE_URL}/api/unitoverallcheck`,
  OVERALL_AREA: `${BASE_URL}/api/getoverallareas`,
  OVERALL_ASSET_LIMITED: `${BASE_URL}/api/overallassetlimited`,
  OVERALL_ASSIGNEDBY: `${BASE_URL}/api/overallassignedby`,
  OVERALL_ASSIGNEDBY_BULKDELETE: `${BASE_URL}/api/overallassignedbybulkdelete`,
  OVERALL_BRANCH: `${BASE_URL}/api/getoverallbranch`,
  OVERALL_BULK_CATEGORY_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeletecategorymasters`,
  OVERALL_BULK_DUEDATE_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleteduedatemasters`,
  OVERALL_BULK_PRIORITY_MASTER_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleteprioritymasters`,
  OVERALL_BULK_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overallBulkreasonmastersdelete`,
  OVERALL_BULK_REQUIRED_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleterequiredmasters`,
  OVERALL_BULK_RESOLVER_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overallBulkresolverreasonmastersdelete`,
  OVERALL_BULK_SELFCHECK_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleteselfcheckmasters`,
  OVERALL_BULK_SUBSUBCOMPONENT_TICKET_DELETE: `${BASE_URL}/api/overallBulksubsubcomponentdelete`,
  OVERALL_BULK_TYPEGROUP_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeletetypegroupmasters`,
  OVERALL_BULK_TYPEMASTER_TICKET_DELETE: `${BASE_URL}/api/overalleditBulktypemastersdelete`,
  OVERALL_CATEGORYANDSUBCATEGORY_NONPRODUCT: `${BASE_URL}/api/categoryandsubcategoryoverall`,
  OVERALL_CATEGORYEXCEL: `${BASE_URL}/api/getoverallcategorytmaster`,
  OVERALL_CATEGORYMASTER: `${BASE_URL}/api/overallcategorymaster`,
  OVERALL_CATEGORYMASTER_BULKDELETE: `${BASE_URL}/api/overallcategorymastersupportbulkdelete`,
  OVERALL_CATEGORYMASTER_SINGLE: `${BASE_URL}/api/overallcategorymastersupport`,
  OVERALL_CATEGORYTHEMEGROUPING: `${BASE_URL}/api/categorythemegroupingoverall`,
  OVERALL_CATEGORY_TICKET: `${BASE_URL}/api/overalleditcategorymasters`,
  OVERALL_CATEGORY_TICKET_DELETE: `${BASE_URL}/api/overalldeletecategorymasters`,
  OVERALL_COMPANY: `${BASE_URL}/api/getoverallcompany`,
  OVERALL_DELETE_EBMATERIAL: `${BASE_URL}/api/ebmaterialdetailoverlldelte`,
  OVERALL_DELETE_EBREADING: `${BASE_URL}/api/ebreadingdetailoveralldelete`,
  OVERALL_DELETE_EBUSEINSTRUMENT: `${BASE_URL}/api/ebuseinstrumentoveralldelete`,
  OVERALL_DELETE_IPCATEGORY: `${BASE_URL}/api/ipcategorieoveralldelete`,
  OVERALL_DELETE_PASSWORDCATEGORY: `${BASE_URL}/api/passwordcategorydelete`,
  OVERALL_DELETE_PASSWORDIPMASTER: `${BASE_URL}/api/overalldeleteip`,
  OVERALL_DELETE_POWERSTATION: `${BASE_URL}/api/powerstationoveralldelete`,
  OVERALL_DEPARTMENT: `${BASE_URL}/api/getoveralldepartments`,
  OVERALL_DESCRIPTION: `${BASE_URL}/api/overalldescriptions`,
  OVERALL_DESIGNATION: `${BASE_URL}/api/getoveralldesignation`,
  OVERALL_DESIGNATIONGROUP: `${BASE_URL}/api/getoveralldesignationgroup`,
  OVERALL_DOCUEMENT_CATEGORY_DELETE: `${BASE_URL}/api/overalldocumentcategorybulkdelete`,
  OVERALL_DUEDATE_TICKET: `${BASE_URL}/api/overalleditduedatemasters`,
  OVERALL_DUEDATE_TICKET_DELETE: `${BASE_URL}/api/overalldeleteduedatemasters`,
  OVERALL_EDITCOMPANYDOMAINUSER: `${BASE_URL}/api/getoveralleditcompanydomainuser`,
  OVERALL_EDITPAIDDATEFIX: `${BASE_URL}/api/getoveralleditpaiddatefix`,
  OVERALL_EDITPAYRUNLIST: `${BASE_URL}/api/getoveralleditpayrunlist`,
  OVERALL_EDITREPORTINGHEADER: `${BASE_URL}/api/getoveralleditreportingheader`,
  OVERALL_EDIT_EBSERVICEMASTER: `${BASE_URL}/api/ebservicemastersoveralledit`,
  OVERALL_EDIT_IPCATEGORY: `${BASE_URL}/api/ipcategorieoveralledit`,
  OVERALL_EDIT_PASSWORDCATEGORY: `${BASE_URL}/api/passwordcategoryedit`,
  OVERALL_EDIT_POWERSTATION: `${BASE_URL}/api/powerstationoveralledit`,
  OVERALL_FLOOR: `${BASE_URL}/api/getoverallfloor`,
  OVERALL_INTERACTORMANAGETYPEG: `${BASE_URL}/api/managetypepgoverallmanagetypeg`,
  OVERALL_INTERACTORMODE: `${BASE_URL}/api/interactormodeoverall`,
  OVERALL_INTERACTORMODE_BULKDELETE: `${BASE_URL}/api/interactormodeoveralloverallBulkdelete`,
  OVERALL_INTERACTORPURPOSE: `${BASE_URL}/api/interactorpurposeoverall`,
  OVERALL_INTERACTORPURPOSE_BULKDELETE: `${BASE_URL}/api/interactorpurposeoverallbulkdelete`,
  OVERALL_INTERACTORTYPE: `${BASE_URL}/api/interactortypeoverall`,
  OVERALL_INTERACTORTYPEPURPOSEGROUP_BULKDELETE: `${BASE_URL}/api/managetypepgoverallmanagetypegbulkdelete`,
  OVERALL_INTERACTORTYPE_BULKDELETE: `${BASE_URL}/api/interactortypeoveralloverallBulkdelete`,
  OVERALL_MODULE: `${BASE_URL}/api/overallmodule`,
  OVERALL_NONPRODUCTIONCATEGORYMASTER_BULKDELETE: `${BASE_URL}/api/categoryandsubcategoryoverallnonproduction`,
  OVERALL_POSTERCATEGORY_BULKDELETE: `${BASE_URL}/api/overallcategorymasterbulkdelete`,
  OVERALL_POSTERMESSAGESETTINGOVERALL: `${BASE_URL}/api/postermessagesettingoverall`,
  OVERALL_PRIORITY: `${BASE_URL}/api/overallpriority`,
  OVERALL_PRIORITY_TICKET: `${BASE_URL}/api/overalleditprioritymasters`,
  OVERALL_PRIORITY_TICKET_DELETE: `${BASE_URL}/api/overalldeleteprioritymasters`,
  OVERALL_PROJECT: `${BASE_URL}/api/overallproj`,
  OVERALL_PROJECTEXCEL: `${BASE_URL}/api/getoverallprojectmaster`,
  OVERALL_PROJECTEXCEL_BULK_DELETE: `${BASE_URL}/api/getoverallbulkdeleteprojectmaster`,
  OVERALL_QUALIFICATIONS: `${BASE_URL}/api/getoverallqualification`,
  OVERALL_RAISEPROBLEM: `${BASE_URL}/api/overallraise`,
  OVERALL_REASONMASTER_TICKET: `${BASE_URL}/api/overalleditreasonmasters`,
  OVERALL_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overalleditreasonmastersdelete`,
  OVERALL_REFERENCE_CATEGORY_DELETE: `${BASE_URL}/api/overallreferencecategorybulkdelete`,
  OVERALL_REQUIRED_TICKET_DELETE: `${BASE_URL}/api/overalldeleterequiredmasters`,
  OVERALL_RESOLVER_REASONMASTER_TICKET: `${BASE_URL}/api/overalleditresolverreasonmasters`,
  OVERALL_RESOLVER_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overallresolverreasonmastersdelete`,
  OVERALL_ROLE: `${BASE_URL}/api/overallrole`,
  OVERALL_SELFCHECK_TICKET_DELETE: `${BASE_URL}/api/overalldeleteselfcheckmasters`,
  OVERALL_SHIFT: `${BASE_URL}/api/getoverallshift`,
  OVERALL_SKILLSET: `${BASE_URL}/api/getoverallskillset`,
  OVERALL_SUBCATEGORY: `${BASE_URL}/api/overallcategory`,
  OVERALL_SUBCATEGORYEXCEL: `${BASE_URL}/api/getoverallsubcategorytmaster`,
  OVERALL_SUBCATEGORYMASTER: `${BASE_URL}/api/overallsubcategorymaster`,
  OVERALL_SUBMODULE: `${BASE_URL}/api/overallsubmodule`,
  OVERALL_SUBPROJECT: `${BASE_URL}/api/overallsubproj`,
  OVERALL_SUBSUBCOMPONENT_TICKET: `${BASE_URL}/api/overalleditsubsubcomponent`,
  OVERALL_SUBSUBCOMPONENT_TICKET_DELETE: `${BASE_URL}/api/overallsubsubcomponentdelete`,
  OVERALL_TASK_CATEGORY_TICKET: `${BASE_URL}/api/taskcategoryOverallEdit`,
  OVERALL_TASK_CATEGORY_TICKET_DELETE: `${BASE_URL}/api/taskcategoryOverallDelete`,
  OVERALL_TASK_SUBCATEGORY_TICKET: `${BASE_URL}/api/tasksubcategoryOverallEdit`,
  OVERALL_TASK_SUBCATEGORY_TICKET_DELETE: `${BASE_URL}/api/tasksubcategoryOverallDelete`,
  OVERALL_TEAMS: `${BASE_URL}/api/getoverallteam`,
  OVERALL_TEMPLATE_CREATION: `${BASE_URL}/api/overalledittemplatecreation`,
  OVERALL_TEMPLATE_CREATION_DELETE: `${BASE_URL}/api/overalledittemplatecreationdelete`,
  OVERALL_TIMEPOINTS_BULK_DELETE: `${BASE_URL}/api/getoverallbulkdeletetimepoints`,
  OVERALL_TRAINING_CATEGORY_TICKET: `${BASE_URL}/api/trainingcategoryOverallEdit`,
  OVERALL_TRAINING_DETAILS_EDIT: `${BASE_URL}/api/trainingDetailsOverallEdit`,
  OVERALL_TRAINING_OVERALL_CATEGORY_TICKET: `${BASE_URL}/api/trainingcategoryOverallDelete`,
  OVERALL_TRAINING_SUBCATEGORY_TICKET: `${BASE_URL}/api/trainingsubcategoryOverallEdit`,
  OVERALL_TRAINING_SUBOVERALL_CATEGORY_TICKET: `${BASE_URL}/api/trainingsubcategoryOverallDelete`,
  OVERALL_TYPEGROUP_TICKET: `${BASE_URL}/api/overalledittypegroupmasters`,
  OVERALL_TYPEGROUP_TICKET_DELETE: `${BASE_URL}/api/overalldeletetypegroupmasters`,
  OVERALL_TYPEMASTER_TICKET: `${BASE_URL}/api/overalledittypemasters`,
  OVERALL_TYPEMASTER_TICKET_DELETE: `${BASE_URL}/api/overalledittypemastersdelete`,
  OVERALL_UNITS: `${BASE_URL}/api/getoverallunits`,
  OVERALL_VENDORMASTER: `${BASE_URL}/api/getoverallvendormaster`,
  PAGEMODEL: `${BASE_URL}/api/pagemodels`,
  PAGEMODEL_CREATE: `${BASE_URL}/api/pagemodel/new`,
  PAGEMODEL_LIMITED: `${BASE_URL}/api/pagemodelslimited`,
  PAGEMODEL_SINGLE: `${BASE_URL}/api/pagemodel`,
  PAGETYPE: `${BASE_URL}/api/pagetypes`,
  PAGETYPE_CREATE: `${BASE_URL}/api/pagetype/new`,
  PAGETYPE_MAIN: `${BASE_URL}/api/pagetypemaindrop`,
  PAGETYPE_MAIN_EST_TIME: `${BASE_URL}/api/pagetypemainEsttime`,
  PAGETYPE_MAIN_MULTI: `${BASE_URL}/api/pagetypemaindropmulti`,
  PAGETYPE_SINGLE: `${BASE_URL}/api/pagetype`,
  PAGETYPE_SUBPAGE_DROP: `${BASE_URL}/api/pagetypesubpagedrop`,
  PAGETYPE_SUBPAGE_DROP_MULTI: `${BASE_URL}/api/pagetypesubpagedropmulti`,
  PAGETYPE_SUBSUBPAGE_DROP: `${BASE_URL}/api/pagetypesubsubpagedrop`,
  PAIDDATEFIX: `${BASE_URL}/api/paiddatefixs`,
  PAIDDATEFIX_CREATE: `${BASE_URL}/api/paiddatefix/new`,
  PAIDDATEFIX_FITLERED: `${BASE_URL}/api/paiddatefixfitlered`,
  PAIDDATEFIX_FUTUREDATEONLY: `${BASE_URL}/api/paiddatefixedfuturedatesonly`,
  PAIDDATEFIX_SINGLE: `${BASE_URL}/api/paiddatefix`,
  PAIDDATEFIX_SORT: `${BASE_URL}/api/paiddatefixssort`,
  PAIDDATEFIX_UPDATE: `${BASE_URL}/api/paiddatefixupdatesingle`,
  PAIDDATEMODE: `${BASE_URL}/api/paiddatemodes`,
  PAIDDATEMODE_CREATE: `${BASE_URL}/api/paiddatemode/new`,
  PAIDDATEMODE_SINGLE: `${BASE_URL}/api/paiddatemode`,
  PAIDSTATUSFIX: `${BASE_URL}/api/paidstatusfixs`,
  PAIDSTATUSFIX_CREATE: `${BASE_URL}/api/paidstatusfix/new`,
  PAIDSTATUSFIX_FILTERED: `${BASE_URL}/api/paidstatusfixsfiltered`,
  PAIDSTATUSFIX_FILTEREDDATA: `${BASE_URL}/api/filterpaidstatusfixdatas`,
  PAIDSTATUSFIX_LIMITED: `${BASE_URL}/api/paidstatusfixslimited`,
  PAIDSTATUSFIX_SINGLE: `${BASE_URL}/api/paidstatusfix`,
  PAIDSTATUSFIX_SORT: `${BASE_URL}/api/paidstatusfixsort`,
  PASSCATEGORYDOCUMENT: `${BASE_URL}/api/passwordcategories`,
  PASSCATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/passwordcategory/new`,
  PASSCATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/passwordcategory`,
  PASSWORD_ACCESS_CONTROL: `${BASE_URL}/api/allpasswordsaccess`,
  PASSWORD_ACTION_EMPLOYEE: `${BASE_URL}/api/allpasswordsactionemployee`,
  PAYMENTDUEREMINDER: `${BASE_URL}/api/paymentduereminder`,
  PAYRUNCONTROL: `${BASE_URL}/api/payruncontrols`,
  PAYRUNCONTROLBYASSIGNBRANCH: `${BASE_URL}/api/payruncontrolsbyassignbranch`,
  PAYRUNCONTROL_CREATE: `${BASE_URL}/api/payruncontrol/new`,
  PAYRUNCONTROL_LIMITED: `${BASE_URL}/api/payruncontrolslimited`,
  PAYRUNCONTROL_SINGLE: `${BASE_URL}/api/payruncontrol`,
  PAYRUNLIST: `${BASE_URL}/api/payrunlists`,
  PAYRUNLISTYEARMONTH: `${BASE_URL}/api/yearmonthpayrunlist`,
  PAYRUNLIST_CREATE: `${BASE_URL}/api/payrunlist/new`,
  PAYRUNLIST_DUPECHECK: `${BASE_URL}/api/payrunlistdupecheck`,
  PAYRUNLIST_LIMITED: `${BASE_URL}/api/payrunlistlimited`,
  PAYRUNLIST_LIMITED_FILTERED: `${BASE_URL}/api/payrunlistlimitedfiltered`,
  PAYRUNLIST_SENT_FIXSALARYDATE: `${BASE_URL}/api/payrunlistsentsalaryfixdate`,
  PAYRUNLIST_SINGLE: `${BASE_URL}/api/payrunlist`,
  PAYRUNLIST_SINGLE_USER_LASTHREE_MONTHS: `${BASE_URL}/api/payrunlistsingleuserlastthreemonths`,
  PAYSLIP_DOCUMENT_MAIL: `${BASE_URL}/api/payslipdocumentmail`,
  PAYSLIP_DOCUMENT_PREPARATION: `${BASE_URL}/api/payslipdocuments`,
  PAYSLIP_DOCUMENT_PREPARATION_ASSIGNBRANCH: `${BASE_URL}/api/payslipdocumentsassignbranch`,
  PAYSLIP_DOCUMENT_PREPARATION_CREATE: `${BASE_URL}/api/payslipdocument/new`,
  PAYSLIP_DOCUMENT_PREPARATION_SINGLE: `${BASE_URL}/api/payslipdocument`,
  PAYSLIP_EMPLOYEE_NAMES: `${BASE_URL}/api/payslipusernames`,
  PAYSLIP_GET_SINGLE_SIGNATURE_SEAL: `${BASE_URL}/api/payslipgetsinglesealandsignature`,
  PAYSLIP_RELATED_PAYRUNDATA: `${BASE_URL}/api/paysliprelatedpayrundatas`,
  PAYSLIP_SIGNATURE_SEAL: `${BASE_URL}/api/payslipsealandsignature`,
  PENALTYAMOUNTCONSOLIDATED: `${BASE_URL}/api/allpenaltyamountconsolidate`,
  PENALTYAMOUNTCONSOLIDATED_CREATE: `${BASE_URL}/api/penaltyamountconsolidate/new`,
  PENALTYAMOUNTCONSOLIDATED_SINGLE: `${BASE_URL}/api/penaltyamountconsolidate`,
  PENALTYCLIENTERROR: `${BASE_URL}/api/penaltyclienterror`,
  PENALTYCLIENTERROR_CREATE: `${BASE_URL}/api/penaltyclienterror/new`,
  PENALTYCLIENTERROR_SINGLE: `${BASE_URL}/api/penaltyclienterror`,
  PENALTYCLIENTERROR_DATE_FILTER: `${BASE_URL}/api/penaltyclienterrordatefilter`,
  PENALTYCLIENTERROR_DATE_FILTER_WITH_ASGN_BRANCH: `${BASE_URL}/api/penaltyclienterrordatefilterwithasgnbranch`,
  PENALTYERRORCONTROLGETALL: `${BASE_URL}/api/penaltyerrorcontrol`,
  PENALTYERRORCONTROL_CREATE: `${BASE_URL}/api/penaltyerrorcontrol/new`,
  PENALTYERRORREASONGETALL: `${BASE_URL}/api/penaltyerrorreason`,
  PENALTYERRORREASON_CREATE: `${BASE_URL}/api/penaltyerrorreason/new`,
  PENALTYERRORUPLOADGETALL: `${BASE_URL}/api/errortypes`,
  PENALTYERRORUPLOADS: `${BASE_URL}/api/penaltyerroruploads`,
  PENALTYERRORUPLOADS_CREATE: `${BASE_URL}/api/penaltyerroruploads/new`,
  PENALTYERRORUPLOADS_DATE_FILTER: `${BASE_URL}/api/penaltyerroruploadsdatefilter`,
  PENALTYERRORUPLOADS_PROJECT_BASED_FILTER: `${BASE_URL}/api/penaltyerroruploadsprojectbasedfilter`,
  PENALTYERRORUPLOAD_CREATE: `${BASE_URL}/api/errortype/new`,
  PENALTYERRORUPLOAD_SINGLE: `${BASE_URL}/api/errortype`,
  PENALTYTOTALFIELDUPLOAD: `${BASE_URL}/api/penaltytotalfielduploads`,
  PENALTYTOTALFIELDUPLOAD_CREATE: `${BASE_URL}/api/penaltytotalfieldupload/new`,
  PENALTYTOTALFIELDUPLOAD_DATEFILTER: `${BASE_URL}/api/penaltytotalfielduploaddatefilters`,
  PENALTYTOTALFIELDUPLOAD_LOGIN_PROJECT: `${BASE_URL}/api/penaltytotalfielduploadloginproject`,
  PENALTYTOTALFIELDUPLOAD_SINGLE: `${BASE_URL}/api/penaltytotalfieldupload`,
  PENALTY_DAY_FILTERED: `${BASE_URL}/api/penaltydayuploadsfiltered`,
  PENALTY_TOTAL_FIELD_VALIDATION_ENTRY_FILTER: `${BASE_URL}/api/penaltytotalfielduploadsvalidation`,
  PENDINGINTERVIEWCHECKLIST: `${BASE_URL}/api/interviewpendingchecklist`,
  PENDINGINTERVIEWCHECKLISTLEAVE: `${BASE_URL}/api/interviewpendingchecklistleave`,
  PENDINGINTERVIEWCHECKLISTPERMISSION: `${BASE_URL}/api/interviewpendingchecklistpermission`,
  PENDINGLONGABSENTCHECKLIST: `${BASE_URL}/api/pendingchecklistlongabsent`,
  PENDING_TASK_COUNT: `${BASE_URL}/api/getpendingtaskcount`,
  PERMISSIONS: `${BASE_URL}/api/persmissions`,
  PERMISSIONS_HOME: `${BASE_URL}/api/persmissionshome`,
  PERMISSION_CREATE: `${BASE_URL}/api/persmission/new`,
  PERMISSION_SINGLE: `${BASE_URL}/api/persmission`,
  POSTERGENERATE: `${BASE_URL}/api/postergenerates`,
  POSTERGENERATEGROUP: `${BASE_URL}/api/postergenerategroup`,
  POSTERGENERATEGROUP_GETBIRTHDAY: `${BASE_URL}/api/postergenerategroupgetbirthday`,
  POSTERGENERATEGROUP_GETWEDDINGANNIVERSARY: `${BASE_URL}/api/postergenerategroupgetweddingannivesary`,
  POSTERGENERATE_CREATE: `${BASE_URL}/api/postergenerate/new`,
  POSTERGENERATE_SINGLE: `${BASE_URL}/api/postergenerate`,
  POSTERMESSAGESETTINGALL: `${BASE_URL}/api/postermessagesetting`,
  POSTERMESSAGESETTING_CREATE: `${BASE_URL}/api/postermessagesetting/new`,
  POSTSINGLEBANKVERIFICATIONUSER: `${BASE_URL}/api/bankdetailsverfication/new`,
  POWERSTATIONFILTER: `${BASE_URL}/api/powerstationfilter`,
  PRIMARYWORKORDERALL: `${BASE_URL}/api/primaryworkorderall`,
  PRIMARYWORKORDERNEARTATDATA: `${BASE_URL}/api/primaryworkorderneartatdata`,
  PRIMARYWORKORDEROVERTATDATA: `${BASE_URL}/api/primaryworkorderovertatdata`,
  PRIMARY_DEFAULT_HIERARCHY_WORKORDERALL: `${BASE_URL}/api/primaryhierarchyworkorderalldefault`,
  PRIMARY_DEFAULT_HIERARCHY_WORKORDERNEARTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderneartatdatadefault`,
  PRIMARY_DEFAULT_HIERARCHY_WORKORDEROVERTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderovertatdatadefault`,
  PRIMARY_HIERARCHY_WORKORDERALL: `${BASE_URL}/api/primaryhierarchyworkorderall`,
  PRIMARY_HIERARCHY_WORKORDERNEARTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderneartatdata`,
  PRIMARY_HIERARCHY_WORKORDEROVERTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderovertatdata`,
  PRIORITY: `${BASE_URL}/api/priorities`,
  PRIORITYMASTER: `${BASE_URL}/api/prioritymastermasters`,
  PRIORITYMASTER_CREATE: `${BASE_URL}/api/prioritymastermaster/new`,
  PRIORITYMASTER_SINGLE: `${BASE_URL}/api/prioritymastermaster`,
  PRIORITYTOTASKPRIORRITYCHECK: `${BASE_URL}/api/task/checkpriority`,
  PRIORITY_CREATE: `${BASE_URL}/api/priority/new`,
  PRIORITY_SINGLE: `${BASE_URL}/api/priority`,
  PROCESSDROP: `${BASE_URL}/api/processdropdwons`,
  PROCESSMONTHSET_ALL: `${BASE_URL}/api/processmonthsets`,
  PROCESSMONTHSET_CREATE: `${BASE_URL}/api/processmonthset/new`,
  PROCESSMONTHSET_SINGLE: `${BASE_URL}/api/processmonthset`,
  PROCESSQUEUENAME_SORT: `${BASE_URL}/api/processqueuenamesort`,
  PROCESS_AND_TEAM_SORT: `${BASE_URL}/api/processteamsort`,
  PROCESS_LIMITED_BY_COMPANY_BRANCH: `${BASE_URL}/api/processlimitedbycompanybranch`,
  PRODDAY_DELETE_BYDATE: `${BASE_URL}/api/proddaydeletebydateorg`,
  PRODUCTIONCLIENTRATEALL: `${BASE_URL}/api/productionclientrate`,
  PRODUCTIONCLIENTRATE_CREATE: `${BASE_URL}/api/productionclientrate/new`,
  PRODUCTIONDAY_TEMP_LASTDATE: `${BASE_URL}/api/productiondaytemplastdate`,
  PRODUCTIONPROCESSQUEUEGETALL: `${BASE_URL}/api/productionprocessqueue`,
  PRODUCTIONPROCESSQUEUE_CREATE: `${BASE_URL}/api/productionprocessqueue/new`,
  PRODUCTIONPROCESSQUEUE_LIMITED_BYPROJECT: `${BASE_URL}/api/productionprocessqueuelimitedbyproject`,
  PRODUCTION_CATEGORY_CHECK_PRODUPLOAD: `${BASE_URL}/api/checkcategoryforprodupload`,
  PRODUCTION_DATE_FILTER: `${BASE_URL}/api/attendancedatefilter`,
  PRODUCTION_DAYPOINTS_CHECK_PRODDAY_DATE: `${BASE_URL}/api/getproddateusingdaypoints`,
  PRODUCTION_DAYPOINT_TEMP_LASTDATE: `${BASE_URL}/api/productiondaypointtemplast`,
  PRODUCTION_DAYS: `${BASE_URL}/api/productiondays`,
  PRODUCTION_DAYS_CHECKAFTER_DAYPOINTCREATE: `${BASE_URL}/api/productiondayscheckafterdaypointcreate`,
  PRODUCTION_DAYS_CHECKAFTER_DAYPOINTCREATE_TEMP: `${BASE_URL}/api/productiondayscheckafterdaypointcreatetemp`,
  PRODUCTION_DAYS_GETLIST_BY_DATE: `${BASE_URL}/api/productiondaylistsgetbydate`,
  PRODUCTION_DAYS_GETLIST_BY_DATE_TEMP: `${BASE_URL}/api/productiondaylistsgetbydatetemp`,
  PRODUCTION_DAYS_LIST: `${BASE_URL}/api/productiondaylists`,
  PRODUCTION_DAYS_LIST_TEMP: `${BASE_URL}/api/productiondayliststemp`,
  PRODUCTION_DAYS_TEMP: `${BASE_URL}/api/productiondaystemp`,
  PRODUCTION_DAYS_TEMP_CHECK_FILESTATUS: `${BASE_URL}/api/productiontempcheckfilestatus`,
  PRODUCTION_DAYS_TEMP_LIMITED: `${BASE_URL}/api/productiondaystemplimited`,
  PRODUCTION_DAY_CATEGORY_FILTER: `${BASE_URL}/api/productiondaycategoryidfilter`,
  PRODUCTION_DAY_CATEGORY_FILTER_TEMP: `${BASE_URL}/api/productiondaycategoryidfiltertemp`,
  PRODUCTION_DAY_CREATE: `${BASE_URL}/api/productionday/new`,
  PRODUCTION_DAY_CREATE_TEMP: `${BASE_URL}/api/productiondaytemp/new`,
  PRODUCTION_DAY_LIST_CREATE: `${BASE_URL}/api/productiondaylist/new`,
  PRODUCTION_DAY_LIST_CREATE_TEMP: `${BASE_URL}/api/productiondaylisttemp/new`,
  PRODUCTION_DAY_LIST_DELETE_UNIQID: `${BASE_URL}/api/productiondaylistdeleteuniqud`,
  PRODUCTION_DAY_LIST_DELETE_UNIQID_TEMP: `${BASE_URL}/api/productiondaylistdeleteuniqudtemp`,
  PRODUCTION_DAY_LIST_GET_DELETE_LIMITED: `${BASE_URL}/api/productiondaylistgetdeletelimited`,
  PRODUCTION_DAY_LIST_GET_DELETE_LIMITED_TEMP: `${BASE_URL}/api/productiondaylistgetdeletelimitedtemp`,
  PRODUCTION_DAY_LIST_GET_VIEW_LIMITED: `${BASE_URL}/api/productiondaylistgetviewlimited`,
  PRODUCTION_DAY_LIST_GET_VIEW_LIMITED_TEMP: `${BASE_URL}/api/productiondaylistgetviewlimitedtemp`,
  PRODUCTION_DAY_LIST_SINGLE: `${BASE_URL}/api/productiondaylist`,
  PRODUCTION_DAY_LIST_SINGLE_TEMP: `${BASE_URL}/api/productiondaylisttemp`,
  PRODUCTION_DAY_SINGLE: `${BASE_URL}/api/productionday`,
  PRODUCTION_DAY_SINGLE_TEMP: `${BASE_URL}/api/productiondaytemp`,
  PRODUCTION_DAY_UNIQID: `${BASE_URL}/api/productiondaysuniqid`,
  PRODUCTION_DAY_UNIQID_TEMP: `${BASE_URL}/api/productiondaysuniqidtemp`,
  PRODUCTION_INDIVIDUAL: `${BASE_URL}/api/productionindividuals`,
  PRODUCTION_INDIVIDUALMANUAL_EXCEL_OVERALL: `${BASE_URL}/api/manualstatusindividualexceloverall`,
  PRODUCTION_INDIVIDUAL_COMPLETED: `${BASE_URL}/api/complatedindividuallimited`,
  PRODUCTION_INDIVIDUAL_CREATE: `${BASE_URL}/api/productionindividual/new`,
  PRODUCTION_INDIVIDUAL_CREATE_BULK: `${BASE_URL}/api/productionindividualcreatebulk`,
  PRODUCTION_INDIVIDUAL_DATEFILTER: `${BASE_URL}/api/productionindividualdatefilter`,
  PRODUCTION_INDIVIDUAL_DUPECHECK: `${BASE_URL}/api/productionindividualdupecheck`,
  PRODUCTION_INDIVIDUAL_EXCEL: `${BASE_URL}/api/productionindividualexcel`,
  PRODUCTION_INDIVIDUAL_EXCEL_OVERALL: `${BASE_URL}/api/productionindividualexceloverall`,
  PRODUCTION_INDIVIDUAL_HIERARCHYFILTER: `${BASE_URL}/api/productionhierarchyfilter`,
  PRODUCTION_INDIVIDUAL_HIERARCHYFILTERANOTHER: `${BASE_URL}/api/getAllProductionHierarchyListanother`,
  PRODUCTION_INDIVIDUAL_HIERARCHYFILTER_HOME: `${BASE_URL}/api/productionhierarchyfilterhome`,
  PRODUCTION_INDIVIDUAL_LIMITED: `${BASE_URL}/api/productionindividuallimited`,
  PRODUCTION_INDIVIDUAL_MANUALSTATUS: `${BASE_URL}/api/manualstatusviceindividualsort`,
  PRODUCTION_INDIVIDUAL_ONPROGRESS: `${BASE_URL}/api/onprogressindividuallimited`,
  PRODUCTION_INDIVIDUAL_OVEALL_EXCEL: `${BASE_URL}/api/productionindividualoveallexcel`,
  PRODUCTION_INDIVIDUAL_OVEALL_EXCEL_PENDING: `${BASE_URL}/api/productionindividualoveallexcelpending`,
  PRODUCTION_INDIVIDUAL_PENDING: `${BASE_URL}/api/pendingindividuallimited`,
  PRODUCTION_INDIVIDUAL_SINGLE: `${BASE_URL}/api/productionindividual`,
  PRODUCTION_INDIVIDUAL_SORT: `${BASE_URL}/api/productionindividualsort`,
  PRODUCTION_MANUAL_FILTER: `${BASE_URL}/api/productionmanualuploadfilter`,
  PRODUCTION_MISMATCH_STATUS_DATEFILTER: `${BASE_URL}/api/productionmismatchstatusdatefilter`,
  PRODUCTION_MISMATCH_STATUS_DATEFILTER_TEMP: `${BASE_URL}/api/productionmismatchstatusdatefiltertemp`,
  PRODUCTION_ORGINAL: `${BASE_URL}/api/productionoriginals`,
  PRODUCTION_ORGINAL_CREATE: `${BASE_URL}/api/productionoriginal/new`,
  PRODUCTION_ORGINAL_LIMITED: `${BASE_URL}/api/productionoriginalslimited`,
  PRODUCTION_ORGINAL_LIMITED_FILTER: `${BASE_URL}/api/productionoriginalslimitedfilter`,
  PRODUCTION_ORGINAL_LIMITED_LASTTHREE: `${BASE_URL}/api/productionoriginallastthree`,
  PRODUCTION_ORGINAL_SINGLE: `${BASE_URL}/api/productionoriginal`,
  PRODUCTION_ORGINAL_UNIQID: `${BASE_URL}/api/productionoriginalslimiteduniqid`,
  PRODUCTION_ORIGINAL_UNITRATE_BULK_UPDATECATSUBCATEGORY: `${BASE_URL}/api/bulkproductionorgupdatecategorysubcategory`,
  PRODUCTION_REPORT_FILTER: `${BASE_URL}/api/productionreportfilter`,
  PRODUCTION_SUBCATEGORY_CHECK_PRODUPLOAD: `${BASE_URL}/api/checksubcategoryforprodupload`,
  PRODUCTION_TEMP: `${BASE_URL}/api/productionstemp`,
  PRODUCTION_TEMP_ATTENDANCES: `${BASE_URL}/api/productiontempattendancesfilter`,
  PRODUCTION_TEMP_CREATE: `${BASE_URL}/api/productiontemp/new`,
  PRODUCTION_TEMP_FILESTATUS: `${BASE_URL}/api/productiontempcheckfilestatusupload`,
  PRODUCTION_TEMP_FILTER: `${BASE_URL}/api/productiontempfilter`,
  PRODUCTION_TEMP_LASTDATE: `${BASE_URL}/api/productiontemplastdate`,
  PRODUCTION_TEMP_LIMITED: `${BASE_URL}/api/productiontemplimited`,
  PRODUCTION_TEMP_LIMITED_FILTER: `${BASE_URL}/api/productiontemplimitedfilter`,
  PRODUCTION_TEMP_LIMITED_LASTTHREE: `${BASE_URL}/api/productiontemplastthree`,
  PRODUCTION_TEMP_REPORT_FILTER: `${BASE_URL}/api/productiontempreportfilter`,
  PRODUCTION_TEMP_SINGLE: `${BASE_URL}/api/productiontemp`,
  PRODUCTION_TEMP_UNIQID: `${BASE_URL}/api/productiontemplimiteduniqid`,
  PRODUCTION_TEMP_UNITRATE_BULK_UPDATECATSUBCATEGORY: `${BASE_URL}/api/bulkproductiontempupdatecategorysubcategory`,
  PRODUCTION_TEMP_UPLOAD: `${BASE_URL}/api/productiontempuploadsall`,
  PRODUCTION_TEMP_UPLOAD_CREATE: `${BASE_URL}/api/productiontempuploadall/new`,
  PRODUCTION_TEMP_UPLOAD_DELETEMULTI: `${BASE_URL}/api/productiontempuploadalldeletemulti`,
  PRODUCTION_TEMP_UPLOAD_FILENAMELIST: `${BASE_URL}/api/productiontempuploadallfilenamelist`,
  PRODUCTION_TEMP_UPLOAD_FILENAMEONLY: `${BASE_URL}/api/productiontempuploadallfilenameonly`,
  PRODUCTION_TEMP_UPLOAD_FILENAMEONLY_BULKDOWNLOAD: `${BASE_URL}/api/productiontempuploadallfilenameonlybulkdownload`,
  PRODUCTION_TEMP_UPLOAD_GETDELETEDATAS: `${BASE_URL}/api/productiontempuploadallgetdeletedatas`,
  PRODUCTION_TEMP_UPLOAD_GETDELETEDATASALL: `${BASE_URL}/api/productiontempuploadallgetdeletedatasall`,
  PRODUCTION_TEMP_UPLOAD_OVERALL_FETCH_LIMITED: `${BASE_URL}/api/productiontempuploadalloverallfetchlimited`,
  PRODUCTION_TEMP_UPLOAD_OVERALL_FETCH_LIMITEDNEW: `${BASE_URL}/api/productiontempuploadoverallfetchlimitednew`,
  PRODUCTION_TEMP_UPLOAD_SINGLE: `${BASE_URL}/api/productiontempuploadall`,
  PRODUCTION_TIMESTUDY_DUPECHECK: `${BASE_URL}/api/timestudydupecheck`,
  PRODUCTION_UNALLOT_FILTER: `${BASE_URL}/api/productionunallotfilter`,
  PRODUCTION_UNALLOT_FILTER_TEMP: `${BASE_URL}/api/productiontempunallotfiltertemp`,
  PRODUCTION_UNALLOT_FILTER_VIEW: `${BASE_URL}/api/productionunallotfilterview`,
  PRODUCTION_UNALLOT_FILTER_VIEW_Manual: `${BASE_URL}/api/productionunallotfilterviewmanual`,
  PRODUCTION_UNALLOT_FILTER_VIEW_TEMP: `${BASE_URL}/api/productiontempviewfilter`,
  PRODUCTION_UNALLOT_FILTER_VIEW_TEMP_MANUAL: `${BASE_URL}/api/productiontempviewmanualfilter`,
  PRODUCTION_UNITRATE: `${BASE_URL}/api/unitsrate`,
  PRODUCTION_UNITRATE_CHECK_PRODUPLOAD: `${BASE_URL}/api/checkunitrateforprodupload`,
  PRODUCTION_UNITRATE_CREATE: `${BASE_URL}/api/unitrate/new`,
  PRODUCTION_UNITRATE_FILTER_CATEGORIES_LIMITED: `${BASE_URL}/api/unitratefiltercategorieslimited`,
  PRODUCTION_UNITRATE_FILTER_CATEGORY_LIMITED: `${BASE_URL}/api/unitratefiltercategorylimited`,
  PRODUCTION_UNITRATE_FILTER_GETMULTI: `${BASE_URL}/api/getprodunitrategetmulti`,
  PRODUCTION_UNITRATE_FILTER_LIMITED: `${BASE_URL}/api/unitratefilterlimited`,
  PRODUCTION_UNITRATE_LIMITED_PROD: `${BASE_URL}/api/unitrateprodlimited`,
  PRODUCTION_UNITRATE_PRODUPLOAD_LIMITED: `${BASE_URL}/api/productionunitrateproduploadlimited`,
  PRODUCTION_UNITRATE_SINGLE: `${BASE_URL}/api/unitrate`,
  PRODUCTION_UNITRATE_SORT: `${BASE_URL}/api/unitsratesort`,
  PRODUCTION_UPLOAD: `${BASE_URL}/api/productionuploads`,
  PRODUCTION_UPLOAD_BULKUPDATE_FLAGONLY: `${BASE_URL}/api/updatedbulkdatasflagonly`,
  PRODUCTION_UPLOAD_BULKUPDATE_FLAGONLY_TEMP: `${BASE_URL}/api/updatedbulkdatasflagonlytemp`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDFLAG: `${BASE_URL}/api/updatedbulkdatasunitandflag`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDFLAG_TEMP: `${BASE_URL}/api/updatedbulkdatasunitandflagtemp`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDSECTION: `${BASE_URL}/api/updatedbulkdatasunitandsection`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDSECTION_TEMP: `${BASE_URL}/api/updatedbulkdatasunitandsectiontemp`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITONLY: `${BASE_URL}/api/updatedbulkdatasunitonly`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITONLY_TEMP: `${BASE_URL}/api/updatedbulkdatasunitonlytemp`,
  PRODUCTION_UPLOAD_CHECKSTATUS: `${BASE_URL}/api/productionuploadcheckstatus`,
  PRODUCTION_UPLOAD_CHECKSTATUS_TEMP: `${BASE_URL}/api/productionuploadcheckstatustemp`,
  PRODUCTION_UPLOAD_CREATE: `${BASE_URL}/api/productionupload/new`,
  PRODUCTION_UPLOAD_DELETEMULTI: `${BASE_URL}/api/productionuploaddeletemulti`,
  PRODUCTION_UPLOAD_FILENAMELIST: `${BASE_URL}/api/productionuploadfilenamelist`,
  PRODUCTION_UPLOAD_FILENAMEONLY: `${BASE_URL}/api/productionuploadfilenameonly`,
  PRODUCTION_UPLOAD_FILENAMEONLY_BULKDOWNLOAD: `${BASE_URL}/api/productionuploadfilenameonlybulkdownload`,
  PRODUCTION_UPLOAD_FILTER: `${BASE_URL}/api/productionuploadfilter`,
  PRODUCTION_UPLOAD_GETDATAS_BYID: `${BASE_URL}/api/getproductionuploaddatasbyid`,
  PRODUCTION_UPLOAD_GETDATAS_BYID_MANUAL: `${BASE_URL}/api/getproductionuploaddatasbyidmanual`,
  PRODUCTION_UPLOAD_GETDATAS_BYID_MANUAL_TEMP: `${BASE_URL}/api/getproductionuploaddatasbyidmanualtemp`,
  PRODUCTION_UPLOAD_GETDATAS_BYID_TEMP: `${BASE_URL}/api/getproductionuploaddatasbyidtemp`,
  PRODUCTION_UPLOAD_GETDELETEDATAS: `${BASE_URL}/api/productionuploadgetdeletedatas`,
  PRODUCTION_UPLOAD_GETDELETEDATASALL: `${BASE_URL}/api/productionuploadgetdeletedatasall`,
  PRODUCTION_UPLOAD_GET_UNITRATEUPDATE_OVERALL_FETCH_LIMITED: `${BASE_URL}/api/productionuploadunitrateoverallfetchlimited`,
  PRODUCTION_UPLOAD_OVERALL_FETCH_LIMITED: `${BASE_URL}/api/productionuploadoverallfetchlimited`,
  PRODUCTION_UPLOAD_OVERALL_FETCH_LIMITEDNEW: `${BASE_URL}/api/productionuploadoverallfetchlimitednew`,
  PRODUCTION_UPLOAD_SINGLE: `${BASE_URL}/api/productionupload`,
  PROD_DAY_DELETE_BYDATE: `${BASE_URL}/api/proddaydeletebydate`,
  PROFFESIONALTAXMASTER_SORT: `${BASE_URL}/api/professionaltaxmastersort`,
  PROFFESIONALTAXMASTER_SORTBYASSIGNBRANCH: `${BASE_URL}/api/professionaltaxmastersortbyassignbranch`,
  PROJECT: `${BASE_URL}/api/projects`,
  PROJECTALLOCATION: `${BASE_URL}/api/projectallocations`,
  PROJECTALLOCATION_CREATE: `${BASE_URL}/api/projectallocation/new`,
  PROJECTALLOCATION_SINGLE: `${BASE_URL}/api/projectallocation`,
  PROJECTCATEGORY: `${BASE_URL}/api/projectcategorys`,
  PROJECTDETAILS: `${BASE_URL}/api/projectdetails`,
  PROJECTDETAILS_CREATE: `${BASE_URL}/api/projectdetail/new`,
  PROJECTDETAILS_SINGLE: `${BASE_URL}/api/projectdetail`,
  PROJECTESTIMATION: `${BASE_URL}/api/projectestimations`,
  PROJECTESTIMATION_CREATE: `${BASE_URL}/api/projectestimation/new`,
  PROJECTESTIMATION_SINGLE: `${BASE_URL}/api/projectestimation`,
  PROJECTLIMIT: `${BASE_URL}/api/projectslimit`,
  PROJECTMASTER: `${BASE_URL}/api/projectmasters`,
  PROJECTMASTERLIMIT: `${BASE_URL}/api/projectmasterlimit`,
  PROJECTMASTER_CREATE: `${BASE_URL}/api/projectmaster/new`,
  PROJECTMASTER_INDIVIDUAL: `${BASE_URL}/api/projectmasterindividual`,
  PROJECTMASTER_LIMITED: `${BASE_URL}/api/projectmasterslimitedname`,
  PROJECTMASTER_SINGLE: `${BASE_URL}/api/projectmaster`,
  PROJECTTEAMCHECK: `${BASE_URL}/api/project/teamcheck`,
  PROJECTVENDOR: `${BASE_URL}/api/projectvendors`,
  PROJECT_CREATE: `${BASE_URL}/api/project/new`,
  PROJECT_SINGLE: `${BASE_URL}/api/project`,
  PROJTOMODULEPROJCHECK: `${BASE_URL}/api/module/checkproject`,
  PROJTOSUBMODULEPROJCHECK: `${BASE_URL}/api/submodule/checkproject`,
  PROJTOSUBPROJCHECK: `${BASE_URL}/api/subproject/checkproject`,
  PROJTOTASKPROJCHECK: `${BASE_URL}/api/task/checkproject`,
  QUALIFICATIONS: `${BASE_URL}/api/qualifications`,
  QUALIFICATION_CREATE: `${BASE_URL}/api/qualification/new`,
  QUALIFICATION_SINGLE: `${BASE_URL}/api/qualification`,
  QUERYCOMPANYEMAIL: `${BASE_URL}/api/querycompanymail`,
  QUEUE: `${BASE_URL}/api/queues`,
  QUEUEGROUP: `${BASE_URL}/api/queuegroups`,
  QUEUEGROUP_CREATE: `${BASE_URL}/api/queuegroup/new`,
  QUEUEGROUP_SINGLE: `${BASE_URL}/api/queuegroup`,
  QUEUEMAPCATEGORY: `${BASE_URL}/api/queueexcel`,
  QUEUEREPORT: `${BASE_URL}/api/queuereports`,
  QUEUEWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelqueuereportocunt`,
  QUEUE_CREATE: `${BASE_URL}/api/queue/new`,
  QUEUE_SINGLE: `${BASE_URL}/api/queue`,
  RAISEISSUE: `${BASE_URL}/api/raiseissues`,
  RAISEISSUE_CREATE: `${BASE_URL}/api/raiseissue/new`,
  RAISEISSUE_SINGLE: `${BASE_URL}/api/raiseissue`,
  RAISEPROBLEM: `${BASE_URL}/api/raises`,
  RAISEPROBLEM_CREATE: `${BASE_URL}/api/raise/new`,
  RAISEPROBLEM_SINGLE: `${BASE_URL}/api/raise`,
  RAISETICKET: `${BASE_URL}/api/raisetickets`,
  RAISETICKETCLOSED: `${BASE_URL}/api/raiseticketsclosed`,
  RAISETICKETEDITDUPLICATE: `${BASE_URL}/api/raiseticketseditduplicate`,
  RAISETICKETOPEN: `${BASE_URL}/api/raiseticketsopen`,
  RAISETICKET_CREATE: `${BASE_URL}/api/raiseticket/new`,
  RAISETICKET_FILTER: `${BASE_URL}/api/raiseticketfilter`,
  RAISETICKET_FILTER_OVERALL: `${BASE_URL}/api/raiseticketfilteroverall`,
  RAISETICKET_INDIVIDUALFILTER: `${BASE_URL}/api/raiseticketindividualfilter`,
  RAISETICKET_LAST: `${BASE_URL}/api/raiseticketslast`,
  RAISETICKET_REPORT: `${BASE_URL}/api/raiseticketsreports`,
  RAISETICKET_REPORT_OVERALL: `${BASE_URL}/api/raiseticketsreportsoverall`,
  RAISETICKET_SINGLE: `${BASE_URL}/api/raiseticket`,
  RAISETICKET_WITHOUT_CLOSED: `${BASE_URL}/api/raiseticketswithoutclosed`,
  RAISE_HIERARCHY_FORWARD: `${BASE_URL}/api/raisehierarchyforward`,
  RAISE_TICKET_FORWARDED_EMPLOYEES: `${BASE_URL}/api/raiseticketforwardedemployee`,
  RAISE_TICKET_HOME: `${BASE_URL}/api/raiseticketindividualfilterhome`,
  RAISE_TICKET_USER_FORWARDED_EMPLOYEES: `${BASE_URL}/api/raiseticketuserforwardedemployee`,
  REASON: `${BASE_URL}/api/addexists`,
  REASONMASTER: `${BASE_URL}/api/reasonmasters`,
  REASONMASTER_CREATE: `${BASE_URL}/api/reasonmaster/new`,
  REASONMASTER_SINGLE: `${BASE_URL}/api/reasonmaster`,
  REASON_CREATE: `${BASE_URL}/api/addexist/new`,
  REASON_SINGLE: `${BASE_URL}/api/addexist`,
  REFCATEGORYDOCUMENT: `${BASE_URL}/api/referencecategories`,
  REFCATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/referencecategory/new`,
  REFCATEGORYDOCUMENT_OVERALLEDIRT: `${BASE_URL}/api/referencecategoryedit`,
  REFCATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/referencecategory`,
  REFDOCUMENT_CREATE: `${BASE_URL}/api/refdocuments/new`,
  REFDOCUMENT_OVERALLDELETE: `${BASE_URL}/api/refdocumentdelete`,
  REFDOCUMENT_SINGLE: `${BASE_URL}/api/refdocument`,
  REFERCANDIDATE: `${BASE_URL}/api/refercandidates`,
  REFERCANDIDATE_CREATE: `${BASE_URL}/api/refercandidate/new`,
  REFERCANDIDATE_SINGLE: `${BASE_URL}/api/refercandidate`,
  REJECTED_CANDIDATES: `${BASE_URL}/api/rejectedcandidates`,
  RELEIVE_HOME_COUNT: `${BASE_URL}/api/userhomecountrelieve`,
  REMAINDER: `${BASE_URL}/api/remainders`,
  REMAINDER_CREATE: `${BASE_URL}/api/remainder/new`,
  REMAINDER_SINGLE: `${BASE_URL}/api/remainder`,
  REMARK: `${BASE_URL}/api/remarks`,
  REMARK_CREATE: `${BASE_URL}/api/remark/new`,
  REMOVEBG: `${BASE_URL}/api/getremovebg`,
  REMOVE_MIKROTIK_ACTIVE_CONNECTION: `${BASE_URL}/api/removemikrotikactiveconnection`,
  REMOVE_ROCKETCHAT_GROUPING_INDIVIDUAL: `${BASE_URL}/api/removeuserinindividual`,
  REMOVE_USERFROM_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/removeuserfromchannel`,
  REPORTINGHEADER: `${BASE_URL}/api/reportingheaders`,
  REPORTINGHEADER_CREATE: `${BASE_URL}/api/reportingheader/new`,
  REPORTINGHEADER_SINGLE: `${BASE_URL}/api/reportingheader`,
  REPORTINGTO_DESIGNATION_USER_HIERARCHY_RELATION: `${BASE_URL}/api/reportingtodesignationuserhierarchyrelation`,
  REPORTINGTO_PROCESS_USER_HIERARCHY_RELATION: `${BASE_URL}/api/reportingtouserhierarchyrelation`,
  REQUIREDFIELDS: `${BASE_URL}/api/requiredfields`,
  REQUIREFIELDS_CREATE: `${BASE_URL}/api/requiredfield/new`,
  REQUIREFIELDS_SINGLE: `${BASE_URL}/api/requiredfield`,
  RESETCLIENTUSERID: `${BASE_URL}/api/resetclientuserid`,
  RESOLVERREASONMASTER: `${BASE_URL}/api/resolverreasonmaster`,
  RESOLVERREASONMASTERCETEGORYFILTER: `${BASE_URL}/api/resolverreasonmasterfilter`,
  RESOLVERREASONMASTER_CREATE: `${BASE_URL}/api/resolverreasonmaster/new`,
  RESPERSONWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelrespersonreportocunt`,
  RESUMEMAILATTACHMENTS: `${BASE_URL}/api/resumemailattachments`,
  RESUMEMAILATTACHMENTS_CREATE: `${BASE_URL}/api/resumemailattachments/new`,
  RESUMEMANAGEMENT_CANDIDATE_FILTERED: `${BASE_URL}/api/resumemanagementfilteredcandidates`,
  RESUMEMANAGEMENT_CANDIDATE_SORT: `${BASE_URL}/api/resumemanagementsortedcandidates`,
  REVENUEAMOUNTS: `${BASE_URL}/api/revenueamounts`,
  REVENUEAMOUNTSASSIGNBRANCH: `${BASE_URL}/api/revenueamountassignbranch`,
  REVENUEAMOUNTSEXCELFILEUPLOADSTORE: `${BASE_URL}/api/revenueuploadfile`,
  REVENUEAMOUNTSLIMITED: `${BASE_URL}/api/revenueamountlimited`,
  REVENUEAMOUNTSLIMITED_HOME: `${BASE_URL}/api/revenueamountlimitedhome`,
  REVENUEAMOUNT_BULK: `${BASE_URL}/api/revenueamountbulk`,
  REVENUEAMOUNT_CREATE: `${BASE_URL}/api/revenueamount/new`,
  REVENUEAMOUNT_SINGLE: `${BASE_URL}/api/revenueamount`,
  REVENUEAMOUNT_SORT: `${BASE_URL}/api/revenueamountsort`,
  ROCKETCHAT_COMBINED_USERS: `${BASE_URL}/api/getrocketchatandhrmsusers`,
  ROCKETCHAT_MEMBERS_FILTER: `${BASE_URL}/api/filterrocketchatuser`,
  ROLE: `${BASE_URL}/api/roles`,
  ROLEANDRESPONSE: `${BASE_URL}/api/allroleandresponsibilities`,
  ROLEANDRESPONSE_CREATE: `${BASE_URL}/api/roleandresponsibile/new`,
  ROLEANDRESPONSE_SINGLE: `${BASE_URL}/api/roleandresponsibile`,
  ROLECANDIDATES_ALL: `${BASE_URL}/api/allcandidatesrole`,
  ROLENAME: `${BASE_URL}/api/rolesname`,
  ROLESANDRES: `${BASE_URL}/api/rolesndresponses`,
  ROLESANDRESPONSECAT: `${BASE_URL}/api/rolesndresponsecategorys`,
  ROLESANDRESPONSECAT_CREATE: `${BASE_URL}/api/rolesndresponsecategory/new`,
  ROLESANDRESPONSECAT_SINGLE: `${BASE_URL}/api/rolesndresponsecategory`,
  ROLESANDRES_CREATE: `${BASE_URL}/api/rolesndres/new`,
  ROLESANDRES_SINGLE: `${BASE_URL}/api/rolesndres`,
  ROLEUSERCHECK: `${BASE_URL}/api/user/rolecheck`,
  ROLE_CREATE: `${BASE_URL}/api/role/new`,
  ROLE_SINGLE: `${BASE_URL}/api/role`,
  ROUNDMASTER: `${BASE_URL}/api/roundmasters`,
  ROUNDMASTER_CREATE: `${BASE_URL}/api/roundmaster/new`,
  ROUNDMASTER_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/interviewroundmaster`,
  ROUNDMASTER_OVERALLDELETE: `${BASE_URL}/api/overalldelete/interviewroundmaster`,
  ROUNDMASTER_OVERALLEDIT: `${BASE_URL}/api/overalledit/interviewroundmaster`,
  ROUNDMASTER_SINGLE: `${BASE_URL}/api/roundmaster`,
  SALARYSLAB_LIMITED: `${BASE_URL}/api/salaryslablimited`,
  SALARYSLAB_LIMITEDASSIGNBRANCH: `${BASE_URL}/api/salaryslablimitedassignbranch`,
  SALARYSLAB_LIMITEDASSIGNBRANCH_HOME: `${BASE_URL}/api/salaryslablimitedassignbranchhome`,
  SALARYSLAB_PROCESS_FILTER: `${BASE_URL}/api/salaryslabprocessfilter`,
  SALARY_FIX_FILTER: `${BASE_URL}/api/salaryfixfilter`,
  SALARY_FIX_FILTERREPORT: `${BASE_URL}/api/salaryfixfilterreport`,
  SCHEDULEMEETINGFILTER: `${BASE_URL}/api/schedulemeetingfilter`,
  SCHEDULEMEETINGFILTERFPAGE: `${BASE_URL}/api/schedulemeetingfilterpage`,
  SCHEDULE_MEETING_LOG: `${BASE_URL}/api/schedulemeetinglog`,
  SCREENSAVERCREATION: `${BASE_URL}/api/creatingscreensaver`,
  SCREENSAVERPROGRESS: `${BASE_URL}/api/getprogresscount`,
  SECONDARYWORKORDERALL: `${BASE_URL}/api/secondaryworkorderall`,
  SECONDARYWORKORDERALLUNALLOTED: `${BASE_URL}/api/secondaryworkorderallunalloted`,
  SECONDARY_CONSOLIDATED_HIERARCHY_FILTER: `${BASE_URL}/api/secondaryhierarchyfilter`,
  SECONDARY_CONSOLIDATED_WORKORDER_FILTER: `${BASE_URL}/api/secondaryworkorderlistfilter`,
  SECONDARY_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/secondaryhierarchydefault`,
  SECONDARY_INDIVIDUALWORKORDER: `${BASE_URL}/api/excelsecondaryindividual`,
  SECONDARY_OLDWORKORDER: `${BASE_URL}/api/liveworkordersecondary`,
  SELECTED_DATE_PROD_DAY_DELETE: `${BASE_URL}/api/selecteddateproddaylisttempdatedelete`,
  SELFCHECKPOINTTICKET: `${BASE_URL}/api/selfcheckpointticketmasters`,
  SELFCHECKPOINTTICKET_CREATE: `${BASE_URL}/api/selfcheckpointticketmaster/new`,
  SELFCHECKPOINTTICKET_SINGLE: `${BASE_URL}/api/selfcheckpointticketmaster`,
  SENDMAIL: `${BASE_URL}/send-email`,
  SHIFT: `${BASE_URL}/api/shifts`,
  SHIFTALLOT: `${BASE_URL}/api/shiftallots`,
  SHIFTALLOT_CREATE: `${BASE_URL}/api/shiftallot/new`,
  SHIFTALLOT_SINGLE: `${BASE_URL}/api/shiftallot`,
  SHIFTBYCONDITION: `${BASE_URL}/api/shiftsbyconditions`,
  SHIFTROASTER: `${BASE_URL}/api/shiftroasters`,
  SHIFTROASTER_CREATE: `${BASE_URL}/api/shiftroaster/new`,
  SHIFTROASTER_SINGLE: `${BASE_URL}/api/shiftroaster`,
  SHIFTS_LIMITED: `${BASE_URL}/api/shiftslimited`,
  SHIFTUSERSTIMINGFILTER: `${BASE_URL}/api/shiftuserstimingfilter`,
  SHIFT_CREATE: `${BASE_URL}/api/shift/new`,
  SHIFT_SINGLE: `${BASE_URL}/api/shift`,
  SHIFT_USER: `${BASE_URL}/api/user/shiftchecktime`,
  SINGLEACHEIVEDACCURACYINDIVIDUALUPLOAD: `${BASE_URL}/api/singleachievedaccuracyindividual`,
  SINGLEEXITINTERVIEWQUESTION: `${BASE_URL}/api/exitinterviewquestion`,
  SINGLE_ANNOUNCEMENT: `${BASE_URL}/api/announcement`,
  SINGLE_ANNOUNCEMENTCATEGORY: `${BASE_URL}/api/announcementcategory`,
  SINGLE_ASSETCAPACITY: `${BASE_URL}/api/assetcapacity`,
  SINGLE_ASSETMODEL: `${BASE_URL}/api/assetmodel`,
  SINGLE_ASSETSIZE: `${BASE_URL}/api/assetsize`,
  SINGLE_ASSETSPECIFICATIONGROUPING: `${BASE_URL}/api/assetspecificationgrouping`,
  SINGLE_ASSETSPECIFICATIONTYPE: `${BASE_URL}/api/assetspecificationtype`,
  SINGLE_ASSETTYPEGROUPING: `${BASE_URL}/api/assettypegrouping`,
  SINGLE_ASSETTYPEMASTER: `${BASE_URL}/api/assettypemaster`,
  SINGLE_ASSETVARIANT: `${BASE_URL}/api/assetvariant`,
  SINGLE_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/singleattendancecontrolcriteria`,
  SINGLE_AUTOLOGOUT: `${BASE_URL}/api/singleautologout`,
  SINGLE_BRANDMASTER: `${BASE_URL}/api/brandmaster`,
  SINGLE_CARDPREPARATION: `${BASE_URL}/api/cardpreparation`,
  SINGLE_CHECKLISTINTERVIEW: `${BASE_URL}/api/checklistinterview`,
  SINGLE_CLIENTUSERID: `${BASE_URL}/api/clientuserid`,
  SINGLE_CLIENTUSERID_FORACCESSFORLOG: `${BASE_URL}/api/clientuseridforlog`,
  SINGLE_CLOCKSPEED: `${BASE_URL}/api/clockspeed`,
  SINGLE_COLOURS: `${BASE_URL}/api/colours`,
  SINGLE_COMPANY_DOCUMENTPREPARATION: `${BASE_URL}/api/companydocumentpreparation`,
  SINGLE_COMPATIBLEDEVICES: `${BASE_URL}/api/compatibledevices`,
  SINGLE_CONNECTIVITY: `${BASE_URL}/api/connectivity`,
  SINGLE_COOLINGFANCOUNT: `${BASE_URL}/api/coolingfancount`,
  SINGLE_CORE: `${BASE_URL}/api/core`,
  SINGLE_DATARANGE: `${BASE_URL}/api/datarange`,
  SINGLE_DAY_POINTS: `${BASE_URL}/api/daypoint`,
  SINGLE_DAY_POINTS_TEMP: `${BASE_URL}/api/daypointtemp`,
  SINGLE_DAY_POINTS_UPLOAD: `${BASE_URL}/api/singledaypoint`,
  SINGLE_DAY_POINTS_UPLOAD_TEMP: `${BASE_URL}/api/singledaypointtemp`,
  SINGLE_DISTANCE: `${BASE_URL}/api/distance`,
  SINGLE_DOCUMENTPREPARATION: `${BASE_URL}/api/documentpreparation`,
  SINGLE_EBUSEINSTRUMENTS: `${BASE_URL}/api/ebuseinstrument`,
  SINGLE_EMPLOYEESTATUS: `${BASE_URL}/api/employeestatus`,
  SINGLE_ETHERNETPORTS: `${BASE_URL}/api/ethernetports`,
  SINGLE_EVENT: `${BASE_URL}/api/scheduleevent`,
  SINGLE_FILEACCESS: `${BASE_URL}/api/fileaccess`,
  SINGLE_FILESHARE: `${BASE_URL}/api/singlefilshare`,
  SINGLE_FREQUENCY: `${BASE_URL}/api/frequency`,
  SINGLE_FREQUENCYMASTER: `${BASE_URL}/api/frequencymaster`,
  SINGLE_HOLIDAY: `${BASE_URL}/api/holiday`,
  SINGLE_INDIVIDUAL_SETTING: `${BASE_URL}/api/individualsettings`,
  SINGLE_INT_FORM_ALLOT: `${BASE_URL}/api/interviewanswerallot`,
  SINGLE_INT_FORM_DESIGN: `${BASE_URL}/api/interviewformdesign`,
  SINGLE_LENGTH: `${BASE_URL}/api/length`,
  SINGLE_MANAGEPENALTYMONTH: `${BASE_URL}/api/managepenaltymonth`,
  SINGLE_MEETING: `${BASE_URL}/api/schedulemeeting`,
  SINGLE_MIKROTIKMASTER: `${BASE_URL}/api/singlemikrotikmaster`,
  SINGLE_MYCREATION: `${BASE_URL}/api/singlemycreation`,
  SINGLE_NONSCHEDULE_TRAININGDETAILS: `${BASE_URL}/api/nonscheduletrainingdetails`,
  SINGLE_NOOFCHANNELS: `${BASE_URL}/api/noofchannels`,
  SINGLE_NOTICEMEETING: `${BASE_URL}/api/singlenoticeperiodmeeting`,
  SINGLE_ONLINE_TEST_MASTER: `${BASE_URL}/api/onlinetestmaster`,
  SINGLE_ONLINE_TEST_QUESTION: `${BASE_URL}/api/onlinetestquestion`,
  SINGLE_OTHERPAYMENTS: `${BASE_URL}/api/otherpayment`,
  SINGLE_OUTPUT: `${BASE_URL}/api/output`,
  SINGLE_OUTPUTPOWER: `${BASE_URL}/api/outputpower`,
  SINGLE_OVERALL_SETTINGS: `${BASE_URL}/api/singleoverallsettings`,
  SINGLE_PANELTYPE: `${BASE_URL}/api/paneltype`,
  SINGLE_PASSWORD: `${BASE_URL}/api/password`,
  SINGLE_PENALTYDAYUPLOAD: `${BASE_URL}/api/penaltydayupload`,
  SINGLE_PENALTY_CLIENT: `${BASE_URL}/api/penaltyclientamount`,
  SINGLE_PENALTY_UPLOAD: `${BASE_URL}/api/singlepenaltydayupload`,
  SINGLE_POWERTSTATION: `${BASE_URL}/api/powerstation`,
  SINGLE_PROCESSQUEUENAME: `${BASE_URL}/api/processqueuename`,
  SINGLE_PROCESS_AND_TEAM: `${BASE_URL}/api/processteam`,
  SINGLE_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/productionconsolidated`,
  SINGLE_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/productiontempconsolidated`,
  SINGLE_PROFFESIONALTAXMASTER: `${BASE_URL}/api/professionaltaxmaster`,
  SINGLE_ROCKETCHAT_TEAMCHANNELGROUPING: `${BASE_URL}/api/singlerocketchatteamchannelgrouping`,
  SINGLE_SALARYSLAB: `${BASE_URL}/api/salaryslab`,
  SINGLE_SCHEDULEPAYMENTMASTER: `${BASE_URL}/api/schedulepaymentmaster`,
  SINGLE_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/schedulepaymentnotaddedbills`,
  SINGLE_SCREENRESOLUTION: `${BASE_URL}/api/screenresolution`,
  SINGLE_SLOT: `${BASE_URL}/api/slot`,
  SINGLE_SPEED: `${BASE_URL}/api/speed`,
  SINGLE_STATUSMASTER: `${BASE_URL}/api/statusmaster`,
  SINGLE_TASKDESIGNATIONGROUPING: `${BASE_URL}/api/taskdesignationgrouping`,
  SINGLE_TASKFORUSER: `${BASE_URL}/api/taskforuser`,
  SINGLE_TASKSCHEDULEGROUPING: `${BASE_URL}/api/taskschedulegrouping`,
  SINGLE_TASK_MAINTENANCE_NONSCHEDULEGROUPING: `${BASE_URL}/api/taskmaintenancenonschedulegrouping`,
  SINGLE_TASK_NONSCHEDULEGROUPING: `${BASE_URL}/api/tasknonschedulegrouping`,
  SINGLE_TEMPLATECREATION: `${BASE_URL}/api/templatecreation`,
  SINGLE_TEMP_POINTS: `${BASE_URL}/api/temppoint`,
  SINGLE_TEMP_POINTS_UPLOAD: `${BASE_URL}/api/singletemppoint`,
  SINGLE_TRAININGDETAILS: `${BASE_URL}/api/trainingdetails`,
  SINGLE_TRAINING_FOR_USER: `${BASE_URL}/api/trainingforuser`,
  SINGLE_TRAINING_USER_RESPONSE: `${BASE_URL}/api/usertrainingresponse`,
  SINGLE_USER_PASS: `${BASE_URL}/api/singleuserpasswordchange`,
  SINGLE_USER_RESPONSE: `${BASE_URL}/api/interviewuserresponse`,
  SINGLE_VENDORDETAILS: `${BASE_URL}/api/singlevendordetails`,
  SINGLE_VENDOREB: `${BASE_URL}/api/singlevendormasterforeb`,
  SINGLE_VENDORGROUPING: `${BASE_URL}/api/singlevendorgrouping`,
  SINGLE_VISITORS: `${BASE_URL}/api/visitors`,
  SINGLE_VISITORS_UPDATEID: `${BASE_URL}/api/visitorsupdateid`,
  SINGLE_VOMMASTERNAME: `${BASE_URL}/api/vommastername`,
  SKILLSET: `${BASE_URL}/api/skillsets`,
  SKILLSET_CREATE: `${BASE_URL}/api/skillset/new`,
  SKILLSET_SINGLE: `${BASE_URL}/api/skillset`,
  SKIPPEDALL_VISITORS: `${BASE_URL}/api/skippedallvisitors`,
  SKIPPEDEXPENSES: `${BASE_URL}/api/skippedexpenses`,
  SKIPPEDINCOME: `${BASE_URL}/api/skippedincomes`,
  SKIPPEDOTHERPAYMENTS: `${BASE_URL}/api/skippedotherpayment`,
  SKIPPED_CANDIDATES: `${BASE_URL}/api/skippedcandidates`,
  SKIPPED_EMPLOYEE: `${BASE_URL}/api/skippedemployee`,
  SKIPPED_INTERVIEWQUESTIONS: `${BASE_URL}/api/skippedinterviewquestions`,
  SKIPPED_RAISEPROBLEM: `${BASE_URL}/api/skippedraiseproblem`,
  SKIPPED_RAISEPROBLEMSTATUS: `${BASE_URL}/api/skippedraiseproblemstatus`,
  SKIPPED_VISITORS: `${BASE_URL}/api/skippedvisitors`,
  SOURCE: `${BASE_URL}/api/sources`,
  SOURCEOFPAYMENT: `${BASE_URL}/api/sourceofpayment`,
  SOURCEOFPAYMENT_CREATE: `${BASE_URL}/api/sourceofpayment/new`,
  SOURCE_CREATE: `${BASE_URL}/api/source/new`,
  SOURCE_SINGLE: `${BASE_URL}/api/source`,
  STOCKCATEGORY: `${BASE_URL}/api/stockcategorys`,
  STOCKCATEGORY_AUTOID: `${BASE_URL}/api/stockcategoryautoid`,
  STOCKCATEGORY_CREATE: `${BASE_URL}/api/stockcategory/new`,
  STOCKCATEGORY_SINGLE: `${BASE_URL}/api/stockcategory`,
  STOCKMANAGE: `${BASE_URL}/api/stockmanages`,
  STOCKMANAGEFILTERED: `${BASE_URL}/api/stockfilter`,
  STOCKMANAGE_CREATE: `${BASE_URL}/api/stockmanage/new`,
  STOCKMANAGE_SINGLE: `${BASE_URL}/api/stockmanage`,
  STOCKPURCHASE: `${BASE_URL}/api/stocks`,
  STOCKPURCHASELIMITED: `${BASE_URL}/api/stockpurchaselimited`,
  STOCKPURCHASELIMITED_HAND: `${BASE_URL}/api/stockpurchaselimitedhand`,
  STOCKPURCHASELIMITED_HAND_RETURN: `${BASE_URL}/api/stockpurchaselimitedhandreturn`,
  STOCKPURCHASELIMITED_HAND_TODO: `${BASE_URL}/api/stockpurchaselimitedhandtodo`,
  STOCKPURCHASELIMITED_HAND_TODO_RETURN: `${BASE_URL}/api/stockpurchaselimitedhandtodoreturn`,
  STOCKPURCHASELIMITED_RETURN: `${BASE_URL}/api/stockpurchaselimitedreturn`,
  STOCKPURCHASE_CREATE: `${BASE_URL}/api/stock/new`,
  STOCKPURCHASE_SINGLE: `${BASE_URL}/api/stock`,
  STOCKPURCHASE_TRANSFER_LIMITED: `${BASE_URL}/api/stockpurchaselimitedtransfer`,
  STOCKPURCHASE_TRANSFER_LOG_LIMITED: `${BASE_URL}/api/stockpurchaselimitedtransferlog`,
  STOCKTRANSFERFILTER: `${BASE_URL}/api/stockmantransferfilter`,
  STOCK_ACCESS: `${BASE_URL}/api/stocksaccess`,
  STOCK_MANAGE_ACCESS: `${BASE_URL}/api/stockmanagesaccess`,
  STOCK_MANAGE_FILTER: `${BASE_URL}/api/stockfilteraccess`,
  SUBCATEGORY: `${BASE_URL}/api/categories`,
  SUBCATEGORYEXCEL: `${BASE_URL}/api/subcategoriesexcel`,
  SUBCATEGORYEXCEL_BULK_DELETE: `${BASE_URL}/api/subcategoryexcelbulkdelete`,
  SUBCATEGORYEXCEL_CHECK: `${BASE_URL}/api/vendor/subcategorycheck`,
  SUBCATEGORYEXCEL_CREATE: `${BASE_URL}/api/subcategoryexcel/new`,
  SUBCATEGORYEXCEL_SINGLE: `${BASE_URL}/api/subcategoryexcel`,
  SUBCATEGORYMASTER: `${BASE_URL}/api/subcategorymasters`,
  SUBCATEGORYMASTER_CREATE: `${BASE_URL}/api/subcategorymaster/new`,
  SUBCATEGORYMASTER_SINGLE: `${BASE_URL}/api/subcategorymaster`,
  SUBCATEGORYPROD: `${BASE_URL}/api/subcategoriesprod`,
  SUBCATEGORYPROD_CREATE: `${BASE_URL}/api/subcategoryprod/new`,
  SUBCATEGORYPROD_LIMITED: `${BASE_URL}/api/subcategoryprodlimited`,
  SUBCATEGORYPROD_LIMITED_BYPROJ_CATE: `${BASE_URL}/api/subcategoryalllimitedbyprojcate`,
  SUBCATEGORYPROD_LIMITED_REPORT_MULTI: `${BASE_URL}/api/subcategoryprodlimitedreportsmultiselect`,
  SUBCATEGORYPROD_LIMITED_UNALLOT: `${BASE_URL}/api/subcategoryprodlimitedunallot`,
  SUBCATEGORYPROD_LIST_LIMITED: `${BASE_URL}/api/getlistsubcategoryprodlimited`,
  SUBCATEGORYPROD_LIST_LIMITED_PAGINATION: `${BASE_URL}/api/getlistsubcategoryprodlimitedpagination`,
  SUBCATEGORYPROD_OVERALL_EDIT: `${BASE_URL}/api/subcategoryprodoveralledit`,
  SUBCATEGORYPROD_OVERALL_EDIT_BULKUPDATE: `${BASE_URL}/api/subcategoryprodoveralleditbulkupdate`,
  SUBCATEGORYPROD_REPORT_LIMITED: `${BASE_URL}/api/getlistsubcategoryprodlimitedreport`,
  SUBCATEGORYPROD_SINGLE: `${BASE_URL}/api/subcategoryprod`,
  SUBCATEGORYPROJECTCHECK: `${BASE_URL}/api/subcategory/projectcheck`,
  SUBCATEGORY_CATEGORYCHECK: `${BASE_URL}/api/subcategory/categorycheck`,
  SUBCATEGORY_CREATE: `${BASE_URL}/api/category/new`,
  SUBCATEGORY_DROPDWONS: `${BASE_URL}/api/subcategorydropdowns`,
  SUBCATEGORY_DROPDWONS_EDIT: `${BASE_URL}/api/subcategorydropdownsedit`,
  SUBCATEGORY_OVERALL_CHECK_BULKDELETE: `${BASE_URL}/api/subcategoryoverallcheckdeletebulk`,
  SUBCATEGORY_OVERALL_CHECK_DELETE: `${BASE_URL}/api/subcategoryoverallcheckdelete`,
  SUBCATEGORY_OVERALL_NONLINK_BULKDELETE: `${BASE_URL}/api/subcategoryoverallnonlinkbulkdelete`,
  SUBCATEGORY_SINGLE: `${BASE_URL}/api/category`,
  SUBCOMPONENTMASTER: `${BASE_URL}/api/subcomponents`,
  SUBCOMPONENT_CODE: `${BASE_URL}/api/subcomponentCode`,
  SUBCOMPONENT_CODE_EDIT: `${BASE_URL}/api/subcomponentCodeEdit`,
  SUBCOMPONENT_CREATE: `${BASE_URL}/api/subcomponent/new`,
  SUBCOMPONENT_SINGLE: `${BASE_URL}/api/subcomponent`,
  SUBMODULE: `${BASE_URL}/api/submodules`,
  SUBMODULELIMIT: `${BASE_URL}/api/submoduleslimit`,
  SUBMODULES_DROP: `${BASE_URL}/api/submoduleDrop`,
  SUBMODULETASKCHECK: `${BASE_URL}/api/submoduletaskcheck`,
  SUBMODULETASKCHECKEDIT: `${BASE_URL}/api/submoduletaskcheckedit`,
  SUBMODULETOTASKSUBMODULECHECK: `${BASE_URL}/api/task/checksubmodule`,
  SUBMODULE_CREATE: `${BASE_URL}/api/submodule/new`,
  SUBMODULE_SINGLE: `${BASE_URL}/api/submodule`,
  SUBPAGEFIVE_CREATE: `${BASE_URL}/api/subpagefive/new`,
  SUBPAGEFIVE_SINGLE: `${BASE_URL}/api/subpagefive`,
  SUBPAGEFOUR_CREATE: `${BASE_URL}/api/subpagefour/new`,
  SUBPAGEFOUR_SINGLE: `${BASE_URL}/api/subpagefour`,
  SUBPAGEONE_CREATE: `${BASE_URL}/api/subpageone/new`,
  SUBPAGEONE_SINGLE: `${BASE_URL}/api/subpageone`,
  SUBPAGESFIVE: `${BASE_URL}/api/subpagesfive`,
  SUBPAGESFOUR: `${BASE_URL}/api/subpagesfour`,
  SUBPAGESONE: `${BASE_URL}/api/subpagesone`,
  SUBPAGESTHREE: `${BASE_URL}/api/subpagesthree`,
  SUBPAGESTWO: `${BASE_URL}/api/subpagestwo`,
  SUBPAGETHREE_CREATE: `${BASE_URL}/api/subpagethree/new`,
  SUBPAGETHREE_SINGLE: `${BASE_URL}/api/subpagethree`,
  SUBPAGETWO_CREATE: `${BASE_URL}/api/subpagetwo/new`,
  SUBPAGETWO_SINGLE: `${BASE_URL}/api/subpagetwo`,
  SUBPROJECT: `${BASE_URL}/api/subprojects`,
  SUBPROJECTLIMIT: `${BASE_URL}/api/subprojectslimit`,
  SUBPROJECTS_DROP: `${BASE_URL}/api/subprojectsDrop`,
  SUBPROJECT_CREATE: `${BASE_URL}/api/subproject/new`,
  SUBPROJECT_SINGLE: `${BASE_URL}/api/subproject`,
  SUBPROJTASKCHECK: `${BASE_URL}/api/subprojecttaskcheck`,
  SUBPROJTASKCHECKEDIT: `${BASE_URL}/api/subprojecttaskcheckedit`,
  SUBPROJTOMODULESUBPROJCHECK: `${BASE_URL}/api/module/checksubproject`,
  SUBPROJTOSUBMODULESUBPROJCHECK: `${BASE_URL}/api/submodule/checksubproject`,
  SUBPROJTOTASKSUBPROJCHECK: `${BASE_URL}/api/task/checksubproject`,
  SUBSUBCOMPONENT: `${BASE_URL}/api/subsubcategorytickets`,
  SUBSUBCOMPONENT_CREATE: `${BASE_URL}/api/subsubcategoryticket/new`,
  SUBSUBCOMPONENT_SINGLE: `${BASE_URL}/api/subsubcategoryticket`,
  SUB_CAT: `${BASE_URL}/api/getsubcategory`,
  TARGETPOINTEXCELFILEUPLOADSTORE: `${BASE_URL}/api/uploadfile`,
  TARGETPOINTS: `${BASE_URL}/api/targetpoints`,
  TARGETPOINTSASSIGNBRANCH: `${BASE_URL}/api/targetpointslimitedassignbranch`,
  TARGETPOINTSDELETE_BULK: `${BASE_URL}/api/targetpointsbulkdelete`,
  TARGETPOINTS_ALLLIMITED: `${BASE_URL}/api/targetpointsalllimited`,
  TARGETPOINTS_FILTER: `${BASE_URL}/api/targetpointsfilters`,
  TARGETPOINTS_LIMITED: `${BASE_URL}/api/targetpointslimited`,
  TARGETPOINT_CREATE: `${BASE_URL}/api/targetpoint/new`,
  TARGETPOINT_SINGLE: `${BASE_URL}/api/targetpoint`,
  TARGET_POINTS_FILTERED: `${BASE_URL}/api/targetpointsfiltered`,
  TASK: `${BASE_URL}/api/tasks`,
  TASKASSIGNBOARDLIST_CREATE: `${BASE_URL}/api/taskassignboardlist/new`,
  TASKASSIGNBOARDLIST_SINGLE: `${BASE_URL}/api/taskassignboardlist`,
  TASKASSIGNBOARDLIST_SINGLE_NEW: `${BASE_URL}/api/taskassignboardlistnew`,
  TASKASSIGNBOARDLIST_SINGLE_WORKORDER_DELETE: `${BASE_URL}/api/taskassignboardlistworkorders`,
  TASKASSIGNCHECKTIMER: `${BASE_URL}/api/taskassignchecktimerstatus`,
  TASKASSIGN_BOARD_LIST: `${BASE_URL}/api/taskassignboardlists`,
  TASKASSIGN_BOARD_LIST_FILTER: `${BASE_URL}/api/taskassignboardlistsfilter`,
  TASKASSIGN_BOARD_LIST_LIMITED: `${BASE_URL}/api/taskassignboardlistslimited`,
  TASKASSIGN_BOARD_LIST_TABLEDATA: `${BASE_URL}/api/taskassignboardliststabledata`,
  TASKBOARD: `${BASE_URL}/api/tasksboard`,
  TASKBOARDDEVELOPERCOMPLETE: `${BASE_URL}/api/taskboardcompleteusers`,
  TASKBOARDDEVELOPERCOMPLETEALL: `${BASE_URL}/api/taskboardcompleteusersallaccess`,
  TASKBOARDDEVELOPERINCOMPLETE: `${BASE_URL}/api/taskboardincompleteusers`,
  TASKBOARDDEVELOPERINCOMPLETEALL: `${BASE_URL}/api/taskboardincompleteusersallaccess`,
  TASKBOARDLIST: `${BASE_URL}/api/taskboardlist`,
  TASKBOARDTESTERCOMPLETE: `${BASE_URL}/api/taskboardcompletetester`,
  TASKBOARDTESTERCOMPLETEALL: `${BASE_URL}/api/taskboardcompletetesterallaccess`,
  TASKBOARDTESTERINCOMPLETE: `${BASE_URL}/api/taskboardincompletetester`,
  TASKBOARDTESTERINCOMPLETEALL: `${BASE_URL}/api/taskboardincompletetesterallaccess`,
  TASKBOARDVIEW_LIST_FILTER: `${BASE_URL}/api/taskboardviewlistsfilter`,
  TASKCATEGORY: `${BASE_URL}/api/taskcategories`,
  TASKCATEGORY_CREATE: `${BASE_URL}/api/taskcategory/new`,
  TASKCATEGORY_SINGLE: `${BASE_URL}/api/taskcategory`,
  TASKCHECKDEFAULT: `${BASE_URL}/api/taskcheckdefaults`,
  TASKCHECKDEFAULT_CREATE: `${BASE_URL}/api/taskcheckdefault/new`,
  TASKCHECKDEFAULT_SINGLE: `${BASE_URL}/api/taskcheckdefault`,
  TASKCURRENTCOMPLETE: `${BASE_URL}/api/taskscurrentcomplete`,
  TASKCURRENTINCOMPLETE: `${BASE_URL}/api/taskscurrentincomplete`,
  TASKCURRENTTESTERCOMPLETE: `${BASE_URL}/api/taskstestercurrentcomplete`,
  TASKCURRENTTESTERINCOMPLETE: `${BASE_URL}/api/taskstestercurrentincomplete`,
  TASKFIVECOMPLETE: `${BASE_URL}/api/tasksfivecomplete`,
  TASKFIVEINCOMPLETE: `${BASE_URL}/api/tasksfiveincomplete`,
  TASKHOMEALLCOMPLETE: `${BASE_URL}/api/taskhomepageallcomplete`,
  TASKHOMEDEVINCOMPLETE: `${BASE_URL}/api/taskhomepagedevincomplete`,
  TASKHOMEDEVINCOMPLETEACCESS: `${BASE_URL}/api/taskhomepagedevincompleteaccess`,
  TASKHOMETESTERINCOMPLETE: `${BASE_URL}/api/taskhomepagetesterincomplete`,
  TASKHOMETESTERINCOMPLETEACCESS: `${BASE_URL}/api/taskhomepagetesterincompleteaccess`,
  TASKHOMETODAYDEVINCOMPLETE: `${BASE_URL}/api/taskhomepagetodaydevincomplete`,
  TASKHOMETODAYTESTERINCOMPLETE: `${BASE_URL}/api/taskhomepagetodaytesterincomplete`,
  TASKLIMIT: `${BASE_URL}/api/taskslimit`,
  TASKLIST: `${BASE_URL}/api/taskslist`,
  TASKMAINTENACEFORUSER_AUTOGENERATE: `${BASE_URL}/api/taskmaintenanceforusersautogenerate`,
  TASKREPORTCOMPLETE: `${BASE_URL}/api/taskreportdevcomplete`,
  TASKREPORTINCOMPLETE: `${BASE_URL}/api/taskreportdevincomplete`,
  TASKREPORTTESTCOMPLETE: `${BASE_URL}/api/taskreporttestercomplete`,
  TASKREPORTTESTINCOMPLETE: `${BASE_URL}/api/taskreporttesterincomplete`,
  TASKSLIMITCREATE: `${BASE_URL}/api/allfiltertaskcreatepage`,
  TASKSUBCATEGORY: `${BASE_URL}/api/tasksubcategories`,
  TASKSUBCATEGORY_CREATE: `${BASE_URL}/api/tasksubcategory/new`,
  TASKSUBCATEGORY_SINGLE: `${BASE_URL}/api/tasksubcategory`,
  TASKUSERTIME: `${BASE_URL}/api/taskusertime`,
  TASK_CREATE: `${BASE_URL}/api/task/new`,
  TASK_FOR_USER_ASSIGNID: `${BASE_URL}/api/taskforuserassignuser`,
  TASK_FOR_USER_AUTOGENERATE: `${BASE_URL}/api/taskforuserautogenerate`,
  TASK_MAINTENANCE_NON_SCHEDULING_ACCESS: `${BASE_URL}/api/taskmaintenancenonschedulegroupingsaccess`,
  TASK_SINGLE: `${BASE_URL}/api/task`,
  TEAMDEPARTMENTCHECK: `${BASE_URL}/api/team/departcheck`,
  TEAMGROUPING: `${BASE_URL}/api/teamgroupings`,
  TEAMGROUPING_ASSIGNBRANCH: `${BASE_URL}/api/teamgroupingassignbranchs`,
  TEAMGROUPING_CREATE: `${BASE_URL}/api/teamgrouping/new`,
  TEAMGROUPING_SINGLE: `${BASE_URL}/api/teamgrouping`,
  TEAMOVERALLCHECK: `${BASE_URL}/api/overalldelcheck`,
  TEAMOVERALLUPDATE: `${BASE_URL}/api/overallupdatecheck`,
  TEAMRESULT: `${BASE_URL}/api/teamresult`,
  TEAMS: `${BASE_URL}/api/teams`,
  TEAMSASSIGNBRANCH: `${BASE_URL}/api/teamsassignbranch`,
  TEAMS_BY_UNIT: `${BASE_URL}/api/teamlimitedbyunit`,
  TEAMS_CREATE: `${BASE_URL}/api/team/new`,
  TEAMS_SINGLE: `${BASE_URL}/api/team`,
  TEAMUNITCHECK: `${BASE_URL}/api/team/unitcheck`,
  TEAMWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelteamreportocunt`,
  TEAMWISEUSERS: `${BASE_URL}/api/getallteamwiseusers`,
  TEAM_DESIGNATION_LOG: `${BASE_URL}/api/teamsdesignationlog`,
  TEAM_PARTICIPANTS: `${BASE_URL}/api/getparticipants`,
  TEMPLATECONTROLPANEL: `${BASE_URL}/api/templatecontrolpanel`,
  TEMPLATECONTROLPANEL_CREATE: `${BASE_URL}/api/templatecontrolpanel/new`,
  TEMPLATECONTROLPANEL_SINGLEDELETE: `${BASE_URL}/api/templatecontrolpanelsingle`,
  TEMPLATECONTROLPANEL_USERFIND: `${BASE_URL}/api/tempcontrolepaneluserfind`,
  TEMPLATEUSERSALL: `${BASE_URL}/api/getalltemplateusers`,
  TEMPMISMATCHFILTER: `${BASE_URL}/api/tempmismatchfilter`,
  TEMPMISMATCHUPDATEFLAGCOUNT: `${BASE_URL}/api/tempmismatchfilter/updateflagcount`,
  TEMPORARY_LOGIN_STATUS: `${BASE_URL}/api/temporaryloginstatus`,
  TEMP_DAY_POINTS_DATAS: `${BASE_URL}/api/temppointsdatasfetch`,
  TEMP_DAY_POINTS_FILTER: `${BASE_URL}/api/temppointsfilter`,
  TEMP_DAY_POINTS_FILTER_HOME: `${BASE_URL}/api/temppointsfilterhome`,
  TERTIARYWORKORDERALL: `${BASE_URL}/api/tertiaryworkorderall`,
  TERTIARY_CONSOLIDATED_HIERARCHY_FILTER: `${BASE_URL}/api/tertiaryhierarchyfilter`,
  TERTIARY_CONSOLIDATED_WORKORDER_FILTER: `${BASE_URL}/api/tertiaryworkorderlistfilter`,
  TERTIARY_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/tertiaryhierarchydefault`,
  TERTIARY_INDIVIDUALWORKORDER: `${BASE_URL}/api/exceltertiaryindividual`,
  TERTIARY_OLDWORKORDER: `${BASE_URL}/api/liveworkordertertiary`,
  TICKETASSETDETAIL: `${BASE_URL}/api/ticketassetdetails`,
  TIMEPOINTS: `${BASE_URL}/api/timepoints`,
  TIMEPOINTSCATEGORYCHECK: `${BASE_URL}/api/timepoints/categorycheck`,
  TIMEPOINTSPROJECTCHECK: `${BASE_URL}/api/timepoints/projectcheck`,
  TIMEPOINTSSUBCATEGORYCHECK: `${BASE_URL}/api/timepoints/subcategorycheck`,
  TIMEPOINTS_CHECK: `${BASE_URL}/api/vendor/timepointcheck`,
  TIMEPOINTS_CHECKEDIT_EXCELDATAS_CATSUBCATE: `${BASE_URL}/api/checkeditexceldatastimepoint`,
  TIMEPOINTS_CREATE: `${BASE_URL}/api/timepoint/new`,
  TIMEPOINTS_LIMITED_UPLOAD: `${BASE_URL}/api/timepointsuploadlimited`,
  TIMEPOINTS_SINGLE: `${BASE_URL}/api/timepoint`,
  TIMER: `${BASE_URL}/api/timers`,
  TIMER_CREATE: `${BASE_URL}/api/timer/new`,
  TIMER_SINGLE: `${BASE_URL}/api/timer`,
  TIMESTULDY_COMPLETED_LIST: `${BASE_URL}/api/timestudycompletedlist`,
  TODAYCANDIDATES: `${BASE_URL}/api/todaycandidates`,
  TODAY_HOLIDAY: `${BASE_URL}/api/todayholidayfilter`,
  TOOLTIPDESCRIPTIONS: `${BASE_URL}/api/tooltipdescription`,
  TOOLTIPDESCRIPTIONSAGGREGATION: `${BASE_URL}/api/tooltipdescriptionaggregation`,
  TOOLTIPDESCRIPTION_CREATE: `${BASE_URL}/api/tooltipdescription/new`,
  TRAININGCATEGORY: `${BASE_URL}/api/trainingcategories`,
  TRAININGCATEGORY_CREATE: `${BASE_URL}/api/trainingcategory/new`,
  TRAININGCATEGORY_SINGLE: `${BASE_URL}/api/trainingcategory`,
  TRAININGSUBCATEGORY: `${BASE_URL}/api/trainingsubcategories`,
  TRAININGSUBCATEGORY_CREATE: `${BASE_URL}/api/trainingsubcategory/new`,
  TRAININGSUBCATEGORY_SINGLE: `${BASE_URL}/api/trainingsubcategory`,
  TRAINING_FOR_USER_ASSIGNID: `${BASE_URL}/api/trainingforuserassignuser`,
  TRAINING_FOR_USER_AUTOGENERATE: `${BASE_URL}/api/trainingforusersautogenerate`,
  TRAINING_USER_PANEL_ONLINE_TEST: `${BASE_URL}/api/traininguserpanelonlinetest`,
  TRAINING_USER_PANEL_ONLINE_TEST_BULK_DELETE: `${BASE_URL}/api/traininguserpanelonlinetestbulkdelete`,
  TYPEMASTER: `${BASE_URL}/api/typemasters`,
  TYPEMASTERDOCUMENT: `${BASE_URL}/api/typemasterdocuments`,
  TYPEMASTERDOCUMENT_CREATE: `${BASE_URL}/api/typemasterdocument/new`,
  TYPEMASTERDOCUMENT_SINGLE: `${BASE_URL}/api/typemasterdocument`,
  TYPEMASTER_CREATE: `${BASE_URL}/api/typemaster/new`,
  TYPEMASTER_SINGLE: `${BASE_URL}/api/typemaster`,
  TYPETICKETMASTER: `${BASE_URL}/api/ticketmastertypes`,
  TYPETICKETMASTER_CREATE: `${BASE_URL}/api/ticketmastertype/new`,
  TYPETICKETMASTER_SINGLE: `${BASE_URL}/api/ticketmastertype`,
  UNASSIGNEDBRANCH: `${BASE_URL}/api/unassignbranches`,
  UNDO_PAYRUNLIST_INNERDATA: `${BASE_URL}/api/undopayrunlistinnerdata`,
  UNDO_PAYRUNLIST_INNERDATA_USER: `${BASE_URL}/api/undopayrunlistinnerdatauser`,
  UNIT: `${BASE_URL}/api/units`,
  UNITACCESSBRANCH: `${BASE_URL}/api/unitsaccessbranch`,
  UNITARRAYLIST: `${BASE_URL}/api/unitarraylist`,
  UNITCATSUBCATEGORYPROD: `${BASE_URL}/api/unitratecatsubprod`,
  UNITCHECK: `${BASE_URL}/api/unitcheck`,
  UNITOVERALLUPDATE: `${BASE_URL}/api/unitoverallupdate`,
  UNITRATE_MANUAL_APPROVALS: `${BASE_URL}/api/unitratemanualapprovals`,
  UNITRATE_MANUAL_APPROVAL_CREATE: `${BASE_URL}/api/unitratemanualapproval/new`,
  UNITRATE_MANUAL_APPROVAL_SINGLE: `${BASE_URL}/api/unitratemanualapproval`,
  UNITRATE_MANUAL_MRATE_UPDATE: `${BASE_URL}/api/unitratemanualmrateupdate`,
  UNITRATE_MANUAL_NOTAPPROVALS: `${BASE_URL}/api/unitratemanualnotapprovals`,
  UNITRATE_UNALLOTTED_LIST: `${BASE_URL}/api/unitrateunallottedlist`,
  UNITRATE_UNALLOTTED_LIST_FILTER: `${BASE_URL}/api/unitrateunallottedlistfilter`,
  UNITRATE_UNALLOT_SINGLE_UPDATE: `${BASE_URL}/api/unitrateunallotsingleupdate`,
  UNITRESULT: `${BASE_URL}/api/unitresult`,
  UNITWISE_TEAM: `${BASE_URL}/api/unitwiseteam`,
  UNIT_BY_ACCESS: `${BASE_URL}/api/unitlimitedbycompanyaccess`,
  UNIT_CREATE: `${BASE_URL}/api/unit/new`,
  UNIT_LIMITED: `${BASE_URL}/api/unitslimited`,
  UNIT_SINGLE: `${BASE_URL}/api/unit`,
  UPDATEANYLOG: `${BASE_URL}/api/updateanylog`,
  UPDATE_ATTANDANCESTATUS: `${BASE_URL}/api/updatesingleattendanceatatus`,
  UPDATE_INNERDATA_SINGLE_USER_RERUN: `${BASE_URL}/api/updateinnerdatasingleuserrerun`,
  UPDATE_INNERDATA_SINGLE_USER_WAIVER: `${BASE_URL}/api/updateinnerdatasingleuserwaiver`,
  UPDATE_INTERVIEWROUNDSTATUS: `${BASE_URL}/api/updateinterviewrounddata`,
  UPDATE_MIKROTIK_PROFILE: `${BASE_URL}/api/updatemikrotikprofile`,
  UPDATE_MIKROTIK_SECRET: `${BASE_URL}/api/updatemikrotiksecret`,
  UPDATE_PAYRUNLIST_INNERDATA: `${BASE_URL}/api/updatepayrunlistinnerdata`,
  UPDATE_PAYRUNLIST_INNERDATA_USER: `${BASE_URL}/api/updatepayrunlistinnerdatauser`,
  UPDATE_REMOVE_REJECT: `${BASE_URL}/api/updateRemoveReject`,
  UPDATE_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/updaterocketchatchannel`,
  UPDATE_ROCKETCHAT_TEAM: `${BASE_URL}/api/updaterocketchatteam`,
  UPDATE_ROCKETCHAT_USER_DETAILS: `${BASE_URL}/api/updaterocketchatuserdetails`,
  UPDATE_UNDO_FIELDNAME: `${BASE_URL}/api/updatefieldundoname`,
  UPDATE_UNDO_FIELDNAME_CONFIRMLIST: `${BASE_URL}/api/undofieldnameconfirmlistfix`,
  UPDATE_UNDO_FIELDNAME_TEMP: `${BASE_URL}/api/updatefieldundonametemp`,
  USER: `${BASE_URL}/api/users`,
  USERACTIVITYDROPDOWN_CONTROLLER: `${BASE_URL}/api/getalluseractivitystorage`,
  USERALLLIMIT: `${BASE_URL}/api/usersalllimit`,
  USERASSIGNBRANCH: `${BASE_URL}/api/usersassignuserbranch`,
  USERCHECK: `${BASE_URL}/api/checkuser`,
  USERCHECKBRANCH: `${BASE_URL}/api/checkuserbranch`,
  USERCHECKS: `${BASE_URL}/api/maintenancelog`,
  USERCHECKS_SINGLE: `${BASE_URL}/api/maintenancelogsingle`,
  USERDELETELOGOBJECTS: `${BASE_URL}/api/deleteuserlogobjects`,
  USERDEPARTMENTCHECK: `${BASE_URL}/api/user/departmentcheck`,
  USERDESIGCHECK: `${BASE_URL}/api/user/desigcheck`,
  USEREMP_TEAMGROUP: `${BASE_URL}/api/usersteamgrouping`,
  USEREXCELDATA_HOME: `${BASE_URL}/api/usersexceldataassignbranchhome`,
  USERFILTER: `${BASE_URL}/api/attendfilter`,
  USERFLOORCHECK: `${BASE_URL}/api/user/floorcheck`,
  USERHANDOVER_LIMITED: `${BASE_URL}/api/userhandoverlimited`,
  USERNEW: `${BASE_URL}/api/usersnew`,
  USERNEWFILTER: `${BASE_URL}/api/usersnewfilter`,
  USERNEWFILTER_MISSINGFIELD: `${BASE_URL}/api/usersnewfiltermissingfield`,
  USERPROJECTCHECK: `${BASE_URL}/api/project/checkuser`,
  USERQUALCHECK: `${BASE_URL}/api/user/qualcheck`,
  USERREFERCANDIDATE: `${BASE_URL}/api/userrefercandidate`,
  USERSENQUIERY: `${BASE_URL}/api/usersenquirystatus`,
  USERSEXCELDATA: `${BASE_URL}/api/usersexceldata`,
  USERSEXCELDATAASSIGNBRANCH: `${BASE_URL}/api/usersexceldataassignbranch`,
  USERSEXCELDATAASSIGNBRANCHSALARYSLAB: `${BASE_URL}/api/usersexceldatabyassignbranch`,
  USERSHIFTCHECK: `${BASE_URL}/api/user/shiftcheck`,
  USERSHIFTCHECKBULK: `${BASE_URL}/api/user/shiftcheckbulk`,
  USERSHIFTCHECKOVERALLBULK: `${BASE_URL}/api/user/shiftcheckbulkdelete`,
  USERSKILLCHECK: `${BASE_URL}/api/user/skillcheck`,
  USERSWITHSTATUS: `${BASE_URL}/api/userwithstatus`,
  USERS_COMPANYNAMES: `${BASE_URL}/api/getalluserscompanyname`,
  USERS_LIMITED_DROPDOWN_FINALSALARY: `${BASE_URL}/api/userslimitedfinalsalary`,
  USERS_LIMITED_EMPCODE: `${BASE_URL}/api/userslimitedempcode`,
  USERS_LIMITED_EMPCODE_CREATE: `${BASE_URL}/api/userslimitedempcodecreate`,
  USERS_LIMITED_EMPCODE_NONMANUAL: `${BASE_URL}/api/userslimitedempcodenonmanual`,
  USERS_LOGIN: `${BASE_URL}/api/usersloginallot`,
  USERTASKPROFILE: `${BASE_URL}/api/usertaskprofile`,
  USERTASKS: `${BASE_URL}/api/getsingleusertasks`,
  USERTEAMCHECK: `${BASE_URL}/api/user/teamcheck`,
  USERTTASKCHECK: `${BASE_URL}/api/user/task`,
  USERUNITCHECK: `${BASE_URL}/api/user/unitcheck`,
  USERWISE_FILTER_ALL: `${BASE_URL}/api/userwisefilter`,
  USERWISE_FILTER_ALL_EDIT: `${BASE_URL}/api/userwisefilteredit`,
  USERWITHACCESSIBLEBRANCH: `${BASE_URL}/api/userwithaccessiblebranch`,
  USER_ATTENDANCE_PAYRUN: `${BASE_URL}/api/getallusersattendancepayrun`,
  USER_ATT_MONTH_STATUS: `${BASE_URL}/api/userattmonthstatus`,
  USER_ATT_MONTH_STATUS_FILTER: `${BASE_URL}/api/userattmonthstatusfilter`,
  USER_ATT_MONTH_STATUS_FILTER_LIMITED: `${BASE_URL}/api/userattmonthstatusfilterlimited`,
  USER_ATT_MONTH_STATUS_FILTER_LIMITED_MINPOINTS: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterminpointsnew`,
  USER_ATT_MONTH_STATUS_FILTER_PAYRUNMASTER: `${BASE_URL}/api/userattmonthstatusfilterpayrunmaster`,
  USER_BOARDINGLOG_UPDATE: `${BASE_URL}/api/boardinglogupdate`,
  USER_CLOCKIN_CLOCKOUT_STATUS: `${BASE_URL}/api/userclockinclockoutstatus`,
  USER_CLOCKIN_CLOCKOUT_STATUS_CHECKLOGIN: `${BASE_URL}/api/userclockinclockoutstatusloginstatuscheck`,
  USER_CLOCKIN_CLOCKOUT_STATUS_DOC_PREPARATION: `${BASE_URL}/api/userclockinclockoutstatusdocprep`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FILTER: `${BASE_URL}/api/userclockinclockoutstatusfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FILTER_DATEWISE: `${BASE_URL}/api/userclockinclockoutstatusfilterdatewise`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL: `${BASE_URL}/api/userclockinclockoutstatusformontlopcal`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER_DOCPREP: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterdocprep`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER_FINALSALARY: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterfinalsalary`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER_PAYRUN_MASTER_FETCH: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterpayrunmasterfetch`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL: `${BASE_URL}/api/userclockinclockoutstatusindvl`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL_FILTER: `${BASE_URL}/api/userclockinclockoutstatusindvlfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL_HIERARFILTER: `${BASE_URL}/api/userclockinclockoutstatusindvlhierarchyfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_LEAVE: `${BASE_URL}/api/userclockinclockoutstatusleave`,
  USER_CLOCKIN_CLOCKOUT_STATUS_LOGIN: `${BASE_URL}/api/userclockinclockoutstatuslogin`,
  USER_CLOCKIN_CLOCKOUT_STATUS_LOGIN_CHECK: `${BASE_URL}/api/userclockinclockoutstatuslogincheck`,
  USER_CLOCKIN_CLOCKOUT_STATUS_MYINDVL: `${BASE_URL}/api/userclockinclockoutstatusmyindvl`,
  USER_CREATE: `${BASE_URL}/api/auth/new`,
  USER_CREDENTIALS: `${BASE_URL}/api/usercredentials`,
  USER_CREDENTIALS_MAIL: `${BASE_URL}/api/user-credentials`,
  USER_FILTER_FOR_SHIFTADJ_PAGE: `${BASE_URL}/api/usershiftadjustmentfilter`,
  USER_FOR_ALL_ATTENDANCE_PAGE: `${BASE_URL}/api/userforallattendancefilter`,
  USER_FOR_ALL_ATTENDANCE_PAGE_ACCESSBRANCHWISELIST: `${BASE_URL}/api/userforallattendancefilterforaccessbranchwiselist`,
  USER_FOR_ALL_ATTENDANCE_PAGE_FILTER: `${BASE_URL}/api/userforallattendancefilterforshiftadjfilterpage`,
  USER_IDLETIME_CREATE: `${BASE_URL}/api/idletime/new`,
  USER_IDLETIME_FILTER: `${BASE_URL}/api/idletimefilter`,
  USER_IDLETIME_FOR_LOGINPAGE: `${BASE_URL}/api/idletimeforloginpage`,
  USER_IDLETIME_OVERALL: `${BASE_URL}/api/idletimes`,
  USER_IDLETIME_SINGLE: `${BASE_URL}/api/idletime`,
  USER_IDLETIME_UPDATE_ENDTIME: `${BASE_URL}/api/idleendtimeupdate`,
  USER_IDLETIME_VIEW_TIME: `${BASE_URL}/api/idletimeview`,
  USER_LOGIN_EXPIRED_STATUS: `${BASE_URL}/api/usersloginexpiredstatus`,
  USER_LOGIN_STATUS: `${BASE_URL}/api/usersloginstatus`,
  USER_LOGIN_STATUS_ACTION: `${BASE_URL}/api/usersloginstatusaction`,
  USER_NAME_SEARCH: `${BASE_URL}/api/usernamesearch`,
  USER_PAYRUNDATA_LIMITED: `${BASE_URL}/api/userspayrundatalimited`,
  USER_PAYRUNDATA_LIMITED_FINAL: `${BASE_URL}/api/userspayrundatalimitedfinal`,
  USER_PCUSERNAMEUPDATE: `${BASE_URL}/api/pcnameuser`,
  USER_POSTERGENERATE: `${BASE_URL}/api/userspostergenerate`,
  USER_PROD: `${BASE_URL}/api/usersprod`,
  USER_PRODUCTION_DAY_SHIFT_ATTENDANCE_FILTER: `${BASE_URL}/api/usersproductiondayshiftattendancefilter`,
  USER_PRODUCTION_DAY_SHIFT_FILTER: `${BASE_URL}/api/usersproductiondayshiftfilter`,
  USER_PROD_LIMITED_REPORT: `${BASE_URL}/api/userslimitedreportprodMulti`,
  USER_PROFILE: `${BASE_URL}/api/getallprofileimages`,
  USER_SHIFTALLOT_BULK_UPDATE: `${BASE_URL}/api/usershiftallotsbulkupdate`,
  USER_SHIFTALLOT_BULK_UPDATE_FOR_WEEKOFFADJUSTMENT_OPT: `${BASE_URL}/api/usershiftallotsbulkupdateforweekoffadjopt`,
  USER_SHIFTALLOT_DELETE: `${BASE_URL}/api/usershiftallotsdelete`,
  USER_SHIFTALLOT_UPDATE: `${BASE_URL}/api/usershiftallotsupdate`,
  USER_SHIFTALLOT_UPDATE_STATUS: `${BASE_URL}/api/usershiftallotsupdatestatus`,
  USER_SINGLE: `${BASE_URL}/api/auth`,
  USER_SINGLE_PWD: `${BASE_URL}/api/userpw`,
  USER_SINGLE_PWD_RESET: `${BASE_URL}/api/userpwreset`,
  USER_SINGLE_ROLE: `${BASE_URL}/api/authmultipleroles`,
  USER_STATUS: `${BASE_URL}/api/usersstatus`,
  USER_STATUS_DEP_CHECK: `${BASE_URL}/api/usersstatusdepCheck`,
  USER_TASK_DESIGNATION_EMP_NAMES: `${BASE_URL}/api/userstaskdesigempnames`,
  USER_TRAINING_DETAILS_EMPNAMES: `${BASE_URL}/api/trainingdetailsempnames`,
  USER_TRAINING_RUNNING_STATUS: `${BASE_URL}/api/traininguserrunningstatus`,
  USER_VISITOR_REGISTER: `${BASE_URL}/api/uservisitorregister`,
  USER_X_EMPLOYEES: `${BASE_URL}/api/userswithxemployee`,
  VALIDATE_ERROR_ENTRY_HIERARCHY: `${BASE_URL}/api/validaterrorentryhierarchy`,
  VALIDATION_ERROR_FILTER: `${BASE_URL}/api/validationerrorfilters`,
  VEIW_USERACTIVITY_SCREENSHOT: `${BASE_URL}/api/useractivity`,
  VENDOREB_CREATE: `${BASE_URL}/api/vendormasterforeb/new`,
  VENDORMASTER: `${BASE_URL}/api/vendormasters`,
  VENDORMASTERLIMIT: `${BASE_URL}/api/vendormasterlimit`,
  VENDORMASTER_CREATE: `${BASE_URL}/api/vendormaster/new`,
  VENDORMASTER_LIMITED_BYPROJECT: `${BASE_URL}/api/vendormasterlimitedbyproject`,
  VENDORMASTER_LIMITED_NAMEONLY: `${BASE_URL}/api/vendormasterlimitednameonly`,
  VENDORMASTER_SINGLE: `${BASE_URL}/api/vendormaster`,
  VENDORPROJECTCHECK: `${BASE_URL}/api/vendor/projectcheck`,
  VENDORS_DROPDOWN: `${BASE_URL}/api/vendordropdown`,
  VENDORS_DROPDOWN_EDIT: `${BASE_URL}/api/vendordropdownedit`,
  VENDOR_AUTOID: `${BASE_URL}/api/vendordetailsautoid`,
  VERIFIEDLIST_SINGLE: `${BASE_URL}/api/verifiedlist`,
  VERIFYVIEWPASSWORD: `${BASE_URL}/api/verification/viewpassword`,
  VISITORCANDIDATESALL: `${BASE_URL}/api/allvisitorcandidates`,
  VISITORS_GETUNIQUEIDDATA: `${BASE_URL}/api/getuniquedatacandidates`,
  VPN_USER_INDIVIDUAL_PASSWORD: `${BASE_URL}/api/getuserindividualpassword`,
  WAVIERPERCENTAGE: `${BASE_URL}/api/wavierpercentage`,
  WAVIERPERCENTAGE_CREATE: `${BASE_URL}/api/wavierpercentage/new`,
  WEDDINGEMAIL_SENT: `${BASE_URL}/api/schedule-weddingemail`,
  WEEKOFFPRESENT: `${BASE_URL}/api/weekoffpresents`,
  WEEKOFFPRESENT_CREATE: `${BASE_URL}/api/weekoffpresent/new`,
  WEEKOFFPRESENT_FILTER: `${BASE_URL}/api/weekoffpresentfilter`,
  WEEKOFFPRESENT_SINGLE: `${BASE_URL}/api/weekoffpresent`,
  WITHOUT_SECONDARY_CONSOLIDATED: `${BASE_URL}/api/withoutsecondaryconsolidated`,
  WITHOUT_TERTIARY_CONSOLIDATED: `${BASE_URL}/api/withouttertiaryconsolidated`,
  WORKANNIVERSARYEMAIL_SENT: `${BASE_URL}/api/schedule-workanniversaryemail`,
  WORKORDERLIVEDATA: `${BASE_URL}/api/workorderlive`,
  WORKSTATION: `${BASE_URL}/api/workstations`,
  WORKSTATION_ACCESS: `${BASE_URL}/api/workstationsaccess`,
  WORKSTATION_CREATE: `${BASE_URL}/api/workstation/new`,
  WORKSTATION_SINGLE: `${BASE_URL}/api/workstation`,
  XEROXMONTHYEARPAIDMODE: `${BASE_URL}/api/xeroxfilterpaiddatemodes`,
  XEROXMONTHYEARPAIDSTATUS: `${BASE_URL}/api/xeroxpaidstatusfixfilter`,
  ASSIGNBRANCHFILTER: `${BASE_URL}/api/assignbranchfilters`,
  UPDATE_INTERN: `${BASE_URL}/api/updateinternstatus`,
  PRODUCTION_MANUALENTRY_DUPECHECK: `${BASE_URL}/api/productionmanualentrydupecheck`,
  COMPANY_OVERALLDELETE: `${BASE_URL}/api/companydelete`,
  BRANCHNAMECHECK: `${BASE_URL}/api/checkbranch`,
  USERCHECK: `${BASE_URL}/api/checkuser`,
  BRANCH: `${BASE_URL}/api/branches`,
  BRANCHLIMIT: `${BASE_URL}/api/brancheslimit`,
  BRANCH_CREATE: `${BASE_URL}/api/branch/new`,
  BRANCH_SINGLE: `${BASE_URL}/api/branch`,
  OVERALL_BRANCH: `${BASE_URL}/api/getoverallbranch`,
  USERCHECKBRANCH: `${BASE_URL}/api/checkuserbranch`,
  UNITCHECK: `${BASE_URL}/api/unitcheck`,
  FLOORCHECK: `${BASE_URL}/api/floorcheck`,
  BRANCHDESIGCHECK: `${BASE_URL}/api/designation/branchcheck`,
  BRANCH_ACCESSBRANCH_SORT: `${BASE_URL}/api/branchesaccessbranch`,
  UNIT: `${BASE_URL}/api/units`,
  UNIT_CREATE: `${BASE_URL}/api/unit/new`,
  UNIT_SINGLE: `${BASE_URL}/api/unit`,
  UNITRESULT: `${BASE_URL}/api/unitresult`,
  UNITARRAYLIST: `${BASE_URL}/api/unitarraylist`,
  TEAMRESULT: `${BASE_URL}/api/teamresult`,
  CUSTOMERDROP: `${BASE_URL}/api/custdropdowns`,
  PROCESSDROP: `${BASE_URL}/api/processdropdwons`,
  QUEUEREPORT: `${BASE_URL}/api/queuereports`,
  OVERALL_UNITS: `${BASE_URL}/api/getoverallunits`,
  TEAMUNITCHECK: `${BASE_URL}/api/team/unitcheck`,
  USERUNITCHECK: `${BASE_URL}/api/user/unitcheck`,
  BRANCHUNIT: `${BASE_URL}/api/branchunits`,
  BRANCHQRCODE: `${BASE_URL}/api/branchesqrcode`,
  UNITACCESSBRANCH: `${BASE_URL}/api/unitsaccessbranch`,
  MANAGE: `${BASE_URL}/api/manages`,
  MANAGE_CREATE: `${BASE_URL}/api/manage/new`,
  MANAGE_SINGLE: `${BASE_URL}/api/manage`,
  MANAGECOMPANY: `${BASE_URL}/api/managecompany`,
  MANAGECOMPANY_CREATE: `${BASE_URL}/api/managecompany/new`,
  MANAGECOMPANY_SINGLE: `${BASE_URL}/api/managecompany`,
  MANPOWER_FLOOR_FILTER: `${BASE_URL}/api/floormanpowers`,
  MANPOWERAREAFILTER: `${BASE_URL}/api/manpowerareas`,
  AREAS: `${BASE_URL}/api/areas`,
  AREA_CREATE: `${BASE_URL}/api/area/new`,
  AREA_SINGLE: `${BASE_URL}/api/area`,
  OVERALL_AREA: `${BASE_URL}/api/getoverallareas`,
  LOCATION: `${BASE_URL}/api/locations`,
  LOCATION_CREATE: `${BASE_URL}/api/location/new`,
  LOCATION_SINGLE: `${BASE_URL}/api/location`,
  FLOOR: `${BASE_URL}/api/floors`,
  FLOOR_CREATE: `${BASE_URL}/api/floor/new`,
  FLOOR_SINGLE: `${BASE_URL}/api/floor`,
  OVERALL_FLOOR: `${BASE_URL}/api/getoverallfloor`,
  USERFLOORCHECK: `${BASE_URL}/api/user/floorcheck`,
  DEPARTMENT: `${BASE_URL}/api/departments`,
  DEPARTMENT_CREATE: `${BASE_URL}/api/department/new`,
  DEPARTMENTOVERALLBULKCHECK: `${BASE_URL}/api/departoverallbulkcheck`,
  DEPARTMENT_SINGLE: `${BASE_URL}/api/department`,
  OVERALL_DEPARTMENT: `${BASE_URL}/api/getoveralldepartments`,
  USERDEPARTMENTCHECK: `${BASE_URL}/api/user/departmentcheck`,
  TEAMDEPARTMENTCHECK: `${BASE_URL}/api/team/departcheck`,
  DESIGNATIONGRP: `${BASE_URL}/api/designationgroup`,
  DESIGNATIONGRP_CREATE: `${BASE_URL}/api/designationgroup/new`,
  DESIGNATIONGRP_SINGLE: `${BASE_URL}/api/designationgroup`,
  OVERALL_DESIGNATIONGROUP: `${BASE_URL}/api/getoveralldesignationgroup`,
  DESIGNATIONGROUPBULKCHECK: `${BASE_URL}/api/getoveralldesignationgroupBulkcheck`,
  DESIGNATION: `${BASE_URL}/api/designation`,
  DESIGNATION_CREATE: `${BASE_URL}/api/designation/new`,
  DESIGNATION_SINGLE: `${BASE_URL}/api/designation`,
  OVERALL_DESIGNATION: `${BASE_URL}/api/getoveralldesignation`,
  GROUPTODESIGNATIONCHECK: `${BASE_URL}/api/designation/groupcheck`,
  USERDESIGCHECK: `${BASE_URL}/api/user/desigcheck`,
  QUALIFICATIONS: `${BASE_URL}/api/qualifications`,
  QUALIFICATION_CREATE: `${BASE_URL}/api/qualification/new`,
  QUALIFICATION_SINGLE: `${BASE_URL}/api/qualification`,
  USERQUALCHECK: `${BASE_URL}/api/user/qualcheck`,
  OVERALL_QUALIFICATIONS: `${BASE_URL}/api/getoverallqualification`,
  TEAMS: `${BASE_URL}/api/teams`,
  TEAMS_CREATE: `${BASE_URL}/api/team/new`,
  TEAMS_SINGLE: `${BASE_URL}/api/team`,
  OVERALL_TEAMS: `${BASE_URL}/api/getoverallteam`,
  USERTEAMCHECK: `${BASE_URL}/api/user/teamcheck`,
  PROJECTTEAMCHECK: `${BASE_URL}/api/project/teamcheck`,
  SHIFT: `${BASE_URL}/api/shifts`,
  SHIFT_CREATE: `${BASE_URL}/api/shift/new`,
  SHIFT_SINGLE: `${BASE_URL}/api/shift`,
  OVERALL_SHIFT: `${BASE_URL}/api/getoverallshift`,
  SHIFT_USER: `${BASE_URL}/api/user/shiftchecktime`,
  USERSHIFTCHECK: `${BASE_URL}/api/user/shiftcheck`,
  CERTIFICATION: `${BASE_URL}/api/certifications`,
  CERTIFICATION_CREATE: `${BASE_URL}/api/certification/new`,
  CERTIFICATION_SINGLE: `${BASE_URL}/api/certification`,
  EDUCATION: `${BASE_URL}/api/educations`,
  EDUCATION_CREATE: `${BASE_URL}/api/education/new`,
  EDUCATION_SINGLE: `${BASE_URL}/api/education`,
  SKILLSET: `${BASE_URL}/api/skillsets`,
  SKILLSET_CREATE: `${BASE_URL}/api/skillset/new`,
  SKILLSET_SINGLE: `${BASE_URL}/api/skillset`,
  OVERALL_SKILLSET: `${BASE_URL}/api/getoverallskillset`,
  USERSKILLCHECK: `${BASE_URL}/api/user/skillcheck`,
  SHIFTROASTER: `${BASE_URL}/api/shiftroasters`,
  SHIFTROASTER_CREATE: `${BASE_URL}/api/shiftroaster/new`,
  SHIFTROASTER_SINGLE: `${BASE_URL}/api/shiftroaster`,
  SHIFTALLOT: `${BASE_URL}/api/shiftallots`,
  SHIFTALLOT_CREATE: `${BASE_URL}/api/shiftallot/new`,
  SHIFTALLOT_SINGLE: `${BASE_URL}/api/shiftallot`,
  USER: `${BASE_URL}/api/users`,
  ALLUSERENQLIVE: `${BASE_URL}/api/alluserenquierylive`,
  ALLUSERSDATA: `${BASE_URL}/api/getallusersdata`,
  USER_CREATE: `${BASE_URL}/api/auth/new`,
  USER_SINGLE: `${BASE_URL}/api/auth`,
  USERALLLIMIT: `${BASE_URL}/api/usersalllimit`,
  USER_SINGLE_PWD: `${BASE_URL}/api/userpw`,
  USERSEXCELDATA: `${BASE_URL}/api/usersexceldata`,
  USERTASKPROFILE: `${BASE_URL}/api/usertaskprofile`,
  SENDMAIL: `${BASE_URL}/send-email`,
  USER_STATUS: `${BASE_URL}/api/usersstatus`,
  USERS_LOGIN: `${BASE_URL}/api/usersloginallot`,
  USER_SHIFTALLOT_UPDATE: `${BASE_URL}/api/usershiftallotsupdate`,
  USER_SHIFTALLOT_BULK_UPDATE: `${BASE_URL}/api/usershiftallotsbulkupdate`,
  USER_SHIFTALLOT_BULK_UPDATE_FOR_WEEKOFFADJUSTMENT_OPT: `${BASE_URL}/api/usershiftallotsbulkupdateforweekoffadjopt`,
  USER_SHIFTALLOT_UPDATE_STATUS: `${BASE_URL}/api/usershiftallotsupdatestatus`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL: `${BASE_URL}/api/userclockinclockoutstatusformontlopcal`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilter`,
  USERSENQUIERY: `${BASE_URL}/api/usersenquirystatus`,
  ALLUSER: `${BASE_URL}/api/allusers`,
  LOGALLUSER: `${BASE_URL}/api/alluserslog`,
  USER_CLOCKIN_CLOCKOUT_STATUS: `${BASE_URL}/api/userclockinclockoutstatus`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FILTER: `${BASE_URL}/api/userclockinclockoutstatusfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL: `${BASE_URL}/api/userclockinclockoutstatusindvl`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL_FILTER: `${BASE_URL}/api/userclockinclockoutstatusindvlfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_INDVL_HIERARFILTER: `${BASE_URL}/api/userclockinclockoutstatusindvlhierarchyfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_MYINDVL: `${BASE_URL}/api/userclockinclockoutstatusmyindvl`,
  SHIFTUSERSTIMINGFILTER: `${BASE_URL}/api/shiftuserstimingfilter`,
  UPDATE_ATTANDANCESTATUS: `${BASE_URL}/api/updatesingleattendanceatatus`,
  INTERN_STATUS: `${BASE_URL}/api/deactiveallinterns`,
  INTERNUPDATE_STATUS: `${BASE_URL}/api/updateinternstatus`,
  UPDATE_INTERN: `${BASE_URL}/api/updateinternstatus`,
  ALL_INTERNS: `${BASE_URL}/api/allinterns`,
  DESIGNATIONMONTHSET_ALL: `${BASE_URL}/api/designationmonthsets`,
  DESIGNATIONMONTHSET_CREATE: `${BASE_URL}/api/designationmonthset/new`,
  DESIGNATIONMONTHSET_SINGLE: `${BASE_URL}/api/designationmonthset`,
  PROCESSMONTHSET_ALL: `${BASE_URL}/api/processmonthsets`,
  PROCESSMONTHSET_CREATE: `${BASE_URL}/api/processmonthset/new`,
  PROCESSMONTHSET_SINGLE: `${BASE_URL}/api/processmonthset`,
  BIRTHDAYEMAIL_SENT: `${BASE_URL}/api/schedule-birthdayemail`,
  WEDDINGEMAIL_SENT: `${BASE_URL}/api/schedule-weddingemail`,
  WORKANNIVERSARYEMAIL_SENT: `${BASE_URL}/api/schedule-workanniversaryemail`,
  EMPLOYEEDOCUMENT: `${BASE_URL}/api/employeedocuments`,
  EMPLOYEEDOCUMENT_CREATE: `${BASE_URL}/api/employeedocuments/new`,
  EMPLOYEEDOCUMENT_SINGLE: `${BASE_URL}/api/employeedocument`,
  EMPLOYEEDOCUMENT_SINGLEWITHALLBYCOMMONID: `${BASE_URL}/api/employeedocumentcommonidwithall`,
  EMPLOYEEDOCUMENTPRE: `${BASE_URL}/api/preemployeedocuments`,
  DRAFT: `${BASE_URL}/api/drafts`,
  DRAFT_CREATE: `${BASE_URL}/api/draft/new`,
  DRAFT_SINGLE: `${BASE_URL}/api/draft`,
  PROJECT: `${BASE_URL}/api/projects`,
  PROJECTLIMIT: `${BASE_URL}/api/projectslimit`,
  PROJECT_CREATE: `${BASE_URL}/api/project/new`,
  PROJECT_SINGLE: `${BASE_URL}/api/project`,
  OVERALL_PROJECT: `${BASE_URL}/api/overallproj`,
  USERPROJECTCHECK: `${BASE_URL}/api/project/checkuser`,
  PROJTOSUBPROJCHECK: `${BASE_URL}/api/subproject/checkproject`,
  PROJTOMODULEPROJCHECK: `${BASE_URL}/api/module/checkproject`,
  PROJTOSUBMODULEPROJCHECK: `${BASE_URL}/api/submodule/checkproject`,
  PROJTOTASKPROJCHECK: `${BASE_URL}/api/task/checkproject`,
  SUBPROJECT: `${BASE_URL}/api/subprojects`,
  SUBPROJECTLIMIT: `${BASE_URL}/api/subprojectslimit`,
  SUBPROJECT_CREATE: `${BASE_URL}/api/subproject/new`,
  SUBPROJECT_SINGLE: `${BASE_URL}/api/subproject`,
  OVERALL_SUBPROJECT: `${BASE_URL}/api/overallsubproj`,
  SUBPROJTOMODULESUBPROJCHECK: `${BASE_URL}/api/module/checksubproject`,
  SUBPROJTOSUBMODULESUBPROJCHECK: `${BASE_URL}/api/submodule/checksubproject`,
  SUBPROJTOTASKSUBPROJCHECK: `${BASE_URL}/api/task/checksubproject`,
  SUBPROJTASKCHECK: `${BASE_URL}/api/subprojecttaskcheck`,
  SUBPROJTASKCHECKEDIT: `${BASE_URL}/api/subprojecttaskcheckedit`,
  MODULE: `${BASE_URL}/api/modules`,
  MODULELIMIT: `${BASE_URL}/api/moduleslimit`,
  MODULE_CREATE: `${BASE_URL}/api/module/new`,
  MODULE_SINGLE: `${BASE_URL}/api/module`,
  OVERALL_MODULE: `${BASE_URL}/api/overallmodule`,
  MODULETOSUBMODULEMODULECHECK: `${BASE_URL}/api/submodule/checkmodule`,
  MODULETOTASKMODULECHECK: `${BASE_URL}/api/task/checkmodule`,
  MODULETASKCHECK: `${BASE_URL}/api/moduletaskcheck`,
  MODULETASKCHECKEDIT: `${BASE_URL}/api/moduletaskcheckedit`,
  SUBMODULE: `${BASE_URL}/api/submodules`,
  SUBMODULELIMIT: `${BASE_URL}/api/submoduleslimit`,
  SUBMODULE_CREATE: `${BASE_URL}/api/submodule/new`,
  SUBMODULETASKCHECK: `${BASE_URL}/api/submoduletaskcheck`,
  SUBMODULETASKCHECKEDIT: `${BASE_URL}/api/submoduletaskcheckedit`,
  SUBMODULE_SINGLE: `${BASE_URL}/api/submodule`,
  OVERALL_SUBMODULE: `${BASE_URL}/api/overallsubmodule`,
  SUBMODULETOTASKSUBMODULECHECK: `${BASE_URL}/api/task/checksubmodule`,
  PAGEMODEL: `${BASE_URL}/api/pagemodels`,
  PAGEMODEL_LIMITED: `${BASE_URL}/api/pagemodelslimited`,
  PAGEMODEL_CREATE: `${BASE_URL}/api/pagemodel/new`,
  PAGETYPE_MAIN: `${BASE_URL}/api/pagetypemaindrop`,
  PAGETYPE_MAIN_MULTI: `${BASE_URL}/api/pagetypemaindropmulti`,
  PAGETYPE_SUBPAGE_DROP: `${BASE_URL}/api/pagetypesubpagedrop`,
  PAGETYPE_SUBPAGE_DROP_MULTI: `${BASE_URL}/api/pagetypesubpagedropmulti`,
  PAGETYPE_SUBSUBPAGE_DROP: `${BASE_URL}/api/pagetypesubsubpagedrop`,
  PAGETYPE_MAIN_EST_TIME: `${BASE_URL}/api/pagetypemainEsttime`,
  PAGEMODEL_SINGLE: `${BASE_URL}/api/pagemodel`,
  SUBPROJECTS_DROP: `${BASE_URL}/api/subprojectsDrop`,
  MODULES_DROP: `${BASE_URL}/api/moduleDrop`,
  SUBMODULES_DROP: `${BASE_URL}/api/submoduleDrop`,
  CHECKPOINTGROUP: `${BASE_URL}/api/checkptgroups`,
  CHECKPOINTGROUP_CREATE: `${BASE_URL}/api/checkptgroup/new`,
  CHECKPOINTGROUP_SINGLE: `${BASE_URL}/api/checkptgroup`,
  GETCHECKPOINTANDTIME: `${BASE_URL}/api/checkpointgettime`,
  GETCHECKPOINTANDTIMEUSECASES: `${BASE_URL}/api/checkpointgettimeusecases`,
  MAINPAGE: `${BASE_URL}/api/mainpages`,
  MAINPAGE_CREATE: `${BASE_URL}/api/mainpage/new`,
  MAINPAGE_SINGLE: `${BASE_URL}/api/mainpage`,
  SUBPAGESONE: `${BASE_URL}/api/subpagesone`,
  SUBPAGEONE_CREATE: `${BASE_URL}/api/subpageone/new`,
  SUBPAGEONE_SINGLE: `${BASE_URL}/api/subpageone`,
  SUBPAGESTWO: `${BASE_URL}/api/subpagestwo`,
  SUBPAGETWO_CREATE: `${BASE_URL}/api/subpagetwo/new`,
  SUBPAGETWO_SINGLE: `${BASE_URL}/api/subpagetwo`,
  SUBPAGESTHREE: `${BASE_URL}/api/subpagesthree`,
  SUBPAGETHREE_CREATE: `${BASE_URL}/api/subpagethree/new`,
  SUBPAGETHREE_SINGLE: `${BASE_URL}/api/subpagethree`,
  SUBPAGESFOUR: `${BASE_URL}/api/subpagesfour`,
  SUBPAGEFOUR_CREATE: `${BASE_URL}/api/subpagefour/new`,
  SUBPAGEFOUR_SINGLE: `${BASE_URL}/api/subpagefour`,
  SUBPAGESFIVE: `${BASE_URL}/api/subpagesfive`,
  SUBPAGEFIVE_CREATE: `${BASE_URL}/api/subpagefive/new`,
  SUBPAGEFIVE_SINGLE: `${BASE_URL}/api/subpagefive`,
  SUBCATEGORY: `${BASE_URL}/api/categories`,
  SUBCATEGORY_CREATE: `${BASE_URL}/api/category/new`,
  SUBCATEGORY_SINGLE: `${BASE_URL}/api/category`,
  CHECKPOINTGROUP_CHECK: `${BASE_URL}/api/checkptgroup/category`,
  OVERALL_SUBCATEGORY: `${BASE_URL}/api/overallcategory`,
  PAGETYPE: `${BASE_URL}/api/pagetypes`,
  PAGETYPE_CREATE: `${BASE_URL}/api/pagetype/new`,
  PAGETYPE_SINGLE: `${BASE_URL}/api/pagetype`,
  COMPONENTSGROUPING: `${BASE_URL}/api/componentsgroupings`,
  COMPONENTSGROUPINGLIMIT: `${BASE_URL}/api/componentsgrouplimit`,
  COMPONENTSGROUPING_CREATE: `${BASE_URL}/api/componentsgroup/new`,
  COMPONENTSGROUPING_SINGLE: `${BASE_URL}/api/componentsgroup`,
  COMPSUBCOMPONENT: `${BASE_URL}/api/compsubcomponents`,
  COMPSUBCOMPONENT_CREATE: `${BASE_URL}/api/compsubcomponent/new`,
  COMPSUBCOMPONENT_SINGLE: `${BASE_URL}/api/compsubcomponent`,
  COMPONENTMASTER: `${BASE_URL}/api/components`,
  COMPONENT_CREATE: `${BASE_URL}/api/component/new`,
  COMPONENT_SINGLE: `${BASE_URL}/api/component`,
  COMPONENT_EDITOVERALL: `${BASE_URL}/api/componentoverall`,
  SUBCOMPONENTMASTER: `${BASE_URL}/api/subcomponents`,
  SUBCOMPONENT_CREATE: `${BASE_URL}/api/subcomponent/new`,
  SUBCOMPONENT_SINGLE: `${BASE_URL}/api/subcomponent`,
  SUBCOMPONENT_CODE: `${BASE_URL}/api/subcomponentCode`,
  SUBCOMPONENT_CODE_EDIT: `${BASE_URL}/api/subcomponentCodeEdit`,
  PRIORITY: `${BASE_URL}/api/priorities`,
  PRIORITY_CREATE: `${BASE_URL}/api/priority/new`,
  PRIORITY_SINGLE: `${BASE_URL}/api/priority`,
  PRIORITYTOTASKPRIORRITYCHECK: `${BASE_URL}/api/task/checkpriority`,
  OVERALL_PRIORITY: `${BASE_URL}/api/overallpriority`,
  PROJECTDETAILS: `${BASE_URL}/api/projectdetails`,
  PROJECTDETAILS_CREATE: `${BASE_URL}/api/projectdetail/new`,
  PROJECTDETAILS_SINGLE: `${BASE_URL}/api/projectdetail`,
  PROJECTESTIMATION: `${BASE_URL}/api/projectestimations`,
  PROJECTESTIMATION_CREATE: `${BASE_URL}/api/projectestimation/new`,
  PROJECTESTIMATION_SINGLE: `${BASE_URL}/api/projectestimation`,
  PROJECTALLOCATION: `${BASE_URL}/api/projectallocations`,
  PROJECTALLOCATION_CREATE: `${BASE_URL}/api/projectallocation/new`,
  PROJECTALLOCATION_SINGLE: `${BASE_URL}/api/projectallocation`,
  ROLE: `${BASE_URL}/api/roles`,
  ROLE_CREATE: `${BASE_URL}/api/role/new`,
  ROLE_SINGLE: `${BASE_URL}/api/role`,
  OVERALL_ROLE: `${BASE_URL}/api/overallrole`,
  ROLEUSERCHECK: `${BASE_URL}/api/user/rolecheck`,
  USER_SINGLE_ROLE: `${BASE_URL}/api/authmultipleroles`,
  CONTROLSGROUPING: `${BASE_URL}/api/controlsgroupings`,
  CONTROLSGROUPING_CREATE: `${BASE_URL}/api/controlsgrouping/new`,
  CONTROLSGROUPING_SINGLE: `${BASE_URL}/api/controlsgrouping`,
  MANPOWER: `${BASE_URL}/api/allmanpowers`,
  MANPOWER_CREATE: `${BASE_URL}/api/manpower/new`,
  MANPOWER_SINGLE: `${BASE_URL}/api/manpower`,
  EXCEL: `${BASE_URL}/api/excels`,
  EXCELFILTERED: `${BASE_URL}/api/excelsfiltered`,
  EXCEL_CREATE: `${BASE_URL}/api/excel/new`,
  EXCEL_SINGLE: `${BASE_URL}/api/excel`,
  EXCELMAPDATA: `${BASE_URL}/api/excelmapdatas`,
  EXCELMAPDATA_CREATE: `${BASE_URL}/api/excelmapdata/new`,
  EXCELMAPDATA_SINGLE: `${BASE_URL}/api/excelmapdata`,
  EXCELMAPDATAQUEUECHECK: `${BASE_URL}/api/timpoints/queue`,
  EXCELMAPCATEGORY: `${BASE_URL}/api/categoryexcel`,
  QUEUEMAPCATEGORY: `${BASE_URL}/api/queueexcel`,
  CUSTOMEREXCEL: `${BASE_URL}/api/customerexcel`,
  EXCELMAPDATARESPERSON_INDIVIDUALWORKORDER: `${BASE_URL}/api/individualworkorderlist`,
  EXCELMAP_MAPPERSON_DELETE: `${BASE_URL}/api/excelmapandpersondelete`,
  GETOVERALL_EXCELMAPDATA: `${BASE_URL}/api/getoverallallottedqueue`,
  EXCELMAPDATAFILTERED: `${BASE_URL}/api/excelmapfiltered`,
  EXCELUNALLOTEDFILTERED: `${BASE_URL}/api/unallotedexcelqueuelist`,
  ALLOTTED_QUEUE_LIST_FILTER: `${BASE_URL}/api/allottedqueuelistfilter`,
  EXCELUNALLOTEDFILTERED_OVERALL: `${BASE_URL}/api/unallotedexcelqueuelistOverall`,
  EXCELMAPDATARESPERSON: `${BASE_URL}/api/excelmaprespersondatas`,
  EXCELMAPDATARESPERSON_CREATE: `${BASE_URL}/api/excelmaprespersondata/new`,
  EXCELMAPDATARESPERSON_SINGLE: `${BASE_URL}/api/excelmaprespersondata`,
  EXCELMAPDATARESPERSON_TEAMTOTAL: `${BASE_URL}/api/excelteamtotal`,
  EXCELMAPDATARESPERSON_BRANCHTOTAL: `${BASE_URL}/api/excelbranchtotal`,
  EXCELMAPDATARESPERSON_RESPERSONTOTAL: `${BASE_URL}/api/excelrespersontotal`,
  EXCELUNALLOTEDRESPERSONFILTERED: `${BASE_URL}/api/unallotedexcelrespersonlist`,
  ALLOTTED_RESPONSIBLE_QUEUE_LIST_FILTER: `${BASE_URL}/api/allottedresponsiblequeuelist`,
  PROJECTVENDOR: `${BASE_URL}/api/projectvendors`,
  PROJECTCATEGORY: `${BASE_URL}/api/projectcategorys`,
  CATEGORYSUBCATEGORY: `${BASE_URL}/api/categorysubcategorytime`,
  CATEGORYQUEUE: `${BASE_URL}/api/categoryqueuegrouping`,
  WORKORDERLIVEDATA: `${BASE_URL}/api/workorderlive`,
  BRANCHWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelbranchreportocunt`,
  TEAMWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelteamreportocunt`,
  RESPERSONWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelrespersonreportocunt`,
  CATEGORYWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelcategoryreportocunt`,
  QUEUEWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelqueuereportocunt`,
  CUSTOMERWISEREPORTEXCELUPLOAD: `${BASE_URL}/api/excelcustomerreportocunt`,
  PRIMARYWORKORDEROVERTATDATA: `${BASE_URL}/api/primaryworkorderovertatdata`,
  PRIMARYWORKORDERNEARTATDATA: `${BASE_URL}/api/primaryworkorderneartatdata`,
  PRIMARYWORKORDERALL: `${BASE_URL}/api/primaryworkorderall`,
  SECONDARYWORKORDERALL: `${BASE_URL}/api/secondaryworkorderall`,
  SECONDARYWORKORDERALLUNALLOTED: `${BASE_URL}/api/secondaryworkorderallunalloted`,
  TERTIARYWORKORDERALL: `${BASE_URL}/api/tertiaryworkorderall`,
  OTHERWORKORDERALL: `${BASE_URL}/api/otherworkorderall`,
  OTHERWORKORDERALL_LIST: `${BASE_URL}/api/excelWorkOrderOtherList`,
  CONSWORKORDER_LIST: `${BASE_URL}/api/consworkorderlist`,
  CONSWORKORDER_LIST_FILTER: `${BASE_URL}/api/consworkorderlistFilter`,
  CONSWORKORDER_LIST_ALL: `${BASE_URL}/api/consworkorderlistAll`,
  CONSWORKORDER_LIST_FILTER_ALL: `${BASE_URL}/api/consworkorderlistAllFilter`,
  TERTIARY_INDIVIDUALWORKORDER: `${BASE_URL}/api/exceltertiaryindividual`,
  EXCELINDIVIDUALPRIMARYWORKORDER: `${BASE_URL}/api/excelindividualprimaryworkorder`,
  SECONDARY_INDIVIDUALWORKORDER: `${BASE_URL}/api/excelsecondaryindividual`,
  INDIVIDUAL_WORKORDER_OTHERSLIST: `${BASE_URL}/api/workorderindividual`,
  INDIVIDUAL_WORKORDER_OTHERSFILTER: `${BASE_URL}/api/workorderindividualfilter`,
  CONSOLIDATED_INDIVIDUAL_LIST: `${BASE_URL}/api/consolidatedindividual`,
  CONSOLIDATED_INDIVIDUAL_LISTFILTER: `${BASE_URL}/api/consolidatedindividualfilter`,
  EXCELINDIVIDUALPRIMARYWORKORDER_NEARTAT: `${BASE_URL}/api/excelindividualprimaryneartat`,
  EXCELINDIVIDUALPRIMARYWORKORDER_ALLLIST: `${BASE_URL}/api/excelindividualprimaryalllist`,
  WITHOUT_SECONDARY_CONSOLIDATED: `${BASE_URL}/api/withoutsecondaryconsolidated`,
  SECONDARY_CONSOLIDATED_WORKORDER_FILTER: `${BASE_URL}/api/secondaryworkorderlistfilter`,
  WITHOUT_TERTIARY_CONSOLIDATED: `${BASE_URL}/api/withouttertiaryconsolidated`,
  TERTIARY_CONSOLIDATED_WORKORDER_FILTER: `${BASE_URL}/api/tertiaryworkorderlistfilter`,
  EXCELOLDPRIMARYWORKORDER: `${BASE_URL}/api/liveprimaryall`,
  EXCELOLDPRIMARYWORKORDER_ALLLIST: `${BASE_URL}/api/liveworkorderprimaryall`,
  EXCELOLDPRIMARYWORKORDER_NEARTAT: `${BASE_URL}/api/liveworkordernearprimaryall`,
  SECONDARY_OLDWORKORDER: `${BASE_URL}/api/liveworkordersecondary`,
  TERTIARY_OLDWORKORDER: `${BASE_URL}/api/liveworkordertertiary`,
  CONSOLIDATED_INDIVIDUAL_LIST_ALL: `${BASE_URL}/api/consolidatedindividualall`,
  CONSOLIDATED_INDIVIDUAL_LISTFILTER_ALL: `${BASE_URL}/api/consolidatedindividualfilterall`,
  QUEUE: `${BASE_URL}/api/queues`,
  QUEUE_CREATE: `${BASE_URL}/api/queue/new`,
  QUEUE_SINGLE: `${BASE_URL}/api/queue`,
  OVERALLQUEUE: `${BASE_URL}/api/getoverallqueuemasteredit`,
  OVERALLQUEUE_DELETE: `${BASE_URL}/api/getoverallqueuemasterdelete`,
  OVERALLQUEUE_BULK_DELETE: `${BASE_URL}/api/getoverallqueuemasterbulkdelete`,
  QUEUEGROUP: `${BASE_URL}/api/queuegroups`,
  QUEUEGROUP_CREATE: `${BASE_URL}/api/queuegroup/new`,
  QUEUEGROUP_SINGLE: `${BASE_URL}/api/queuegroup`,
  OVERALLQUEUE_GROUP_EDIT: `${BASE_URL}/api/getoverallqueuegroupdit`,
  OVERALLQUEUE_GROUP_DELETE: `${BASE_URL}/api/getoverallqueuegroupdelete`,
  OVERALLQUEUE_GROUP_BULK_DELETE: `${BASE_URL}/api/getoverallqueuegroupbulkdelete`,
  PROJECTMASTER: `${BASE_URL}/api/projectmasters`,
  PROJECTMASTERLIMIT: `${BASE_URL}/api/projectmasterlimit`,
  PROJECTMASTER_CREATE: `${BASE_URL}/api/projectmaster/new`,
  PROJECTMASTER_SINGLE: `${BASE_URL}/api/projectmaster`,
  OVERALL_PROJECTEXCEL: `${BASE_URL}/api/getoverallprojectmaster`,
  OVERALL_PROJECTEXCEL_BULK_DELETE: `${BASE_URL}/api/getoverallbulkdeleteprojectmaster`,
  VENDORMASTER: `${BASE_URL}/api/vendormasters`,
  VENDORMASTERLIMIT: `${BASE_URL}/api/vendormasterlimit`,
  VENDORMASTER_CREATE: `${BASE_URL}/api/vendormaster/new`,
  VENDORMASTER_SINGLE: `${BASE_URL}/api/vendormaster`,
  OVERALL_VENDORMASTER: `${BASE_URL}/api/getoverallvendormaster`,
  VENDORPROJECTCHECK: `${BASE_URL}/api/vendor/projectcheck`,
  FILTEREDVENDOREXCELUPLOAD: `${BASE_URL}/api/projectvendorsfilteredexcelupload`,
  OVERALLEDIT_VENDOR: `${BASE_URL}/api/getoverallvendormasteredit`,
  OVERALLEDIT_VENDOR_BULKDELETE: `${BASE_URL}/api/getoverallbulkdeletevendormasteredit`,
  OVERALLEDIT_VENDOR_DELETE: `${BASE_URL}/api/getoverallDeletevendormasteredit`,
  CATEGORYEXCEL: `${BASE_URL}/api/categoriesexcel`,
  CATEGORYEXCEL_CREATE: `${BASE_URL}/api/categoryexcel/new`,
  CATEGORYEXCEL_SINGLE: `${BASE_URL}/api/categoryexcel`,
  CATEGORYPROJECTCHECK: `${BASE_URL}/api/category/projectcheck`,
  OVERALL_CATEGORYEXCEL: `${BASE_URL}/api/getoverallcategorytmaster`,
  CATEGORYEXCEL_CHECK: `${BASE_URL}/api/vendor/categorycheck`,
  CATEGORYEXCEL_BULK_DELETE: `${BASE_URL}/api/categoryexcelbulkdelete`,
  SUBCATEGORYEXCEL: `${BASE_URL}/api/subcategoriesexcel`,
  SUBCATEGORYEXCEL_CREATE: `${BASE_URL}/api/subcategoryexcel/new`,
  SUBCATEGORYEXCEL_SINGLE: `${BASE_URL}/api/subcategoryexcel`,
  SUBCATEGORYPROJECTCHECK: `${BASE_URL}/api/subcategory/projectcheck`,
  SUBCATEGORY_CATEGORYCHECK: `${BASE_URL}/api/subcategory/categorycheck`,
  OVERALL_SUBCATEGORYEXCEL: `${BASE_URL}/api/getoverallsubcategorytmaster`,
  SUBCATEGORYEXCEL_CHECK: `${BASE_URL}/api/vendor/subcategorycheck`,
  SUBCATEGORYEXCEL_BULK_DELETE: `${BASE_URL}/api/subcategoryexcelbulkdelete`,
  TIMEPOINTS: `${BASE_URL}/api/timepoints`,
  TIMEPOINTS_CREATE: `${BASE_URL}/api/timepoint/new`,
  TIMEPOINTS_SINGLE: `${BASE_URL}/api/timepoint`,
  TIMEPOINTSPROJECTCHECK: `${BASE_URL}/api/timepoints/projectcheck`,
  TIMEPOINTSCATEGORYCHECK: `${BASE_URL}/api/timepoints/categorycheck`,
  TIMEPOINTSSUBCATEGORYCHECK: `${BASE_URL}/api/timepoints/subcategorycheck`,
  TIMEPOINTS_CHECK: `${BASE_URL}/api/vendor/timepointcheck`,
  TIMEPOINTS_CHECKEDIT_EXCELDATAS_CATSUBCATE: `${BASE_URL}/api/checkeditexceldatastimepoint`,
  OVERALL_TIMEPOINTS_BULK_DELETE: `${BASE_URL}/api/getoverallbulkdeletetimepoints`,
  VENDORS_DROPDOWN: `${BASE_URL}/api/vendordropdown`,
  CATEGORY_DROPDOWNS: `${BASE_URL}/api/categorydowns`,
  SUBCATEGORY_DROPDWONS: `${BASE_URL}/api/subcategorydropdowns`,
  VENDORS_DROPDOWN_EDIT: `${BASE_URL}/api/vendordropdownedit`,
  CATEGORY_DROPDOWNS_EDIT: `${BASE_URL}/api/categorydownsedit`,
  SUBCATEGORY_DROPDWONS_EDIT: `${BASE_URL}/api/subcategorydropdownsedit`,
  INTERNCOURSE: `${BASE_URL}/api/internCourses`,
  INTERNCOURSE_CREATE: `${BASE_URL}/api/internCourse/new`,
  INTERNCOURSE_SINGLE: `${BASE_URL}/api/internCourse`,
  TASK: `${BASE_URL}/api/tasks`,
  TASKLIMIT: `${BASE_URL}/api/taskslimit`,
  TASKBOARD: `${BASE_URL}/api/tasksboard`,
  TASKBOARDLIST: `${BASE_URL}/api/taskboardlist`,
  TASKLIST: `${BASE_URL}/api/taskslist`,
  TASK_CREATE: `${BASE_URL}/api/task/new`,
  TASK_SINGLE: `${BASE_URL}/api/task`,
  GETDEVTASKS: `${BASE_URL}/api/usertaskprofile`,
  TASKSLIMITCREATE: `${BASE_URL}/api/allfiltertaskcreatepage`,
  USERTTASKCHECK: `${BASE_URL}/api/user/task`,
  CHECKPOINTGROUPTOTASK: `${BASE_URL}/api/task/checkgroup`,
  USERTASKS: `${BASE_URL}/api/getsingleusertasks`,
  ALLTASKSADMIN: `${BASE_URL}/api/getalltasksadminview`,
  TASKBOARDVIEW_LIST_FILTER: `${BASE_URL}/api/taskboardviewlistsfilter`,
  TASKASSIGN_BOARD_LIST: `${BASE_URL}/api/taskassignboardlists`,
  TASKASSIGNBOARDLIST_CREATE: `${BASE_URL}/api/taskassignboardlist/new`,
  TASKASSIGNBOARDLIST_SINGLE: `${BASE_URL}/api/taskassignboardlist`,
  TASKASSIGNBOARDLIST_SINGLE_WORKORDER_DELETE: `${BASE_URL}/api/taskassignboardlistworkorders`,
  TASKASSIGN_BOARD_LIST_FILTER: `${BASE_URL}/api/taskassignboardlistsfilter`,
  TASKASSIGN_BOARD_LIST_LIMITED: `${BASE_URL}/api/taskassignboardlistslimited`,
  TASKASSIGN_BOARD_LIST_TABLEDATA: `${BASE_URL}/api/taskassignboardliststabledata`,
  NOTTASKASSIGN_BOARD_LIST_TABLEDATA: `${BASE_URL}/api/nottaskassignboardliststabledata`,
  TASKASSIGNBOARDLIST_SINGLE_NEW: `${BASE_URL}/api/taskassignboardlistnew`,
  ALLTASKCOMPLETED: `${BASE_URL}/api/getallcompletedtask`,
  GETTASKBOARDIDSTOUPDATE: `${BASE_URL}/api/gettaskidstoupdaterequirements`,
  TASKBOARDDEVELOPERINCOMPLETE: `${BASE_URL}/api/taskboardincompleteusers`,
  TASKBOARDDEVELOPERCOMPLETE: `${BASE_URL}/api/taskboardcompleteusers`,
  TASKBOARDTESTERCOMPLETE: `${BASE_URL}/api/taskboardcompletetester`,
  TASKBOARDTESTERINCOMPLETE: `${BASE_URL}/api/taskboardincompletetester`,
  TASKBOARDDEVELOPERINCOMPLETEALL: `${BASE_URL}/api/taskboardincompleteusersallaccess`,
  TASKBOARDDEVELOPERCOMPLETEALL: `${BASE_URL}/api/taskboardcompleteusersallaccess`,
  TASKBOARDTESTERCOMPLETEALL: `${BASE_URL}/api/taskboardcompletetesterallaccess`,
  TASKBOARDTESTERINCOMPLETEALL: `${BASE_URL}/api/taskboardincompletetesterallaccess`,
  TASKREPORTINCOMPLETE: `${BASE_URL}/api/taskreportdevincomplete`,
  TASKREPORTCOMPLETE: `${BASE_URL}/api/taskreportdevcomplete`,
  TASKREPORTTESTCOMPLETE: `${BASE_URL}/api/taskreporttestercomplete`,
  TASKREPORTTESTINCOMPLETE: `${BASE_URL}/api/taskreporttesterincomplete`,
  TASKCURRENTINCOMPLETE: `${BASE_URL}/api/taskscurrentincomplete`,
  TASKCURRENTCOMPLETE: `${BASE_URL}/api/taskscurrentcomplete`,
  TASKCURRENTTESTERINCOMPLETE: `${BASE_URL}/api/taskstestercurrentincomplete`,
  TASKCURRENTTESTERCOMPLETE: `${BASE_URL}/api/taskstestercurrentcomplete`,
  TASKFIVEINCOMPLETE: `${BASE_URL}/api/tasksfiveincomplete`,
  TASKFIVECOMPLETE: `${BASE_URL}/api/tasksfivecomplete`,
  TASKHOMETODAYDEVINCOMPLETE: `${BASE_URL}/api/taskhomepagetodaydevincomplete`,
  TASKHOMETODAYTESTERINCOMPLETE: `${BASE_URL}/api/taskhomepagetodaytesterincomplete`,
  TASKHOMEDEVINCOMPLETE: `${BASE_URL}/api/taskhomepagedevincomplete`,
  TASKHOMETESTERINCOMPLETE: `${BASE_URL}/api/taskhomepagetesterincomplete`,
  TASKHOMEALLCOMPLETE: `${BASE_URL}/api/taskhomepageallcomplete`,
  TASKHOMEDEVINCOMPLETEACCESS: `${BASE_URL}/api/taskhomepagedevincompleteaccess`,
  TASKHOMETESTERINCOMPLETEACCESS: `${BASE_URL}/api/taskhomepagetesterincompleteaccess`,
  TASKASSIGNCHECKTIMER: `${BASE_URL}/api/taskassignchecktimerstatus`,
  TASKCHECKDEFAULT: `${BASE_URL}/api/taskcheckdefaults`,
  TASKCHECKDEFAULT_CREATE: `${BASE_URL}/api/taskcheckdefault/new`,
  TASKCHECKDEFAULT_SINGLE: `${BASE_URL}/api/taskcheckdefault`,
  OVERALL_DESCRIPTION: `${BASE_URL}/api/overalldescriptions`,
  TIMER: `${BASE_URL}/api/timers`,
  TIMER_CREATE: `${BASE_URL}/api/timer/new`,
  TIMER_SINGLE: `${BASE_URL}/api/timer`,
  TASKUSERTIME: `${BASE_URL}/api/taskusertime`,
  NOTIFICATION: `${BASE_URL}/api/notifications`,
  NOTIFICATION_CREATE: `${BASE_URL}/api/notification/new`,
  NOTIFICATION_SINGLE: `${BASE_URL}/api/notification`,
  LOGINOUT: `${BASE_URL}/api/attandances`,
  INDUSERSLOGINOUT: `${BASE_URL}/api/userindividualattandances`,
  LOGINOUT_CREATE: `${BASE_URL}/api/attandance/new`,
  LOGINOUT_SINGLE: `${BASE_URL}/api/attandance`,
  ATTENDANCE_FILTER: `${BASE_URL}/api/attendancefilter`,
  ATTENDANCE_COLUMN_FILTER: `${BASE_URL}/api/attendancecolumnfilter`,
  ATTANDANCE_STATUS_DATE: `${BASE_URL}/api/attandancesstatusdates`,
  LOGINOUT_USERID: `${BASE_URL}/api/attandanceid`,
  ATTANDANCE_SINGLE: `${BASE_URL}/api/attandance`,
  USERFILTER: `${BASE_URL}/api/attendfilter`,
  ATTANDANCE_STATUS_USERDATE: `${BASE_URL}/api/attandancesstatususerdates`,
  ATTANDANCE_STATUS: `${BASE_URL}/api/attandancesstatus`,
  LOGINOUT_STATUSFALSE: `${BASE_URL}/api/attandancefalse`,
  LOGINOUT_STATUSTRUE: `${BASE_URL}/api/attandancetrue`,
  ATTENDANCE_HIERARCHYFILTER: `${BASE_URL}/api/attendancehierarchyfilter`,
  ATTENDANCE_HIERARCHYFILTERANOTHER: `${BASE_URL}/api/attendancehierarchyfilteranother`,
  ATTENDANCE_CLOCKIN_CREATE: `${BASE_URL}/api/attandanceclockintimecreate`,
  ATTENDANCE_CLOCKIN_SINGLE: `${BASE_URL}/api/attandanceclockinouttimeedit`,
  OVERALLSORT_ASSET: `${BASE_URL}/api/overallassettablesort`,
  OVERALLSORT_STOCK: `${BASE_URL}/api/stockpurchasesort`,
  REMARK_CREATE: `${BASE_URL}/api/remark/new`,
  REMARK: `${BASE_URL}/api/remarks`,
  ALLJOBOPENINGS: `${BASE_URL}/api/alljobopenings`,
  ASSIGNCHECKLISTALLJOBOPENINGS: `${BASE_URL}/api/assignchecklistalljobopenings`,
  CLOSEDALLJOBOPENINGS: `${BASE_URL}/api/withoutclosedjobopenings`,
  ONPROGRESSALLJOBOPENINGS: `${BASE_URL}/api/onprogressjobopenings`,
  JOBOPENING_CREATE: `${BASE_URL}/api/jobopening/new`,
  JOBOPENING_SINGLE: `${BASE_URL}/api/jobopening`,
  JOBOPENINGSDESIGNATION: `${BASE_URL}/api/jobopeningdesignation`,
  BRANCHADDRESS: `${BASE_URL}/api/branchaddress`,
  GETHRMANAGERS: `${BASE_URL}/api/gethrmanagers`,
  JOBOPNEING_FILTER: `${BASE_URL}/api/jobfilters`,
  CANDIDATES_ROLEUPDATE: `${BASE_URL}/api/updatecandidaterole`,
  APPROVEDS: `${BASE_URL}/api/approveds`,
  APPROVEDS_CREATE: `${BASE_URL}/api/approved/new`,
  APPROVEDS_SINGLE: `${BASE_URL}/api/approved`,
  REASON: `${BASE_URL}/api/addexists`,
  REASON_CREATE: `${BASE_URL}/api/addexist/new`,
  REASON_SINGLE: `${BASE_URL}/api/addexist`,
  ORGANISATION: `${BASE_URL}/api/addexistworks`,
  ORGANISATION_CREATE: `${BASE_URL}/api/addexistwork/new`,
  ORGANISATION_SINGLE: `${BASE_URL}/api/addexistwork`,
  ADDEXISTSALL: `${BASE_URL}/api/addexistalls`,
  ADDEXISTSALL_CREATE: `${BASE_URL}/api/addexistall/new`,
  ADDEXISTSALL_SINGLE: `${BASE_URL}/api/addexitsall`,
  NOTICEREASON: `${BASE_URL}/api/noticereasons`,
  NOTICEREASON_CREATE: `${BASE_URL}/api/noticereason/new`,
  NOTICEREASON_SINGLE: `${BASE_URL}/api/noticereason`,
  NOTICEPERIODAPPLY: `${BASE_URL}/api/noticeperiodapplies`,
  NOTICEPERIODAPPLY_CREATE: `${BASE_URL}/api/noticeperiodapply/new`,
  NOTICEPERIODAPPLY_SINGLE: `${BASE_URL}/api/noticeperiodapply`,
  CHECKLISTNOTICEPERIODAPPLY: `${BASE_URL}/api/checklistnoticeperiodapplies`,
  REFERCANDIDATE: `${BASE_URL}/api/refercandidates`,
  USERREFERCANDIDATE: `${BASE_URL}/api/userrefercandidate`,
  REFERCANDIDATE_CREATE: `${BASE_URL}/api/refercandidate/new`,
  REFERCANDIDATE_SINGLE: `${BASE_URL}/api/refercandidate`,
  LOCATIONWISE_ALL: `${BASE_URL}/api/locationwiseall`,
  BRANCHWISE_UNIT: `${BASE_URL}/api/branchwiseunit`,
  UNITWISE_TEAM: `${BASE_URL}/api/unitwiseteam`,
  USERWISE_FILTER_ALL: `${BASE_URL}/api/userwisefilter`,
  HIRERARCHI: `${BASE_URL}/api/hirerarchies`,
  HIRERARCHI_CREATE: `${BASE_URL}/api/hirerarchi/new`,
  HIRERARCHI_SINGLE: `${BASE_URL}/api/hirerarchi`,
  CONTROLNAME: `${BASE_URL}/api/designationcontname`,
  CHECKHIERARCHYADDNEWEMP: `${BASE_URL}/api/checkhierarchyaddnewemp`,
  HIRERARCHI_EDIT_MATCHCHECK: `${BASE_URL}/api/hierarchyeditmatchcheck`,
  USERWISE_FILTER_ALL_EDIT: `${BASE_URL}/api/userwisefilteredit`,
  CHECKHIERARCHYEDITEMPDETAILS: `${BASE_URL}/api/checkhierarchyeditempdetails`,
  CHECKHIERARCHYEDITEMPDETAILSDESIGN: `${BASE_URL}/api/checkhierarchyeditempdetailsdesig`,
  NOTASSIGNHIERARCHYLISTDATA: `${BASE_URL}/api/notassignhierarchylistdata`,
  CANDIDATES: `${BASE_URL}/api/candidates`,
  ACTIVECANDIDATES: `${BASE_URL}/api/activecandidates`,
  TODAYCANDIDATES: `${BASE_URL}/api/todaycandidates`,
  CANDIDATES_CREATE: `${BASE_URL}/api/candidate/new`,
  CANDIDATES_SINGLE: `${BASE_URL}/api/candidate`,
  FILTERALLUSER_RESUME: `${BASE_URL}/api/ageandlocationfilter`,
  CANDIDATESALL: `${BASE_URL}/api/allcandidates`,
  VISITORCANDIDATESALL: `${BASE_URL}/api/allvisitorcandidates`,
  INTERVIEWCANDIDATES: `${BASE_URL}/api/allinterviewcandidates`,
  ROLECANDIDATES_ALL: `${BASE_URL}/api/allcandidatesrole`,
  CUSTOMFORMS: `${BASE_URL}/api/customforms`,
  CUSTOMFORM_CREATE: `${BASE_URL}/api/customform/new`,
  CUSTOMFORM_SINGLE: `${BASE_URL}/api/customform`,
  CUSTOMFORMS_LIST: `${BASE_URL}/api/customformslist`,
  CUSTOMFORM_LIST_CREATE: `${BASE_URL}/api/customformlist/new`,
  CUSTOMFORM_LIST_SINGLE: `${BASE_URL}/api/customformlist`,
  ROLEANDRESPONSE: `${BASE_URL}/api/allroleandresponsibilities`,
  ROLEANDRESPONSE_CREATE: `${BASE_URL}/api/roleandresponsibile/new`,
  ROLEANDRESPONSE_SINGLE: `${BASE_URL}/api/roleandresponsibile`,
  BULKOVERALL_ALOTTEDQUEUE_LIST: `${BASE_URL}/api/bulkoveralleditallottedqueuelist`,
  BULKOVERALLDELETE_ALOTTEDQUEUE_LIST: `${BASE_URL}/api/bulkoveralldelete`,
  ALL_DOCUMENT: `${BASE_URL}/api/documents`,
  ALLASSIGNDOCUMENT: `${BASE_URL}/api/alldocumentassigned`,
  DOCUMENT_CREATE: `${BASE_URL}/api/documents/new`,
  DOCUMENT_SINGLE: `${BASE_URL}/api/document`,
  LISTOFDOCUMENT: `${BASE_URL}/api/listofdocuments`,
  LISTOFDOCUMENT_CREATE: `${BASE_URL}/api/listofdocument/new`,
  LISTOFDOCUMENT_SINGLE: `${BASE_URL}/api/listofdocument`,
  DOCUMENTGROUPING: `${BASE_URL}/api/documentgroupings`,
  DOCUMENTGROUPING_CREATE: `${BASE_URL}/api/documentgrouping/new`,
  DOCUMENTGROUPING_SINGLE: `${BASE_URL}/api/documentgrouping`,
  CATEGORYDOCUMENT: `${BASE_URL}/api/documentcategories`,
  CATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/documentcategory/new`,
  CATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/documentcategory`,
  CATEGORYDOCUMENT_OVERALLEDIT: `${BASE_URL}/api/overalldocumentedit`,
  CATEGORYDOCUMENT_OVERALLDELETE: `${BASE_URL}/api/documentscategorydelete`,
  GROUP: `${BASE_URL}/api/groups`,
  GROUP_CREATE: `${BASE_URL}/api/group/new`,
  GROUP_SINGLE: `${BASE_URL}/api/group`,
  ACCOUNTGROUP: `${BASE_URL}/api/accountgroups`,
  ACCOUNTGROUP_CREATE: `${BASE_URL}/api/accountgroup/new`,
  ACCOUNTGROUP_SINGLE: `${BASE_URL}/api/accountgroup`,
  ACCOUNTHEAD: `${BASE_URL}/api/accounts`,
  ACCOUNTHEAD_CREATE: `${BASE_URL}/api/account/new`,
  ACCOUNTHEAD_SINGLE: `${BASE_URL}/api/account`,
  ASSETS: `${BASE_URL}/api/assets`,
  ASSET_CREATE: `${BASE_URL}/api/asset/new`,
  ASSET_SINGLE: `${BASE_URL}/api/asset`,
  OVERALL_ASSET_LIMITED: `${BASE_URL}/api/overallassetlimited`,
  ASSETCATEGORYGROUPING: `${BASE_URL}/api/assetcategorygroupings`,
  ASSETCATEGORYGROUPING_CREATE: `${BASE_URL}/api/assetcategorygrouping/new`,
  ASSETCATEGORYGROUPING_SINGLE: `${BASE_URL}/api/assetcategorygrouping`,
  STOCKPURCHASELIMITED: `${BASE_URL}/api/stockpurchaselimited`,
  ASSETDETAIL_STOCK_LIMITED: `${BASE_URL}/api/assetdetailsstocklimited`,
  CREATE_CONTROLNAME: `${BASE_URL}/api/controlname/new`,
  ALL_CONTROLNAME: `${BASE_URL}/api/controlnames`,
  CONTROLNAME_SINGLE: `${BASE_URL}/api/controlname`,
  ROLENAME: `${BASE_URL}/api/rolesname`,
  ASSETDETAIL: `${BASE_URL}/api/assetdetails`,
  TICKETASSETDETAIL: `${BASE_URL}/api/ticketassetdetails`,
  FILTERASSETDETAIL: `${BASE_URL}/api/boardingassetdetails`,
  BRANCHFLOORASSETDETAIL: `${BASE_URL}/api/branchfloorassetdetails`,
  ASSETDETAILFILTER: `${BASE_URL}/api/assetdetailsfilter`,
  ASSETDETAILCOUNTFILTER: `${BASE_URL}/api/assetdetailscountfilter`,
  ASSETDETAIL_CREATE: `${BASE_URL}/api/assetdetail/new`,
  ASSETDETAIL_SINGLE: `${BASE_URL}/api/assetdetail`,
  ASSETDETAIL_LIMITED: `${BASE_URL}/api/assetdetailslimited`,
  CREATE_VOMMASTERNAME: `${BASE_URL}/api/vommastername/new`,
  ALL_VOMMASTERNAME: `${BASE_URL}/api/vommasternames`,
  SINGLE_VOMMASTERNAME: `${BASE_URL}/api/vommastername`,
  EMPLOYEEASSET: `${BASE_URL}/api/employeeassets`,
  EMPLOYEEASSET_CREATE: `${BASE_URL}/api/employeeasset/new`,
  EMPLOYEEASSET_SINGLE: `${BASE_URL}/api/employeeasset`,
  ADD_VENDORDETAILS: `${BASE_URL}/api/vendordetails/new`,
  ALL_VENDORDETAILS: `${BASE_URL}/api/allvendordetails`,
  SINGLE_VENDORDETAILS: `${BASE_URL}/api/singlevendordetails`,
  CREATE_FREQUENCYMASTER: `${BASE_URL}/api/frequencymaster/new`,
  ALL_FREQUENCYMASTER: `${BASE_URL}/api/frequencymasters`,
  SINGLE_FREQUENCYMASTER: `${BASE_URL}/api/frequencymaster`,
  NEW_OTHERPAYMENTS: `${BASE_URL}/api/otherpayment/new`,
  ALL_OTHERPAYMENTS: `${BASE_URL}/api/allotherpayments`,
  SINGLE_OTHERPAYMENTS: `${BASE_URL}/api/otherpayment`,
  MAINTENTANCE: `${BASE_URL}/api/maintentances`,
  MAINTENTANCE_CREATE: `${BASE_URL}/api/maintentance/new`,
  MAINTENTANCE_SINGLE: `${BASE_URL}/api/maintentance`,
  CATEGORYTICKET: `${BASE_URL}/api/ticketcategories`,
  CATEGORYTICKET_CREATE: `${BASE_URL}/api/ticketcategory/new`,
  CATEGORYTICKET_SINGLE: ` ${BASE_URL}/api/ticketcategory`,
  TYPEMASTER: `${BASE_URL}/api/typemasters`,
  TYPEMASTER_CREATE: ` ${BASE_URL}/api/typemaster/new`,
  TYPEMASTER_SINGLE: `${BASE_URL}/api/typemaster`,
  TYPETICKETMASTER: `${BASE_URL}/api/ticketmastertypes`,
  TYPETICKETMASTER_CREATE: `${BASE_URL}/api/ticketmastertype/new`,
  TYPETICKETMASTER_SINGLE: ` ${BASE_URL}/api/ticketmastertype`,
  REASONMASTER: `${BASE_URL}/api/reasonmasters`,
  REASONMASTER_CREATE: ` ${BASE_URL}/api/reasonmaster/new`,
  REASONMASTER_SINGLE: `${BASE_URL}/api/reasonmaster`,
  RESOLVERREASONMASTER: `${BASE_URL}/api/resolverreasonmaster`,
  RESOLVERREASONMASTER_CREATE: `${BASE_URL}/api/resolverreasonmaster/new`,
  RESOLVERREASONMASTER_SINGLE: `${BASE_URL}/api/resolverreasonmaster`,
  RESOLVERREASONMASTERCETEGORYFILTER: `${BASE_URL}/api/resolverreasonmasterfilter`,
  MEETINGMASTER: `${BASE_URL}/api/meetingmasters`,
  MEETINGMASTER_CREATE: `${BASE_URL}/api/meetingmaster/new`,
  MEETINGMASTER_SINGLE: `${BASE_URL}/api/meetingmaster`,
  CREATE_HOLIDAY: `${BASE_URL}/api/holiday/new`,
  ALL_HOLIDAY: `${BASE_URL}/api/holidays`,
  SINGLE_HOLIDAY: `${BASE_URL}/api/holiday`,
  HOLIDAYFILTER: `${BASE_URL}/api/holidayfilter`,
  TODAY_HOLIDAY: `${BASE_URL}/api/todayholidayfilter`,
  GETTODAYSHIFT: `${BASE_URL}/api/todayshifts`,
  CREATE_STATUSMASTER: `${BASE_URL}/api/statusmaster/new`,
  ALL_STATUSMASTER: `${BASE_URL}/api/statusmasters`,
  SINGLE_STATUSMASTER: `${BASE_URL}/api/statusmaster`,
  SUBSUBCOMPONENT: `${BASE_URL}/api/subsubcategorytickets`,
  SUBSUBCOMPONENT_CREATE: `${BASE_URL}/api/subsubcategoryticket/new`,
  SUBSUBCOMPONENT_SINGLE: `${BASE_URL}/api/subsubcategoryticket`,
  DEPT_TEAM: `${BASE_URL}/api/getdeptandteam`,
  APPLYLEAVE: `${BASE_URL}/api/applyleaves`,
  ACTIVEAPPLYLEAVE: `${BASE_URL}/api/activeuserapplyleaves`,
  APPLYLEAVE_CREATE: `${BASE_URL}/api/applyleave/new`,
  APPLYLEAVE_SINGLE: `${BASE_URL}/api/applyleave`,
  CHECKPOINTTICKET: `${BASE_URL}/api/checkpointtickets`,
  CHECKPOINTTICKET_CREATE: `${BASE_URL}/api/checkpointticket/new`,
  CHECKPOINTTICKET_SINGLE: `${BASE_URL}/api/checkpointticket`,
  STOCKPURCHASE: `${BASE_URL}/api/stocks`,
  STOCKPURCHASE_CREATE: `${BASE_URL}/api/stock/new`,
  STOCKPURCHASE_SINGLE: `${BASE_URL}/api/stock`,
  STOCKTRANSFERFILTER: `${BASE_URL}/api/stockmantransferfilter`,
  STOCKMANAGE: `${BASE_URL}/api/stockmanages`,
  STOCKMANAGE_CREATE: `${BASE_URL}/api/stockmanage/new`,
  STOCKMANAGE_SINGLE: `${BASE_URL}/api/stockmanage`,
  STOCKMANAGEFILTERED: `${BASE_URL}/api/stockfilter`,
  SECONDARY_CONSOLIDATED_HIERARCHY_FILTER: `${BASE_URL}/api/secondaryhierarchyfilter`,
  TERTIARY_CONSOLIDATED_HIERARCHY_FILTER: `${BASE_URL}/api/tertiaryhierarchyfilter`,
  PRIMARY_HIERARCHY_WORKORDEROVERTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderovertatdata`,
  PRIMARY_HIERARCHY_WORKORDERNEARTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderneartatdata`,
  PRIMARY_HIERARCHY_WORKORDERALL: `${BASE_URL}/api/primaryhierarchyworkorderall`,
  OTHER_WORKORDER_HIERARCHY_FILTER: `${BASE_URL}/api/orderhierarchyworkorder`,
  CONSOLIDATED_HIERARCHY_FILTER_PRISECTER: `${BASE_URL}/api/consolidatedheirarchyprimsectert`,
  CONSOLIDATED_HIERARCHY_FILTER_ALL: `${BASE_URL}/api/consolidatedheirarchyall`,
  TEAMGROUPING: `${BASE_URL}/api/teamgroupings`,
  TEAMGROUPING_CREATE: `${BASE_URL}/api/teamgrouping/new`,
  TEAMGROUPING_SINGLE: `${BASE_URL}/api/teamgrouping`,
  RAISETICKET: `${BASE_URL}/api/raisetickets`,
  RAISETICKETOPEN: `${BASE_URL}/api/raiseticketsopen`,
  RAISETICKETCLOSED: `${BASE_URL}/api/raiseticketsclosed`,
  RAISETICKETEDITDUPLICATE: `${BASE_URL}/api/raiseticketseditduplicate`,
  RAISETICKET_CREATE: `${BASE_URL}/api/raiseticket/new`,
  RAISETICKET_SINGLE: `${BASE_URL}/api/raiseticket`,
  RAISETICKET_INDIVIDUALFILTER: `${BASE_URL}/api/raiseticketindividualfilter`,
  RAISE_HIERARCHY_FORWARD: `${BASE_URL}/api/raisehierarchyforward`,
  RAISETICKET_WITHOUT_CLOSED: `${BASE_URL}/api/raiseticketswithoutclosed`,
  SECONDARY_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/secondaryhierarchydefault`,
  TERTIARY_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/tertiaryhierarchydefault`,
  OTHER_WORKORDER_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/orderhierarchyworkorderdefault`,
  CONSOLIDATED_HIERARCHY_DEFAULT_PRISECTER: `${BASE_URL}/api/consolidatedheirarchyprimsectertdefault`,
  CONSOLIDATED_DEFAULT_HIERARCHY_FILTER: `${BASE_URL}/api/consolidatedheirarchyalldefault`,
  PRIMARY_DEFAULT_HIERARCHY_WORKORDEROVERTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderovertatdatadefault`,
  PRIMARY_DEFAULT_HIERARCHY_WORKORDERNEARTATDATA: `${BASE_URL}/api/primaryhierarchyworkorderneartatdatadefault`,
  PRIMARY_DEFAULT_HIERARCHY_WORKORDERALL: `${BASE_URL}/api/primaryhierarchyworkorderalldefault`,
  HIERARCHI_TEAM_DESIGNATION_CHECK: `${BASE_URL}/api/hierarchyteamdesignationcheck`,
  EXPENSES_CREATE: `${BASE_URL}/api/expenses/new`,
  EXPENSES_SINGLE: `${BASE_URL}/api/expenses`,
  EXPENSESALL: `${BASE_URL}/api/allexpenses`,
  LOCATIONWISEBRANCH: `${BASE_URL}/api/locationwisebranch`,
  EXPENSESUBCAT: `${BASE_URL}/api/expensesubcat`,
  EXPENSEREMINDER: `${BASE_URL}/api/expensereminders`,
  EXPENSEREMINDER_CREATE: `${BASE_URL}/api/expensereminder/new`,
  EXPENSEREMINDER_SINGLE: `${BASE_URL}/api/expensereminder`,
  ALLREMINDER: `${BASE_URL}/api/allreminder`,
  PAYMENTDUEREMINDER: `${BASE_URL}/api/paymentduereminder`,
  EXPENSECATEGORY: `${BASE_URL}/api/expensecategories`,
  EXPENSECATEGORY_CREATE: `${BASE_URL}/api/expensecategory/new`,
  EXPENSECATEGORY_SINGLE: `${BASE_URL}/api/expensecategory`,
  CREATE_EVENT: `${BASE_URL}/api/scheduleevent/new`,
  ALL_EVENT: `${BASE_URL}/api/allscheduleevents`,
  SINGLE_EVENT: `${BASE_URL}/api/scheduleevent`,
  BRANCH_UNIT: `${BASE_URL}/api/getunitbybranch`,
  BRANCH_TEAM: `${BASE_URL}/api/getteambybranchandunit`,
  TEAM_PARTICIPANTS: `${BASE_URL}/api/getparticipants`,
  ALL_EVENTFILTER: `${BASE_URL}/api/eventallfilter`,
  CATEGORYINTERVIEW: `${BASE_URL}/api/interviewcategories`,
  CATEGORYINTERVIEW_CREATE: `${BASE_URL}/api/interviewcategory/new`,
  CATEGORYINTERVIEW_SINGLE: ` ${BASE_URL}/api/interviewcategory`,
  ROUNDMASTER: ` ${BASE_URL}/api/roundmasters`,
  ROUNDMASTER_CREATE: ` ${BASE_URL}/api/roundmaster/new`,
  ROUNDMASTER_SINGLE: `${BASE_URL}/api/roundmaster`,
  INTERVIEWTYPEMASTER: `${BASE_URL}/api/interviewtypemasters`,
  INTERVIEWTYPEMASTER_CREATE: `${BASE_URL}/api/interviewtypemaster/new`,
  INTERVIEWTYPEMASTER_SINGLE: `${BASE_URL}/api/interviewtypemaster`,
  CREATE_MEETING: `${BASE_URL}/api/schedulemeeting/new`,
  ALL_MEETING: `${BASE_URL}/api/allschedulemeetings`,
  SINGLE_MEETING: `${BASE_URL}/api/schedulemeeting`,
  BRANCH_DEPT: `${BASE_URL}/api/getdeptbybranch`,
  BRANCH_DEPT_TEAM: `${BASE_URL}/api/getteambybranchanddept`,
  MEETING_PARTICIPANTS: `${BASE_URL}/api/getparticipantsformeeting`,
  SCHEDULEMEETINGFILTER: `${BASE_URL}/api/schedulemeetingfilter`,
  SCHEDULEMEETINGFILTERFPAGE: `${BASE_URL}/api/schedulemeetingfilterpage`,
  SINGLE_NOTICEMEETING: `${BASE_URL}/api/singlenoticeperiodmeeting`,
  AREAGROUPING: `${BASE_URL}/api/areagroupings`,
  AREAGROUPING_CREATE: `${BASE_URL}/api/areagrouping/new`,
  AREAGROUPING_SINGLE: `${BASE_URL}/api/areagrouping`,
  LOCATIONGROUPING: `${BASE_URL}/api/locationgroupings`,
  LOCATIONGROUPING_CREATE: `${BASE_URL}/api/locationgrouping/new`,
  LOCATIONGROUPING_SINGLE: `${BASE_URL}/api/locationgrouping`,
  REFCATEGORYDOCUMENT: `${BASE_URL}/api/referencecategories`,
  REFCATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/referencecategory/new`,
  REFCATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/referencecategory`,
  REFCATEGORYDOCUMENT_OVERALLEDIRT: `${BASE_URL}/api/referencecategoryedit`,
  ALL_REFDOCUMENT: `${BASE_URL}/api/allrefdocuments`,
  REFDOCUMENT_CREATE: `${BASE_URL}/api/refdocuments/new`,
  REFDOCUMENT_SINGLE: `${BASE_URL}/api/refdocument`,
  GET_SUBCAT: `${BASE_URL}/api/getsubcategoryref`,
  REFDOCUMENT_OVERALLDELETE: `${BASE_URL}/api/refdocumentdelete`,
  DUEDATE: `${BASE_URL}/api/duedatemasters`,
  DUEDATE_CREATE: `${BASE_URL}/api/duedatemaster/new`,
  DUEDATE_SINGLE: `${BASE_URL}/api/duedatemaster`,
  PRIORITYMASTER: `${BASE_URL}/api/prioritymastermasters`,
  PRIORITYMASTER_CREATE: `${BASE_URL}/api/prioritymastermaster/new`,
  PRIORITYMASTER_SINGLE: `${BASE_URL}/api/prioritymastermaster`,
  INTERVIEWQUESTION: `${BASE_URL}/api/interviewquestions`,
  INTERVIEWQUESTION_CREATE: `${BASE_URL}/api/interviewquestion/new`,
  INTERVIEWQUESTION_SINGLE: `${BASE_URL}/api/interviewquestion`,
  INTERVIEWQUESTIONGROUPING: `${BASE_URL}/api/interviewquestiongroupings`,
  INTERVIEWQUESTIONGROUPING_CREATE: `${BASE_URL}/api/interviewquestiongrouping/new`,
  INTERVIEWQUESTIONGROUPING_SINGLE: `${BASE_URL}/api/interviewquestiongrouping`,
  GET_INT_FORM_DESIGN: `${BASE_URL}/api/interviewformdesigns`,
  CREATE_INT_FORM_DESIGN: `${BASE_URL}/api/interviewformdesign/new`,
  SINGLE_INT_FORM_DESIGN: `${BASE_URL}/api/interviewformdesign`,
  INTERVIEWQUESTIONSORDER: `${BASE_URL}/api/interviewquestionsorders`,
  INTERVIEWQUESTIONSORDER_CREATE: `${BASE_URL}/api/interviewquestionsorder/new`,
  INTERVIEWQUESTIONSORDER_SINGLE: `${BASE_URL}/api/interviewquestionsorder`,
  GET_USER_RESPONSES: `${BASE_URL}/api/interviewuserresponses`,
  CREATE_USER_RESPONSE: `${BASE_URL}/api/interviewuserresponse/new`,
  SINGLE_USER_RESPONSE: `${BASE_URL}/api/interviewuserresponse`,
  PASSCATEGORYDOCUMENT: `${BASE_URL}/api/passwordcategories`,
  PASSCATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/passwordcategory/new`,
  PASSCATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/passwordcategory`,
  CREATE_PASSWORD: `${BASE_URL}/api/password/new`,
  ALL_PASSWORD: `${BASE_URL}/api/allpasswords`,
  ACTIVEALL_PASSWORD: `${BASE_URL}/api/activeallpasswords`,
  SINGLE_PASSWORD: `${BASE_URL}/api/password`,
  EMP_NAME: `${BASE_URL}/api/getemployeename`,
  EMP_DETAILS: `${BASE_URL}/api/getemployeedetails`,
  SUB_CAT: `${BASE_URL}/api/getsubcategory`,
  DEPMONTHSET_ALL: `${BASE_URL}/api/departmentmonthsets`,
  DEPMONTHSET_CREATE: `${BASE_URL}/api/departmentmonthset/new`,
  DEPMONTHSET_SINGLE: `${BASE_URL}/api/departmentmonthset`,
  USEREMP_TEAMGROUP: `${BASE_URL}/api/usersteamgrouping`,
  DEPTMONTHSET_LIMITED: `${BASE_URL}/api/departmentmonthsetslimited`,
  LEAVECRITERIA: `${BASE_URL}/api/leavecriterias`,
  LEAVECRITERIA_CREATE: `${BASE_URL}/api/leavecriteria/new`,
  LEAVECRITERIA_SINGLE: `${BASE_URL}/api/leavecriteria`,
  CREATE_ASSETTYPEGROUPING: `${BASE_URL}/api/assettypegrouping/new`,
  ALL_ASSETTYPEGROUPING: `${BASE_URL}/api/assettypegroupings`,
  SINGLE_ASSETTYPEGROUPING: `${BASE_URL}/api/assettypegrouping`,
  CREATE_ASSETTYPEMASTER: ` ${BASE_URL}/api/assettypemaster/new`,
  ALL_ASSETTYPEMASTER: `${BASE_URL}/api/assettypemasters`,
  SINGLE_ASSETTYPEMASTER: `${BASE_URL}/api/assettypemaster`,
  WORKSTATION: `${BASE_URL}/api/workstations`,
  WORKSTATION_CREATE: `${BASE_URL}/api/workstation/new`,
  WORKSTATION_SINGLE: `${BASE_URL}/api/workstation`,
  RAISEISSUE: `${BASE_URL}/api/raiseissues`,
  RAISEISSUE_CREATE: `${BASE_URL}/api/raiseissue/new`,
  RAISEISSUE_SINGLE: `${BASE_URL}/api/raiseissue`,
  ASSETWORKSTAION: `${BASE_URL}/api/assetworkstations`,
  ASSETWORKSTAION_CREATE: `${BASE_URL}/api/assetworkstation/new`,
  ASSETWORKSTAION_SINGLE: `${BASE_URL}/api/assetworkstation`,
  CREATE_BRANDMASTER: `${BASE_URL}/api/brandmaster/new`,
  ALL_BRANDMASTER: `${BASE_URL}/api/brandmasters`,
  SINGLE_BRANDMASTER: `${BASE_URL}/api/brandmaster`,
  CONTROLCRITERIA: `${BASE_URL}/api/controlcriterias`,
  CONTROLCRITERIA_CREATE: `${BASE_URL}/api/controlcriteria/new`,
  CONTROLCRITERIA_SINGLE: `${BASE_URL}/api/controlcriteria`,
  CREATE_ASSETMODEL: `${BASE_URL}/api/assetmodel/new`,
  ALL_ASSETMODEL: `${BASE_URL}/api/assetmodels`,
  SINGLE_ASSETMODEL: `${BASE_URL}/api/assetmodel`,
  CREATE_ASSETVARIANT: `${BASE_URL}/api/assetvariant/new`,
  ALL_ASSETVARIANT: `${BASE_URL}/api/assetvariants`,
  SINGLE_ASSETVARIANT: `${BASE_URL}/api/assetvariant`,
  CREATE_ASSETSIZE: `${BASE_URL}/api/assetsize/new`,
  ALL_ASSETSIZE: `${BASE_URL}/api/assetsizes`,
  SINGLE_ASSETSIZE: `${BASE_URL}/api/assetsize`,
  CREATE_ASSETSPECIFICATIONTYPE: `${BASE_URL}/api/assetspecificationtype/new`,
  ALL_ASSETSPECIFICATIONTYPE: `${BASE_URL}/api/assetspecificationtypes`,
  SINGLE_ASSETSPECIFICATIONTYPE: `${BASE_URL}/api/assetspecificationtype`,
  CREATE_CLIENTUSERID: `${BASE_URL}/api/clientuserid/new`,
  ALL_CLIENTUSERID: `${BASE_URL}/api/clientuserids`,
  ALL_CLIENTUSERIDDATA: `${BASE_URL}/api/clientuseridsdata`,
  SINGLE_CLIENTUSERID: `${BASE_URL}/api/clientuserid`,
  CLIENTUSERID_SORT: `${BASE_URL}/api/clientuseridsort`,
  SINGLE_CLIENTUSERID_FORACCESSFORLOG: `${BASE_URL}/api/clientuseridforlog`,
  CREATE_PROCESSQUEUENAME: `${BASE_URL}/api/processqueuename/new`,
  ALL_PROCESSQUEUENAME: `${BASE_URL}/api/processqueuenames`,
  SINGLE_PROCESSQUEUENAME: `${BASE_URL}/api/processqueuename`,
  PROCESSQUEUENAME_SORT: `${BASE_URL}/api/processqueuenamesort`,
  ALL_PROCESS_AND_TEAM_FILTER: `${BASE_URL}/api/processteam_filter`,
  TARGETPOINTS: `${BASE_URL}/api/targetpoints`,
  TARGETPOINT_CREATE: `${BASE_URL}/api/targetpoint/new`,
  TARGETPOINT_SINGLE: `${BASE_URL}/api/targetpoint`,
  TARGETPOINTS_LIMITED: `${BASE_URL}/api/targetpointslimited`,
  ADD_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/productiontempconsolidated/new`,
  GET_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/productiontempconsolidateds`,
  SINGLE_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/productiontempconsolidated`,
  FILTER_PRODUCTION_TEMP_CONSOLIDATED: `${BASE_URL}/api/filterproductiontempconsolidated`,
  MANAGECATEGORY: `${BASE_URL}/api/managecategorys`,
  MANAGECATEGORY_CREATE: `${BASE_URL}/api/managecategory/new`,
  MANAGECATEGORY_SORT: `${BASE_URL}/api/managecategoryssort`,
  MANAGECATEGORY_SINGLE: `${BASE_URL}/api/managecategory`,
  PAIDSTATUSFIX: `${BASE_URL}/api/paidstatusfixs`,
  PAIDSTATUSFIX_CREATE: `${BASE_URL}/api/paidstatusfix/new`,
  PAIDSTATUSFIX_SINGLE: `${BASE_URL}/api/paidstatusfix`,
  XEROXMONTHYEARPAIDSTATUS: `${BASE_URL}/api/xeroxpaidstatusfixfilter`,
  PAIDDATEFIX: `${BASE_URL}/api/paiddatefixs`,
  PAIDDATEFIX_CREATE: `${BASE_URL}/api/paiddatefix/new`,
  PAIDDATEFIX_SINGLE: `${BASE_URL}/api/paiddatefix`,
  PAIDDATEMODE: `${BASE_URL}/api/paiddatemodes`,
  PAIDDATEMODE_CREATE: `${BASE_URL}/api/paiddatemode/new`,
  PAIDDATEMODE_SINGLE: `${BASE_URL}/api/paiddatemode`,
  XEROXMONTHYEARPAIDMODE: `${BASE_URL}/api/xeroxfilterpaiddatemodes`,
  CATEGORYPROCESSMAP: `${BASE_URL}/api/categoryprocessmaps`,
  CATEGORYPROCESSMAP_CREATE: `${BASE_URL}/api/categoryprocessmap/new`,
  CATEGORYPROCESSMAP_SINGLE: `${BASE_URL}/api/categoryprocessmap`,
  CATEGORYPROCESSMAP_BULKDELETE: `${BASE_URL}/api/categoryprocessmapmutidelete`,
  CATEGORYPROCESSMAP_SORT: `${BASE_URL}/api/categoryprocessmapssort`,
  ADD_TEMP_POINTS: `${BASE_URL}/api/temppoint/new`,
  GET_TEMP_POINTS: `${BASE_URL}/api/temppoints`,
  SINGLE_TEMP_POINTS: `${BASE_URL}/api/temppoint`,
  SINGLE_TEMP_POINTS_UPLOAD: `${BASE_URL}/api/singletemppoint`,
  CREATE_PROFFESIONALTAXMASTER: `${BASE_URL}/api/professionaltaxmaster/new`,
  ALL_PROFFESIONALTAXMASTER: `${BASE_URL}/api/professionaltaxmasters`,
  SINGLE_PROFFESIONALTAXMASTER: `${BASE_URL}/api/professionaltaxmaster`,
  DEPARTMENTGROUPINGS: `${BASE_URL}/api/departmentgroupings`,
  DEPARTMENTGROUPING_CREATE: `${BASE_URL}/api/departmentgrouping/new`,
  DEPARTMENTGROUPING_SINGLE: `${BASE_URL}/api/departmentgrouping`,
  LEAD_CREATE: `${BASE_URL}/api/lead/new`,
  LEADS: `${BASE_URL}/api/leads`,
  LEAD_SINGLE: `${BASE_URL}/api/lead`,
  SINGLE_OVERALL_SETTINGS: `${BASE_URL}/api/singleoverallsettings`,
  CREATE_OVERALL_SETTINGS: `${BASE_URL}/api/createoverallsettings`,
  GET_OVERALL_SETTINGS: `${BASE_URL}/api/getoverallsettings`,
  CREATE_INDIVIDUAL_SETTING: `${BASE_URL}/api/individualsettings/new`,
  ALL_INDIVIDUAL_SETTING: `${BASE_URL}/api/allindividualsettings`,
  SINGLE_INDIVIDUAL_SETTING: `${BASE_URL}/api/individualsettings`,
  GET_OVERALL_USERSWITCH_INFO: `${BASE_URL}/api/getoverallusersswitch`,
  CLOCKINIP: `${BASE_URL}/api/allclockinip`,
  ASSIGNCLOCKINIP: `${BASE_URL}/api/assignallclockinip`,
  CLOCKINIP_CREATE: `${BASE_URL}/api/clockinip/new`,
  CLOCKINIP_SINGLE: `${BASE_URL}/api/clockinip`,
  BRANCH_IP: `${BASE_URL}/api/getipbybranch`,
  ALL_USER_PASS: `${BASE_URL}/api/alluserspasswordchange`,
  SINGLE_USER_PASS: `${BASE_URL}/api/singleuserpasswordchange`,
  ORGCATEGORYDOCUMENT_CREATE: `${BASE_URL}/api/organizationdocumentcategory/new`,
  ORGCATEGORYDOCUMENT: `${BASE_URL}/api/organizationdocumentcategorys`,
  ORGCATEGORYDOCUMENT_SINGLE: `${BASE_URL}/api/organizationdocumentcategory`,
  ORGCATEGORYDOCUMENT_OVERALLEDIRT: `${BASE_URL}/api/organizationcategorydocumentedit`,
  ALL_ORGDOCUMENT: `${BASE_URL}/api/allorgdocuments`,
  IMAGEALL_ORGDOCUMENT: `${BASE_URL}/api/imageorgdocuments`,
  ORGDOCUMENT_CREATE: `${BASE_URL}/api/orgdocuments/new`,
  ORGDOCUMENT_SINGLE: `${BASE_URL}/api/orgdocument`,
  GET_ORGSUBCAT: `${BASE_URL}/api/getorgsubcategoryref`,
  ORGDOCUMENT_OVERALLDELETE: `${BASE_URL}/api/orgdocumentdelete`,
  USER_CLOCKIN_CLOCKOUT_STATUS_LOGIN_CHECK: `${BASE_URL}/api/userclockinclockoutstatuslogincheck`,
  TASKCATEGORY: `${BASE_URL}/api/taskcategories`,
  TASKCATEGORY_CREATE: `${BASE_URL}/api/taskcategory/new`,
  TASKCATEGORY_SINGLE: ` ${BASE_URL}/api/taskcategory`,
  TASKSUBCATEGORY: `${BASE_URL}/api/tasksubcategories`,
  TASKSUBCATEGORY_CREATE: `${BASE_URL}/api/tasksubcategory/new`,
  TASKSUBCATEGORY_SINGLE: ` ${BASE_URL}/api/tasksubcategory`,
  TRAININGCATEGORY: `${BASE_URL}/api/trainingcategories`,
  TRAININGCATEGORY_CREATE: `${BASE_URL}/api/trainingcategory/new`,
  TRAININGCATEGORY_SINGLE: ` ${BASE_URL}/api/trainingcategory`,
  ALL_NONSCHEDULE_TRAININGDETAILS: `${BASE_URL}/api/nonscheduletrainingdetailss`,
  CREATE_NONSCHEDULE_TRAININGDETAILS: `${BASE_URL}/api/nonscheduletrainingdetails/new`,
  SINGLE_NONSCHEDULE_TRAININGDETAILS: `${BASE_URL}/api/nonscheduletrainingdetails`,
  TRAININGSUBCATEGORY: `${BASE_URL}/api/trainingsubcategories`,
  TRAININGSUBCATEGORY_CREATE: `${BASE_URL}/api/trainingsubcategory/new`,
  TRAININGSUBCATEGORY_SINGLE: ` ${BASE_URL}/api/trainingsubcategory`,
  FILTERTRAININGSUBCATEGORY: `${BASE_URL}/api/filtertrainingsubcategories`,
  FILTER_DOCUMENT_TRAINING: `${BASE_URL}/api/filterdocumentstraining`,
  SOURCE: `${BASE_URL}/api/sources`,
  SOURCE_CREATE: `${BASE_URL}/api/source/new`,
  SOURCE_SINGLE: ` ${BASE_URL}/api/source`,
  ASSIGNBRANCH: `${BASE_URL}/api/assignbranches`,
  ASSIGNBRANCH_CREATE: `${BASE_URL}/api/assignbranch/new`,
  ASSIGNBRANCH_SINGLE: `${BASE_URL}/api/assignbranch`,
  GETUSERASSIGNBRANCH: `${BASE_URL}/api/usersassignbranch`,
  ASSIGNBRANCH_ACCESSIBLE: `${BASE_URL}/api/assignbranchesaccessible`,
  INCOME: `${BASE_URL}/api/incomes`,
  INCOME_CREATE: `${BASE_URL}/api/income/new`,
  INCOME_SINGLE: `${BASE_URL}/api/income`,
  REMAINDER: `${BASE_URL}/api/remainders`,
  REMAINDER_CREATE: `${BASE_URL}/api/remainder/new`,
  REMAINDER_SINGLE: `${BASE_URL}/api/remainder`,
  CREATE_ASSETCAPACITY: `${BASE_URL}/api/assetcapacity/new`,
  ALL_ASSETCAPACITY: `${BASE_URL}/api/assetcapacitys`,
  SINGLE_ASSETCAPACITY: `${BASE_URL}/api/assetcapacity`,
  ERAAMOUNT_CREATE: `${BASE_URL}/api/eraamount/new`,
  ERAAMOUNTS: `${BASE_URL}/api/eraamounts`,
  ERAAMOUNT_SINGLE: `${BASE_URL}/api/eraamount`,
  ERAAMOUNTS_PAGINATED_TABLE_COLUMN_FILTER: `${BASE_URL}/api/eraamountspaginatedtablecolumnfilter`,
  REVENUEAMOUNT_CREATE: `${BASE_URL}/api/revenueamount/new`,
  REVENUEAMOUNTS: `${BASE_URL}/api/revenueamounts`,
  REVENUEAMOUNT_SINGLE: `${BASE_URL}/api/revenueamount`,
  CREATE_SALARYSLAB: `${BASE_URL}/api/salaryslab/new`,
  ALL_SALARYSLAB: `${BASE_URL}/api/salaryslabs`,
  SINGLE_SALARYSLAB: `${BASE_URL}/api/salaryslab`,
  SALARYSLAB_LIMITED: `${BASE_URL}/api/salaryslablimited`,
  SALARYSLAB_PROCESS_FILTER: `${BASE_URL}/api/salaryslabprocessfilter`,
  REVENUEAMOUNTSLIMITED: `${BASE_URL}/api/revenueamountlimited`,
  CREATE_PROCESS_AND_TEAM: `${BASE_URL}/api/processteam/new`,
  ALL_PROCESS_AND_TEAM: `${BASE_URL}/api/processteams`,
  PROCESS_AND_TEAM_SORT: `${BASE_URL}/api/processteamsort`,
  SINGLE_PROCESS_AND_TEAM: `${BASE_URL}/api/processteam`,
  IPMASTER: `${BASE_URL}/api/ipmasters`,
  IPMASTER_CREATE: ` ${BASE_URL}/api/ipmaster/new`,
  IPMASTER_SINGLE: `${BASE_URL}/api/ipmaster`,
  IPMASTER_UPDATE: `${BASE_URL}/api/ipmasterupdate`,
  IPMASTER_UPDATE_UPDATEBY: `${BASE_URL}/api/ipmasterupdateedby`,
  IPMASTER_DELETE: `${BASE_URL}/api/ipmasterdelete`,
  IPCATEGORY: `${BASE_URL}/api/ipcategories`,
  IP_SUBCAT: `${BASE_URL}/api/ipsubcategory`,
  IPCATEGORY_CREATE: ` ${BASE_URL}/api/ipcategorie/new`,
  IPCATEGORY_SINGLE: `${BASE_URL}/api/ipcategorie`,
  ADD_DAY_POINTS: `${BASE_URL}/api/daypoint/new`,
  GET_DAY_POINTS: `${BASE_URL}/api/daypoints`,
  SINGLE_DAY_POINTS: `${BASE_URL}/api/daypoint`,
  SINGLE_DAY_POINTS_UPLOAD: `${BASE_URL}/api/singledaypoint`,
  ADD_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/productionconsolidated/new`,
  GET_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/productionconsolidateds`,
  SINGLE_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/productionconsolidated`,
  FILTER_PRODUCTION_CONSOLIDATED: `${BASE_URL}/api/filterproductionconsolidated`,
  CREATE_EMPLOYEESTATUS: `${BASE_URL}/api/employeestatus/new`,
  ALL_EMPLOYEESTATUS: `${BASE_URL}/api/employeestatuss`,
  SINGLE_EMPLOYEESTATUS: `${BASE_URL}/api/employeestatus`,
  CREATE_CHECKLISTINTERVIEW: `${BASE_URL}/api/checklistinterview/new`,
  ALL_CHECKLISTINTERVIEW: `${BASE_URL}/api/checklistinterviews`,
  SINGLE_CHECKLISTINTERVIEW: `${BASE_URL}/api/checklistinterview`,
  ALLINCOMEANDEXPENSE: `${BASE_URL}/api/allincomeandexpenses`,
  CHECKLISTTYPE: ` ${BASE_URL}/api/checklisttypes`,
  CHECKLISTTYPE_CREATE: ` ${BASE_URL}/api/checklisttype/new`,
  CHECKLISTTYPE_SINGLE: `${BASE_URL}/api/checklisttype`,
  CHECKLISTCATEGORY: `${BASE_URL}/api/checklistcategories`,
  CHECKLISTCATEGORY_CREATE: `${BASE_URL}/api/checklistcategory/new`,
  CHECKLISTCATEGORY_SINGLE: ` ${BASE_URL}/api/checklistcategory`,
  CREATE_FILEACCESS: `${BASE_URL}/api/fileaccess/new`,
  ALL_FILEACCESS: `${BASE_URL}/api/fileaccesss`,
  SINGLE_FILEACCESS: `${BASE_URL}/api/fileaccess`,
  CREATE_FILESHARE: `${BASE_URL}/api/fileshare/new`,
  ALL_FILESHARE: `${BASE_URL}/api/fileshares`,
  SINGLE_FILESHARE: `${BASE_URL}/api/singlefilshare`,
  CREATE_ANNOUNCEMENTCATEGORY: `${BASE_URL}/api/announcementcategory/new`,
  ALL_ANNOUNCEMENTCATEGORY: `${BASE_URL}/api/announcementcategorys`,
  SINGLE_ANNOUNCEMENTCATEGORY: `${BASE_URL}/api/announcementcategory`,
  CREATE_ANNOUNCEMENT: `${BASE_URL}/api/announcement/new`,
  ALL_ANNOUNCEMENT: `${BASE_URL}/api/announcements`,
  SINGLE_ANNOUNCEMENT: `${BASE_URL}/api/announcement`,
  SELFCHECKPOINTTICKET: `${BASE_URL}/api/selfcheckpointticketmasters`,
  SELFCHECKPOINTTICKET_CREATE: `${BASE_URL}/api/selfcheckpointticketmaster/new`,
  SELFCHECKPOINTTICKET_SINGLE: `${BASE_URL}/api/selfcheckpointticketmaster`,
  PERMISSIONS: `${BASE_URL}/api/persmissions`,
  ACTIVEPERMISSIONS: `${BASE_URL}/api/activeuserpersmissions`,
  PERMISSION_CREATE: `${BASE_URL}/api/persmission/new`,
  PERMISSION_SINGLE: `${BASE_URL}/api/persmission`,
  REQUIREDFIELDS: `${BASE_URL}/api/requiredfields`,
  REQUIREFIELDS_CREATE: `${BASE_URL}/api/requiredfield/new`,
  REQUIREFIELDS_SINGLE: `${BASE_URL}/api/requiredfield`,
  GETUSERDATES: `${BASE_URL}/api/getallusersdates`,
  GET_ALL_DOB: `${BASE_URL}/api/getallusersdob`,
  GET_ALL_DOJ: `${BASE_URL}/api/getallusersdoj`,
  GET_ALL_DOM: `${BASE_URL}/api/getallusersdom`,
  CREATE_TASKSCHEDULEGROUPING: `${BASE_URL}/api/taskschedulegrouping/new`,
  ALL_TASKSCHEDULEGROUPING: `${BASE_URL}/api/taskschedulegroupings`,
  SINGLE_TASKSCHEDULEGROUPING: `${BASE_URL}/api/taskschedulegrouping`,
  NONSCHEDULETASKFORUSER: `${BASE_URL}/api/nonscheduletaskforuserlog`,
  NONSCHEDULLOGREASSIGNTASKFORUSER: `${BASE_URL}/api/nonschedulelogreassignforuser`,
  CREATE_PANELTYPE: `${BASE_URL}/api/paneltype/new`,
  ALL_PANELTYPE: `${BASE_URL}/api/paneltypes`,
  SINGLE_PANELTYPE: `${BASE_URL}/api/paneltype`,
  CREATE_SCREENRESOLUTION: `${BASE_URL}/api/screenresolution/new`,
  ALL_SCREENRESOLUTION: `${BASE_URL}/api/screenresolutions`,
  SINGLE_SCREENRESOLUTION: `${BASE_URL}/api/screenresolution`,
  CREATE_CONNECTIVITY: `${BASE_URL}/api/connectivity/new`,
  ALL_CONNECTIVITY: `${BASE_URL}/api/connectivitys`,
  SINGLE_CONNECTIVITY: `${BASE_URL}/api/connectivity`,
  CREATE_DATARANGE: `${BASE_URL}/api/datarange/new`,
  ALL_DATARANGE: `${BASE_URL}/api/dataranges`,
  SINGLE_DATARANGE: `${BASE_URL}/api/datarange`,
  CREATE_COMPATIBLEDEVICES: `${BASE_URL}/api/compatibledevices/new`,
  ALL_COMPATIBLEDEVICES: `${BASE_URL}/api/compatibledevicess`,
  SINGLE_COMPATIBLEDEVICES: `${BASE_URL}/api/compatibledevices`,
  CREATE_OUTPUTPOWER: `${BASE_URL}/api/outputpower/new`,
  ALL_OUTPUTPOWER: `${BASE_URL}/api/outputpowers`,
  SINGLE_OUTPUTPOWER: `${BASE_URL}/api/outputpower`,
  CREATE_COOLINGFANCOUNT: `${BASE_URL}/api/coolingfancount/new`,
  ALL_COOLINGFANCOUNT: `${BASE_URL}/api/coolingfancounts`,
  SINGLE_COOLINGFANCOUNT: `${BASE_URL}/api/coolingfancount`,
  CREATE_CLOCKSPEED: `${BASE_URL}/api/clockspeed/new`,
  ALL_CLOCKSPEED: `${BASE_URL}/api/clockspeeds`,
  SINGLE_CLOCKSPEED: `${BASE_URL}/api/clockspeed`,
  CREATE_CORE: `${BASE_URL}/api/core/new`,
  ALL_CORE: `${BASE_URL}/api/cores`,
  SINGLE_CORE: `${BASE_URL}/api/core`,
  CREATE_SPEED: `${BASE_URL}/api/speed/new`,
  ALL_SPEED: `${BASE_URL}/api/speeds`,
  SINGLE_SPEED: `${BASE_URL}/api/speed`,
  CREATE_FREQUENCY: `${BASE_URL}/api/frequency/new`,
  ALL_FREQUENCY: `${BASE_URL}/api/frequencys`,
  SINGLE_FREQUENCY: `${BASE_URL}/api/frequency`,
  CREATE_OUTPUT: `${BASE_URL}/api/output/new`,
  ALL_OUTPUT: `${BASE_URL}/api/outputs`,
  SINGLE_OUTPUT: `${BASE_URL}/api/output`,
  CREATE_ETHERNETPORTS: `${BASE_URL}/api/ethernetports/new`,
  ALL_ETHERNETPORTS: `${BASE_URL}/api/ethernetportss`,
  SINGLE_ETHERNETPORTS: `${BASE_URL}/api/ethernetports`,
  CREATE_DISTANCE: `${BASE_URL}/api/distance/new`,
  ALL_DISTANCE: `${BASE_URL}/api/distances`,
  SINGLE_DISTANCE: `${BASE_URL}/api/distance`,
  CREATE_LENGTH: `${BASE_URL}/api/length/new`,
  ALL_LENGTH: `${BASE_URL}/api/lengths`,
  SINGLE_LENGTH: `${BASE_URL}/api/length`,
  CREATE_SLOT: `${BASE_URL}/api/slot/new`,
  ALL_SLOT: `${BASE_URL}/api/slots`,
  SINGLE_SLOT: `${BASE_URL}/api/slot`,
  CREATE_NOOFCHANNELS: `${BASE_URL}/api/noofchannels/new`,
  ALL_NOOFCHANNELS: `${BASE_URL}/api/noofchannelss`,
  SINGLE_NOOFCHANNELS: `${BASE_URL}/api/noofchannels`,
  CREATE_COLOURS: `${BASE_URL}/api/colours/new`,
  ALL_COLOURS: `${BASE_URL}/api/colourss`,
  SINGLE_COLOURS: `${BASE_URL}/api/colours`,
  ROLESANDRESPONSECAT: `${BASE_URL}/api/rolesndresponsecategorys`,
  ROLESANDRESPONSECAT_CREATE: `${BASE_URL}/api/rolesndresponsecategory/new`,
  ROLESANDRESPONSECAT_SINGLE: `${BASE_URL}/api/rolesndresponsecategory`,
  ROLESANDRES: `${BASE_URL}/api/rolesndresponses`,
  ROLESANDRES_CREATE: `${BASE_URL}/api/rolesndres/new`,
  ROLESANDRES_SINGLE: `${BASE_URL}/api/rolesndres`,
  CREATE_ASSETSPECIFICATIONGROUPING: `${BASE_URL}/api/assetspecificationgrouping/new`,
  ALL_ASSETSPECIFICATIONGROUPING: `${BASE_URL}/api/assetspecificationgroupings`,
  SINGLE_ASSETSPECIFICATIONGROUPING: `${BASE_URL}/api/assetspecificationgrouping`,
  EBSERVICEMASTER: `${BASE_URL}/api/ebservicemasters`,
  FILTEREBSERVICEMASTER: `${BASE_URL}/api/boardingebservicemasters`,
  EBSERVICEMASTER_CREATE: `${BASE_URL}/api/ebservicemaster/new`,
  EBSERVICEMASTER_SINGLE: `${BASE_URL}/api/ebservicemaster`,
  MANAGEMATERIAL: `${BASE_URL}/api/managematerials`,
  MANAGEMATERIAL_CREATE: `${BASE_URL}/api/managematerial/new`,
  MANAGEMATERIAL_SINGLE: `${BASE_URL}/api/managematerial`,
  CHECK_EBREADINGDETAILLIST: `${BASE_URL}/api/ebreadingdetailslistFilter`,
  VENDOREB_CREATE: `${BASE_URL}/api/vendormasterforeb/new`,
  ALL_VENDOREB: `${BASE_URL}/api/allvendormasterforeb`,
  SINGLE_VENDOREB: `${BASE_URL}/api/singlevendormasterforeb`,
  ALL_EBUSEINSTRUMENTS: `${BASE_URL}/api/ebuseinstruments`,
  CREATE_EBUSEINSTRUMENTS: `${BASE_URL}/api/ebuseinstrument/new`,
  SINGLE_EBUSEINSTRUMENTS: `${BASE_URL}/api/ebuseinstrument`,
  CHECK_EBUSE_INSTRUMENTS: `${BASE_URL}/api/ebuseinstrumentsFilter`,
  EBMATERIALDETAILS: `${BASE_URL}/api/ebmaterialdetails`,
  EBMATERIALDETAILS_CREATE: `${BASE_URL}/api/ebmaterialdetail/new`,
  EBMATERIALDETAILS_SINGLE: `${BASE_URL}/api/ebmaterialdetail`,
  EBRATES: `${BASE_URL}/api/ebrates`,
  EBRATES_CREATE: `${BASE_URL}/api/ebrate/new`,
  EBRATES_SINGLE: `${BASE_URL}/api/ebrate`,
  EBREADINGDETAIL: `${BASE_URL}/api/ebreadingdetails`,
  EBREADINGDETAIL_CREATE: `${BASE_URL}/api/ebreadingdetail/new`,
  EBREADINGDETAIL_SINGLE: `${BASE_URL}/api/ebreadingdetail`,
  CHECK_EBREADINGDETAIL: `${BASE_URL}/api/ebreadingdetailsFilter`,
  EB_SERVICEFILTER: `${BASE_URL}/api/ebservicefilter`,
  MINIMUMPOINTS: `${BASE_URL}/api/minimumpointss`,
  MINIMUMPOINT_CREATE: `${BASE_URL}/api/minimumpoints/new`,
  MINIMUMPOINT_SINGLE: `${BASE_URL}/api/minimumpoints`,
  MINIMUMPOINTS_BULKDELETE: `${BASE_URL}/api/minimumpointsbulkdelete`,
  MINIMUMPOINTS_SORT: `${BASE_URL}/api/minimumpointssort`,
  GETALLBANKVERIFICATIONUSERS: `${BASE_URL}/api/bankdetailsverfication/all`,
  POSTSINGLEBANKVERIFICATIONUSER: `${BASE_URL}/api/bankdetailsverfication/new`,
  BANKDETAILS_SINGLE: `${BASE_URL}/api/bankdetailsverfication/single`,
  GETSINGLEUSERDETAILSUSINGEMPID: `${BASE_URL}/api/bankdetailsverfication/single/empidbased`,
  GETSINGLEUSERDETAILSUSINGEMPIDARR: `${BASE_URL}/api/bankdetailsverfication/single/empidbasedarr`,
  CREATE_POWERSTATION: `${BASE_URL}/api/powerstation/new`,
  ALL_POWERSTATION: `${BASE_URL}/api/powerstations`,
  SINGLE_POWERTSTATION: `${BASE_URL}/api/powerstation`,
  POWERSTATIONFILTER: `${BASE_URL}/api/powerstationfilter`,
  MANAGESTOCKITEMS: `${BASE_URL}/api/managestockitems`,
  MANAGESTOCKITEMS_CREATE: `${BASE_URL}/api/managestockitems/new`,
  MANAGESTOCKITEMS_SINGLE: `${BASE_URL}/api/managestockitems`,
  GETALLSHIFTGROUPS: `${BASE_URL}/api/shiftgroupings`,
  CREATESINGLESHIFTGROUP: `${BASE_URL}/api/shiftgrouping/new`,
  GETSINGLESHIFTGROUP: `${BASE_URL}/api/shiftgrouping`,
  DESIGNATIONREQUIREMENTS: `${BASE_URL}/api/designationrequirements`,
  DESIGNATIONREQ_CREATE: `${BASE_URL}/api/designationrequirement/new`,
  DESIGNATIONREQUUIREMENTS_SINGLE: `${BASE_URL}/api/designationrequirement`,
  DESIGNATIONREQUIREMENTSFILTER: `${BASE_URL}/api/designationmanpowerfilter`,
  CATEGORYEDUCATION: `${BASE_URL}/api/educationcategories`,
  CATEGORYEDUCATION_CREATE: `${BASE_URL}/api/educationcategory/new`,
  CATEGORYEDUCATION_SINGLE: `${BASE_URL}/api/educationcategory`,
  EDUCATIONSPECILIZATION: `${BASE_URL}/api/educationspecilizations`,
  EDUCATIONSPECILIZATION_CREATE: `${BASE_URL}/api/educationspecilization/new`,
  EDUCATIONSPECILIZATION_SINGLE: `${BASE_URL}/api/educationspecilization`,
  CREATE_INTERACTORTYPE: `${BASE_URL}/api/interactortype/new`,
  ALL_INTERACTORTYPE: `${BASE_URL}/api/interactortype`,
  SINGLE_INTERACTORTYPE: `${BASE_URL}/api/interactortype`,
  OVERALL_INTERACTORTYPE: `${BASE_URL}/api/interactortypeoverall`,
  OVERALL_INTERACTORTYPE_BULKDELETE: `${BASE_URL}/api/interactortypeoveralloverallBulkdelete`,
  CREATE_INTERACTORMODE: `${BASE_URL}/api/interactormode/new`,
  ALL_INTERACTORMODE: `${BASE_URL}/api/interactormode`,
  SINGLE_INTERACTORMODE: `${BASE_URL}/api/interactormode`,
  OVERALL_INTERACTORMODE: `${BASE_URL}/api/interactormodeoverall`,
  OVERALL_INTERACTORMODE_BULKDELETE: `${BASE_URL}/api/interactormodeoveralloverallBulkdelete`,
  CREATE_INTERACTORPURPOSE: `${BASE_URL}/api/interactorpurpose/new`,
  ALL_INTERACTORPURPOSE: `${BASE_URL}/api/interactorpurpose`,
  SINGLE_INTERACTORPURPOSE: `${BASE_URL}/api/interactorpurpose`,
  OVERALL_INTERACTORPURPOSE: `${BASE_URL}/api/interactorpurposeoverall`,
  OVERALL_INTERACTORPURPOSE_BULKDELETE: `${BASE_URL}/api/interactorpurposeoverallbulkdelete`,
  CREATE_VISITORS: `${BASE_URL}/api/visitors/new`,
  ALL_VISITORS: `${BASE_URL}/api/allvisitors`,
  CHECKEXISTING_VISITOR: `${BASE_URL}/api/checkexistingvisitor`,
  LASTINDEX_VISITORS: `${BASE_URL}/api/lastindexvisitors`,
  SINGLE_VISITORS: `${BASE_URL}/api/visitors`,
  DUPLICATECANDIDATEFACEDETECTVISITOR: `${BASE_URL}/api/duplicatecandidatefacecheckvisitor`,
  INTERVIEWROUNDORDER: `${BASE_URL}/api/interviewroundorders`,
  INTERVIEWROUNDORDER_CREATE: `${BASE_URL}/api/interviewroundorder/new`,
  INTERVIEWROUNDORDER_SINGLE: `${BASE_URL}/api/interviewroundorder`,
  PRODUCTION_ORGINAL: `${BASE_URL}/api/productionoriginals`,
  PRODUCTION_ORGINAL_LIMITED: `${BASE_URL}/api/productionoriginalslimited`,
  PRODUCTION_ORGINAL_UNIQID: `${BASE_URL}/api/productionoriginalslimiteduniqid`,
  PRODUCTION_ORGINAL_CREATE: `${BASE_URL}/api/productionoriginal/new`,
  PRODUCTION_ORGINAL_SINGLE: `${BASE_URL}/api/productionoriginal`,
  PRODUCTION_TEMP: `${BASE_URL}/api/productionstemp`,
  PRODUCTION_TEMP_LIMITED: `${BASE_URL}/api/productiontemplimited`,
  PRODUCTION_TEMP_UNIQID: `${BASE_URL}/api/productiontemplimiteduniqid`,
  PRODUCTION_TEMP_CREATE: `${BASE_URL}/api/productiontemp/new`,
  PRODUCTION_TEMP_SINGLE: `${BASE_URL}/api/productiontemp`,
  PRODUCTION_UPLOAD: `${BASE_URL}/api/productionuploads`,
  PRODUCTION_UPLOAD_CREATE: `${BASE_URL}/api/productionupload/new`,
  PRODUCTION_UPLOAD_SINGLE: `${BASE_URL}/api/productionupload`,
  PRODUCTION_UPLOAD_FILENAMELIST: `${BASE_URL}/api/productionuploadfilenamelist`,
  PRODUCTION_UPLOAD_FILENAMEONLY: `${BASE_URL}/api/productionuploadfilenameonly`,
  PRODUCTION_UPLOAD_GETDELETEDATAS: `${BASE_URL}/api/productionuploadgetdeletedatas`,
  PRODUCTION_UPLOAD_GETDELETEDATASALL: `${BASE_URL}/api/productionuploadgetdeletedatasall`,
  PRODUCTION_UPLOAD_DELETEMULTI: `${BASE_URL}/api/productionuploaddeletemulti`,
  PRODUCTION_UPLOAD_OVERALL_FETCH_LIMITED: `${BASE_URL}/api/productionuploadoverallfetchlimited`,
  PRODUCTION_TEMP_UPLOAD: `${BASE_URL}/api/productiontempuploadsall`,
  PRODUCTION_TEMP_UPLOAD_CREATE: `${BASE_URL}/api/productiontempuploadall/new`,
  PRODUCTION_TEMP_UPLOAD_SINGLE: `${BASE_URL}/api/productiontempuploadall`,
  PRODUCTION_TEMP_UPLOAD_FILENAMELIST: `${BASE_URL}/api/productiontempuploadallfilenamelist`,
  PRODUCTION_TEMP_UPLOAD_FILENAMEONLY: `${BASE_URL}/api/productiontempuploadallfilenameonly`,
  PRODUCTION_TEMP_UPLOAD_GETDELETEDATAS: `${BASE_URL}/api/productiontempuploadallgetdeletedatas`,
  PRODUCTION_TEMP_UPLOAD_GETDELETEDATASALL: `${BASE_URL}/api/productiontempuploadallgetdeletedatasall`,
  PRODUCTION_TEMP_UPLOAD_DELETEMULTI: `${BASE_URL}/api/productiontempuploadalldeletemulti`,
  PRODUCTION_TEMP_UPLOAD_OVERALL_FETCH_LIMITED: `${BASE_URL}/api/productiontempuploadalloverallfetchlimited`,
  PRODUCTION_UPLOAD_OVERALL_FETCH_LIMITEDNEW: `${BASE_URL}/api/productionuploadoverallfetchlimitednew`,
  PRODUCTION_TEMP_UPLOAD_OVERALL_FETCH_LIMITEDNEW: `${BASE_URL}/api/productiontempuploadoverallfetchlimitednew`,
  PRODUCTION_UNITRATE: `${BASE_URL}/api/unitsrate`,
  PRODUCTION_UNITRATE_CREATE: `${BASE_URL}/api/unitrate/new`,
  PRODUCTION_UNITRATE_SINGLE: `${BASE_URL}/api/unitrate`,
  PRODUCTION_UNITRATE_SORT: `${BASE_URL}/api/unitsratesort`,
  CATEGORYPROD: `${BASE_URL}/api/categoriesprod`,
  CATEGORYPROD_CREATE: `${BASE_URL}/api/categoryprod/new`,
  CATEGORYPROD_SORT: `${BASE_URL}/api/categoriesprodsort`,
  CATEGORYPROD_SINGLE: `${BASE_URL}/api/categoryprod`,
  SUBCATEGORYPROD: `${BASE_URL}/api/subcategoriesprod`,
  SUBCATEGORYPROD_CREATE: `${BASE_URL}/api/subcategoryprod/new`,
  SUBCATEGORYPROD_SINGLE: `${BASE_URL}/api/subcategoryprod`,
  EXCELFILEUPLOADSTORE: `${BASE_URL}/api/upload`,
  USERS_LIMITED_EMPCODE: `${BASE_URL}/api/userslimitedempcode`,
  USERS_LIMITED_EMPCODE_CREATE: `${BASE_URL}/api/userslimitedempcodecreate`,
  USERS_LIMITED_EMPCODE_NONMANUAL: `${BASE_URL}/api/userslimitedempcodenonmanual`,
  ASSIGNINTERVIEWERS: `${BASE_URL}/api/assigninterviewers`,
  ASSIGNINTERVIEWER_CREATE: `${BASE_URL}/api/assigninterviewer/new`,
  ASSIGNINTERVIEWER_SINGLE: `${BASE_URL}/api/assigninterviewer`,
  VERIFYVIEWPASSWORD: `${BASE_URL}/api/verification/viewpassword`,
  ASSIGNEDBY: `${BASE_URL}/api/assignedby`,
  ASSIGNEDBY_CREATE: `${BASE_URL}/api/assignedby/new`,
  ASSIGNEDBY_SINGLE: `${BASE_URL}/api/assignedby`,
  ALL_ASSIGNEDBY_SORT: `${BASE_URL}/api/assignedbysort`,
  OVERALL_ASSIGNEDBY: `${BASE_URL}/api/overallassignedby`,
  OVERALL_ASSIGNEDBY_BULKDELETE: `${BASE_URL}/api/overallassignedbybulkdelete`,
  MANAGEASSIGNEDMODE: `${BASE_URL}/api/manageassignedmode`,
  MANAGEASSIGNED_CREATE: `${BASE_URL}/api/manageassignedmode/new`,
  MANAGEASSIGNED_SINGLE: `${BASE_URL}/api/manageassignedmode`,
  MANAGEASSIGNEDMODE_OVERALL: `${BASE_URL}/api/manageassignedmodeoverall`,
  MANAGEASSIGNEDMODE_OVERALL_BULKDELETE: `${BASE_URL}/api/manageassignedmodeoverallbulkdelete`,
  STOCKCATEGORY: `${BASE_URL}/api/stockcategorys`,
  STOCKCATEGORY_CREATE: `${BASE_URL}/api/stockcategory/new`,
  STOCKCATEGORY_SINGLE: `${BASE_URL}/api/stockcategory`,
  CREATE_MANAGETYPEPG: `${BASE_URL}/api/managetypepg/new`,
  ALL_MANAGETYPEPG: `${BASE_URL}/api/managetypepg`,
  SINGLE_MANAGETYPEPG: `${BASE_URL}/api/managetypepg`,
  OVERALL_INTERACTORMANAGETYPEG: `${BASE_URL}/api/managetypepgoverallmanagetypeg`,
  OVERALL_INTERACTORTYPEPURPOSEGROUP_BULKDELETE: `${BASE_URL}/api/managetypepgoverallmanagetypegbulkdelete`,
  CREATE_PURPOSE: `${BASE_URL}/api/purpose/new`,
  ALL_PURPOSE: `${BASE_URL}/api/purpose`,
  SINGLE_PURPOSE: `${BASE_URL}/api/purpose`,
  MEETING_DELETE: `${BASE_URL}/api/deletemultipleschedulemeeting`,
  SCHEDULE_MEETING_LOG: `${BASE_URL}/api/schedulemeetinglog`,
  MANAGEOTHERTASK: `${BASE_URL}/api/manageothertasks`,
  MANAGEOTHERTASK_CREATE: `${BASE_URL}/api/manageothertask/new`,
  MANAGEOTHERTASK_SINGLE: `${BASE_URL}/api/manageothertask`,
  ALL_OTHERTASK_SORT: `${BASE_URL}/api/othertasksort`,
  SOURCEOFPAYMENT: `${BASE_URL}/api/sourceofpayment`,
  SOURCEOFPAYMENT_CREATE: `${BASE_URL}/api/sourceofpayment/new`,
  SOURCEOFPAYMENT_SINGLE: `${BASE_URL}/api/sourceofpayment`,
  ACCURACYMASTERGETALL: `${BASE_URL}/api/accuracymaster`,
  ACCURACYMASTER_CREATE: `${BASE_URL}/api/accuracymaster/new`,
  ACCURACYMASTER_SINGLE: `${BASE_URL}/api/accuracymaster`,
  EXPECTEDACCURACYGETALL: `${BASE_URL}/api/expectedaccuracy`,
  EXPECTEDACCURACY_CREATE: `${BASE_URL}/api/expectedaccuracy/new`,
  EXPECTEDACCURACY_SINGLE: `${BASE_URL}/api/expectedaccuracy`,
  ACHEIVEDACCURACYGETALL: `${BASE_URL}/api/acheivedaccuracy`,
  ACHEIVEDACCURACY_CREATE: `${BASE_URL}/api/acheivedaccuracy/new`,
  ACHEIVEDACCURACY_SINGLE: `${BASE_URL}/api/acheivedaccuracy`,
  EXPECTEDACCURACY_SINGLEBYDETAILS: `${BASE_URL}/api/expectedaccuracy/single`,
  ACHEIVEDACCURACY_SINGLEBYDETAILS: `${BASE_URL}/api/acheivedaccuracy/single`,
  MANAGEPOWERSHUTDOWNTYPE: `${BASE_URL}/api/managepowershutdowntype`,
  MANAGEPOWERSHUTDOWNTYPE_CREATE: `${BASE_URL}/api/managepowershutdowntype/new`,
  MANAGEPOWERSHUTDOWNTYPE_SINGLE: `${BASE_URL}/api/managepowershutdowntype`,
  PRODUCTIONPROCESSQUEUEGETALL: `${BASE_URL}/api/productionprocessqueue`,
  PRODUCTIONPROCESSQUEUE_CREATE: `${BASE_URL}/api/productionprocessqueue/new`,
  PRODUCTIONPROCESSQUEUE_SINGLE: `${BASE_URL}/api/productionprocessqueue`,
  PENALTYERRORUPLOADGETALL: `${BASE_URL}/api/errortypes`,
  PENALTYERRORUPLOAD_CREATE: `${BASE_URL}/api/errortype/new`,
  PENALTYERRORUPLOAD_SINGLE: `${BASE_URL}/api/errortype`,
  PAYRUNCONTROL: `${BASE_URL}/api/payruncontrols`,
  PAYRUNCONTROL_CREATE: `${BASE_URL}/api/payruncontrol/new`,
  PAYRUNCONTROL_SINGLE: `${BASE_URL}/api/payruncontrol`,
  EXPERIENCEBASE: `${BASE_URL}/api/expericencebases`,
  EXPERIENCEBASE_CREATE: `${BASE_URL}/api/expericencebase/new`,
  EXPERIENCEBASE_SINGLE: `${BASE_URL}/api/expericencebase`,
  MASTERFIELDNAME: `${BASE_URL}/api/masterfieldnames`,
  MASTERFIELDNAME_CREATE: `${BASE_URL}/api/masterfieldname/new`,
  MASTERFIELDNAME_SINGLE: `${BASE_URL}/api/masterfieldname`,
  OTHERPENALTYCONTROL: `${BASE_URL}/api/otherpenaltycontrols`,
  OTHERPENALTYCONTROL_CREATE: `${BASE_URL}/api/otherpenaltycontrol/new`,
  OTHERPENALTYCONTROL_SINGLE: `${BASE_URL}/api/otherpenaltycontrol`,
  PENALTYERRORREASONGETALL: `${BASE_URL}/api/penaltyerrorreason`,
  PENALTYERRORREASON_CREATE: `${BASE_URL}/api/penaltyerrorreason/new`,
  PENALTYERRORREASON_SINGLE: `${BASE_URL}/api/penaltyerrorreason`,
  PENALTYERRORCONTROLGETALL: `${BASE_URL}/api/penaltyerrorcontrol`,
  PENALTYERRORCONTROL_CREATE: `${BASE_URL}/api/penaltyerrorcontrol/new`,
  PENALTYERRORCONTROL_SINGLE: `${BASE_URL}/api/penaltyerrorcontrol`,
  MANAGEIDLEWORK: `${BASE_URL}/api/manageidleworks`,
  MANAGEIDLEWORK_CREATE: `${BASE_URL}/api/manageidlework/new`,
  MANAGEIDLEWORK_SINGLE: `${BASE_URL}/api/manageidlework`,
  ACCURACYQUEUEGROUPING: `${BASE_URL}/api/accuracyqueuegroupings`,
  ACCURACYQUEUEGROUPING_CREATE: `${BASE_URL}/api/accuracyqueuegrouping/new`,
  ACCURACYQUEUEGROUPING_SINGLE: `${BASE_URL}/api/accuracyqueuegrouping`,
  NONPRODUCTIONUNITRATEGETALL: `${BASE_URL}/api/nonproductionunitrate`,
  NONPRODUCTIONUNITRATE_CREATE: `${BASE_URL}/api/nonproductionunitrate/new`,
  NONPRODUCTIONUNITRATE_SINGLE: `${BASE_URL}/api/nonproductionunitrate`,
  NONPRODUCTIONUNITALLOT: `${BASE_URL}/api/nonproductionunitallot`,
  NONPRODUCTIONUNITALLOT_CREATE: `${BASE_URL}/api/nonproductionunitallot/new`,
  NONPRODUCTIONUNITALLOT_SINGLE: `${BASE_URL}/api/nonproductionunitallot`,
  CATEGORYANDSUBCATEGORYGETALL: `${BASE_URL}/api/categoryandsubcategory`,
  CATEGORYANDSUBCATEGORY_CREATE: `${BASE_URL}/api/categoryandsubcategory/new`,
  CATEGORYANDSUBCATEGORY_SINGLE: `${BASE_URL}/api/categoryandsubcategory`,
  OVERALL_CATEGORYANDSUBCATEGORY_NONPRODUCT: `${BASE_URL}/api/categoryandsubcategoryoverall`,
  OVERALL_NONPRODUCTIONCATEGORYMASTER_BULKDELETE: `${BASE_URL}/api/categoryandsubcategoryoverallnonproduction`,
  MANAGECATEGORYGETALL: `${BASE_URL}/api/managecategorypercentage`,
  MANAGECATEGORYPERCENTAGE_CREATE: `${BASE_URL}/api/managecategorypercentage/new`,
  MANAGECATEGORYPERCENTAGE_SINGLE: `${BASE_URL}/api/managecategorypercentage`,
  RAISEPROBLEM: `${BASE_URL}/api/raises`,
  RAISEPROBLEM_CREATE: `${BASE_URL}/api/raise/new`,
  RAISEPROBLEM_SINGLE: `${BASE_URL}/api/raise`,
  OVERALL_RAISEPROBLEM: `${BASE_URL}/api/overallraise`,
  PRODUCTIONCLIENTRATEALL: `${BASE_URL}/api/productionclientrate`,
  PRODUCTIONCLIENTRATE_CREATE: `${BASE_URL}/api/productionclientrate/new`,
  PRODUCTIONCLIENTRATE_SINGLE: `${BASE_URL}/api/productionclientrate`,
  ACHEIVEDACCURACYINTERNALGETALL: `${BASE_URL}/api/acheivedaccuracyinternal`,
  ACHEIVEDACCURACYINTERNAL_CREATE: `${BASE_URL}/api/acheivedaccuracyinternal/new`,
  ACHEIVEDACCURACYINTERNAL_SINGLE: `${BASE_URL}/api/acheivedaccuracyinternal`,
  ACHEIVEDACCURACYINTERNAL_SINGLEBYDETAILS: `${BASE_URL}/api/acheivedaccuracyinternal/single`,
  ACHEIVEDACCURACYCLIENTGETALL: `${BASE_URL}/api/acheivedaccuracyclient`,
  ACHEIVEDACCURACYCLIENT_CREATE: `${BASE_URL}/api/acheivedaccuracyclient/new`,
  ACHEIVEDACCURACYCLIENT_SINGLE: `${BASE_URL}/api/acheivedaccuracyclient`,
  ACHEIVEDACCURACYCLIENT_SINGLEBYDETAILS: `${BASE_URL}/api/acheivedaccuracyclient/single`,
  ADD_PENALTY_CLIENT: `${BASE_URL}/api/penaltyclientamount/new`,
  GET_PENALTY_CLIENT: `${BASE_URL}/api/penaltyclientamounts`,
  SINGLE_PENALTY_CLIENT: `${BASE_URL}/api/penaltyclientamount`,
  DAY_POINTS_FILTER: `${BASE_URL}/api/daypointsfilter`,
  DAY_POINTS_DATAS: `${BASE_URL}/api/daypointsdatasfetch`,
  TEMP_DAY_POINTS_FILTER: `${BASE_URL}/api/temppointsfilter`,
  TEMP_DAY_POINTS_DATAS: `${BASE_URL}/api/temppointsdatasfetch`,
  ADD_PENALTYDAYUPLOAD: `${BASE_URL}/api/penaltydayupload/new`,
  GET_PENALTYDAYUPLOAD: `${BASE_URL}/api/penaltydayuploads`,
  SINGLE_PENALTYDAYUPLOAD: `${BASE_URL}/api/penaltydayupload`,
  SINGLE_PENALTY_UPLOAD: `${BASE_URL}/api/singlepenaltydayupload`,
  ADD_MANAGEPENALTYMONTH: `${BASE_URL}/api/managepenaltymonth/new`,
  GET_MANAGEPENALTYMONTH: `${BASE_URL}/api/managepenaltymonths`,
  SINGLE_MANAGEPENALTYMONTH: `${BASE_URL}/api/managepenaltymonth`,
  FILTER_MANAGEPENALTYMONTH: `${BASE_URL}/api/filtermanagepenaltymonth`,
  CATEGORYMASTERGETALL: `${BASE_URL}/api/categorymaster`,
  CATEGORYMASTER_CREATE: `${BASE_URL}/api/categorymaster/new`,
  CATEGORYMASTER_SINGLE: `${BASE_URL}/api/categorymaster`,
  OVERALL_CATEGORYMASTER_SINGLE: `${BASE_URL}/api/overallcategorymastersupport`,
  OVERALL_CATEGORYMASTER_BULKDELETE: `${BASE_URL}/api/overallcategorymastersupportbulkdelete`,
  PROFFESIONALTAXMASTER_SORT: `${BASE_URL}/api/professionaltaxmastersort`,
  PAIDDATEFIX_SORT: `${BASE_URL}/api/paiddatefixssort`,
  PAIDSTATUSFIX_SORT: `${BASE_URL}/api/paidstatusfixsort`,
  MANAGESHORTAGEMASTER: `${BASE_URL}/api/manageshortagemasters`,
  MANAGESHORTAGEMASTER_CREATE: `${BASE_URL}/api/manageshortagemaster/new`,
  MANAGESHORTAGEMASTER_SINGLE: `${BASE_URL}/api/manageshortagemaster`,
  MANAGESHORTAGEMASTER_SORT: `${BASE_URL}/api/manageshortagemasterssort`,
  USER_ATT_MONTH_STATUS: `${BASE_URL}/api/userattmonthstatus`,
  USER_ATT_MONTH_STATUS_FILTER: `${BASE_URL}/api/userattmonthstatusfilter`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FILTER_DATEWISE: `${BASE_URL}/api/userclockinclockoutstatusfilterdatewise`,
  PENALTYAMOUNTCONSOLIDATED: `${BASE_URL}/api/allpenaltyamountconsolidate`,
  PENALTYAMOUNTCONSOLIDATED_CREATE: `${BASE_URL}/api/penaltyamountconsolidate/new`,
  PENALTYAMOUNTCONSOLIDATED_SINGLE: `${BASE_URL}/api/penaltyamountconsolidate`,
  FILTERED_PENALTYAMOUNTCONSOLIDATED: `${BASE_URL}/api/filterpenaltyamountconsolidated`,
  SINGLE_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/singleattendancecontrolcriteria`,
  CREATE_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/createattendancecontrolcriteria`,
  GET_ATTENDANCE_CONTROL_CRITERIA: `${BASE_URL}/api/allattendancecontrolcriteria`,
  ATTENDANCE_STATUS: `${BASE_URL}/api/attendancestatus`,
  ATTENDANCE_STATUS_CREATE: `${BASE_URL}/api/attendancestatus/new`,
  ATTENDANCE_STATUS_SINGLE: `${BASE_URL}/api/attendancestatus`,
  ATTENDANCE_MODE_STATUS: `${BASE_URL}/api/allattendancemodestatus`,
  ATTENDANCE_MODE_STATUS_CREATE: `${BASE_URL}/api/attendancemodestatus/new`,
  ATTENDANCE_MODE_STATUS_SINGLE: `${BASE_URL}/api/attendancemodestatus`,
  DEPARTMENTANDDESIGNATIONGROUPING: `${BASE_URL}/api/departmentanddesignationgroupings`,
  DEPARTMENTANDDESIGNATIONGROUPING_CREATE: `${BASE_URL}/api/departmentanddesignationgrouping/new`,
  DEPARTMENTANDDESIGNATIONGROUPING_SINGLE: `${BASE_URL}/api/departmentanddesignationgrouping`,
  ALL_ASSIGNDOCUMENT: `${BASE_URL}/api/allassigndocument`,
  ASSIGNDOCUMENT_CREATE: `${BASE_URL}/api/assigndocument/new`,
  ASSIGNDOCUMENT_SINGLE: `${BASE_URL}/api/assigndocument`,
  SINGLE_MYCREATION: `${BASE_URL}/api/singlemycreation`,
  CREATE_MYCREATION: `${BASE_URL}/api/createmycreation`,
  GET_MYCREATION: `${BASE_URL}/api/allmycreation`,
  CREATE_TASKDESIGNATIONGROUPING: `${BASE_URL}/api/taskdesignationgrouping/new`,
  ALL_TASKDESIGNATIONGROUPING: `${BASE_URL}/api/taskdesignationgroupings`,
  SINGLE_TASKDESIGNATIONGROUPING: `${BASE_URL}/api/taskdesignationgrouping`,
  DEPMONTHSETAUTO_ALL: `${BASE_URL}/api/deptmonthsetautos`,
  DEPMONTHSETAUTO_CREATE: `${BASE_URL}/api/deptmonthsetauto/new`,
  DEPMONTHSETAUTO_SINGLE: `${BASE_URL}/api/deptmonthsetauto`,
  ADDACHEIVEDACCURACYINDIVIDUAL: `${BASE_URL}/api/achievedaccuracyindividual/new`,
  GETACHEIVEDACCURACYINDIVIDUAL: `${BASE_URL}/api/achievedaccuracyindividual`,
  SINGLEACHEIVEDACCURACYINDIVIDUAL: `${BASE_URL}/api/achievedaccuracyindividual`,
  SINGLEACHEIVEDACCURACYINDIVIDUALUPLOAD: `${BASE_URL}/api/singleachievedaccuracyindividual`,
  PRODUCTION_INDIVIDUAL: `${BASE_URL}/api/productionindividuals`,
  PRODUCTION_INDIVIDUAL_DATEFILTER: `${BASE_URL}/api/productionindividualdatefilter`,
  PRODUCTION_INDIVIDUAL_LIMITED: `${BASE_URL}/api/productionindividuallimited`,
  PRODUCTION_INDIVIDUAL_CREATE: `${BASE_URL}/api/productionindividual/new`,
  PRODUCTION_INDIVIDUAL_SINGLE: `${BASE_URL}/api/productionindividual`,
  PRODUCTION_INDIVIDUAL_HIERARCHYFILTER: `${BASE_URL}/api/productionhierarchyfilter`,
  PRODUCTION_INDIVIDUAL_HIERARCHYFILTERANOTHER: `${BASE_URL}/api/getAllProductionHierarchyListanother`,
  PRODUCTION_INDIVIDUAL_SORT: `${BASE_URL}/api/productionindividualsort`,
  USER_ATT_MONTH_STATUS_FILTER_LIMITED: `${BASE_URL}/api/userattmonthstatusfilterlimited`,
  PENALTYCLIENTERROR: `${BASE_URL}/api/penaltyclienterror`,
  PENALTYCLIENTERROR_CREATE: `${BASE_URL}/api/penaltyclienterror/new`,
  PENALTYCLIENTERROR_SINGLE: `${BASE_URL}/api/penaltyclienterror`,
  PENALTYCLIENTERROR_DATE_FILTER_WITH_ASGN_BRANCH: `${BASE_URL}/api/penaltyclienterrordatefilterwithasgnbranch`,
  PENALTYCLIENTERROR_DATE_FILTER: `${BASE_URL}/api/penaltyclienterrordatefilter`,
  GET_INT_FORM_ALLOT: `${BASE_URL}/api/interviewanswerallots`,
  CREATE_INT_FORM_ALLOT: `${BASE_URL}/api/interviewanswerallot/new`,
  SINGLE_INT_FORM_ALLOT: `${BASE_URL}/api/interviewanswerallot`,
  ACHIEVEDACCURACYINDIVIDUALFILTER: `${BASE_URL}/api/acheivedaccuracyindividual`,
  ALLMANUALALL_TASKFORUSER: `${BASE_URL}/api/allmanualtaskforusers`,
  SINGLE_TASKFORUSER: `${BASE_URL}/api/taskforuser`,
  ALL_TASKDESIGNATIONGROUPING_ACTIVE: `${BASE_URL}/api/taskdesignationgroupingsactive`,
  ALL_TASKFORUSER_REPORTS: `${BASE_URL}/api/taskforuserreports`,
  ALL_TASKFORUSER_REPORTS_OVERALL: `${BASE_URL}/api/taskforuserreportsoverall`,
  TASK_FOR_USER_AUTOGENERATE: `${BASE_URL}/api/taskforuserautogenerate`,
  ALL_TASK_HIERARCHY_REPORTS: `${BASE_URL}/api/taskhierarchyreports`,
  ALL_TRAINING_HIERARCHY_REPORTS: `${BASE_URL}/api/traininghierarchyreports`,
  ALL_MAINTENANCE_HIERARCHY_REPORTS: `${BASE_URL}/api/maintenancehierarchyreports`,
  TASK_FOR_USER_ASSIGNID: `${BASE_URL}/api/taskforuserassignuser`,
  MAINTENANCE_FOR_USER_ASSIGNID: `${BASE_URL}/api/maintenanceforuserassignuser`,
  TRAINING_FOR_USER_ASSIGNID: `${BASE_URL}/api/trainingforuserassignuser`,
  ACPOINTCALCULATION: `${BASE_URL}/api/acpointcalculation`,
  ACPOINTCALCULATION_CREATE: `${BASE_URL}/api/acpointcalculation/new`,
  ACPOINTCALCULATION_SINGLE: `${BASE_URL}/api/acpointcalculation`,
  ACPOINTCALCULATION_SORT: `${BASE_URL}/api/acpointcalculationsort`,
  PRODUCTION_TEMP_FILTER: `${BASE_URL}/api/productiontempfilter`,
  PRODUCTION_TEMP_REPORT_FILTER: `${BASE_URL}/api/productiontempreportfilter`,
  CATEGORYDATECHANGE: `${BASE_URL}/api/categorydatechange`,
  CATEGORYDATECHANGE_CREATE: `${BASE_URL}/api/categorydatechange/new`,
  CATEGORYDATECHANGE_SINGLE: `${BASE_URL}/api/categorydatechange`,
  CATEGORYDATECHANGE_SORT: `${BASE_URL}/api/categorydatechangesort`,
  CREATE_TASK_NONSCHEDULEGROUPING: `${BASE_URL}/api/tasknonschedulegrouping/new`,
  ALL_TASK_NONSCHEDULEGROUPING: `${BASE_URL}/api/tasknonschedulegroupings`,
  SINGLE_TASK_NONSCHEDULEGROUPING: `${BASE_URL}/api/tasknonschedulegrouping`,
  USER_PAYRUNDATA_LIMITED: `${BASE_URL}/api/userspayrundatalimited`,
  PENALTY_DAY_FILTERED: `${BASE_URL}/api/penaltydayuploadsfiltered`,
  USER_ATT_MONTH_STATUS_FILTER_PAYRUNMASTER: `${BASE_URL}/api/userattmonthstatusfilterpayrunmaster`,
  DAY_POINTS_MONTH_YEAR_FILTER: `${BASE_URL}/api/daypointsmonthwisefilter`,
  USERDELETELOGOBJECTS: `${BASE_URL}/api/deleteuserlogobjects`,
  PAIDSTATUSFIX_LIMITED: `${BASE_URL}/api/paidstatusfixslimited`,
  DAY_POINTS_MONTH_YEAR_FILTER_NXTMONTH: `${BASE_URL}/api/daypointsmonthwisefilternxtmonth`,
  ERAAMOUNTSLIMITED: `${BASE_URL}/api/eraamountslimited`,
  PAYRUNCONTROL_LIMITED: `${BASE_URL}/api/payruncontrolslimited`,
  PRODUCTION_UPLOAD_FILTER: `${BASE_URL}/api/productionuploadfilter`,
  PRODUCTION_REPORT_FILTER: `${BASE_URL}/api/productionreportfilter`,
  LEAVETYPE: `${BASE_URL}/api/leavetype`,
  LEAVETYPE_CREATE: `${BASE_URL}/api/leavetype/new`,
  LEAVETYPE_SINGLE: `${BASE_URL}/api/leavetype`,
  INTERVIEWTESTMASTER: `${BASE_URL}/api/interviewtestmaster`,
  INTERVIEWTESTMASTER_CREATE: `${BASE_URL}/api/interviewtestmaster/new`,
  INTERVIEWTESTMASTER_SINGLE: `${BASE_URL}/api/interviewtestmaster`,
  COMPANYDOMAIN: `${BASE_URL}/api/companydomain`,
  ALLCOMPANYDOMAIN: `${BASE_URL}/api/allcompanydomain`,
  COMPANYDOMAIN_CREATE: `${BASE_URL}/api/companydomain/new`,
  COMPANYDOMAIN_SINGLE: `${BASE_URL}/api/companydomain`,
  PENALTYERRORUPLOADS: `${BASE_URL}/api/penaltyerroruploads`,
  PENALTYERRORUPLOADS_CREATE: `${BASE_URL}/api/penaltyerroruploads/new`,
  PENALTYERRORUPLOADS_SINGLE: `${BASE_URL}/api/penaltyerroruploads`,
  MULTIPLEPENALTYERRORUPLOAD_SINGLE: `${BASE_URL}/api/multiplepenaltyerroruploads`,
  PENALTYERRORUPLOADS_DATE_FILTER: `${BASE_URL}/api/penaltyerroruploadsdatefilter`,
  PENALTYERRORUPLOADS_PROJECT_BASED_FILTER: `${BASE_URL}/api/penaltyerroruploadsprojectbasedfilter`,
  CLIENTERRORMONTHAMOUNT: `${BASE_URL}/api/clienterrormonthamount`,
  CLIENTERRORMONTHAMOUNT_CREATE: `${BASE_URL}/api/clienterrormonthamount/new`,
  CLIENTERRORMONTHAMOUNT_SINGLE: `${BASE_URL}/api/clienterrormonthamount`,
  CLIENTERRORMONTHAMOUNT_CONSOLIDATE: `${BASE_URL}/api/clienterrormonthamountconsolidate`,
  WAVIERPERCENTAGE: `${BASE_URL}/api/wavierpercentage`,
  WAVIERPERCENTAGE_CREATE: `${BASE_URL}/api/wavierpercentage/new`,
  WAVIERPERCENTAGE_SINGLE: `${BASE_URL}/api/wavierpercentage`,
  PRODUCTION_UNALLOT_FILTER: `${BASE_URL}/api/productionunallotfilter`,
  PRODUCTION_UNALLOT_FILTER_VIEW: `${BASE_URL}/api/productionunallotfilterview`,
  PRODUCTION_UNALLOT_FILTER_VIEW_Manual: `${BASE_URL}/api/productionunallotfilterviewmanual`,
  PRODUCTION_UNALLOT_FILTER_TEMP: `${BASE_URL}/api/productiontempunallotfiltertemp`,
  PRODUCTION_UNALLOT_FILTER_VIEW_TEMP: `${BASE_URL}/api/productiontempviewfilter`,
  PRODUCTION_UNALLOT_FILTER_VIEW_TEMP_MANUAL: `${BASE_URL}/api/productiontempviewmanualfilter`,
  NONPRODUCTION: `${BASE_URL}/api/nonproduction`,
  NONPRODUCTION_CREATE: `${BASE_URL}/api/nonproduction/new`,
  NONPRODUCTION_SINGLE: `${BASE_URL}/api/nonproduction`,
  GETFILTERDATA: `${BASE_URL}/api/nonproductionfilter`,
  INTERVIEW_LOGIN: `${BASE_URL}/api/interviewlogin`,
  UPDATE_INTERVIEWROUNDSTATUS: `${BASE_URL}/api/updateinterviewrounddata`,
  DELETE_INTERVIEWROUND: `${BASE_URL}/api/deleteinterviewround`,
  CANDIDATE_SCREENING: `${BASE_URL}/api/canidatescreening`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER_FINALSALARY: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterfinalsalary`,
  USER_PAYRUNDATA_LIMITED_FINAL: `${BASE_URL}/api/userspayrundatalimitedfinal`,
  SHIFTS_LIMITED: `${BASE_URL}/api/shiftslimited`,
  USERS_LIMITED_DROPDOWN_FINALSALARY: `${BASE_URL}/api/userslimitedfinalsalary`,
  USER_ATT_MONTH_STATUS_FILTER_LIMITED_MINPOINTS: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterminpointsnew`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER_PAYRUN_MASTER_FETCH: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterpayrunmasterfetch`,
  USER_CLOCKIN_CLOCKOUT_STATUS_LEAVE: `${BASE_URL}/api/userclockinclockoutstatusleave`,
  USER_CLOCKIN_CLOCKOUT_STATUS_LOGIN: `${BASE_URL}/api/userclockinclockoutstatuslogin`,
  SUBCATEGORYPROD_LIMITED: `${BASE_URL}/api/subcategoryprodlimited`,
  PRODUCTION_UNITRATE_PRODUPLOAD_LIMITED: `${BASE_URL}/api/productionunitrateproduploadlimited`,
  OVERALL_SUBSUBCOMPONENT_TICKET: `${BASE_URL}/api/overalleditsubsubcomponent`,
  OVERALL_TYPEMASTER_TICKET: `${BASE_URL}/api/overalledittypemasters`,
  OVERALL_REASONMASTER_TICKET: `${BASE_URL}/api/overalleditreasonmasters`,
  OVERALL_RESOLVER_REASONMASTER_TICKET: `${BASE_URL}/api/overalleditresolverreasonmasters`,
  OVERALL_CATEGORY_TICKET: `${BASE_URL}/api/overalleditcategorymasters`,
  OVERALL_PRIORITY_TICKET: `${BASE_URL}/api/overalleditprioritymasters`,
  OVERALL_DUEDATE_TICKET: `${BASE_URL}/api/overalleditduedatemasters`,
  OVERALL_TYPEGROUP_TICKET: `${BASE_URL}/api/overalledittypegroupmasters`,
  OVERALL_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overalleditreasonmastersdelete`,
  OVERALL_TYPEMASTER_TICKET_DELETE: `${BASE_URL}/api/overalledittypemastersdelete`,
  OVERALL_SUBSUBCOMPONENT_TICKET_DELETE: `${BASE_URL}/api/overallsubsubcomponentdelete`,
  OVERALL_RESOLVER_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overallresolverreasonmastersdelete`,
  OVERALL_CATEGORY_TICKET_DELETE: `${BASE_URL}/api/overalldeletecategorymasters`,
  OVERALL_SELFCHECK_TICKET_DELETE: `${BASE_URL}/api/overalldeleteselfcheckmasters`,
  OVERALL_REQUIRED_TICKET_DELETE: `${BASE_URL}/api/overalldeleterequiredmasters`,
  OVERALL_PRIORITY_TICKET_DELETE: `${BASE_URL}/api/overalldeleteprioritymasters`,
  OVERALL_DUEDATE_TICKET_DELETE: `${BASE_URL}/api/overalldeleteduedatemasters`,
  OVERALL_TYPEGROUP_TICKET_DELETE: `${BASE_URL}/api/overalldeletetypegroupmasters`,
  OVERALL_BULK_RESOLVER_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overallBulkresolverreasonmastersdelete`,
  OVERALL_BULK_SUBSUBCOMPONENT_TICKET_DELETE: `${BASE_URL}/api/overallBulksubsubcomponentdelete`,
  OVERALL_BULK_PRIORITY_MASTER_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleteprioritymasters`,
  OVERALL_BULK_REQUIRED_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleterequiredmasters`,
  OVERALL_BULK_DUEDATE_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleteduedatemasters`,
  OVERALL_BULK_SELFCHECK_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeleteselfcheckmasters`,
  OVERALL_BULK_CATEGORY_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeletecategorymasters`,
  OVERALL_BULK_TYPEGROUP_TICKET_DELETE: `${BASE_URL}/api/overallBulkdeletetypegroupmasters`,
  OVERALL_BULK_TYPEMASTER_TICKET_DELETE: `${BASE_URL}/api/overalleditBulktypemastersdelete`,
  OVERALL_BULK_REASONMASTER_TICKET_DELETE: `${BASE_URL}/api/overallBulkreasonmastersdelete`,
  SINGLE_AUTOLOGOUT: `${BASE_URL}/api/singleautologout`,
  CREATE_AUTOLOGOUT: `${BASE_URL}/api/createautologout`,
  GET_AUTOLOGOUT: `${BASE_URL}/api/allautologout`,
  ASSETMATERIALIP: `${BASE_URL}/api/assetmaterialips`,
  ASSETMATERIALIP_CREATE: `${BASE_URL}/api/assetmaterialip/new`,
  ASSETMATERIALIP_SINGLE: `${BASE_URL}/api/assetmaterialip`,
  ASSETWORKSTATIONGROUP: `${BASE_URL}/api/assetworkgrps`,
  ASSETWORKSTATIONGROUP_CREATE: `${BASE_URL}/api/assetworkgrp/new`,
  ASSETWORKSTATIONGROUP_SINGLE: `${BASE_URL}/api/assetworkgrp`,
  LEAVEVERIFICATION: `${BASE_URL}/api/leaveverifications`,
  LEAVEVERIFICATION_ASSIGNBRANCH: `${BASE_URL}/api/leaveverificationsassignbranch`,
  LEAVEVERIFICATION_CREATE: `${BASE_URL}/api/leaveverification/new`,
  LEAVEVERIFICATION_SINGLE: `${BASE_URL}/api/leaveverification`,
  APPLYLEAVE_FILTERED: `${BASE_URL}/api/applyleavesfilter`,
  NEW_SCHEDULEPAYMENTMASTER: `${BASE_URL}/api/schedulepaymentmaster/new`,
  ALL_SCHEDULEPAYMENTMASTER: `${BASE_URL}/api/allschedulepaymentmasters`,
  SINGLE_SCHEDULEPAYMENTMASTER: `${BASE_URL}/api/schedulepaymentmaster`,
  NEW_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/schedulepaymentnotaddedbills/new`,
  ALL_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/allschedulepaymentnotaddedbills`,
  SINGLE_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/schedulepaymentnotaddedbills`,
  IGNORED_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/ignorednotaddedbills`,
  NOTADDED_SCHEDULEPAYMENT_NOTADDEDBILLS: `${BASE_URL}/api/notaddednotaddedbills`,
  ASSETPROBLEMMASTER: `${BASE_URL}/api/assetproblemmaster`,
  ASSETPROBLEMMASTER_CREATE: `${BASE_URL}/api/assetproblemmaster/new`,
  ASSETPROBLEMMASTER_SINGLE: `${BASE_URL}/api/assetproblemmaster`,
  ASSETDETAIL_REPAIRED: `${BASE_URL}/api/repairedasset`,
  ASSETDETAIL_DAMAGED: `${BASE_URL}/api/damagedasset`,
  CREATE_TASK_MAINTENANCE_NONSCHEDULEGROUPING: `${BASE_URL}/api/taskmaintenancenonschedulegrouping/new`,
  ALL_TASK_MAINTENANCE_NONSCHEDULEGROUPING: `${BASE_URL}/api/taskmaintenancenonschedulegroupings`,
  SINGLE_TASK_MAINTENANCE_NONSCHEDULEGROUPING: `${BASE_URL}/api/taskmaintenancenonschedulegrouping`,
  CREATE_TASKMAINTENACEFORUSER: `${BASE_URL}/api/taskmaintenanceforusers/new`,
  ALL_TASKMAINTENACEFORUSER: `${BASE_URL}/api/taskmaintenanceforusers`,
  ALL_SORTED_TASKMAINTENACEFORUSER: `${BASE_URL}/api/sortedtaskmaintenanceforusers`,
  SINGLE_TASKMAINTENACEFORUSER: `${BASE_URL}/api/taskmaintenanceforusers`,
  ALL_TASKMAINTENACEFORUSER_REPORTS: `${BASE_URL}/api/taskmaintenanceforusersreports`,
  ALL_TASKMAINTENACEFORUSER_AUTOGENERATE: `${BASE_URL}/api/taskmaintenanceautogenerate`,
  TASKMAINTENACEFORUSER_AUTOGENERATE: `${BASE_URL}/api/taskmaintenanceforusersautogenerate`,
  ALL_TASKMAINTENACEFORUSER_ONPROGRESS: `${BASE_URL}/api/taskmaintenanceonprogress`,
  ALL_TASKMAINTENACEFORUSER_COMPLETED: `${BASE_URL}/api/taskmaintenancecompleted`,
  TEMPMISMATCHFILTER: `${BASE_URL}/api/tempmismatchfilter`,
  TEMPMISMATCHUPDATEFLAGCOUNT: `${BASE_URL}/api/tempmismatchfilter/updateflagcount`,
  TEMPUNMATCHFILTER: `${BASE_URL}/api/tempmismatchfilter`,
  ORIGINALMISMATCHFILTER: `${BASE_URL}/api/originalmismatchfilter`,
  ORIGINALMISMATCHUPDATEFLAGCOUNT: `${BASE_URL}/api/originalmismatchfilter/updateflagcount`,
  ORIGINALUNMATCHFILTER: `${BASE_URL}/api/originalunmatchfilter`,
  ADVANCE: `${BASE_URL}/api/advance`,
  ADVANCE_CREATE: `${BASE_URL}/api/advance/new`,
  ADVANCE_SINGLE: `${BASE_URL}/api/advance`,
  CANDIDATESALLBYRESTRICTION: `${BASE_URL}/api/candidatesbyrestricted`,
  INTERVIEWQUESTIONGROUPING_FILTER: `${BASE_URL}/api/interviewquestiongroupingsfilter`,
  STOCKPURCHASELIMITED_HAND: `${BASE_URL}/api/stockpurchaselimitedhand`,
  STOCKPURCHASELIMITED_RETURN: `${BASE_URL}/api/stockpurchaselimitedreturn`,
  USERHANDOVER_LIMITED: `${BASE_URL}/api/userhandoverlimited`,
  STOCKPURCHASE_TRANSFER_LIMITED: `${BASE_URL}/api/stockpurchaselimitedtransfer`,
  STOCKPURCHASE_TRANSFER_LOG_LIMITED: `${BASE_URL}/api/stockpurchaselimitedtransferlog`,
  ASSET_LOG_LIMITED: `${BASE_URL}/api/assetdetaillog`,
  STOCKPURCHASELIMITED_HAND_RETURN: `${BASE_URL}/api/stockpurchaselimitedhandreturn`,
  STOCKPURCHASELIMITED_HAND_TODO: `${BASE_URL}/api/stockpurchaselimitedhandtodo`,
  STOCKPURCHASELIMITED_HAND_TODO_RETURN: `${BASE_URL}/api/stockpurchaselimitedhandtodoreturn`,
  ASSETDETAIL_SINGLE_REPAIR: `${BASE_URL}/api/assetdetailsinglerepair`,
  MANUAL_STOCKPURCHASE: `${BASE_URL}/api/manualstocks`,
  MANUAL_STOCKPURCHASE_CREATE: `${BASE_URL}/api/manualstock/new`,
  MANUAL_STOCKPURCHASE_SINGLE: `${BASE_URL}/api/manualstock`,
  GETFILTEREMOTEUSERUNALLOTTED: `${BASE_URL}/api/unallotgetfilterremoteuser`,
  PRODUCTION_UNITRATE_FILTER_LIMITED: `${BASE_URL}/api/unitratefilterlimited`,
  PRODUCTION_UNITRATE_FILTER_CATEGORY_LIMITED: `${BASE_URL}/api/unitratefiltercategorylimited`,
  PRODUCTION_UNITRATE_FILTER_CATEGORIES_LIMITED: `${BASE_URL}/api/unitratefiltercategorieslimited`,
  CLIENTUSERID_LIMITED: `${BASE_URL}/api/clientuseridslimited`,
  CHECKLISTVERIFICATIONMASTER: `${BASE_URL}/api/checklistverificationmasters`,
  CHECKLISTVERIFICATIONMASTER_CREATE: `${BASE_URL}/api/checklistverificationmaster/new`,
  CHECKLISTVERIFICATIONMASTER_SINGLE: `${BASE_URL}/api/checklistverificationmaster`,
  LOAN: `${BASE_URL}/api/loan`,
  LOAN_CREATE: `${BASE_URL}/api/loan/new`,
  LOAN_SINGLE: `${BASE_URL}/api/loan`,
  CREATE_TRAINING_FOR_USER: `${BASE_URL}/api/trainingforuser/new`,
  ALL_TRAINING_FOR_USER: `${BASE_URL}/api/trainingforusers`,
  ALL_TRAINING_FOR_USER_POSTPONED: `${BASE_URL}/api/trainingforuserspostponed`,
  ALL_TRAINING_FOR_USER_ONPROGRESS: `${BASE_URL}/api/trainingforusersonprogress`,
  ALL_TRAINING_FOR_USER_COMPLETED: `${BASE_URL}/api/trainingforuserscompleted`,
  SINGLE_TRAINING_FOR_USER: `${BASE_URL}/api/trainingforuser`,
  ALL_SORTED_TRAININGFORUSER: `${BASE_URL}/api/sortedtrainingforusers`,
  ALL_TRAINIGFORUSER_REPORTS: `${BASE_URL}/api/trainingforuserreports`,
  ALL_TRAINIGFORUSER_REPORTS_OVERALL: `${BASE_URL}/api/trainingforuserreportsoverall`,
  CREATE_ONLINE_TEST_QUESTION: `${BASE_URL}/api/onlinetestquestion/new`,
  ALL_ONLINE_TEST_QUESTION: `${BASE_URL}/api/onlinetestquestions`,
  SINGLE_ONLINE_TEST_QUESTION: `${BASE_URL}/api/onlinetestquestion`,
  CREATE_ONLINE_TEST_MASTER: `${BASE_URL}/api/onlinetestmaster/new`,
  ALL_ONLINE_TEST_MASTER: `${BASE_URL}/api/onlinetestmasters`,
  SINGLE_ONLINE_TEST_MASTER: `${BASE_URL}/api/onlinetestmaster`,
  CREATE_TRAINING_USER_RESPONSE: `${BASE_URL}/api/createusertrainingresponse/new`,
  ALL_TRAINING_USER_RESPONSE: `${BASE_URL}/api/usertrainingresponses`,
  SINGLE_TRAINING_USER_RESPONSE: `${BASE_URL}/api/usertrainingresponse`,
  CREATE_TASKFORUSER: `${BASE_URL}/api/taskforuser/new`,
  ALL_TASKFORUSER: `${BASE_URL}/api/taskforusers`,
  ALL_TASKFORUSER_MANUAL: `${BASE_URL}/api/taskforusersmanuual`,
  ALL_TASKFORUSER_ONPROGRESS: `${BASE_URL}/api/taskforusersonprogress`,
  ALL_TASKFORUSER_COMPLETED: `${BASE_URL}/api/taskforuserscompleted`,
  ALL_SORTED_TASKFORUSER: `${BASE_URL}/api/sortedtasksforusers`,
  MANUALALL_TASKFORUSER: `${BASE_URL}/api/manualtaskforusers`,
  ONPROGRESSALL_TASKFORUSER: `${BASE_URL}/api/onprogresstaskforusers`,
  COMPLETEDALL_TASKFORUSER: `${BASE_URL}/api/completedtaskforusers`,
  INDIVIDUALALL_TASKFORUSER: `${BASE_URL}/api/individualtaskforusers`,
  ALL_TRAININGDETAILS: `${BASE_URL}/api/trainingdetailss`,
  ALL_TRAININGDETAILS_DOCUMENT: `${BASE_URL}/api/trainingdetailsdocument`,
  CREATE_TRAININGDETAILS: `${BASE_URL}/api/trainingdetails/new`,
  SINGLE_TRAININGDETAILS: `${BASE_URL}/api/trainingdetails`,
  ALL_TRAININGDETAILS_DOCUMENT_ACTIVE: `${BASE_URL}/api/trainingdetailsdocumentactive`,
  ALL_TASKFORUSER_USERNAME: `${BASE_URL}/api/taskforusersusername`,
  ADDTOPRINTQUEUE: `${BASE_URL}/api/addtoprintqueues`,
  ADDTOPRINTQUEUE_LIMIT: `${BASE_URL}/api/addtoprintqueueslimit`,
  ADDTOPRINTQUEUE_LIMIT_PRINT: `${BASE_URL}/api/addtoprintqueueslimitprint`,
  ADDTOPRINTQUEUEFILTER: `${BASE_URL}/api/addtoprintqueuefilter`,
  ADDTOPRINTQUEUE_CREATE: `${BASE_URL}/api/addtoprintqueue/new`,
  ADDTOPRINTQUEUE_SINGLE: `${BASE_URL}/api/addtoprintqueue`,
  ALLDATATO_ADDTOPRINTQUEUE: `${BASE_URL}/api/alldatatoaddtoprintqueue`,
  INTERVIEW_ROUND: `${BASE_URL}/api/fetchinterviewround`,
  USER_PROD: `${BASE_URL}/api/usersprod`,
  PRODUCTION_UPLOAD_GET_UNITRATEUPDATE_OVERALL_FETCH_LIMITED: `${BASE_URL}/api/productionuploadunitrateoverallfetchlimited`,
  PRODUCTION_UNITRATE_LIMITED_PROD: `${BASE_URL}/api/unitrateprodlimited`,
  CATEGORYPROD_LIMITED: `${BASE_URL}/api/categoryprodlimited`,
  LABELNAME: `${BASE_URL}/api/labelname`,
  LABELNAME_CREATE: `${BASE_URL}/api/labelname/new`,
  LABELNAME_SINGLE: `${BASE_URL}/api/labelname`,
  MYCHECKLIST: `${BASE_URL}/api/mychecklist`,
  MYCHECKLIST_CREATE: `${BASE_URL}/api/mychecklist/new`,
  MYCHECKLIST_SINGLE: `${BASE_URL}/api/mychecklist`,
  MYCHECKLIST_SINGLEBYOBJECTID: `${BASE_URL}/api/mychecklist/usingobjectid`,
  ALL_PROCESS_AND_TEAM_FILTER_LIMITED: `${BASE_URL}/api/processteamfilterlimited`,
  PRODUCTION_UPLOAD_GET_SINGLEDATE_DATA_PRODUCTION_DAY: `${BASE_URL}/api/productiondaygetsingledatedataday`,
  DEPTMONTHSET_PROD_LIMITED: `${BASE_URL}/api/departmentmonthsetsprodlimited`,
  CATEGORYPROCESSMAP_LIMITED: `${BASE_URL}/api/categoryprocessmapslimited`,
  USER_BOARDINGLOG_UPDATE: `${BASE_URL}/api/boardinglogupdate`,
  UNITCATSUBCATEGORYPROD: `${BASE_URL}/api/unitratecatsubprod`,
  RESUMEMAILATTACHMENTS: `${BASE_URL}/api/resumemailattachments`,
  RESUMEMAILATTACHMENTS_CREATE: `${BASE_URL}/api/resumemailattachments/new`,
  RESUMEMAILATTACHMENTS_SINGLE: `${BASE_URL}/api/resumemailattachments`,
  SALARY_FIX_FILTER: `${BASE_URL}/api/salaryfixfilter`,
  ADD_VENDORGROUPING: `${BASE_URL}/api/vendorgrouping/new`,
  ALL_VENDORGROUPING: `${BASE_URL}/api/vendorgrouping`,
  SINGLE_VENDORGROUPING: `${BASE_URL}/api/singlevendorgrouping`,
  INTERVIEWROUNDORDER_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewroundorder`,
  INTERVIEWROUNDORDER_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewroundorder`,
  INTERVIEWQUESTIONSORDER_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewquestionorder`,
  INTERVIEWQUESTIONSORDER_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewquestionorder`,
  INTERVIEWANSWERALLOT_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewanswerallot`,
  INTERVIEWANSWERALLOT_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewanswerallot`,
  INTERVIEWSTATUSALLOT_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewstatusallot`,
  INTERVIEWSTATUSALLOT_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewstatusallot`,
  INTERVIEWQUESTIONGROUPING_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewquestiongrouping`,
  INTERVIEWQUESTIONGROUPING_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewquestiongrouping`,
  INTERVIEWQUESTION_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/interviewquestions`,
  INTERVIEWQUESTION_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewquestions`,
  INTERVIEWQUESTION_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewquestions`,
  INTERVIEWTESTMASTER_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/interviewtestmaster`,
  INTERVIEWTESTMASTER_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewtestmaster`,
  INTERVIEWTESTMASTER_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewtestmaster`,
  INTERVIEWTYPEMASTER_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/interviewtypemaster`,
  INTERVIEWTYPEMASTER_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewtypemaster`,
  INTERVIEWTYPEMASTER_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewtypemaster`,
  ROUNDMASTER_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/interviewroundmaster`,
  ROUNDMASTER_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewroundmaster`,
  ROUNDMASTER_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewroundmaster`,
  CATEGORYINTERVIEW_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/interviewcategory`,
  CATEGORYINTERVIEW_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/interviewcategory`,
  CATEGORYINTERVIEW_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/interviewcategory`,
  PRODUCTION_DAYS: `${BASE_URL}/api/productiondays`,
  PRODUCTION_DAY_CREATE: `${BASE_URL}/api/productionday/new`,
  PRODUCTION_DAY_SINGLE: `${BASE_URL}/api/productionday`,
  PRODUCTION_DAY_UNIQID: `${BASE_URL}/api/productiondaysuniqid`,
  PRODUCTION_DAYS_LIST: `${BASE_URL}/api/productiondaylists`,
  PRODUCTION_DAY_LIST_CREATE: `${BASE_URL}/api/productiondaylist/new`,
  PRODUCTION_DAY_LIST_SINGLE: `${BASE_URL}/api/productiondaylist`,
  PRODUCTION_DAY_LIST_GET_DELETE_LIMITED: `${BASE_URL}/api/productiondaylistgetdeletelimited`,
  PRODUCTION_DAY_LIST_GET_VIEW_LIMITED: `${BASE_URL}/api/productiondaylistgetviewlimited`,
  PRODUCTION_DAY_LIST_DELETE_UNIQID: `${BASE_URL}/api/productiondaylistdeleteuniqud`,
  PRODUCTION_DAYS_GETLIST_BY_DATE: `${BASE_URL}/api/productiondaylistsgetbydate`,
  GET_LOGINALLOT_ID_DETAILS: `${BASE_URL}/api/getloginallotiddetails`,
  GET_DAY_POINTS_LIMITED: `${BASE_URL}/api/daypointslimited`,
  CHECK_ZERO_MISMATCH_PRESENT: `${BASE_URL}/api/checkzeromismatchpresent`,
  GET_PRODUCTION_SINGLE_DAYUSER: `${BASE_URL}/api/getproductionsignledayuser`,
  OVERALL_TASK_CATEGORY_TICKET: `${BASE_URL}/api/taskcategoryOverallEdit`,
  OVERALL_TASK_CATEGORY_TICKET_DELETE: `${BASE_URL}/api/taskcategoryOverallDelete`,
  OVERALL_TASK_SUBCATEGORY_TICKET: `${BASE_URL}/api/tasksubcategoryOverallEdit`,
  OVERALL_TASK_SUBCATEGORY_TICKET_DELETE: `${BASE_URL}/api/tasksubcategoryOverallDelete`,
  RAISETICKET_REPORT_OVERALL: `${BASE_URL}/api/raiseticketsreportsoverall`,
  OVERALL_TRAINING_CATEGORY_TICKET: `${BASE_URL}/api/trainingcategoryOverallEdit`,
  OVERALL_TRAINING_OVERALL_CATEGORY_TICKET: `${BASE_URL}/api/trainingcategoryOverallDelete`,
  OVERALL_TRAINING_SUBCATEGORY_TICKET: `${BASE_URL}/api/trainingsubcategoryOverallEdit`,
  OVERALL_TRAINING_SUBOVERALL_CATEGORY_TICKET: `${BASE_URL}/api/trainingsubcategoryOverallDelete`,
  OVERALL_TRAINING_DETAILS_EDIT: `${BASE_URL}/api/trainingDetailsOverallEdit`,
  ALL_DOCUMENT_TRAINING: `${BASE_URL}/api/documentstraining`,
  ALLINTERVIEWQUESTION: `${BASE_URL}/api/allinterviewquestions`,
  INTERVIEWTYPINGQUESTION: `${BASE_URL}/api/allinterviewtypingquestions`,
  GET_DAY_POINTS_LIMITED_DATE: `${BASE_URL}/api/checkdaypointdate`,
  PRODUCTION_UNITRATE_FILTER_GETMULTI: `${BASE_URL}/api/getprodunitrategetmulti`,
  ALLUSERSWITHOUTSTATUS: `${BASE_URL}/api/userswithoutstatus`,
  FIND_ATTANDANCESTATUS: `${BASE_URL}/api/findattendance`,
  ANNOUNCEMENTCATEGORY_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/announcementcategory`,
  ANNOUNCEMENTCATEGORY_OVERALLDELETE: ` ${BASE_URL}/api/overalldelete/announcementcategory`,
  ANNOUNCEMENTCATEGORY_OVERALLBULKDELETE: ` ${BASE_URL}/api/overallbulkdelete/announcementcategory`,
  ORGCATEGORYDOCUMENT_OVERALLBULKDELETE: `${BASE_URL}/api/overallbulkdelete/organizationcategory`,
  GETDOCUMENTS: `${BASE_URL}/api/employeedocumentcommonid`,
  GETPROFILES: `${BASE_URL}/api/employeeprofile`,
  SUBCATEGORYPROD_LIST_LIMITED: `${BASE_URL}/api/getlistsubcategoryprodlimited`,
  CREATE_CARD_PREPARATION: `${BASE_URL}/api/cardpreparation/new`,
  ALL_CARDPREPARATION: `${BASE_URL}/api/cardpreparations`,
  SINGLE_CARDPREPARATION: `${BASE_URL}/api/cardpreparation`,
  OVERALL_DELETE_IPCATEGORY: `${BASE_URL}/api/ipcategorieoveralldelete`,
  OVERALL_EDIT_IPCATEGORY: `${BASE_URL}/api/ipcategorieoveralledit`,
  OVERALL_EDIT_PASSWORDCATEGORY: `${BASE_URL}/api/passwordcategoryedit`,
  OVERALL_DELETE_PASSWORDCATEGORY: `${BASE_URL}/api/passwordcategorydelete`,
  OVERALL_DELETE_PASSWORDIPMASTER: `${BASE_URL}/api/overalldeleteip`,
  OVERALL_DELETE_EBUSEINSTRUMENT: `${BASE_URL}/api/ebuseinstrumentoveralldelete`,
  OVERALL_DELETE_EBREADING: `${BASE_URL}/api/ebreadingdetailoveralldelete`,
  OVERALL_DELETE_EBMATERIAL: `${BASE_URL}/api/ebmaterialdetailoverlldelte`,
  OVERALL_EDIT_EBSERVICEMASTER: `${BASE_URL}/api/ebservicemastersoveralledit`,
  OVERALL_EDIT_POWERSTATION: `${BASE_URL}/api/powerstationoveralledit`,
  OVERALL_DELETE_POWERSTATION: `${BASE_URL}/api/powerstationoveralldelete`,
  EB_READING_DATACOUNT: `${BASE_URL}/api/ebreadingdatacount`,
  CANDIDATEDOCUMENT: `${BASE_URL}/api/candidatedocuments`,
  CANDIDATEDOCUMENT_CREATE: `${BASE_URL}/api/candidatedocument/new`,
  CANDIDATEDOCUMENT_SINGLE: `${BASE_URL}/api/candidatedocument`,
  ORIGINALUNMATCHFILTERCOUNTCHECK: `${BASE_URL}/api/originalunmatchfiltercountcheck`,
  GET_MISMATCH_UPDATEDLIST: `${BASE_URL}/api/getmismatchupdatedlist`,
  UPDATE_UNDO_FIELDNAME: `${BASE_URL}/api/updatefieldundoname`,
  GETDEPARTMENTMONTHSETBYPAGINATION: `${BASE_URL}/api/departmentmonthsetspaginationlimited`,
  GETDESIGNATIONMONTHSETBYPAGINATION: `${BASE_URL}/api/designationmonthsetspaginationlimited`,
  GETPROCESSMONTHSETBYPAGINATION: `${BASE_URL}/api/processmonthsetspaginationlimited`,
  GETDEPARTMENTMONTHSETAUTOBYPAGINATION: `${BASE_URL}/api/deptmonthsetautobypagination`,
  OVERALLSORT_EBREADING: `${BASE_URL}/api/ebreadingdetailpagenationsort`,
  RESUMEMANAGEMENT_CANDIDATE_SORT: ` ${BASE_URL}/api/resumemanagementsortedcandidates`,
  RESUMEMANAGEMENT_CANDIDATE_FILTERED: ` ${BASE_URL}/api/resumemanagementfilteredcandidates`,
  CANDIDATEFILEUPLOAD_LINK: ` ${BASE_URL}/api/candidatefileuploadusinglink`,
  SKIPPED_CANDIDATES: ` ${BASE_URL}/api/skippedcandidates`,
  SKIPPED_INTERVIEWQUESTIONS: ` ${BASE_URL}/api/skippedinterviewquestions`,
  INTERVIEWMAIL_SENT: `${BASE_URL}/api/interviewmail`,
  SKIPPED_VISITORS: `${BASE_URL}/api/skippedvisitors`,
  SKIPPEDALL_VISITORS: `${BASE_URL}/api/skippedallvisitors`,
  SKIPPED_RAISEPROBLEM: `${BASE_URL}/api/skippedraiseproblem`,
  ALL_MANAGEASSIGNED_SORT: `${BASE_URL}/api/manageassignedsort`,
  ACHEIVEDACCURACYINDIVIDUAL_SORT: `${BASE_URL}/api/achievedaccuracyindividualsort`,
  GETACHEIVEDACCURACYINDIVIDUALINTERNALSTATUSLIST: `${BASE_URL}/api/achievedaccuracyindividualclientstatus`,
  ALL_SALARYSLAB_SORT: `${BASE_URL}/api/salaryslabprocessfiltersort`,
  ALL_ACCURACYMASTER: `${BASE_URL}/api/accuracymastersort`,
  ALL_ACCURACYQUEUEGROUPING_SORT: `${BASE_URL}/api/accuracyqueuegroupingsort`,
  ALL_EXPECTEDACCURACYMASTER: `${BASE_URL}/api/expectedaccuracysort`,
  ACHEIVEDACCURACYGETALL_SORT: `${BASE_URL}/api/acheivedaccuracysort`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDSECTION: `${BASE_URL}/api/updatedbulkdatasunitandsection`,
  MAINTENTANCE_ACTIVE: `${BASE_URL}/api/maintentancesactive`,
  DEPTMONTHSET_LIMITED_WITH_DEPT: `${BASE_URL}/api/departmentmonthsetslimitedforleave`,
  PRODUCTION_UPLOAD_CHECKSTATUS: `${BASE_URL}/api/productionuploadcheckstatus`,
  GET_MISMATCH_DATAS_ID: `${BASE_URL}/api/getmismatchdatasid`,
  PRODUCTION_UPLOAD_GETDATAS_BYID: `${BASE_URL}/api/getproductionuploaddatasbyid`,
  PRODUCTION_UPLOAD_GETDATAS_BYID_MANUAL: `${BASE_URL}/api/getproductionuploaddatasbyidmanual`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDFLAG: `${BASE_URL}/api/updatedbulkdatasunitandflag`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITONLY: `${BASE_URL}/api/updatedbulkdatasunitonly`,
  PRODUCTION_UPLOAD_BULKUPDATE_FLAGONLY: `${BASE_URL}/api/updatedbulkdatasflagonly`,
  WEEKOFFPRESENT: `${BASE_URL}/api/weekoffpresents`,
  WEEKOFFPRESENT_CREATE: `${BASE_URL}/api/weekoffpresent/new`,
  WEEKOFFPRESENT_SINGLE: `${BASE_URL}/api/weekoffpresent`,
  WEEKOFFPRESENT_FILTER: `${BASE_URL}/api/weekoffpresentfilter`,
  LEAVECRITERIA_FOR_APPLY_LEAVE: `${BASE_URL}/api/leavecriteriaforapplyleave`,
  NOTICEPERIODAPPLY_FOR_LEAVE: `${BASE_URL}/api/noticeperiodappliesforleave`,
  ATTENDANCE_ID_FILTER: `${BASE_URL}/api/attandanceidfilter`,
  SKIPPEDEXPENSES: `${BASE_URL}/api/skippedexpenses`,
  SKIPPEDINCOME: `${BASE_URL}/api/skippedincomes`,
  SKIPPEDOTHERPAYMENTS: `${BASE_URL}/api/skippedotherpayment`,
  APPLYLEAVE_APPROVED: `${BASE_URL}/api/applyleavesapproved`,
  ASSETMATERIALIP_LIMITED: `${BASE_URL}/api/assetmaterialipslimited`,
  USERNEW: `${BASE_URL}/api/usersnew`,
  GETSINGLEUSERBRANCH: `${BASE_URL}/api/singleassignbranch`,
  EMPLOYEEDOCUMENT_SINGLEWITHALLBYCOMMONIDONE: `${BASE_URL}/api/employeedocumentcommonidwithallnew`,
  CHECKUNITRATE_MANUAL_CREATION: `${BASE_URL}/api/checkunitrateformanualcreation`,
  CHECKSUBCATEGORY_MANUAL_CREATION: `${BASE_URL}/api/checksubcategoryformanualcreation`,
  SUBCATEGORYPROD_LIST_LIMITED_PAGINATION: `${BASE_URL}/api/getlistsubcategoryprodlimitedpagination`,
  OVERALLACHEIVEDACCURACYINDIVIDUAL_SORT: `${BASE_URL}/api/overallachievedaccuracyindividualsort`,
  HIERARCHI_SALARY_FILTER: `${BASE_URL}/api/getallhierarchylistsalary`,
  REVENUEAMOUNT_SORT: `${BASE_URL}/api/revenueamountsort`,
  ERAAMOUNT_SORT: `${BASE_URL}/api/eraamountsort`,
  ALL_TARGETPOINTS_SORT: `${BASE_URL}/api/targetpointsort`,
  PASSWORD_ACTION_EMPLOYEE: `${BASE_URL}/api/allpasswordsactionemployee`,
  DRAFTDUPLICATE: `${BASE_URL}/api/draftduplicatecheck`,
  TARGETPOINTSASSIGNBRANCH: `${BASE_URL}/api/targetpointslimitedassignbranch`,
  RAISETICKET_LAST: `${BASE_URL}/api/raiseticketslast`,
  COMPANYNAME_DUPLICATECHECK_CREATE: `${BASE_URL}/api/checkcompanynamecreate`,
  COMPANYNAME_DUPLICATECHECK_EDIT: `${BASE_URL}/api/checkcompanynameedit`,
  GETALLCHECKLISTBYPAGINATION: `${BASE_URL}/api/mychecklistbypagination`,
  SALARY_FIX_FILTERREPORT: `${BASE_URL}/api/salaryfixfilterreport`,
  USER_CREDENTIALS_MAIL: `${BASE_URL}/api/user-credentials`,
  USER_SINGLE_PWD_RESET: `${BASE_URL}/api/userpwreset`,
  USERCHECKS: `${BASE_URL}/api/maintenancelog`,
  CREATE_USERCHECKS: `${BASE_URL}/api/maintenancelog/new`,
  USERCHECKS_SINGLE: `${BASE_URL}/api/maintenancelogsingle`,
  SKIPPED_EMPLOYEE: `${BASE_URL}/api/skippedemployee`,
  PRODUCTION_ORGINAL_LIMITED_FILTER: `${BASE_URL}/api/productionoriginalslimitedfilter`,
  CHECK_ISPRODDAY_CREATED: `${BASE_URL}/api/checkisproddaycreated`,
  BULK_DELETE_UNITRATE_UNALLOT: `${BASE_URL}/api/bulkdeleteunitrateunallot`,
  CLIENTUSERID_CHECK_USER: `${BASE_URL}/api/clientuseridusercheck`,
  EMPLOYEEDOCUMENTIDCARD: `${BASE_URL}/api/employeedocumentsidcard`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDFLAG_TEMP: `${BASE_URL}/api/updatedbulkdatasunitandflagtemp`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITONLY_TEMP: `${BASE_URL}/api/updatedbulkdatasunitonlytemp`,
  PRODUCTION_UPLOAD_BULKUPDATE_FLAGONLY_TEMP: `${BASE_URL}/api/updatedbulkdatasflagonlytemp`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDSECTION_TEMP: `${BASE_URL}/api/updatedbulkdatasunitandsectiontemp`,
  BULK_DELETE_UNITRATE_UNALLOT_TEMP: `${BASE_URL}/api/bulkdeleteunitrateunallottemp`,
  GET_MISMATCH_DATAS_ID_TEMP: `${BASE_URL}/api/getmismatchdatasidtemp`,
  PRODUCTION_UPLOAD_GETDATAS_BYID_TEMP: `${BASE_URL}/api/getproductionuploaddatasbyidtemp`,
  PRODUCTION_UPLOAD_GETDATAS_BYID_MANUAL_TEMP: `${BASE_URL}/api/getproductionuploaddatasbyidmanualtemp`,
  PRODUCTION_TEMP_LIMITED_FILTER: `${BASE_URL}/api/productiontemplimitedfilter`,
  PRODUCTION_TEMP_UNITRATE_BULK_UPDATECATSUBCATEGORY: `${BASE_URL}/api/bulkproductiontempupdatecategorysubcategory`,
  PRODUCTION_ORIGINAL_UNITRATE_BULK_UPDATECATSUBCATEGORY: `${BASE_URL}/api/bulkproductionorgupdatecategorysubcategory`,
  GET_MISMATCH_UPDATEDLIST_TEMP: `${BASE_URL}/api/getmismatchupdatedlisttemp`,
  UPDATE_UNDO_FIELDNAME_TEMP: `${BASE_URL}/api/updatefieldundonametemp`,
  PRODUCTION_UPLOAD_CHECKSTATUS_TEMP: `${BASE_URL}/api/productionuploadcheckstatustemp`,
  PRODUCTION_DAYS_TEMP: `${BASE_URL}/api/productiondaystemp`,
  PRODUCTION_DAY_CREATE_TEMP: `${BASE_URL}/api/productiondaytemp/new`,
  PRODUCTION_DAY_SINGLE_TEMP: `${BASE_URL}/api/productiondaytemp`,
  PRODUCTION_DAY_UNIQID_TEMP: `${BASE_URL}/api/productiondaysuniqidtemp`,
  PRODUCTION_DAYS_LIST_TEMP: `${BASE_URL}/api/productiondayliststemp`,
  PRODUCTION_DAY_LIST_CREATE_TEMP: `${BASE_URL}/api/productiondaylisttemp/new`,
  PRODUCTION_DAY_LIST_SINGLE_TEMP: `${BASE_URL}/api/productiondaylisttemp`,
  PRODUCTION_DAY_LIST_GET_DELETE_LIMITED_TEMP: `${BASE_URL}/api/productiondaylistgetdeletelimitedtemp`,
  PRODUCTION_DAY_LIST_GET_VIEW_LIMITED_TEMP: `${BASE_URL}/api/productiondaylistgetviewlimitedtemp`,
  PRODUCTION_DAY_LIST_DELETE_UNIQID_TEMP: `${BASE_URL}/api/productiondaylistdeleteuniqudtemp`,
  PRODUCTION_DAYS_GETLIST_BY_DATE_TEMP: `${BASE_URL}/api/productiondaylistsgetbydatetemp`,
  CHECK_ZERO_MISMATCH_PRESENT_TEMP: `${BASE_URL}/api/checkzeromismatchpresenttemp`,
  GET_PRODUCTION_SINGLE_DAYUSER_TEMP: `${BASE_URL}/api/getproductionsignledayusertemp`,
  PRODUCTION_UPLOAD_GET_SINGLEDATE_DATA_PRODUCTION_DAY_TEMP: `${BASE_URL}/api/productiondaygetsingledatedatadaytemp`,
  GET_DAY_POINTS_LIMITED_DATE_TEMP: `${BASE_URL}/api/checkdaypointdatetemp`,
  ADD_DAY_POINTS_TEMP: `${BASE_URL}/api/daypointtemp/new`,
  GET_DAY_POINTS_TEMP: `${BASE_URL}/api/daypointstemp`,
  SINGLE_DAY_POINTS_TEMP: `${BASE_URL}/api/daypointtemp`,
  SINGLE_DAY_POINTS_UPLOAD_TEMP: `${BASE_URL}/api/singledaypointtemp`,
  GET_DAY_POINTS_LIMITED_TEMP: `${BASE_URL}/api/daypointslimitedtemp`,
  DAY_POINTS_MONTH_YEAR_FILTER_NXTMONTH_TEMP: `${BASE_URL}/api/daypointsmonthwisefilternxtmonthtemp`,
  DAY_POINTS_MONTH_YEAR_FILTER_TEMP: `${BASE_URL}/api/daypointsmonthwisefiltertemp`,
  DAY_POINTS_FILTER_TEMP: `${BASE_URL}/api/daypointsfiltertemp`,
  DAY_POINTS_DATAS_TEMP: `${BASE_URL}/api/daypointsdatasfetchtemp`,
  ALLUSEREMPLOYEE: `${BASE_URL}/api/alluseremployee`,
  GETFILTEREUSERDATA: `${BASE_URL}/api/getfilteralluserdata`,
  FORMERUSERS: `${BASE_URL}/api/formerusernames`,
  ONBOARDING_SALARY_FIX_FILTERREPORT: `${BASE_URL}/api/onboardingsalaryfixfilter`,
  CREATE_DOCUMENT_PREPARATION: `${BASE_URL}/api/documentpreparation/new`,
  ALL_DOCUMENTPREPARATION: `${BASE_URL}/api/documentpreparations`,
  ACCESIBLEBRANCHALL_DOCUMENTPREPARATION: `${BASE_URL}/api/accessiblebranchdocumentpreparations`,
  SINGLE_DOCUMENTPREPARATION: `${BASE_URL}/api/documentpreparation`,
  DOCUMENT_PREPARATION_CODES: `${BASE_URL}/api/documentpreparationcodes`,
  MYVERIFICATION_CREATE: `${BASE_URL}/api/myverification/new`,
  MYVERIFICATION: `${BASE_URL}/api/myverifications`,
  MYVERIFICATION_SINGLE: `${BASE_URL}/api/myverification`,
  MYFIELDVERIFICATION_CREATE: `${BASE_URL}/api/myfieldverification/new`,
  MYFIELDVERIFICATION: `${BASE_URL}/api/myfieldverifications`,
  MYFIELDVERIFICATION_SINGLE: `${BASE_URL}/api/myfieldverification`,
  VERIFIEDLIST_SINGLE: `${BASE_URL}/api/verifiedlist`,
  CANDIDATESTATUS_FILTER: `${BASE_URL}/api/candidatestatusfilter`,
  USERSWITHSTATUS: `${BASE_URL}/api/userwithstatus`,
  TEMPLATECONTROLPANEL: `${BASE_URL}/api/templatecontrolpanel`,
  ACCESSIBLETEMPLATECONTROLPANEL: `${BASE_URL}/api/accessibletemplatecontrolpanel`,
  FILTERTEMPLATECONTROLPANEL: `${BASE_URL}/api/filtertemplatecontrolpanel`,
  TEMPLATECONTROLPANEL_CREATE: `${BASE_URL}/api/templatecontrolpanel/new`,
  TEMPLATECONTROLPANEL_SINGLE: `${BASE_URL}/api/templatecontrolpanel`,
  DUPLICATIONTEMPLATECONTROLPANEL: `${BASE_URL}/api/duplicatetemplatecontrolpanel`,
  CREATE_TEMPLATECREATION: `${BASE_URL}/api/templatecreation/new`,
  ALL_TEMPLATECREATION: `${BASE_URL}/api/templatecreations`,
  ACCESSIBLEBRANCHALL_TEMPLATECREATION: `${BASE_URL}/api/accessibletemplatecreations`,
  EMPLOYEE_TEMPLATECREATION: `${BASE_URL}/api/employeetemplatecreations`,
  COMPANY_TEMPLATECREATION: `${BASE_URL}/api/companytemplatecreations`,
  SINGLE_TEMPLATECREATION: `${BASE_URL}/api/templatecreation`,
  OVERALL_TEMPLATE_CREATION: `${BASE_URL}/api/overalledittemplatecreation`,
  OVERALL_TEMPLATE_CREATION_DELETE: `${BASE_URL}/api/overalledittemplatecreationdelete`,
  RAISE_TICKET_FORWARDED_EMPLOYEES: `${BASE_URL}/api/raiseticketforwardedemployee`,
  RAISE_TICKET_USER_FORWARDED_EMPLOYEES: `${BASE_URL}/api/raiseticketuserforwardedemployee`,
  CONTROL_NAMES_BASED_ON_DESIG: `${BASE_URL}/api/controlnamesbasedondesignation`,
  CANDIDATESALLFIELDS_FILTER: `${BASE_URL}/api/candidatesafieldsfilter`,
  CANDIDATESALLFIELDS: `${BASE_URL}/api/candidatesallfields`,
  USERNEWFILTER: `${BASE_URL}/api/usersnewfilter`,
  GETALLFILTEREDUSERS: `${BASE_URL}/api/filteredusers`,
  DOCUMENT_PREPARATION_MAIL: `${BASE_URL}/api/documentpreparationmail`,
  USER_NAME_SEARCH: `${BASE_URL}/api/usernamesearch`,
  TEMPLATECONTROLPANEL_USERFIND: `${BASE_URL}/api/tempcontrolepaneluserfind`,
  PAYRUNLIST: `${BASE_URL}/api/payrunlists`,
  PAYRUNLIST_CREATE: `${BASE_URL}/api/payrunlist/new`,
  PAYRUNLIST_SINGLE: `${BASE_URL}/api/payrunlist`,
  PAYRUNLIST_LIMITED: `${BASE_URL}/api/payrunlistlimited`,
  UPDATE_INNERDATA_SINGLE_USER_RERUN: `${BASE_URL}/api/updateinnerdatasingleuserrerun`,
  UPDATE_PAYRUNLIST_INNERDATA: `${BASE_URL}/api/updatepayrunlistinnerdata`,
  UNDO_PAYRUNLIST_INNERDATA: `${BASE_URL}/api/undopayrunlistinnerdata`,
  UPDATE_PAYRUNLIST_INNERDATA_USER: `${BASE_URL}/api/updatepayrunlistinnerdatauser`,
  UNDO_PAYRUNLIST_INNERDATA_USER: `${BASE_URL}/api/undopayrunlistinnerdatauser`,
  CHECK_PAYRUN_ISCREATED: `${BASE_URL}/api/checkpayruniscreated`,
  USER_LOGIN_STATUS: `${BASE_URL}/api/usersloginstatus`,
  INDIVIDUAL_USER_LOGIN_STATUS: `${BASE_URL}/api/individualusersloginstatus`,
  HIERARCHY_BASED_USER_LOGIN_STATUS: `${BASE_URL}/api/hierarchybasedemployeeloginstatus`,
  HIERARCHY_BASED_USER_LOGIN_STATUS_DEFAULT: `${BASE_URL}/api/hierarchybasedemployeeloginstatusdefault`,
  USERS_COMPANYNAMES: `${BASE_URL}/api/getalluserscompanyname`,
  PAYRUNLIST_SENT_FIXSALARYDATE: `${BASE_URL}/api/payrunlistsentsalaryfixdate`,
  UPDATE_INNERDATA_SINGLE_USER_WAIVER: `${BASE_URL}/api/updateinnerdatasingleuserwaiver`,
  PAIDSTATUSFIX_FILTERED: `${BASE_URL}/api/paidstatusfixsfiltered`,
  PAIDDATEFIX_FITLERED: `${BASE_URL}/api/paiddatefixfitlered`,
  ERAAMOUNT_FILEDEL: `${BASE_URL}/api/eraamountbulkdel`,
  TARGETPOINTSDELETE_BULK: `${BASE_URL}/api/targetpointsbulkdelete`,
  REVENUEAMOUNT_BULK: `${BASE_URL}/api/revenueamountbulk`,
  UPDATE_UNDO_FIELDNAME_CONFIRMLIST: `${BASE_URL}/api/undofieldnameconfirmlistfix`,
  FETCH_PAYRUNLIST_MONTHWISE: `${BASE_URL}/api/fetchpayrunlistdatamonthwise`,
  CONFIRM_FIXSALARYDATE: `${BASE_URL}/api/confirmfixsalarydate`,
  CONFIRM_FIXHOLDSALARYDATE: `${BASE_URL}/api/confirmfixholdsalarydate`,
  CONFIRM_FIXHOLDSALARY_LOGUPDATE: `${BASE_URL}/api/confirmfixholdsalarylogupdate`,
  USER_CLOCKIN_CLOCKOUT_STATUS_DOC_PREPARATION: `${BASE_URL}/api/userclockinclockoutstatusdocprep`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FOR_MONTH_LOP_CAL_FILTER_DOCPREP: `${BASE_URL}/api/userclockinclockoutstatusformontlopcalfilterdocprep`,
  PRODUCTION_DATE_FILTER: `${BASE_URL}/api/attendancedatefilter`,
  CLIENTSUPPORT: `${BASE_URL}/api/clientsupport`,
  CLIENTSUPPORT_OVERALLEXPORT: `${BASE_URL}/api/clientsupportoverallexport`,
  MANAGECLIENTDETAILS: `${BASE_URL}/api/manageclientdetailss`,
  MANAGECLIENTDETAILS_CREATE: `${BASE_URL}/api/manageclientdetails/new`,
  MANAGECLIENTDETAILS_SINGLE: `${BASE_URL}/api/manageclientdetails`,
  MANAGETICKETGROUPING: `${BASE_URL}/api/allticketgrouping`,
  MANAGETICKETGROUPING_CREATE: `${BASE_URL}/api/createticketgrouping`,
  MANAGETICKETGROUPING_SINGLE: `${BASE_URL}/api/singleticketgrouping`,
  SINGLE_VISITORS_UPDATEID: `${BASE_URL}/api/visitorsupdateid`,
  ALL_VISITORS_CHECKOUT: `${BASE_URL}/api/allvisitorscheckout`,
  CANDIDATESALLCOUNT: `${BASE_URL}/api/allcandidatescount`,
  USER_VISITOR_REGISTER: `${BASE_URL}/api/uservisitorregister`,
  ALL_VISITORS_FILTEREDID: `${BASE_URL}/api/visitorsfilteredid`,
  VISITORS_GETUNIQUEIDDATA: `${BASE_URL}/api/getuniquedatacandidates`,
  JOB_OPEN_CANDIDATE_REGISTER: `${BASE_URL}/api/jobopenregister`,
  MATTERMOST_TEAM_NAMES: `${BASE_URL}/api/getmattermostteamnames`,
  MATTERMOST_CHANNEL_NAMES: `${BASE_URL}/api/getmattermostchannelnames`,
  MATTERMOST_DEACTIVATE_USER: `${BASE_URL}/api/deactivatemattermostuser`,
  MATTERMOST_CREATE_TEAM: `${BASE_URL}/api/createmattermostteam`,
  MATTERMOST_DELETE_TEAM: `${BASE_URL}/api/deletemattermostteam`,
  MATTERMOST_RESTORE_TEAM: `${BASE_URL}/api/restoremattermostteam`,
  MATTERMOST_UPDATE_TEAM: `${BASE_URL}/api/updatemattermostteam`,
  MATTERMOST_ALL_TEAM_CHANNELS: `${BASE_URL}/api/getmattermostallteamchannels`,
  MATTERMOST_CREATE_CHANNEL: `${BASE_URL}/api/createmattermostchannel`,
  MATTERMOST_DELETE_CHANNEL: `${BASE_URL}/api/deletemattermostchannel`,
  MATTERMOST_RESTORE_CHANNEL: `${BASE_URL}/api/restoremattermostchannel`,
  MATTERMOST_UPDATE_CHANNEL: `${BASE_URL}/api/updatemattermostchannel`,
  ALL_SALARYSLAB_LIST_FILTER: `${BASE_URL}/api/salaryslablistfilter`,
  EMPLOYEENAMEOVERALLUPDATE: `${BASE_URL}/api/employeenameoverallupdate`,
  EMPLOYEECODEOVERALLUPDATE: `${BASE_URL}/api/employeecodeoverallupdate`,
  DELETEANYLOG: `${BASE_URL}/api/deleteanylog`,
  DELETESHIFTANYLOG: `${BASE_URL}/api/deleteshiftanylog`,
  UPDATEANYLOG: `${BASE_URL}/api/updateanylog`,
  TEMPLATEUSERSALL: `${BASE_URL}/api/getalltemplateusers`,
  PRODUCTION_INDIVIDUAL_EXCEL_OVERALL: `${BASE_URL}/api/productionindividualexceloverall`,
  APPROVEDPERMISSIONS: `${BASE_URL}/api/approvedpersmissions`,
  APPROVEDLEAVE: `${BASE_URL}/api/approvedleaves`,
  LOGINALLOTLOG: `${BASE_URL}/api/loginallotlog`,
  RESETCLIENTUSERID: `${BASE_URL}/api/resetclientuserid`,
  USER_PRODUCTION_DAY_SHIFT_FILTER: `${BASE_URL}/api/usersproductiondayshiftfilter`,
  TOOLTIPDESCRIPTIONS: `${BASE_URL}/api/tooltipdescription`,
  TOOLTIPDESCRIPTIONSAGGREGATION: `${BASE_URL}/api/tooltipdescriptionaggregation`,
  TOOLTIPDESCRIPTION_CREATE: `${BASE_URL}/api/tooltipdescription/new`,
  TOOLTIPDESCRIPTION_SINGLE: `${BASE_URL}/api/tooltipdescription`,
  GET_ALL_USER_EMPLOYEE: `${BASE_URL}/api/getalluseremployee`,
  INTERVIEWANSWERALLOT_OVERALL: ` ${BASE_URL}/api/overalledit/interviewanswerallot`,
  INTERVIEWSTATUSALLOT_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/interviewstatusallot`,
  INTERVIEWQUESTIONGROUPING_OVERALLEDIT: ` ${BASE_URL}/api/overalledit/interviewquestiongrouping`,
  PRODUCTION_DAY_CATEGORY_FILTER: `${BASE_URL}/api/productiondaycategoryidfilter`,
  TEMPLATECONTROLPANEL_SINGLEDELETE: `${BASE_URL}/api/templatecontrolpanelsingle`,
  USER_PRODUCTION_DAY_SHIFT_ATTENDANCE_FILTER: `${BASE_URL}/api/usersproductiondayshiftattendancefilter`,
  GET_ATTENDANCE_CONTROL_CRITERIA_LAST_INDEX: `${BASE_URL}/api/allattendancecontrolcriterialastindex`,
  CHECK_ISPRODDAY_CREATED_TEMP: `${BASE_URL}/api/checkisproddaycreatedtemp`,
  PRODUCTION_DAY_CATEGORY_FILTER_TEMP: `${BASE_URL}/api/productiondaycategoryidfiltertemp`,
  USERSHIFTCHECKBULK: `${BASE_URL}/api/user/shiftcheckbulk`,
  USERSHIFTCHECKOVERALLBULK: `${BASE_URL}/api/user/shiftcheckbulkdelete`,
  SHIFTBYCONDITION: `${BASE_URL}/api/shiftsbyconditions`,
  GETALLSHIFTGROUPSBYCONDITION: `${BASE_URL}/api/shiftgroupingsbycondition`,
  GETALLSHIFTGROUPSBULK: `${BASE_URL}/api/shiftgroupingsbulk`,
  NOTICE_HIERARCHY_LIST: `${BASE_URL}/api/noticehierarchylist`,
  HIERARCHY_REPORTING_TO: `${BASE_URL}/api/hierarchyreportingtousers`,
  ASSIGNINTERVIEW_FILTER: `${BASE_URL}/api/assigninterviewersfilter`,
  ASSIGNINTERVIEW_FILTER_MANUAL: `${BASE_URL}/api/assigninterviewersfiltermanual`,
  USER_STATUS_DEP_CHECK: `${BASE_URL}/api/usersstatusdepCheck`,
  USER_LOGIN_STATUS_ACTION: `${BASE_URL}/api/usersloginstatusaction`,
  USER_LOGIN_EXPIRED_STATUS: `${BASE_URL}/api/usersloginexpiredstatus`,
  RAISETICKET_FILTER_OVERALL: `${BASE_URL}/api/raiseticketfilteroverall`,
  USER_PROFILE: `${BASE_URL}/api/getallprofileimages`,
  CANDIDATE_MISSINGFIELDS: `${BASE_URL}/api/candidatesmissingfields`,
  REJECTED_CANDIDATES: `${BASE_URL}/api/rejectedcandidates`,
  CONTROL_SETTINGS_LAST_INDEX: `${BASE_URL}/api/getoverallsettingslastindex`,
  INDIVIDUAL_SETTINGS_LAST_INDEX: `${BASE_URL}/api/userindividuallastindex`,
  INDIVIDUAL_SETTINGS_COMPANY: `${BASE_URL}/api/userindividual`,
  GETALLCHECKLISTBYPAGINATIONNOTASSIGNED: `${BASE_URL}/api/mychecklistbypaginationnotassigned`,
  CONSOLIDATED_SALARY_RELEASE: `${BASE_URL}/api/consolidatedsalaryrelease`,
  CONSOLIDATED_SALARY_RELEASE_CREATE: `${BASE_URL}/api/consolidatedsalaryrelease/new`,
  CONSOLIDATED_SALARY_RELEASE_SINGLE: `${BASE_URL}/api/consolidatedsalaryrelease`,
  CONSOLIDATED_SALARY_RELEASE_MONTHWISE: `${BASE_URL}/api/consolidatedsalaryreleasemonthwise`,
  HOLD_SALARY_RELEASE: `${BASE_URL}/api/holdsalaryrelease`,
  HOLD_SALARY_RELEASE_CREATE: `${BASE_URL}/api/holdsalaryrelease/new`,
  HOLD_SALARY_RELEASE_SINGLE: `${BASE_URL}/api/holdsalaryrelease`,
  FETCH_HOLD_SALARY_CONFIRMLIST: `${BASE_URL}/api/holdsalaryyettoconfirmlist`,
  CONFIRM_FIX_HOLDSALARYDATE: `${BASE_URL}/api/confirmfixholdsalarydate`,
  CONFIRM_FIXHOLDSALARY_REJECT: `${BASE_URL}/api/fixholdsalaryreject`,
  UPDATE_REMOVE_REJECT: `${BASE_URL}/api/updateRemoveReject`,
  CONFIRM_FIX_HOLDRELEASE_SAVE: `${BASE_URL}/api/confirmholdreleasesave`,
  CONFIRM_CONSOLIDATED_RELEASE_SAVE: `${BASE_URL}/api/confirmconsolidatedreleasesave`,
  BANK_RELEASE: `${BASE_URL}/api/bankreleases`,
  BANK_RELEASE_CREATE: `${BASE_URL}/api/bankrelease/new`,
  BANK_RELEASE_SINGLE: `${BASE_URL}/api/bankrelease`,
  BANK_RELEASE_LIMITED: `${BASE_URL}/api/bankreleaselimited`,
  CHECKWITH_BANK_RELEASE: `${BASE_URL}/api/checkwithbankrelease`,
  PRODUCTION_CATEGORY_CHECK_PRODUPLOAD: `${BASE_URL}/api/checkcategoryforprodupload`,
  PRODUCTION_SUBCATEGORY_CHECK_PRODUPLOAD: `${BASE_URL}/api/checksubcategoryforprodupload`,
  PRODUCTION_UNITRATE_CHECK_PRODUPLOAD: `${BASE_URL}/api/checkunitrateforprodupload`,
  PAIDDATEFIX_UPDATE: `${BASE_URL}/api/paiddatefixupdatesingle`,
  PENDING_TASK_COUNT: `${BASE_URL}/api/getpendingtaskcount`,
  FETCH_BANKRELEASE_PAYRUNLIST_MONTHWISE: `${BASE_URL}/api/fetchbankreleasepayrunlistmonthwise`,
  MYCHECKLISTVIEW: `${BASE_URL}/api/checklistview`,
  PENDINGINTERVIEWCHECKLIST: `${BASE_URL}/api/interviewpendingchecklist`,
  CHECKLISTUSERDATAS: `${BASE_URL}/api/fetchuserdatas`,
  PENDINGINTERVIEWCHECKLISTLEAVE: `${BASE_URL}/api/interviewpendingchecklistleave`,
  PENDINGINTERVIEWCHECKLISTPERMISSION: `${BASE_URL}/api/interviewpendingchecklistpermission`,
  CANDIDATEBYIDFORDOCUMENT: `${BASE_URL}/api/candidatefordocument`,
  USER_CLOCKIN_CLOCKOUT_STATUS_CHECKLOGIN: `${BASE_URL}/api/userclockinclockoutstatusloginstatuscheck`,
  REPORTINGTO_PROCESS_USER_HIERARCHY_RELATION: `${BASE_URL}/api/reportingtouserhierarchyrelation`,
  REPORTINGTO_DESIGNATION_USER_HIERARCHY_RELATION: `${BASE_URL}/api/reportingtodesignationuserhierarchyrelation`,
  HIERARCHY_DEISGNATIONLOG_RELATION: `${BASE_URL}/api/hierarchydesignationlogrelation`,
  HIERARCHY_PROCESSALOOT_TEAM_RELATION: `${BASE_URL}/api/hierarchyprocessteamrelation`,
  HIRERARCHI_GROU_LIST_INDIVIDUAL: `${BASE_URL}/api/hirerarchigroupindividual`,
  USER_TRAINING_RUNNING_STATUS: `${BASE_URL}/api/traininguserrunningstatus`,
  GET_EMPLOYEE_PRODUCTION_LAST_THREEMONHTS: `${BASE_URL}/api/getemployeeproductionlastthreemonths`,
  GET_PRODUCTIONDAY_LAST_THREEMONTHS: `${BASE_URL}/api/getempproductiondaylastthreemonths`,
  GET_EMPLOYEE_PRODUCTION_LAST_THREEMONHTS_TEMP: `${BASE_URL}/api/getemployeeproductionlastthreemonthstemp`,
  GET_PRODUCTIONDAY_LAST_THREEMONTHS_TEMP: `${BASE_URL}/api/getempproductiondaylastthreemonthstemp`,
  EMPLOYEE_MISSINGFIELDS: `${BASE_URL}/api/getemployeemissingfields`,
  USER_CREDENTIALS: `${BASE_URL}/api/usercredentials`,
  TEMPORARY_LOGIN_STATUS: `${BASE_URL}/api/temporaryloginstatus`,
  GET_WEEOFF_DAYS_FORUSER: `${BASE_URL}/api/getuserweekoffdays`,
  GET_PRODUCTIONUPDATE_CURRMONTH: `${BASE_URL}/api/getallproductionsbyuserforcurrmonth`,
  GET_PRODUCTIONUPDATE_CURRMONTH_VIEW: `${BASE_URL}/api/getallproductionsbyuserforcurrmonthview`,
  BRANCHOVERALLUPDATE: `${BASE_URL}/api/branchoverallupdate`,
  BRANCHALLCHECK: `${BASE_URL}/api/branchAllCheck`,
  UNITOVERALLUPDATE: `${BASE_URL}/api/unitoverallupdate`,
  OVERALLUNITCHECK: `${BASE_URL}/api/unitoverallcheck`,
  OVERALLAREACHECK: `${BASE_URL}/api/overallareascheck`,
  AREAOVERALLUPDATE: `${BASE_URL}/api/overallareasupdate`,
  LOCATIONOVERALLUPDATE: `${BASE_URL}/api/locationoverallupdate`,
  OVERALLLOCATIONCHECK: `${BASE_URL}/api/locationoverallcheck`,
  FLOOROVERALLUPDATE: `${BASE_URL}/api/flooroverallupdate`,
  FLOOROVERALLCHECK: `${BASE_URL}/api/flooroverallcheck`,
  DYNAMICUSER_CONTROLLER: `${BASE_URL}/api/dynamicqueryuserapi`,
  LOGIN_ALLOT_HIERARCHY_LIST: `${BASE_URL}/api/productionloginallothierarchyfilter`,
  TEAM_DESIGNATION_LOG: `${BASE_URL}/api/teamsdesignationlog`,
  ACTIVEALL_PASSWORD_ACCESS: `${BASE_URL}/api/activeallpasswordsaccess`,
  PASSWORD_ACCESS_CONTROL: `${BASE_URL}/api/allpasswordsaccess`,
  IP_MASTER_ACCESS: `${BASE_URL}/api/ipmastersaccess`,
  ASSET_DATA_FILTER_ACCESS: `${BASE_URL}/api/assetdetailsfilteraccess`,
  ASSET_MATERIALIP_LIMITED_ACCESS: `${BASE_URL}/api/assetmaterialipslimitedaccess`,
  ASSET_WORKSTATION_GRP_ACCESS: `${BASE_URL}/api/assetworkgrpsaccess`,
  WORKSTATION_ACCESS: `${BASE_URL}/api/workstationsaccess`,
  ASSET_DAMAGED_ACCESS: `${BASE_URL}/api/damagedassetaccess`,
  ASSET_REPAIR_ACCESS: `${BASE_URL}/api/repairedassetaccess`,
  MAINTENANCE_ACCESS: `${BASE_URL}/api/maintentancesaccess`,
  EMPLOYEE_ASSET_ACCESS: `${BASE_URL}/api/employeeassetsaccess`,
  TASK_MAINTENANCE_NON_SCHEDULING_ACCESS: `${BASE_URL}/api/taskmaintenancenonschedulegroupingsaccess`,
  STOCK_MANAGE_ACCESS: `${BASE_URL}/api/stockmanagesaccess`,
  STOCK_ACCESS: `${BASE_URL}/api/stocksaccess`,
  MANUAL_STOCK_ACCESS: `${BASE_URL}/api/manualstocksaccess`,
  ADDTOPRINT_QUEUE_LIMITED_ACCESS: `${BASE_URL}/api/addtoprintqueueslimitaccess`,
  ADDTOPRINT_PRINT_LIMITED_ACCESS: `${BASE_URL}/api/addtoprintqueueslimitprintaccess`,
  STOCK_MANAGE_FILTER: `${BASE_URL}/api/stockfilteraccess`,
  GET_PRODUCTIONUPDATE_CURRMONTH_TEMP: `${BASE_URL}/api/getallproductionsbyuserforcurrmonthtemp`,
  GET_PRODUCTIONUPDATE_CURRMONTH_VIEW_TEMP: `${BASE_URL}/api/getallproductionsbyuserforcurrmonthviewtemp`,
  COMPANYACCESS: `${BASE_URL}/api/companyaccess`,
  USERSEXCELDATAASSIGNBRANCH: `${BASE_URL}/api/usersexceldataassignbranch`,
  TARGETPOINTS_LIMITEDASSIGNBRANCH: `${BASE_URL}/api/targetpointslimitedassignbranch`,
  ERAAMOUNTSASSIGNBRANCH: `${BASE_URL}/api/eraamountsassignbranch`,
  REVENUEAMOUNTSASSIGNBRANCH: `${BASE_URL}/api/revenueamountassignbranch`,
  ALL_PROCESS_AND_TEAM_ASSIGNBRANCH: `${BASE_URL}/api/processteamsassignbranch`,
  ACPOINTCALCULATIONASSIGNBRANCH: `${BASE_URL}/api/acpointcalculationassignbranch`,
  MINIMUMPOINTSACCESSBRANCH: `${BASE_URL}/api/minimumpointssaccessbranch`,
  SALARYSLAB_LIMITEDASSIGNBRANCH: `${BASE_URL}/api/salaryslablimitedassignbranch`,
  APPLYLEAVE_APPROVEDASSIGNBRANCH: `${BASE_URL}/api/applyleavesapprovedassignbranch`,
  CATEGORYPROCESSMAPASSIGNBRANCH: `${BASE_URL}/api/categoryprocessmapsassignbranch`,
  PAYRUNCONTROLBYASSIGNBRANCH: `${BASE_URL}/api/payruncontrolsbyassignbranch`,
  ALL_PROFFESIONALTAXMASTERBYASSIGNBRANCH: `${BASE_URL}/api/professionaltaxmastersbyassignbranch`,
  PROFFESIONALTAXMASTER_SORTBYASSIGNBRANCH: `${BASE_URL}/api/professionaltaxmastersortbyassignbranch`,
  ADVANCEBYASSIGNBRANCH: `${BASE_URL}/api/advancebyassignbranch`,
  LOANBYASSIGNBRANCH: `${BASE_URL}/api/loanbyassignbrach`,
  GET_OVERALL_SETTINGSASSIGNBRANCH: `${BASE_URL}/api/getoverallsettingsassignbranch`,
  GET_ATTENDANCE_CONTROL_CRITERIAASSIGNBRANCH: `${BASE_URL}/api/allattendancecontrolcriteriaassignbranch`,
  GET_AUTOLOGOUTASSIGNBRANCH: `${BASE_URL}/api/allautologoutassignbranch`,
  MYFIELDVERIFICATIONASSIGNBRANCH: `${BASE_URL}/api/myfieldverificationsassignbranch`,
  USERASSIGNBRANCH: `${BASE_URL}/api/usersassignuserbranch`,
  MYVERIFICATIONASSIGNEDBRANCH: `${BASE_URL}/api/myverificationsassignbranchuser`,
  USERSEXCELDATAASSIGNBRANCHSALARYSLAB: `${BASE_URL}/api/usersexceldatabyassignbranch`,
  DOCUMENT_PREPARATION_AUTOID: `${BASE_URL}/api/documentpreparationautoid`,
  ASSIGNINTERVIEW_FILTER_ISSUING_AUTHORITY: `${BASE_URL}/api/assigninterviewersfilterissuingauthority`,
  NOTASSIGN_HIERARCHY_DEISGNATIONLOG: `${BASE_URL}/api/notassignhierarchydata`,
  NOTASSIGN_HIERARCHY_DEISGNATIONLOG_BACKEND: `${BASE_URL}/api/notassignhierarchylistdatabackend`,
  NOTASSIGN_HIERARCHY_DEISGNATIONLOG_FILTERED: `${BASE_URL}/api/notassignhierarchydatafiltered`,
  CREATE_COMPANY_DOCUMENT_PREPARATION: `${BASE_URL}/api/companydocumentpreparation/new`,
  ALL_COMPANY_DOCUMENTPREPARATION: `${BASE_URL}/api/companydocumentpreparations`,
  ACCESSIBLEBRANCHALL_COMPANY_DOCUMENTPREPARATION: `${BASE_URL}/api/accessiblebranchcompanydocumentpreparations`,
  SINGLE_COMPANY_DOCUMENTPREPARATION: `${BASE_URL}/api/companydocumentpreparation`,
  COMPANY_DOCUMENT_PREPARATION_CODES: `${BASE_URL}/api/companydocumentpreparationcodes`,
  COMPANY_DOCUMENT_PREPARATION_AUTOID: `${BASE_URL}/api/companydocumentpreparationautoid`,
  BANK_RELEASE_CLOSED: `${BASE_URL}/api/bankreleaseclosed`,
  UNASSIGNEDBRANCH: ` ${BASE_URL}/api/unassignbranches`,
  GETFILTEREUSERDATALONGABSEND: `${BASE_URL}/api/getfilteralluserdatalongabsend`,
  RAISETICKET_REPORT: `${BASE_URL}/api/raiseticketsreports`,
  RAISETICKET_FILTER: `${BASE_URL}/api/raiseticketfilter`,
  EBREADINGDETAIL_LIST: `${BASE_URL}/api/ebreadingdetailslist`,
  PRODUCTION_TEMP_ATTENDANCES: `${BASE_URL}/api/productiontempattendancesfilter`,
  DESIGNATIONCONTROLGROUPINGGROUPING: `${BASE_URL}/api/designationandcontrolgroupings`,
  DESIGNATIONCONTROLGROUPINGGROUPING_CREATE: `${BASE_URL}/api/designationandcontrolgrouping/new`,
  DESIGNATIONCONTROLGROUPINGGROUPING_SINGLE: `${BASE_URL}/api/designationandcontrolgrouping`,
  USERWITHACCESSIBLEBRANCH: `${BASE_URL}/api/userwithaccessiblebranch`,
  USER_X_EMPLOYEES: `${BASE_URL}/api/userswithxemployee`,
  CATEGORYMASTER: `${BASE_URL}/api/themecategorymasters`,
  CATEGORYMASTERS_CREATE: `${BASE_URL}/api/themecategorymaster/new`,
  CATEGORYMASTERS_SINGLE: `${BASE_URL}/api/themecategorymaster`,
  OVERALL_CATEGORYMASTER: `${BASE_URL}/api/overallcategorymaster`,
  OVERALL_POSTERCATEGORY_BULKDELETE: `${BASE_URL}/api/overallcategorymasterbulkdelete`,
  SUBCATEGORYMASTER: `${BASE_URL}/api/subcategorymasters`,
  SUBCATEGORYMASTER_CREATE: `${BASE_URL}/api/subcategorymaster/new`,
  SUBCATEGORYMASTER_SINGLE: ` ${BASE_URL}/api/subcategorymaster`,
  OVERALL_SUBCATEGORYMASTER: `${BASE_URL}/api/overallsubcategorymaster`,
  CATEGROYTHEMEGROUPING: `${BASE_URL}/api/categorythemegroupings`,
  CATEGROYTHEMEGROUPING_CREATE: `${BASE_URL}/api/categorythemegrouping/new`,
  CATEGROYTHEMEGROUPING_SINGLE: ` ${BASE_URL}/api/categorythemegrouping`,
  OVERALL_CATEGORYTHEMEGROUPING: ` ${BASE_URL}/api/categorythemegroupingoverall`,
  POSTERGENERATE: `${BASE_URL}/api/postergenerates`,
  POSTERGENERATE_CREATE: `${BASE_URL}/api/postergenerate/new`,
  POSTERGENERATE_SINGLE: ` ${BASE_URL}/api/postergenerate`,
  POSTERGENERATEGROUP: ` ${BASE_URL}/api/postergenerategroup`,
  GET_WEEOFF_DAYS_FORUSER_ATTENDANCE: `${BASE_URL}/api/getuserweekoffdaysattendance`,
  PAIDSTATUSFIX_FILTEREDDATA: `${BASE_URL}/api/filterpaidstatusfixdatas`,
  GET_DAY_POINTS_LIMITED_DATE_ONLY: `${BASE_URL}/api/daypointslimiteddateonly`,
  GET_DAY_POINTS_TEMP_DATE_ONLY: `${BASE_URL}/api/daypointstempdateonly`,
  CHECKLISTMODULE: ` ${BASE_URL}/api/checklistmodule`,
  CHECKLISTMODULE_CREATE: ` ${BASE_URL}/api/checklistmodule/new`,
  CHECKLISTMODULE_SINGLE: `${BASE_URL}/api/checklistmodule`,
  GETALLCHECKLISTMODULEBYPAGINATION: `${BASE_URL}/api/checklistmodulebypagination`,
  CREATEEXITINTERVIEWQUESTION: `${BASE_URL}/api/exitinterviewquestion/new`,
  ALLEXITINTERVIEWQUESTIONS: `${BASE_URL}/api/exitinterviewquestions`,
  SINGLEEXITINTERVIEWQUESTION: `${BASE_URL}/api/exitinterviewquestion`,
  EXITINTERVIEWTESTMASTER: `${BASE_URL}/api/exitinterviewtestmaster`,
  EXITINTERVIEWTESTMASTER_CREATE: `${BASE_URL}/api/exitinterviewtestmaster/new`,
  EXITINTERVIEWTESTMASTER_SINGLE: `${BASE_URL}/api/exitinterviewtestmaster`,
  EXITINTERVIEW_LOGIN: `${BASE_URL}/api/exitinterviewlogin`,
  ALLNOTINEMPLOYEES: `${BASE_URL}/api/allnotinemployees`,
  NOTICEPERIODAPPLYBYASSIGNBRANCH: `${BASE_URL}/api/noticeperiodappliesbyassignbranch`,
  TRAINING_USER_PANEL_ONLINE_TEST: `${BASE_URL}/api/traininguserpanelonlinetest`,
  TRAINING_USER_PANEL_ONLINE_TEST_BULK_DELETE: `${BASE_URL}/api/traininguserpanelonlinetestbulkdelete`,
  PENDINGLONGABSENTCHECKLIST: `${BASE_URL}/api/pendingchecklistlongabsent`,
  USER_SHIFTALLOT_DELETE: `${BASE_URL}/api/usershiftallotsdelete`,
  ATTENDANCE_STATUS_OVERALL_DELETE: `${BASE_URL}/api/attendancemodestatusoveralldelete`,
  ATTENDANCE_STATUS_OVERALL_EDIT: `${BASE_URL}/api/attendancemodestatusoveralledit`,
  EXPENSECATEGORY_AUTOID: `${BASE_URL}/api/expensecategoriesautoid`,
  EXPENSE_AUTOID: `${BASE_URL}/api/expensesautoid`,
  STOCKCATEGORY_AUTOID: `${BASE_URL}/api/stockcategoryautoid`,
  VENDOR_AUTOID: `${BASE_URL}/api/vendordetailsautoid`,
  ALL_OTHERTASKCOMPANY_SORT: `${BASE_URL}/api/othertasksortcompany`,
  ALL_OTHERTASKEMPLOYEE_SORT: `${BASE_URL}/api/othertasksortemployee`,
  DESIGNATIONOVERALLCHECK: `${BASE_URL}/api/desigoverallcheck`,
  DESIGNATIONBULKCHECK: `${BASE_URL}/api/designationbulkcheck`,
  DESIGNATIONOVERALLUPDATE: `${BASE_URL}/api/desigoverallupdate`,
  DEPARTMENTOVERALLUPDATE: `${BASE_URL}/api/departoverallupdate`,
  DEPARTMENTOVERALLCHECK: `${BASE_URL}/api/departoverallcheck`,
  TEAMOVERALLUPDATE: `${BASE_URL}/api/overallupdatecheck`,
  TEAMOVERALLCHECK: `${BASE_URL}/api/overalldelcheck`,
  GET_UNIQID_FROM_DATE_PRODUPLOAD: `${BASE_URL}/api/getuniqidfromdateprodupload`,
  GET_UNIQID_FROM_DATE_PRODUPLOAD_TEMP: `${BASE_URL}/api/getuniqidfromdateproduploadtemp`,
  GET_PAYRUN_BULKDATA_EXCEL: `${BASE_URL}/api/getpayrunbulkdataexcel`,
  DELETE_PAYRUN_BULKDATA: `${BASE_URL}/api/deletepayrunbulkdata`,
  PRODUCTION_MANUAL_FILTER: `${BASE_URL}/api/productionmanualuploadfilter`,
  EMPLOYEE_NAMES_BASES_ON_STATUS_PAYRUN: `${BASE_URL}/api/employeenamesstatuswisepayrun`,
  FILTER_PAY_RUN_REPORT_EMPLOYEE_NAMES: `${BASE_URL}/api/filterpayrunemployeenames`,
  FILTER_PAY_RUN_REPORT_DATA: `${BASE_URL}/api/filterpayrunreportdata`,
  FLAG_COUNT_SORT: `${BASE_URL}/api/othertasksortflag`,
  FLAG_COUNT_SORT_VIEW: `${BASE_URL}/api/othertasksortview`,
  GET_DAYPOINT_ID_BYDATE: `${BASE_URL}/api/getdaypointidbydate`,
  GET_DAYPOINT_ID_BYDATE_TEMP: `${BASE_URL}/api/getdaypointidbydatetemp`,
  TEAMSASSIGNBRANCH: `${BASE_URL}/api/teamsassignbranch`,
  SKIPPED_RAISEPROBLEMSTATUS: `${BASE_URL}/api/skippedraiseproblemstatus`,
  USER_FILTER_FOR_SHIFTADJ_PAGE: `${BASE_URL}/api/usershiftadjustmentfilter`,
  USER_FOR_ALL_ATTENDANCE_PAGE: `${BASE_URL}/api/userforallattendancefilter`,
  USER_FOR_ALL_ATTENDANCE_PAGE_FILTER: `${BASE_URL}/api/userforallattendancefilterforshiftadjfilterpage`,
  USER_FOR_ALL_ATTENDANCE_PAGE_ACCESSBRANCHWISELIST: `${BASE_URL}/api/userforallattendancefilterforaccessbranchwiselist`,
  GETUSE_TOTALSHIFT_DAYS: `${BASE_URL}/api/getalluserstotalshiftdays`,
  CHECKPAIDDATEFIX: `${BASE_URL}/api/checkpaiddatefix`,
  OVERALL_EDITPAIDDATEFIX: `${BASE_URL}/api/getoveralleditpaiddatefix`,
  CHECKPAIDSTATUS_PAYRUN: `${BASE_URL}/api/checkpaidstatuspayrun`,
  OVERALL_EDITPAYRUNLIST: `${BASE_URL}/api/getoveralleditpayrunlist`,
  LISTPAGEACCESSMODES: `${BASE_URL}/api/listpageaccessmode`,
  LISTPAGEACCESSMODESAGGREGATION: `${BASE_URL}/api/listpageaccessmodeaggregation`,
  LISTPAGEACCESSMODE_CREATE: `${BASE_URL}/api/listpageaccessmode/new`,
  LISTPAGEACCESSMODE_SINGLE: `${BASE_URL}/api/listpageaccessmode`,
  REPORTINGHEADER: `${BASE_URL}/api/reportingheaders`,
  REPORTINGHEADER_CREATE: `${BASE_URL}/api/reportingheader/new`,
  REPORTINGHEADER_SINGLE: `${BASE_URL}/api/reportingheader`,
  LONGABSENTRESTRICTION_HIERARCHYLIST: `${BASE_URL}/api/longabsentrestrictionhierarchylist`,
  POSTERMESSAGESETTINGALL: `${BASE_URL}/api/postermessagesetting`,
  POSTERMESSAGESETTING_CREATE: `${BASE_URL}/api/postermessagesetting/new`,
  POSTERMESSAGESETTING_SINGLE: `${BASE_URL}/api/postermessagesetting`,
  OVERALL_POSTERMESSAGESETTINGOVERALL: `${BASE_URL}/api/postermessagesettingoverall`,
  FOOTERMESSAGESETTINGALL: `${BASE_URL}/api/footermessage`,
  FOOTERMESSAGESETTING_CREATE: `${BASE_URL}/api/footermessage/new`,
  FOOTERMESSAGESETTING_SINGLE: `${BASE_URL}/api/footermessage`,
  POSTERGENERATEGROUP_GETBIRTHDAY: ` ${BASE_URL}/api/postergenerategroupgetbirthday`,
  POSTERGENERATEGROUP_GETWEDDINGANNIVERSARY: ` ${BASE_URL}/api/postergenerategroupgetweddingannivesary`,
  ALL_CLIENTUSERID_REPORT_LIMITED_IDSONLY: `${BASE_URL}/api/clientuseridsreportidsonly`,
  SUBCATEGORYPROD_REPORT_LIMITED: `${BASE_URL}/api/getlistsubcategoryprodlimitedreport`,
  USER_POSTERGENERATE: `${BASE_URL}/api/userspostergenerate`,
  PROJECTMASTER_INDIVIDUAL: `${BASE_URL}/api/projectmasterindividual`,
  FACEDETECTLOGINMODEL: `${BASE_URL}/api/weights`,
  PAIDDATEFIX_FUTUREDATEONLY: `${BASE_URL}/api/paiddatefixedfuturedatesonly`,
  TIMEPOINTS_LIMITED_UPLOAD: `${BASE_URL}/api/timepointsuploadlimited`,
  DUPLICATEFACEDETECT: `${BASE_URL}/api/duplicatefacecheck`,
  PAYSLIP_DOCUMENT_PREPARATION: `${BASE_URL}/api/payslipdocuments`,
  PAYSLIP_DOCUMENT_PREPARATION_CREATE: `${BASE_URL}/api/payslipdocument/new`,
  PAYSLIP_DOCUMENT_PREPARATION_SINGLE: `${BASE_URL}/api/payslipdocument`,
  PAYSLIP_EMPLOYEE_NAMES: `${BASE_URL}/api/payslipusernames`,
  FILTERED_EMPLOYEE_NAMES_PAYSLIP: `${BASE_URL}/api/filteredempnamespayslip`,
  PAYSLIP_SIGNATURE_SEAL: `${BASE_URL}/api/payslipsealandsignature`,
  PAYSLIP_GET_SINGLE_SIGNATURE_SEAL: `${BASE_URL}/api/payslipgetsinglesealandsignature`,
  PAYSLIP_DOCUMENT_MAIL: `${BASE_URL}/api/payslipdocumentmail`,
  PAYSLIP_RELATED_PAYRUNDATA: `${BASE_URL}/api/paysliprelatedpayrundatas`,
  HIERARCHY_BASED_USER_WORKSTATION: `${BASE_URL}/api/hierarchybasedemployeeworkstationhira`,
  HIERARCHY_BASED_USER_WORKSTATION_DEFAULT: `${BASE_URL}/api/hierarchybasedemployeeworkstation`,
  LEAVE_HOME: `${BASE_URL}/api/applyleaveshome`,
  LEAVE_HOME_LIST: `${BASE_URL}/api/applyleaveshomelist`,
  EMPLOYEE_HOME_COUNT: `${BASE_URL}/api/userwithstatushomecount`,
  RELEIVE_HOME_COUNT: `${BASE_URL}/api/userhomecountrelieve`,
  NOTCLOCKIN_HOME_COUNT: `${BASE_URL}/api/userhomecountnotclockin`,
  NOTCLOCKIN_HOME_COUNT_LIST: `${BASE_URL}/api/userhomecountnotclockinlist`,
  ALL_VISITORSFORCANDIDATE: `${BASE_URL}/api/allvisitorsforcandidate`,
  CANDIDATES_ALL_COUNT_HOME: `${BASE_URL}/api/allcandidatescounthome`,
  CANDIDATES_ALL_UPCOMING: `${BASE_URL}/api/allcandidatesupcominginterview`,
  DUPLICATECANDIDATEFACEDETECT: `${BASE_URL}/api/duplicatecandidatefacecheck`,
  OVERALL_EDITCOMPANYDOMAINUSER: `${BASE_URL}/api/getoveralleditcompanydomainuser`,
  CHECKCOMPANYDOMAINUSER: `${BASE_URL}/api/checkcompanydomainuser`,
  CHECK_ISDAYPOINT_CREATED: `${BASE_URL}/api/checkdaypointiscreated`,
  CHECK_ISDAYPOINT_CREATED_TEMP: `${BASE_URL}/api/checkdaypointiscreatedtemp`,
  ALL_VISITORS_REGISTER: `${BASE_URL}/api/allvisitorsregister`,
  BULK_UPDATE_UNDO_FIELDNAME: `${BASE_URL}/api/updatefieldundonamebulk`,
  BULK_UPDATE_UNDO_FIELDNAME_TEMP: `${BASE_URL}/api/updatefieldundonamebulktemp`,
  CHECKREPORTINGHEADER: `${BASE_URL}/api/checkreportingheader`,
  OVERALL_EDITREPORTINGHEADER: `${BASE_URL}/api/getoveralleditreportingheader`,
  PRODUCTION_INDIVIDUAL_ONPROGRESS: `${BASE_URL}/api/onprogressindividuallimited`,
  PRODUCTION_INDIVIDUAL_COMPLETED: `${BASE_URL}/api/complatedindividuallimited`,
  PRODUCTION_INDIVIDUAL_PENDING: `${BASE_URL}/api/pendingindividuallimited`,
  PRODUCTION_INDIVIDUAL_MANUALSTATUS: `${BASE_URL}/api/manualstatusviceindividualsort`,
  PRODUCTION_INDIVIDUALMANUAL_EXCEL_OVERALL: `${BASE_URL}/api/manualstatusindividualexceloverall`,
  PRODUCTION_DAYPOINTS_CHECK_PRODDAY_DATE: `${BASE_URL}/api/getproddateusingdaypoints`,
  ALL_MIKROTIKMASTER: `${BASE_URL}/api/getallmikrotikmaster`,
  CREATE_MIKROTIKMASTER: `${BASE_URL}/api/createmikrotikmaster`,
  SINGLE_MIKROTIKMASTER: `${BASE_URL}/api/singlemikrotikmaster`,
  GETALL_MIKROTIK_INTERFACES: `${BASE_URL}/api/getallmikrotikinterfaces`,
  GET_MIKROTIK_SECRETS: `${BASE_URL}/api/getmikrotiksecrets`,
  CREATE_MIKROTIK_SECRET: `${BASE_URL}/api/createmikrotiksecret`,
  DELETE_MIKROTIK_SECRET: `${BASE_URL}/api/deletemikrotiksecret`,
  UPDATE_MIKROTIK_SECRET: `${BASE_URL}/api/updatemikrotiksecret`,
  GET_MIKROTIK_PROFILES: `${BASE_URL}/api/getmikrotikprofiles`,
  GET_MIKROTIK_IPPOOL: `${BASE_URL}/api/getmikrotikippool`,
  CREATE_MIKROTIK_PROFILE: `${BASE_URL}/api/createmikrotikprofile`,
  UPDATE_MIKROTIK_PROFILE: `${BASE_URL}/api/updatemikrotikprofile`,
  DELETE_MIKROTIK_PROFILE: `${BASE_URL}/api/deletemikrotikprofile`,
  GET_MIKROTIK_LOGS: `${BASE_URL}/api/getallmikrotiklogs`,
  DYNAMICEVENT_CONTROLLER: `${BASE_URL}/api/queryeventsonpc`,
  EVENTDROPDOWN_CONTROLLER: `${BASE_URL}/api/alleventstorage`,
  USER_IDLETIME_OVERALL: `${BASE_URL}/api/idletimes`,
  USER_IDLETIME_CREATE: `${BASE_URL}/api/idletime/new`,
  USER_IDLETIME_SINGLE: `${BASE_URL}/api/idletime`,
  USER_IDLETIME_FILTER: `${BASE_URL}/api/idletimefilter`,
  USER_IDLETIME_UPDATE_ENDTIME: `${BASE_URL}/api/idleendtimeupdate`,
  USER_IDLETIME_VIEW_TIME: `${BASE_URL}/api/idletimeview`,
  USER_IDLETIME_FOR_LOGINPAGE: `${BASE_URL}/api/idletimeforloginpage`,
  USER_PCUSERNAMEUPDATE: `${BASE_URL}/api/pcnameuser`,
  EVENTSCREDENTIALSUPDATE: `${BASE_URL}/api/updatemultipleusers`,
  GET_MIKROTIK_L2TPSERVER: `${BASE_URL}/api/getmikrotikl2tpserver`,
  GET_MIKROTIK_PPTPSERVER: `${BASE_URL}/api/getmikrotikpptpserver`,
  GET_MIKROTIK_ACTIVE_CONNECTIONS: `${BASE_URL}/api/getmikrotikactiveconnections`,
  REMOVE_MIKROTIK_ACTIVE_CONNECTION: `${BASE_URL}/api/removemikrotikactiveconnection`,
  CATEGORYPROD_LIMITED_UPLOAD: `${BASE_URL}/api/categorylimitedkeyword`,
  PRODUCTION_INDIVIDUAL_HIERARCHYFILTER_HOME: `${BASE_URL}/api/productionhierarchyfilterhome`,
  APPLYLEAVE_FILTERED_HOME_COUNT: `${BASE_URL}/api/applyleavesfilterhomecount`,
  PERMISSIONS_HOME: `${BASE_URL}/api/persmissionshome`,
  LONG_ABSENT_HOME: `${BASE_URL}/api/longabsentrestrictionhierarchylistHome`,
  ADVANCE_HOME: `${BASE_URL}/api/advancebyassignbranchhome`,
  LOAN_HOME: `${BASE_URL}/api/loanbyassignbrachhome`,
  ADVANCE_HOME_LIST: `${BASE_URL}/api/advancebyassignbranchhomelist`,
  DAY_POINTS_FILTER_HOME: `${BASE_URL}/api/daypointsfilterhome`,
  TEMP_DAY_POINTS_FILTER_HOME: `${BASE_URL}/api/temppointsfilterhome`,
  ACCURACY_HOME: `${BASE_URL}/api/acheivedaccuracyindividualhome`,
  MINIMUM_HOME: `${BASE_URL}/api/getalluserstotalshiftdayshome`,
  USEREXCELDATA_HOME: `${BASE_URL}/api/usersexceldataassignbranchhome`,
  APPLY_LEAVE_HOME: `${BASE_URL}/api/applyleavesapprovedassignbranchhome`,
  EMP_DISTRIBUTION_HOME: `${BASE_URL}/api/employeeassetsaccesshome`,
  ASSET_HOME: `${BASE_URL}/api/assetdetailsfilteraccesshome`,
  ASSET_DAMAGE_HOME: `${BASE_URL}/api/assetdetailsdamagehome`,
  ASSET_REPAIR_HOME: `${BASE_URL}/api/assetdetailsrepairhome`,
  EXPENSES_HOME: `${BASE_URL}/api/allexpenseshome`,
  INCOME_HOME: `${BASE_URL}/api/incomeshome`,
  ASSIGNED_HOME: `${BASE_URL}/api/taskforassignedhome`,
  RAISE_TICKET_HOME: `${BASE_URL}/api/raiseticketindividualfilterhome`,
  SALARYSLAB_LIMITEDASSIGNBRANCH_HOME: `${BASE_URL}/api/salaryslablimitedassignbranchhome`,
  REVENUEAMOUNTSLIMITED_HOME: `${BASE_URL}/api/revenueamountlimitedhome`,
  MAINTENANCE_HOME: `${BASE_URL}/api/sortedtaskmaintenanceforusershome`,
  MAINTENANCE_HOME_LIST: `${BASE_URL}/api/sortedtaskmaintenanceforusershomelist`,
  PRODUCTION_UPLOAD_FILENAMEONLY_BULKDOWNLOAD: `${BASE_URL}/api/productionuploadfilenameonlybulkdownload`,
  PRODUCTION_TEMP_UPLOAD_FILENAMEONLY_BULKDOWNLOAD: `${BASE_URL}/api/productiontempuploadallfilenameonlybulkdownload`,
  DYNAMIC_USERACTIVITY_CONTROLLER: `${BASE_URL}/api/dynamicqueryuseractivitycontroller`,
  DYNAMIC_USERACTIVITY_SCREENSHOT_CONTROLLER: `${BASE_URL}/api/dynamicqueryuseractivityscreeenshotcontroller`,
  VEIW_USERACTIVITY_SCREENSHOT: `${BASE_URL}/api/useractivity`,
  USERACTIVITYDROPDOWN_CONTROLLER: `${BASE_URL}/api/getalluseractivitystorage`,
  GETPOSTFIXMAILUSERS: `${BASE_URL}/api/postfixmailusers`,
  CREATEPOSTFIXMAILUSER: `${BASE_URL}/api/postfixmailusercreate`,
  CREATEPOSTFIXMAILUSERBYEMPLOYEE: `${BASE_URL}/api/postfixmailusercreatebyemployee`,
  QUERYCOMPANYEMAIL: `${BASE_URL}/api/querycompanymail`,
  DYNAMIC_USERACTIVITY_LIVESCREEN_CONTROLLER: `${BASE_URL}/api/dynamicqueryuseractivitylivescreencontroller`,
  LIVE_SCREEN_VIEW: `${BASE_URL}/api/livescreen`,
  LIVE_SCREEN_UPDATE: `${BASE_URL}/api/update-livescreen-status`,
  PRODUCTION_TEMP_LASTDATE: `${BASE_URL}/api/productiontemplastdate`,
  PRODUCTION_ORGINAL_LIMITED_LASTTHREE: `${BASE_URL}/api/productionoriginallastthree`,
  PRODUCTION_TEMP_LIMITED_LASTTHREE: `${BASE_URL}/api/productiontemplastthree`,
  PRODUCTION_DAYS_TEMP_LIMITED: `${BASE_URL}/api/productiondaystemplimited`,
  SELECTED_DATE_PROD_DAY_DELETE: `${BASE_URL}/api/selecteddateproddaylisttempdatedelete`,
  PRODUCTION_TEMP_FILESTATUS: `${BASE_URL}/api/productiontempcheckfilestatusupload`,
  DAYPOINT_DELETE_BYDATE: `${BASE_URL}/api/daypointtempdeletebydate`,
  PROD_DAY_DELETE_BYDATE: `${BASE_URL}/api/proddaydeletebydate`,
  PRODUCTION_DAYS_TEMP_CHECK_FILESTATUS: `${BASE_URL}/api/productiontempcheckfilestatus`,
  PRODUCTION_DAYPOINT_TEMP_LASTDATE: `${BASE_URL}/api/productiondaypointtemplast`,
  PRODUCTIONDAY_TEMP_LASTDATE: `${BASE_URL}/api/productiondaytemplastdate`,
  HIERARCHY_BASED_USER_RESTRICTION: `${BASE_URL}/api/hierarchybaseduserrestriction`,
  HEADER_CONTROLS_FILTER: `${BASE_URL}/api/headercontrolsfilter`,
  OVERALL_REFERENCE_CATEGORY_DELETE: `${BASE_URL}/api/overallreferencecategorybulkdelete`,
  OVERALL_DOCUEMENT_CATEGORY_DELETE: `${BASE_URL}/api/overalldocumentcategorybulkdelete`,
  ALL_ASSIGNDOCUMENT_ACCESSS_BRANCH: `${BASE_URL}/api/allassigndocumentaccessbranch`,
  ALL_CARDPREPARATION_ASSIGNBRANCH: `${BASE_URL}/api/assignbranchidcardpreparation`,
  ALLMANUALALL_TASKFORUSER_ID: `${BASE_URL}/api/allmanualtaskforusersids`,
  USER_TRAINING_DETAILS_EMPNAMES: `${BASE_URL}/api/trainingdetailsempnames`,
  TRAINING_FOR_USER_AUTOGENERATE: `${BASE_URL}/api/trainingforusersautogenerate`,
  TEAMGROUPING_ASSIGNBRANCH: `${BASE_URL}/api/teamgroupingassignbranchs`,
  ALL_TRAINING_USER_RESPONSE_COMPLETED: `${BASE_URL}/api/usertrainingresponsescompleted`,
  USER_TASK_DESIGNATION_EMP_NAMES: `${BASE_URL}/api/userstaskdesigempnames`,
  BULK_ERROR_UPLOADS: `${BASE_URL}/api/bulkerroruploads`,
  BULK_ERROR_UPLOADS_CREATE: `${BASE_URL}/api/bulkerroruploads/new`,
  BULK_ERROR_UPLOADS_SINGLE: `${BASE_URL}/api/bulkerroruploadssingle`,
  MULTIPLE_BULK_ERROR_UPLOAD_SINGLE: `${BASE_URL}/api/multiplebulkerroruploads`,
  BULK_ERROR_UPLOADS_FILTER: `${BASE_URL}/api/multiplebulkerroruploadsfilter`,
  BULK_ERROR_UPLOADS_FILENAME: `${BASE_URL}/api/bulkerroruploadsfilename`,
  BULK_ERROR_UPLOADS_FILENAME_UNIQUE: `${BASE_URL}/api/bulkerroruploadsunique`,
  BULK_ERROR_UPLOADS_FILTER_LIST: `${BASE_URL}/api/bulkerroruploadsfilterlist`,
  MAILCONFIGURATIONALL: `${BASE_URL}/api/mailconfigurationall`,
  MAILCONFIGURATION_CREATE: `${BASE_URL}/api/mailconfiguration/new`,
  MAILCONFIGURATION_SINGLE: `${BASE_URL}/api/mailconfiguration`,
  MEETINGCONFIGURATIONALL: `${BASE_URL}/api/meetingconfigurationall`,
  MEETINGCONFIGURATION_CREATE: `${BASE_URL}/api/meetingconfiguration/new`,
  MEETINGCONFIGURATION_SINGLE: `${BASE_URL}/api/meetingconfiguration`,
  CHECK_DAYPOINT_ORG_BYDATE: `${BASE_URL}/api/checklastdaypointorg`,
  DELETE_DAYPOINT_ORG_BYDATE: `${BASE_URL}/api/daypointdeletebydate`,
  PRODDAY_DELETE_BYDATE: `${BASE_URL}/api/proddaydeletebydateorg`,
  ALL_NOTIFICATION_SOUNDS: ` ${BASE_URL}/api/notificationsounds`,
  CREATE_NOTIFICATION_SOUND: ` ${BASE_URL}/api/notificationsound/new`,
  NOTIFICATION_SOUND_SINGLE: `${BASE_URL}/api/notificationsound`,
  GETFILTEREUSERDATALONGABSEND_COMPLETED: `${BASE_URL}/api/getfilteralluserdatalongabsendcompleted`,
  GETFILTEREUSERDATALONGABSEND_HIRARCHY_COMPLETED: `${BASE_URL}/api/longabsentrestrictionhierarchylistcompleted`,
  ALL_TASK_NONSCHEDULEGROUPING_ACCESSBRANCH: `${BASE_URL}/api/tasknonschedulegroupingsaccessbranch`,
  ALL_TASKDESIGNATIONGROUPING_ASSIGNBRANCH: `${BASE_URL}/api/taskdesignationgroupingsassignbranch`,
  PAYSLIP_DOCUMENT_PREPARATION_ASSIGNBRANCH: `${BASE_URL}/api/payslipdocumentsassignbranch`,
  PENALTYTOTALFIELDUPLOAD: `${BASE_URL}/api/penaltytotalfielduploads`,
  PENALTYTOTALFIELDUPLOAD_DATEFILTER: `${BASE_URL}/api/penaltytotalfielduploaddatefilters`,
  PENALTYTOTALFIELDUPLOAD_CREATE: `${BASE_URL}/api/penaltytotalfieldupload/new`,
  PENALTYTOTALFIELDUPLOAD_SINGLE: `${BASE_URL}/api/penaltytotalfieldupload`,
  MULTIPLEPENALTYTOTALFIELDUPLOAD_SINGLE: `${BASE_URL}/api/multiplepenaltytotalfieldupload`,
  PENALTYTOTALFIELDUPLOAD_LOGIN_PROJECT: `${BASE_URL}/api/penaltytotalfielduploadloginproject`,
  ERRORMODES: `${BASE_URL}/api/errormodes`,
  ERRORMODE_CREATE: `${BASE_URL}/api/errormode/new`,
  ERRORMODE_SINGLE: `${BASE_URL}/api/errormode`,
  ERRORMODE_UNALLOT_LIST: `${BASE_URL}/api/errormodeunallotlist`,
  ERRORMODE_ALLOTED_LIST: `${BASE_URL}/api/errormodeallotedlist`,
  FETCH_FIELDNAME_BYPROCESS: `${BASE_URL}/api/fetchfieldnamebyprocess`,
  GET_ORGIN_DATA: `${BASE_URL}/api/getorgindata`,
  PRODUCTIONPROCESSQUEUE_LIMITED_BYPROJECT: `${BASE_URL}/api/productionprocessqueuelimitedbyproject`,
  VENDORMASTER_LIMITED_NAMEONLY: `${BASE_URL}/api/vendormasterlimitednameonly`,
  DEPARTMENTMONTHSETYEARMONTH: `${BASE_URL}/api/yearmonthdepartmentmonthset`,
  PAYRUNLISTYEARMONTH: `${BASE_URL}/api/yearmonthpayrunlist`,
  PRODUCTION_INDIVIDUAL_DUPECHECK: `${BASE_URL}/api/productionindividualdupecheck`,
  PRODUCTION_INDIVIDUAL_CREATE_BULK: `${BASE_URL}/api/productionindividualcreatebulk`,
  ERROR_UPLOAD_CONFIRM_HIERARCHY: `${BASE_URL}/api/erroruploadconfirmhierarchylist`,
  GET_ALL_ROCKETCHAT_TEAMS: `${BASE_URL}/api/getrocketchatteams`,
  CREATE_ROCKETCHAT_TEAM: `${BASE_URL}/api/createrocketchatteam`,
  UPDATE_ROCKETCHAT_TEAM: `${BASE_URL}/api/updaterocketchatteam`,
  DELETE_ROCKETCHAT_TEAM: `${BASE_URL}/api/deleterocketchatteam`,
  GET_ALL_ROCKETCHAT_CHANNELS: `${BASE_URL}/api/getrocketchatchannels`,
  CREATE_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/createrocketchatchannel`,
  UPDATE_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/updaterocketchatchannel`,
  DELETE_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/deleterocketchatchannel`,
  GET_ALL_ROCKETCHAT_TEAMCHANNELGROUPING: `${BASE_URL}/api/getallrocketchatteamchannelgrouping`,
  CREATE_ROCKETCHAT_TEAMCHANNELGROUPING: `${BASE_URL}/api/createrocketchatteamchannelgrouping`,
  SINGLE_ROCKETCHAT_TEAMCHANNELGROUPING: `${BASE_URL}/api/singlerocketchatteamchannelgrouping`,
  GET_ROCKETCHAT_ROLES: `${BASE_URL}/api/getrocketchatroles`,
  ADD_ROCKETCHAT_GROUPING_INDIVIDUAL: `${BASE_URL}/api/addnewuserinindividual`,
  REMOVE_ROCKETCHAT_GROUPING_INDIVIDUAL: `${BASE_URL}/api/removeuserinindividual`,
  DELETE_ROCKETCHAT_USER: `${BASE_URL}/api/deleterocketchatuser`,
  ACTIVESTATUS_ROCKETCHAT_USER: `${BASE_URL}/api/activestatusrocketchatuser`,
  CREATE_ROCKETCHAT_USER_INEDIT: `${BASE_URL}/api/createrocketchataccountinedit`,
  ROCKETCHAT_MEMBERS_FILTER: `${BASE_URL}/api/filterrocketchatuser`,
  REMOVE_USERFROM_ROCKETCHAT_CHANNEL: `${BASE_URL}/api/removeuserfromchannel`,
  ROCKETCHAT_COMBINED_USERS: `${BASE_URL}/api/getrocketchatandhrmsusers`,
  DELETE_ROCKETCHAT_USER: `${BASE_URL}/api/deleterocketchatuser`,
  UPDATE_ROCKETCHAT_USER_DETAILS: `${BASE_URL}/api/updaterocketchatuserdetails`,
  CREATE_ROCKETCHAT_USER: `${BASE_URL}/api/createrocketchatuser`,
  CHATCONFIGURATIONALL: `${BASE_URL}/api/chatconfigurationall`,
  CHATCONFIGURATION_CREATE: `${BASE_URL}/api/chatconfiguration/new`,
  CHATCONFIGURATION_SINGLE: `${BASE_URL}/api/chatconfiguration`,
  TYPEMASTERDOCUMENT: `${BASE_URL}/api/typemasterdocuments`,
  TYPEMASTERDOCUMENT_CREATE: `${BASE_URL}/api/typemasterdocument/new`,
  TYPEMASTERDOCUMENT_SINGLE: `${BASE_URL}/api/typemasterdocument`,
  CHECK_DUPE_BILL_EB: `${BASE_URL}/api/checkdupebill`,
  CHECK_DUPE_DAILY_EB: `${BASE_URL}/api/checkdupedaily`,
  CHECK_DUPE_MONTH_EB: `${BASE_URL}/api/checkdupemonth`,
  CHECK_DUPE_BILL_BEFORE_EB: `${BASE_URL}/api/checkdupebillbefore`,
  CHECK_DUPE_BILL_EB_EDIT: `${BASE_URL}/api/checkdupebilledit`,
  CHECK_DUPE_DAILY_EB_EDIT: `${BASE_URL}/api/checkdupedailyedit`,
  CHECK_DUPE_MONTH_EB_EDIT: `${BASE_URL}/api/checkdupemonthedit`,
  CHECK_DUPE_BILL_BEFORE_EB_EDIT: `${BASE_URL}/api/checkdupebillbeforeedit`,
  PAYRUNLIST_SINGLE_USER_LASTHREE_MONTHS: `${BASE_URL}/api/payrunlistsingleuserlastthreemonths`,
  CATEGORYPROD_LIMITED_ORIGINAL: `${BASE_URL}/api/categoryprodlimitedoriginal`,
  CATEGORYPROD_LIMITED_TEMP: `${BASE_URL}/api/categoryprodlimitedtemp`,
  CATEGORYPROD_LIMITED_ORIGINAL_FLAGCALC: `${BASE_URL}/api/categoryprodlimitedorgflagcalc`,
  CATEGORYPROD_LIMITED_TEMP_FLAGCALC: `${BASE_URL}/api/categoryprodlimitedtempflagcalc`,
  PRODUCTION_INDIVIDUAL_EXCEL: `${BASE_URL}/api/productionindividualexcel`,
  PRODUCTION_INDIVIDUAL_OVEALL_EXCEL: `${BASE_URL}/api/productionindividualoveallexcel`,
  PRODUCTION_INDIVIDUAL_OVEALL_EXCEL_PENDING: `${BASE_URL}/api/productionindividualoveallexcelpending`,
  MY_PRODUCTION_INDIVIDUAL_FILTERED: `${BASE_URL}/api/myproductionindividual`,
  PROCESS_LIMITED_BY_COMPANY_BRANCH: `${BASE_URL}/api/processlimitedbycompanybranch`,
  SUBCATEGORY_OVERALL_CHECK_DELETE: `${BASE_URL}/api/subcategoryoverallcheckdelete`,
  SUBCATEGORY_OVERALL_CHECK_BULKDELETE: `${BASE_URL}/api/subcategoryoverallcheckdeletebulk`,
  SUBCATEGORYPROD_OVERALL_EDIT: `${BASE_URL}/api/subcategoryprodoveralledit`,
  SUBCATEGORYPROD_OVERALL_EDIT_BULKUPDATE: `${BASE_URL}/api/subcategoryprodoveralleditbulkupdate`,
  SCREENSAVERCREATION: `${BASE_URL}/api/creatingscreensaver`,
  SCREENSAVERPROGRESS: `${BASE_URL}/api/getprogresscount`,
  CATEGORY_OVERALL_CHECK_DELETE: `${BASE_URL}/api/categoryoverallcheckdelete`,
  CATEGORY_OVERALL_CHECK_BULKDELETE: `${BASE_URL}/api/categoryoverallcheckdeletebulk`,
  CATEGORYPROD_OVERALL_EDIT: `${BASE_URL}/api/categoryprodoveralledit`,
  CATEGORYPROD_OVERALL_EDIT_BULKUPDATE: `${BASE_URL}/api/categoryprodoveralleditbulkupdate`,
  TARGET_POINTS_FILTERED: `${BASE_URL}/api/targetpointsfiltered`,
  TARGETPOINTS_ALLLIMITED: `${BASE_URL}/api/targetpointsalllimited`,
  BRANCH_LIMITED: `${BASE_URL}/api/branchlimited`,
  UNIT_LIMITED: `${BASE_URL}/api/unitslimited`,
  CATEGORY_OVERALL_NONLINK_BULKDELETE: `${BASE_URL}/api/categoryoverallnonlinkbulkdelete`,
  SUBCATEGORY_OVERALL_NONLINK_BULKDELETE: `${BASE_URL}/api/subcategoryoverallnonlinkbulkdelete`,
  CHECK_VPN_DETAILS: `${BASE_URL}/api/checkuservpndetails`,
  GET_MIKROTIK_SECRETS_LOCAL: `${BASE_URL}/api/getmikrotiksecretslocal`,
  GET_MIKROTIK_SECRETS_LOCAL_FILTER: `${BASE_URL}/api/getmikrotiksecretslocalfilter`,
  PENALTY_TOTAL_FIELD_VALIDATION_ENTRY_FILTER: `${BASE_URL}/api/penaltytotalfielduploadsvalidation`,
  VALIDATION_ERROR_FILTER: `${BASE_URL}/api/validationerrorfilters`,
  ERROR_TYPE_FILTER: `${BASE_URL}/api/errortypefilter`,
  ERROR_REASON_FILTER: `${BASE_URL}/api/penaltyerrorreasonfilter`,
  ERROR_REASON_FILTER_STATUS: `${BASE_URL}/api/errortypefilterstatus`,
  ERROR_MODE_FILTER: `${BASE_URL}/api/errormodefilter`,
  UNITRATE_MANUAL_APPROVALS: `${BASE_URL}/api/unitratemanualapprovals`,
  UNITRATE_MANUAL_APPROVAL_CREATE: `${BASE_URL}/api/unitratemanualapproval/new`,
  UNITRATE_MANUAL_APPROVAL_SINGLE: `${BASE_URL}/api/unitratemanualapproval`,
  UNITRATE_MANUAL_MRATE_UPDATE: `${BASE_URL}/api/unitratemanualmrateupdate`,
  UNITRATE_MANUAL_NOTAPPROVALS: `${BASE_URL}/api/unitratemanualnotapprovals`,
  INVALID_ERROR_ENTRY_HIERARCHY: `${BASE_URL}/api/invaliderrorentryhierarchy`,
  VALIDATE_ERROR_ENTRY_HIERARCHY: `${BASE_URL}/api/validaterrorentryhierarchy`,
  ASSIGN_INTERVIEWER_VISITOR: `${BASE_URL}/api/assigninterviewervisitor`,
  TEAMWISEUSERS: `${BASE_URL}/api/getallteamwiseusers`,
  TARGETPOINTS_FILTER: `${BASE_URL}/api/targetpointsfilters`,
  TARGETPOINTEXCELFILEUPLOADSTORE: `${BASE_URL}/api/uploadfile`,
  MODULEFILTERREPORTINGTOHEADER: `${BASE_URL}/api/modulereportingtoheaerfilter`,
  REVENUEAMOUNTSEXCELFILEUPLOADSTORE: `${BASE_URL}/api/revenueuploadfile`,
  REVENUEAMOUNTS_FILTER: `${BASE_URL}/api/revenueamountsfilters`,
  TYPEFILTERDOCUMENT: `${BASE_URL}/api/typefilterdocuments`,
  ERAAMOUNTSEXCELFILEUPLOADSTORE: `${BASE_URL}/api/eraamountuploadfile`,
  ERAAMOUNTS_PAGINATED_DEFAULT: `${BASE_URL}/api/eraamountsortdefault`,
  PRODUCTION_INDIVIDUAL_FILTER_LIST: `${BASE_URL}/api/productionindividuallistfilter`,
  CLIENTUSERID_LIMITED_BYCOMPNYNAME_MULTI: `${BASE_URL}/api/clientuseridlimitedtimestudybycompanynamemulti`,
  PENALTYWAIVERMASTER: `${BASE_URL}/api/penaltywaivermasters`,
  PENALTYWAIVERMASTER_CREATE: `${BASE_URL}/api/penaltywaivermaster/new`,
  PENALTYWAIVERMASTER_SINGLE: `${BASE_URL}/api/penaltywaivermaster`,
  MANAGE_STOCK_ITEMS_PAGINATION: `${BASE_URL}/api/managestockitemspagination`,
  MANAGE_STOCK_ACCESS_PAGINATION: `${BASE_URL}/api/stockmanagesaccessstock`,
  STOCK_ACCESS_PAGINATION: `${BASE_URL}/api/stocksaccessstock`,
  FILTERED_ASSETMATERIAL_IP: `${BASE_URL}/api/assetmaterialipfilter`,
  FILTERED_WOKRSTATIONGROUPING: `${BASE_URL}/api/assetworkgrpslist`,
  ASSET_DATA_FILTER_ACCESS_OLD: `${BASE_URL}/api/assetdetailsfilteraccessold`,
  CHEKCK_MANAGER_APPROVE_PENALTYTOTAL: `${BASE_URL}/api/checkmanager`,
  DYNAMICBIOMETRICUSERDETAILS: `${BASE_URL}/api/biometricalldata`,
  REMOVEEXISTINGEMPS: `${BASE_URL}/api/removeemployeenamefromchecklistdatas`,
  SALARYSLABPROCESSQUEUE: `${BASE_URL}/api/salaryslabprocessqueue`,
  USER_FOR_ALL_ATTENDANCE_PAGE_PROD_DAYPOINT: `${BASE_URL}/api/userforallattendancefilterproddaypoint`,
  USER_CLOCKIN_CLOCKOUT_STATUS_FILTER_PROD_DAYPOINT: `${BASE_URL}/api/userclockinclockoutstatusfilterproddaypoint`,
  SHEETNAMEALL: `${BASE_URL}/api/sheetname`,
  SHEETNAME_CREATE: `${BASE_URL}/api/sheetname/new`,
  SHEETNAME_SINGLE: `${BASE_URL}/api/sheetname`,
  GET_ATTENDANCE_CONTROL_CRITERIA_LAST_INDEX_PAYAMOUNT: `${BASE_URL}/api/allattendancecontrolcriterialastindexpayamount`,
  INTERNCODE_AUTOGENERATE: `${BASE_URL}/api/interncodeautogenerate`,
  UPDATE_LOGINALLOT_MOVETOLIVE: `${BASE_URL}/api/updateloginallotdetailsmovetolive`,
  GETFILTEREMOTEUSER: `${BASE_URL}/api/getfilterremoteuser`,
  GETFILTEREMOTE_INDIVIDUALUSER: `${BASE_URL}/api/getfilterremoteindividualuser`,
  GETFILTERED_REMOTE_HIERARCHY_LIST: `${BASE_URL}/api/getfilteredhierarchyremotelist`,
  PRODUCTION_TYPEMASTER: `${BASE_URL}/api/productiontypemasters`,
  PRODUCTION_TYPEMASTER_CREATE: `${BASE_URL}/api/productiontypemaster/new`,
  PRODUCTION_TYPEMASTER_SINGLE: `${BASE_URL}/api/productiontypemaster`,
  PRODUCTION_QUEUETYPEMASTER: `${BASE_URL}/api/productionqueuequeuetypemasters`,
  PRODUCTION_QUEUETYPEMASTER_UNITRATE: `${BASE_URL}/api/productionqueuemasterunitrate`,
  PRODUCTION_QUEUETYPEMASTER_CREATE: `${BASE_URL}/api/productionqueuetypemaster/new`,
  PRODUCTION_QUEUETYPEMASTER_SINGLE: `${BASE_URL}/api/productionqueuetypemaster`,
  ORATE_VALUE_BY_QUEUEMASTER: `${BASE_URL}/api/orratevaluequeuemaster`,
  UPDATE_LOGINALLOT_LOGPAGES: `${BASE_URL}/api/updateloginallotdetailsemployeelog`,
  PRODUCTION_QUEUETYPEMASTER_DUPLICATE: `${BASE_URL}/api/queuetypemasterduplicate`,
  BIOMETRICUSERSALL: `${BASE_URL}/api/biometricusersall`,
  USER_FOR_ALL_HOLIDAY_WEEKOFF_FILTER: `${BASE_URL}/api/userforallholidayweekofffilter`,
  OTHER_TASK_CONSOLIDATED_REPORT: `${BASE_URL}/api/othertaskconsolidatedreport`,
  OTHER_TASK_INDIVIDUAL_REPORT: `${BASE_URL}/api/othertaskindividualreport`,
  CHECK_PAYRUN_GENERATED: `${BASE_URL}/api/checkispayrungenerated`,
  CHECK_PAYRUN_ISCREATED_FOR_ATTENDANCE: `${BASE_URL}/api/checkpayruniscreatedforattendance`,
  MERGE_ROCKETCHAT_ACCOUNT: `${BASE_URL}/api/mergerocketchatuserdetails`,
  ROCKETCHAT_UNASSIGNED_USERS: `${BASE_URL}/api/getrocketchatunassignedusers`,
  UNITRATE_PRODUCTION_ORATE_CATEGORY: `${BASE_URL}/api/unitrateoratecategory`,
  UNITRATE_PRODUCTION_ORATE_SUBCATEGORY: `${BASE_URL}/api/unitsrateoratesubcategory`,
  CREATE_HOLIDAYWEEKOFF_RESTRICTION: `${BASE_URL}/api/holidayWeekoffrestriction/new`,
  SINGLE_HOLIDAYWEEKOFF_RESTRICTION: `${BASE_URL}/api/holidayWeekoffrestriction`,
  ALL_HOLIDAYWEEKOFF_RESTRICTIONS: `${BASE_URL}/api/allholidayWeekoffrestrictions`,
  DELETE_HOLIDAYWEEKOFF_RESTRICTION: `${BASE_URL}/api/deleteholidayweekoffrestriction`,
  USERS_HOLIDAYWEEEKOFF_STATUS: `${BASE_URL}/api/usersholidayweekofstatus`,
  ATTENDANCE_CHECKLIST_UNDO: `${BASE_URL}/api/undoattendancestatusfromchecklist`,
  CLIENTERRORMONTHAMOUNT_WAIVER: `${BASE_URL}/api/clienterrorwaiver`,
  CLIENT_ERROR_FORWARD_HIERARCHY: `${BASE_URL}/api/clienterrorforwardhierarchy`,
  CLIENT_ERROR_WAIVER_APPROVAL_HIERARCHY_WITH_DATE: `${BASE_URL}/api/clienterrorwaiverapprovalhierarchywithdate`,
  FILTEREDACHIEVEDACCURACY: `${BASE_URL}/api/filteredacheivedaccuracy`,
  PRODUCTIONUPLOAD_QUEUE_TYPE_MASTER: `${BASE_URL}/api/productionuploadqueuemasterfilter`,
  QUEUE_TYPE_MASTER_CATEGORY_WISE_TYPE: `${BASE_URL}/api/queuetypecategorywisetype`,
  QUEUE_TYPE_MASTER_SUBCATEGORY_WISE_TYPE: `${BASE_URL}/api/queuetypesubcategorywisetype`,
  GET_DAY_POINTS_BY_DATE: `${BASE_URL}/api/getdaypointsdate`,
  GET_DAY_POINTS_TEMP_BY_DATE: `${BASE_URL}/api/daypointstempbydate`,
  PAY_RUN_LIST_CONSOLIDATED_DATE: `${BASE_URL}/api/payrunlistsconsolidateddate`,
  PAY_RUN_LIST_CONSOLIDATED_DATE_TEMP: `${BASE_URL}/api/payrunlistsconsolidateddatetemp`,
  GET_WEEOFF_DAYS_FORUSER_EMPLOYEEPOINTS: `${BASE_URL}/api/getuserweekoffdaysemployeepoints`,
  APPLYWORKFROMHOME: `${BASE_URL}/api/applyworkfromhomes`,
  ACTIVEAPPLYWORKFROMHOME: `${BASE_URL}/api/activeuserapplyworkfromhomes`,
  APPLYWORKFROMHOME_CREATE: `${BASE_URL}/api/applyworkfromhome/new`,
  APPLYWORKFROMHOME_SINGLE: `${BASE_URL}/api/applyworkfromhome`,
  APPLYWORKFROMHOME_APPROVED: `${BASE_URL}/api/applyworkfromhomesapproved`,
  APPLYWORKFROMHOME_APPROVEDASSIGNBRANCH: `${BASE_URL}/api/applyworkfromhomesapprovedassignbranch`,
  CATEGORY_PROD_LIMITED_QUEUE_TYPE_MASTER: `${BASE_URL}/api/categoryprodlimitedproductionqueuetypemaster`,
  QUEUE_TYPE_VENDOR_DROP: `${BASE_URL}/api/queuetypevendordrop`,
  QUEUE_TYPE_CATEGORY_DROP: `${BASE_URL}/api/queuetypecategorydrop`,
  QUEUE_TYPE_TYPE_DROP: `${BASE_URL}/api/queuetypetypedrop`,
  UPDATEDOMAINMAILUSERPASSWORD: `${BASE_URL}/api/updatepassworddomainmail`,
  CHECK_NEW_JOINER: `${BASE_URL}/api/checknewjoiner`,
  TASK_FOR_USER_TRIGGERED: `${BASE_URL}/api/tasktriggeredcheck`,
  PAYSLIPEMAILDATAS: `${BASE_URL}/api/payslipemaildatas`,
  ASSIGNWORKFROMHOMES: `${BASE_URL}/api/assignworkfromhomes`,
  ASSIGNWORKFROMHOME_CREATE: `${BASE_URL}/api/assignworkfromhome/new`,
  ASSIGNWORKFROMHOME_SINGLE: `${BASE_URL}/api/assignworkfromhome`,
  PENALTYTOTALFIELDUPLOAD_INVALID_REJECTED: `${BASE_URL}/api/penaltytotaluploadinvalidreject`,
  CHECK_PAYRUN_ISCREATED_FOR_PENALTYDAYUPLOAD: `${BASE_URL}/api/checkpayruniscreatedforpenaltydayupload`,
  PAYRUN_BULKUNDO: `${BASE_URL}/api/payrunbulkundo`,
  PAYRUN_BULK_UPDATE_BYFILEUPLOAD: `${BASE_URL}/api/payrunbulkupdateusingfileupload`,
  USER_FOR_ALL_ATTENDANCE_PAGE_INDIVIDUAL_TYPE: `${BASE_URL}/api/userforallattendancefilterindividualtype`,
  PRODUCTIONUPLOAD_QUEUE_TYPE_MASTER_COUNT: `${BASE_URL}/api/productionuploadqueuemasterfiltercount`,
  QUEUE_TYPE_MASTER_VENDOR_MASTER: `${BASE_URL}/api/queuetypevendormasterdrop`,
  ACTIVEPERMISSIONS_HIERARCHYBASED: `${BASE_URL}/api/activeuserpermissions_hierarchybased`,
  PERMISSIONS_FILTERED_HIERARCHY_PAGE_BASED: `${BASE_URL}/api/activeuserpermissions_hierarchybased_page`,
  ACTIVEAPPLYLEAVE_HIERARCHYBASED: `${BASE_URL}/api/activeuserleaves_hierarchybased`,
  APPLYLEAVE_FILTERED_HIERARCHY_PAGE_BASED: `${BASE_URL}/api/activeuserleaves_hierarchybased_page`,
  PRODUCTION_UPLOAD_GET_SINGLEDATE_DATA_PRODUCTION_DAY_ALLBATCH: `${BASE_URL}/api/productiondaygetsingledatedatadayallbatch`,
  //Other Task upload List
  PRODUCTION_UPLOAD_OTHER: `${BASE_URL}/api/productionuploadsother`,
  PRODUCTION_UPLOAD_CREATE_OTHER: `${BASE_URL}/api/productionuploadother/new`,
  PRODUCTION_UPLOAD_SINGLE_OTHER: `${BASE_URL}/api/productionuploadother`,
  PRODUCTION_UPLOAD_FILENAMELIST_OTHER: `${BASE_URL}/api/productionuploadfilenamelistother`,
  PRODUCTION_UPLOAD_FILENAMEONLY_OTHER: `${BASE_URL}/api/productionuploadfilenameonlyother`,
  PRODUCTION_UPLOAD_GETDELETEDATAS_OTHER: `${BASE_URL}/api/productionuploadgetdeletedatasother`,
  PRODUCTION_UPLOAD_GETDELETEDATASALL_OTHER: `${BASE_URL}/api/productionuploadgetdeletedatasallother`,
  PRODUCTION_UPLOAD_DELETEMULTI_OTHER: `${BASE_URL}/api/productionuploaddeletemultiother`,
  PRODUCTION_UPLOAD_OVERALL_FETCH_LIMITED_OTHER: `${BASE_URL}/api/productionuploadoverallfetchlimitedother`,
  GET_PRODUCTION_SINGLE_DAYUSER_OTHER: `${BASE_URL}/api/getproductionsignledayuserother`,
  CHECK_ZERO_MISMATCH_PRESENT_OTHER: `${BASE_URL}/api/checkzeromismatchpresentother`,
  PRODUCTION_UPLOAD_GET_UNITRATEUPDATE_OVERALL_FETCH_LIMITED_OTHER: `${BASE_URL}/api/productionuploadunitrateoverallfetchlimitedother`,
  PRODUCTION_UNALLOT_FILTER_OTHER: `${BASE_URL}/api/productionunallotfilterother`,
  PRODUCTION_UNALLOT_FILTER_VIEW_OTHER: `${BASE_URL}/api/productionunallotfilterviewother`,
  PRODUCTION_UNALLOT_FILTER_VIEW_Manual_OTHER: `${BASE_URL}/api/productionunallotfilterviewmanualother`,
  GET_MISMATCH_UPDATEDLIST_OTHER: `${BASE_URL}/api/getmismatchupdatedlistother`,
  UPDATE_UNDO_FIELDNAME_OTHER: `${BASE_URL}/api/updatefieldundonameother`,
  PRODUCTION_UPLOAD_GETDATAS_BYID_OTHER: `${BASE_URL}/api/getproductionuploaddatasbyidother`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDFLAG_OTHER: `${BASE_URL}/api/updatedbulkdatasunitandflagother`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITONLY_OTHER: `${BASE_URL}/api/updatedbulkdatasunitonlyother`,
  PRODUCTION_UPLOAD_BULKUPDATE_FLAGONLY_OTHER: `${BASE_URL}/api/updatedbulkdatasflagonlyother`,
  PRODUCTION_UPLOAD_BULKUPDATE_UNITANDSECTION_OTHER: `${BASE_URL}/api/updatedbulkdatasunitandsectionother`,
  PRODUCTION_ORIGINAL_UNITRATE_BULK_UPDATECATSUBCATEGORY_OTHER: `${BASE_URL}/api/bulkproductionorgupdatecategorysubcategoryother`,
  BULK_DELETE_UNITRATE_UNALLOT_OTHER: `${BASE_URL}/api/bulkdeleteunitrateunallotother`,
  PRODUCTION_UPLOAD_FILENAMEONLY_BULKDOWNLOAD_OTHER: `${BASE_URL}/api/productionuploadfilenameonlybulkdownloadother`,
  PRODUCTION_ORGINAL_OTHER_OTHER: `${BASE_URL}/api/othertaskuploads`,
  PRODUCTION_ORGINAL_LIMITED_OTHER: `${BASE_URL}/api/othertaskuploadslimited`,
  PRODUCTION_ORGINAL_UNIQID_OTHER: `${BASE_URL}/api/othertaskuploadslimiteduniqid`,
  PRODUCTION_ORGINAL_CREATE_OTHER: `${BASE_URL}/api/othertaskupload/new`,
  PRODUCTION_ORGINAL_SINGLE_OTHER: `${BASE_URL}/api/othertaskupload`,
  GET_UNIQID_FROM_DATE_PRODUPLOAD_OTHER: `${BASE_URL}/api/getuniqidfromdateproduploadother`,
  PRODUCTION_ORGINAL_LIMITED_LASTTHREE_OTHER: `${BASE_URL}/api/othertaskuploadlastthree`,
  PRODUCTION_ORGINAL_LIMITED_FILTER_OTHER: `${BASE_URL}/api/othertaskuploadslimitedfilter`,
  PRODUCTION_UPLOAD_OVERALL_FETCH_LIMITEDNEW_OTHER: `${BASE_URL}/api/productionuploadoverallfetchlimitednewother`,
  PRODUCTION_UPLOAD_CHECKSTATUS_OTHER: `${BASE_URL}/api/productionuploadcheckstatusother`,
  EXCELFILEUPLOADSTORE_OTHERTASK: `${BASE_URL}/api/uploadothertask`,
  ALL_PROCESSQUEUENAME_UNASSIGNED_REPORT: `${BASE_URL}/api/processqueuenamesunassignedreports`,
  UNASSIGNED_REPORT_LIST: `${BASE_URL}/api/unassignedreportlist`,
  REMOVEOVERALLEMPLOYEENAME: `${BASE_URL}/api/employeenameremoval`,
  ATTENDANCE_CHECKLIST_UNDO: `${BASE_URL}/api/undoattendancestatusfromchecklist`,
  ATTENDANCE_BULK_UPDATE: `${BASE_URL}/api/attendancebulkupdate`,
  PRODUCTION_TEMP_FLAG_UPDATE_BULK_INUPLOAD: `${BASE_URL}/api/productiontempflagupdatebulkinupload`,
  CHECK_FINAL_VENDOR_CREATED_TEMP: `${BASE_URL}/api/productiontempfinalvendorcreated`,

  //PPT Category And SubCategory
  PPTCATEGORYSUBCATEGORY: `${BASE_URL}/api/pptcategory&subcategory`,
  PPTCATEGORYSUBCATEGORY_CREATE: `${BASE_URL}/api/pptcategory&subcategory/new`,
  PPTCATEGORYSUBCATEGORY_SINGLE: `${BASE_URL}/api/pptcategory&subcategory`,
  PPTCATEGORYSUBCATEGORY_AUTOID: `${BASE_URL}/api/pptcategory&subcategoryautoid`,

  //PPT Category And SubCategory
  POWERPOINT: `${BASE_URL}/api/powerpoint`,
  POWERPOINT_CREATE: `${BASE_URL}/api/powerpoint/new`,
  POWERPOINT_SINGLE: `${BASE_URL}/api/powerpoint`,

  //Maintance Details Master
  MAINTENANCEDETAILSMASTER: `${BASE_URL}/api/maintenancedetailsmaster`,
  MAINTENANCEDETAILSMASTER_GETDATA: `${BASE_URL}/api/maintenancedetailsmastergetdata`,
  MAINTENANCEDETAILSMASTER_CREATE: `${BASE_URL}/api/maintenancedetailsmaster/new`,
  MAINTENANCEDETAILSMASTER_SINGLE: `${BASE_URL}/api/maintenancedetailsmaster`,
  MAINTENANCEDETAILSMASTER_SINGLE_GROUP: `${BASE_URL}/api/maintenancedetailsmastergroup`,
  MAINTENANCEDETAILSMASTER_SINGLE_GROUP_DELETE: `${BASE_URL}/api/maintenancedetailsmastergroupdelete`,
  ASSET_MATCHED_SUBCOMPONENT: `${BASE_URL}/api/matchedassetsubcomponent`,
  INDIVIDUAL_EMPLOYEE_ASSET: `${BASE_URL}/api/individualemployeeassets`,  
  PAYRUNLIST_LIMITED_FILTERED_LOSSPAYRUN: `${BASE_URL}/api/payrunlistlimitedfilteredlosspayrun`,
  ASSET_DISTRIBUTION_GROUPED_DATAS: `${BASE_URL}/api/assetdistributiongroupeddatas`,
  ASSET_DISTRIBUTION_LOG_DATAS: `${BASE_URL}/api/assetdistributionlogdatas`,
  GET_PENALTYDAYUPLOAD_FILTER_LIST: `${BASE_URL}/api/penaltydayuploadsfilterlist`,
  GET_PENALTYDAYUPLOAD_FILTER: `${BASE_URL}/api/penaltydayuploadsfilter`,

  ASSET_DISTRIBUTION_STATUS: `${BASE_URL}/api/markassetdistributedstatus`,
  TEAM_ASSET_DISTRIBUTION_LIST: `${BASE_URL}/api/teamemployeeassets`,
  FILTER_DISTRIBUTION_LIST: `${BASE_URL}/api/assetdistributiondetailsfilter`,
  //EMPLOYEE ASSET RETURN REGISTER
  EMPLOYEEASSETRETURNREGISTER: `${BASE_URL}/api/allemployeeassetreturn`,
  EMPLOYEEASSETRETURNREGISTER_CREATE: `${BASE_URL}/api/employeeassetreturn/new`,
  EMPLOYEEASSETRETURNREGISTER_SINGLE: `${BASE_URL}/api/singleemployeeassetreturn`,
  PRODUCTION_ORIGINAL_SUMMARY_REPORT: `${BASE_URL}/api/productionuploadoriginalsummayreport`,
  PRODUCTION_UPLOAD_GET_SINGLEDATE_DATA_PRODUCTION_DAY_TEMP_ALLBATCH: `${BASE_URL}/api/productiondaygetsingledatedatadayallbatchtemp`,
};
